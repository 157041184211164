<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <div *ngIf="!context.isLabelHovered">
        <table class="lg-chart__tooltip__table" *ngIf="!_tooltipIsComparing">
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ context.name }} -
                    {{ mainLabel }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Max ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Maximum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.max) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.UpperQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Upper_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.q3) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Median ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Median" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.median) }}
                </td>
            </tr>
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Average" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.average) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.LowerQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Lower_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.q1) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Min ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Minimum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.min) }}
                </td>
            </tr>
        </table>

        <table class="lg-chart__tooltip__table" *ngIf="_tooltipIsComparing">
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ context.name }} -
                    {{ comparingAgainst }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Max ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Maximum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.max) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.UpperQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Upper_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.q3) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Median ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Median" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.median) }}
                </td>
            </tr>
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Average" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.average) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.LowerQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Lower_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.q1) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Min ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Minimum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.comparing.min) }}
                </td>
            </tr>
        </table>
    </div>
    <div *ngIf="context.isLabelHovered">
        <table class="lg-chart__tooltip__table">
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ context.name }}
                </td>
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ mainLabel }}
                </td>
                <td
                    class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name"
                    *ngIf="comparingAgainst"
                >
                    {{ comparingAgainst }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Max ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Maximum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.max) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.max) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.UpperQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Upper_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.q3) }}
                </td>

                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.q3) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Median ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Median" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.median) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.median) }}
                </td>
            </tr>
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Average" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.average) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.average) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="
                    context.highlighted === Highlighted.LowerQuartile ? 'bold' : 'normal'
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Lower_quartile" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.q1) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.q1) }}
                </td>
            </tr>
            <tr
                class="lg-chart__tooltip__table__item"
                [style.font-weight]="context.highlighted === Highlighted.Min ? 'bold' : 'normal'"
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ ".Minimum" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _tooltipFormatter.format(context.main.min) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column" *ngIf="comparingAgainst">
                    {{ _tooltipFormatter.format(context.comparing.min) }}
                </td>
            </tr>
        </table>
    </div>
</ng-template>

<div #chartWithLegend [style.height.px]="height" [style.width.px]="width">
    <div
        class="lg-boxplot-vertical-chart__wrapper"
        [class.lg-boxplot-vertical-chart__wrapper--flex-row]="legendOptions.position === 'right'"
        [class.lg-boxplot-vertical-chart__wrapper--flex-column]="
            legendOptions.position === 'bottom'
        "
        style="width: 100%; height: 100%"
    >
        <div
            #chartHolder
            [style.height.px]="legendOptions.visible ? _svgHeight + _margin.top : _svgHeight"
            [style.marginTop.px]="_margin.top"
            [style.marginLeft.px]="_margin.left"
        ></div>

        <lg-chart-legend
            *ngIf="legendOptions.visible"
            [className]="legendOptions.className"
            [definition]="_legendDefinition"
            [position]="legendOptions.position"
            [cropLongNames]="legendOptions.cropLongNames"
            [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
            [style.paddingBottom.px]="_legendPaddingBottom"
            [width]="_legendWidth"
            (onClick)="_onLegendItemClick($event)"
        >
        </lg-chart-legend>
    </div>
</div>
