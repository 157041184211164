<div
    #chartWithLegend
    class="lg-chart-stacked-bar__wrapper lg-chart-small-text"
    [class.lg-chart-stacked-bar__wrapper--flex-row]="legendOptions.position === 'right'"
    [class.lg-chart-stacked-bar__wrapper--flex-column]="legendOptions.position === 'bottom'"
    [style.height.px]="height"
    [style.width.px]="width"
>
    <div #chart></div>

    <lg-chart-legend
        *ngIf="legendOptions.visible"
        [className]="legendOptions.className"
        [definition]="_legendDefinition"
        [position]="legendOptions.position"
        [cropLongNames]="legendOptions.cropLongNames"
        [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
        [style.paddingBottom.px]="_legendPaddingBottom"
        [width]="_legendWidth"
        (onClick)="_onLegendItemClick($event)"
    >
    </lg-chart-legend>
</div>

<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <ng-container *ngIf="!$any(context).data.item">
        <b>{{ $any(context).data[0].columnName }}</b>
        <br /><br />
        <div *ngFor="let i of $any(context).data">
            {{ i.item[barIdProperty] }}:
            {{ this._numberFormat(i.value) }}
        </div>
    </ng-container>

    <ng-container *ngIf="$any(context).data.item">
        <b>{{ $any(context).data.columnName }}</b>
        <br /><br />
        {{ $any(context).data.item[barIdProperty] }}:
        {{ this._numberFormat($any(context).data.value) }}
    </ng-container>
</ng-template>
