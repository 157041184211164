import ldSortBy from "lodash-es/sortBy";
import ldUniqBy from "lodash-es/uniqBy";

/* eslint-disable @typescript-eslint/no-this-alias */
import { coerceNumberProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { LgSimpleChanges } from "@logex/framework/types";
import { BaseChartComponent } from "../shared/base-chart.component";
import {
    IStackedBarChartColumn,
    IStackedBarChartGroup,
    IStackedBarChartItem,
    IStackedBarChartTooltipContext
} from "./stacked-bar-chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { CHART_SEPARATOR_SIZE, LegendItem, LegendOptions, Margin } from "../shared/chart.types";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import { LgColorPalette } from "../shared/lg-color-palette";
import { getLegendWidth } from "../shared/getLegendWidth";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";

const MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const SPACE_BETWEEN_Y_LABELS_AND_GRID = 8;
const DECIMAL_FOR_WHITE_COLOR = 16777215;
const SPACE_FOR_LEGEND_BELOW = 30;
const SPACE_FOR_Y_AXIS_TITLE = 26;

@Component({
    selector: "lg-stacked-bar-chart",
    templateUrl: "./lg-stacked-bar-chart.component.html"
})
export class LgStackedBarChartComponent
    extends BaseChartComponent<IStackedBarChartColumn, IStackedBarChartTooltipContext>
    implements OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _legacyColorPalette = inject(LgColorPalette);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);

    /**
     * @requires
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (locals: any) => string;

    /**
     * @optional
     * Callback for providing opacity of group bar column. Valid returned value is number from 0 to 1.
     */
    @Input() columnOpacity?: (locals: any) => number;

    /**
     * @optional
     * Specifies property name of data to get groups data from.
     *
     * @default "groups"
     */
    @Input() groupsProperty?: string;

    /**
     * @optional
     * Specifies property name of data to sort groups by.
     *
     * @default "sorting"
     */
    @Input() groupsOrderByProperty?: string;

    /**
     * @optional
     * Specifies property name of data to get bar id from.
     *
     * @default "barId"
     */
    @Input() barIdProperty?: string;

    /**
     * @optional
     * Specifies property name of data to get bars data from.
     *
     * @default "bars"
     */
    @Input() barsProperty?: string;

    /**
     * @optional
     * Specifies property name of data to sort bars by.
     */
    @Input() barsOrderByProperty?: string;

    /**
     * @requires
     * Specifies property name of data group to get value from.
     */
    @Input({ required: true }) barValueProperty!: string;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() barColor?: (locals: any) => any[];

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() barColorProperty?: string;

    /**
     * @optional
     * Callback for providing opacity of specific bar. Valid returned value is number from 0 to 1.
     */
    @Input() barOpacity?: (locals: any) => number;

    /**
     * @optional
     * Specifies property name of data to define highlight bar with type "kind".
     * Required if `highlight` input is "kind"
     */
    @Input() barKindProperty?: string;

    /**
     * @optional
     * Specifies highlight type.
     *
     * @type {"bar" | "group" | "kind"}
     *
     * @default "bar"
     */
    @Input() highlight?: "bar" | "group" | "kind";

    /**
     * @optional
     * Specifies the legend options. Legend is visible by default.
     */
    @Input() legendOptions?: LegendOptions = getDefaultLegendOptions({
        visible: true
    });

    /**
     * Specifies the Y-axis title. If not specified then there is no Y-axis title.
     */
    @Input() yAxisLabel?: string;

    /**
     * Specifies the X-axis title. If not specified then there is no Y-axis title.
     */
    @Input() xAxisLabel: string;

    /**
     * Specifies padding of X-axis labels.
     *
     * @default 0
     */
    @Input() xAxisLabelsPadding = 0;

    /**
     * @optional
     * Specifies maximum number of ticks on axis. Defaults to 6.
     *
     * @default 6
     */
    @Input() tickCount?: number;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColors: string | string[];

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration?: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies whether the tooltip is visible when hovering over X axis tick mark.
     * Optional parameter with default value false.
     */
    @Input() showTickTooltip = false;

    @ViewChild("chart", { static: true }) private _chartDivRef: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef: ElementRef<HTMLDivElement>;

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick: EventEmitter<LegendItem>;

    _legendDefinition: LegendItem[];
    _legendWidth: number;
    _legendPaddingBottom: number;

    private _groupColors: d3.ScaleOrdinal<string, string>;

    private _columnNames: string[];
    private _bars: any[];
    private _yMax: number = null;
    private _groupsCount: number = null;
    private _xScale: d3.ScaleBand<any>;
    private _xGroupScale: d3.ScaleBand<any>;
    private _yScale: d3.ScaleLinear<number, number>;
    private _yAxisGridGroup: d3.Selection<any, any, any, any>;
    private _xAxisG: d3.Selection<any, any, any, any>;
    private _yAxisG: d3.Selection<any, any, any, any>;
    private _yAxisLabelG: d3.Selection<any, any, any, any>;
    private _xAxisLabelG: d3.Selection<any, any, any, any>;

    private _spaceForYAxisLabels = 0;
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener: () => void;

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);
        return Math.max(0, this.width - (legendOnTheRight ? legendSize : 0));
    }

    private get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);
        return Math.max(0, this.height - (legendBelow ? legendSize : 0));
    }

    private get _horizontalPositionOfYAxis(): number {
        return (
            this._margin.left +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_Y_LABELS_AND_GRID +
            SPACE_FOR_Y_AXIS_TITLE
        );
    }

    constructor() {
        super();
        this.legendClick = new EventEmitter(null);
        this._margin = MARGIN;
        this._trackMousePosition();
    }

    ngOnChanges(changes: LgSimpleChanges<LgStackedBarChartComponent>): void {
        if (!this._initialized) {
            this._initializeFormatters();
            this._defaultProps();
            this._propsToState();

            this._triggerDataSpecificMethods();
            this._drawMainSvgHolder(this._chartDivRef.nativeElement);
            this._create();
            this._updateSize();

            this._render(true);
            this._initializeTooltip();
            this._trackMousePosition();
            this._initialized = true;
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (changes.data) {
            this._tooltip.hide();
        }

        let wasSizeAlreadyUpdated = false;
        if (changes.data || changes.columnName) {
            this._triggerDataSpecificMethods();
            this._sizePropsToState();
            this._updateSize();
            wasSizeAlreadyUpdated = true;
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height) {
            if (!wasSizeAlreadyUpdated) {
                this._sizePropsToState();
                this._updateSize();
            }
            needsRender = true;
            renderImmediate = true;
            renderAsTransition = false;
        }

        if (changes.formatterType || changes.formatterOptions) {
            needsRender = true;
        }

        if (changes.yAxisLabel) {
            if (this._chart) {
                this._yAxisLabelG.text(changes.yAxisLabel.currentValue);
            }
        }

        if (changes.xAxisLabel) {
            if (this._chart) {
                this._xAxisLabelG.text(changes.xAxisLabel.currentValue);
            }
        }

        if (changes.tickCount) {
            needsRender = true;
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _getSpaceForYAxisLabels(scale: d3.ScaleLinear<number, number>): number {
        let maxWidth = 0;

        // if text nodes were rendered inside the chart svg,
        // then sometimes `getComputedTextLength` returned 0
        // so appending to `body` instead
        // using css class so that measurement is based on styled text
        const fakeSvg = d3.select("body").append("svg").attr("class", "lg-stacked-bar-chart");

        fakeSvg
            .append("g")
            .attr("class", "axis__title")
            .selectAll("text")
            .data(scale.ticks().map(x => this._formatter.format(x)))
            .enter()
            .append("text")
            .text(d => d)
            .each(function () {
                maxWidth = Math.max(
                    maxWidth,
                    (this as SVGTextContentElement).getComputedTextLength()
                );
            });

        fakeSvg.remove();

        return Math.min(maxWidth, this._svgWidth / Math.PI);
    }

    _defaultProps(): void {
        this.margins = this.margins || "16,16,16,16";
        this.tickCount = this.tickCount || 6;
        this.highlight = this.highlight || "bar";
        this.groupsProperty = this.groupsProperty || "groups";
        this.barsProperty = this.barsProperty || "bars";
        this.groupsOrderByProperty = this.groupsOrderByProperty || "sorting";
        this.barIdProperty = this.barIdProperty || "barId";
    }

    _propsToState(): void {
        this._sizePropsToState();
    }

    _sizePropsToState(): void {
        this._margin = this._getMargin(this.margins.split(","));
        this._width = Math.max(0, ~~this.width - this._margin.left - this._margin.right);
        this._height = Math.max(0, ~~this.height - this._margin.top - this._margin.bottom);
    }

    private _triggerDataSpecificMethods(): void {
        this._initializeColorScales();
        this._convertData();
        this._initializeColorScales(this._data);
        this._setLegend();
    }

    private _getLegendSize(below: boolean, onTheRight: boolean): number {
        if (!below && !onTheRight) return 0;

        if (below) {
            return SPACE_FOR_LEGEND_BELOW;
        }

        return getLegendWidth(this.width, this.legendOptions.widthInPercents, this._columnNames);
    }

    private _initializeColorScales(data?: IStackedBarChartColumn[]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._setLegacyColorScale(data);
    }

    private _setLegacyColorScale(data: IStackedBarChartColumn[]): void {
        if (!data || !data.length) {
            return;
        }

        let colors: string[];
        if (this.groupColors) {
            colors = this._legacyColorPalette.getPaletteForColors(this.groupColors);
        } else {
            colors = this._bars.map(i => this.barColor && this.barColor(i)[0]);
        }

        this._groupColors = d3.scaleOrdinal(colors);
    }

    _setLegend(): void {
        const legend: any[] = [];

        if (!this._columnNames || !this._columnNames.length) {
            this._legendDefinition = legend;
            return;
        }

        this._bars.forEach((bar, index) => {
            const row = {
                colors: [this._groupColors(bar[this.barIdProperty])],
                name: bar[this.barIdProperty],
                item: bar
            };

            legend.push({
                color: row.colors[0],
                name: row.name,
                opacity: this.columnOpacity ? this.columnOpacity(index) : 1
            });
        });

        this._legendDefinition = legend;
        const groupToColor: Record<string, LegendItem> = {};
        this._legendDefinition.forEach(def => (groupToColor[def.name] = def));
    }

    _updateSize(): void {
        this._svg.attr("width", this._svgWidth).attr("height", this._svgHeight);

        this._yScale
            .range([
                this._svgHeight - this._margin.bottom - X_AXIS_LABELS_LINE_HEIGHT,
                this._margin.top
            ])
            .nice();

        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);

        const spaceBelowAxis =
            this._margin.bottom + (legendBelow ? legendSize - 6 : 0) + X_AXIS_LABELS_LINE_HEIGHT;

        if (legendOnTheRight) {
            this._legendWidth = legendSize;
            this._legendPaddingBottom = spaceBelowAxis;
        }

        this._spaceForYAxisLabels = this._getSpaceForYAxisLabels(this._yScale);
        this._xScale
            .range([this._horizontalPositionOfYAxis, this.width - this._margin.right])
            .paddingInner(0.1);

        this._xAxisG.attr("transform", `translate( 0, ${this._yScale(0)} )`);
        this._yAxisG.attr("transform", `translate( ${this._horizontalPositionOfYAxis}, 0 )`);
        this._yAxisLabelG.attr(
            "transform",
            `translate( ${this._margin.left}, ${this._yScale(0)} ) rotate( -90 )`
        );
        this._xAxisLabelG.attr(
            "transform",
            `translate( ${this._horizontalPositionOfYAxis - this._margin.left}, ${
                this._yScale(0) + this._margin.bottom + this.xAxisLabelsPadding
            } )`
        );
        this._yAxisGridGroup.attr(
            "transform",
            `translate( ${this._horizontalPositionOfYAxis}, 0 )`
        );
    }

    _render(immediate?: boolean): void {
        if (!this._chart) {
            immediate = false;
            this._create();
        }

        if (!this.data || !this.data.length || this._svgHeight <= 0) {
            return;
        }

        this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip.hide());

        const groupsList = [];
        for (let i = 0; i < this._groupsCount; i++) {
            groupsList.push(i);
        }

        const self = this;

        this._xScale
            .domain(this._columnNames)
            .range([this._horizontalPositionOfYAxis, this._svgWidth - this._margin.right])
            .round(true);
        this._xGroupScale
            .domain(groupsList)
            .range([0, this._xScale.bandwidth()])
            .padding(0.1)
            .round(true);
        this._yScale.domain([0, this._yMax]).nice().interpolate(d3.interpolateRound);

        this._xAxisG
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getXAxis(this._xScale))
            .attr("transform", `translate( 0, ${this._yScale(0)} )`);

        if (self.showTickTooltip) {
            d3.select(self._chartDivRef.nativeElement)
                .selectAll(`${this._useNewLabels ? ".x__axis .tick" : ".x__axis__legacy .tick"}`)
                .on("mouseover", function (_event: MouseEvent) {
                    const tickName = d3.select(this).data()[0] as string;
                    const data = self._data.find(item => item.columnName === tickName);
                    self.tooltipContext = {
                        data: data.groups[0].barData,
                        group: self._data[data.groups[0].groupIndex]
                    };
                    self._tooltip.hideShow();
                    self._updateTooltipPosition();
                })
                .on("mouseleave", (_event: MouseEvent) => this._tooltip.scheduleHide());
        }

        this._yAxisG
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getYAxis(this._yScale));

        this._yAxisGridGroup
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getYAxisGrid(this._yScale));

        const columns = this._chart.selectAll(".column").data(this._data, (d: any) => d.columnName);

        columns.exit().remove();

        columns.enter().append("g").attr("class", "column");

        const groups = this._chart
            .selectAll<SVGGElement, any>(".column")
            .selectAll<SVGGElement, any>(".group")
            .data(
                d => d.groups,
                (d: any) => d.barId || d.groupIndex
            );

        groups.exit<IStackedBarChartItem[]>().remove();

        groups
            .enter()
            .append("g")
            .attr("class", "group")
            .merge(groups)
            .order()
            .on("mouseover", function (_event: MouseEvent, g: any) {
                switch (self.highlight) {
                    case "bar":
                        d3.select(this)
                            .selectAll("rect")
                            .style("fill", (d: Partial<IStackedBarChartItem>) => d.hoverColor);
                        break;
                    case "group":
                        self._chart
                            .selectAll("rect")
                            .style("fill", (d: Partial<IStackedBarChartItem>) =>
                                g.groupIndex === d.groupIndex ? d.hoverColor : d.color
                            );
                        break;
                }
            });

        // const bars = groups.selectAll( "rect" )
        const bars = this._chart
            .selectAll<SVGGElement, any>(".column")
            .selectAll<SVGGElement, any>(".group")
            .selectAll<SVGRectElement, any>("rect")
            .data((d: any) => d.barData);

        bars.exit()
            .attr("y", (d: Partial<IStackedBarChartItem>) => this._yScale(d.toValue))
            .attr("height", 0)
            .style("opacity", 0)
            .remove();

        const newBars = bars.enter().append("rect");

        let barsMerged = newBars
            .attr("class", "bar")
            .attr(
                "x",
                (d: Partial<IStackedBarChartItem>) =>
                    this._xScale(d.columnName) + this._xGroupScale(d.groupIndex)
            )
            .attr("y", () => this._yScale(0))
            .attr("width", this._xGroupScale.bandwidth())
            .attr("height", 0)
            .style("fill", (d: Partial<IStackedBarChartItem>) => d.color)
            .style("opacity", (d: Partial<IStackedBarChartItem>) => d.opacity)
            .style("cursor", () => (this.clickable ? "pointer" : "default"))
            .on("mouseover.1", function (_event: MouseEvent, data: Partial<IStackedBarChartItem>) {
                self.tooltipContext = {
                    data,
                    group: self._data[data.groupIndex]
                };
                self._tooltip.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseleave.1", (_event: MouseEvent) => this._tooltip.scheduleHide())
            .on("click", function (_event: MouseEvent, d) {
                const index = newBars.nodes().indexOf(this);
                self.onClick(d, index);
            })
            .merge(bars)
            .order()
            .on("mouseover.2", function (_event: MouseEvent, g: any) {
                switch (self.highlight) {
                    case "kind":
                        if (!self.barKindProperty) {
                            console.warn(
                                'lg-stacked-bar-chart: Using highlight type "kind" without bar-kind-property is impossible'
                            );
                        }
                        self._chart
                            .selectAll("rect")
                            .style("fill", (d: Partial<IStackedBarChartItem>) =>
                                g.item[self.barKindProperty] === d.item[self.barKindProperty]
                                    ? d.hoverColor
                                    : d.color
                            );
                        break;
                }
            })
            .on("mouseout.2", function (_event: MouseEvent) {
                self._chart
                    .selectAll("rect")
                    .style("fill", (d: Partial<IStackedBarChartItem>) => d.color);
            });

        barsMerged = immediate ? barsMerged : (barsMerged.transition() as any);

        barsMerged
            .attr(
                "x",
                (d: Partial<IStackedBarChartItem>) =>
                    this._xScale(d.columnName) + this._xGroupScale(d.groupIndex)
            )
            .attr("y", (d: Partial<IStackedBarChartItem>) => this._yScale(d.toValue))
            .attr("width", this._xGroupScale.bandwidth())
            .attr("height", (d: Partial<IStackedBarChartItem>) => {
                const drawFrom = this._yScale(d.fromValue);
                const drawTo = this._yScale(d.toValue);
                const barSize = drawFrom - drawTo;
                const hasSeparator = d.fromValue !== 0;
                return hasSeparator && barSize >= CHART_SEPARATOR_SIZE
                    ? barSize - CHART_SEPARATOR_SIZE
                    : barSize;
            })
            .style("fill", (d: Partial<IStackedBarChartItem>) => d.color);
    }

    _create(): void {
        this._yAxisGridGroup = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis__grid" : "y__axis__grid__legacy"}`);

        this._chart = this._svgG.append("g");

        this._xScale = d3.scaleBand().paddingInner(0.1);
        this._xGroupScale = d3.scaleBand<any>();
        this._yScale = d3.scaleLinear();

        this._yAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`);
        this._xAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis" : "x__axis__legacy"}`);

        this._yAxisLabelG = this._svgG
            .append("text")
            .attr("class", "axis__title")
            .text(this.yAxisLabel)
            .attr("transform", "rotate(-90)");

        this._xAxisLabelG = this._svgG
            .append("text")
            .attr("class", "axis__title")
            .text(this.xAxisLabel);
    }

    private _getXAxis(scale: d3.ScaleBand<any>): d3.Axis<any> {
        return d3
            .axisBottom(scale)
            .tickSize(0)
            .tickPadding(5 + this.xAxisLabelsPadding)
            .tickFormat(d => d);
    }

    private _getYAxis(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisLeft<any>(scale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._numberFormat)
            .ticks(coerceNumberProperty(this.tickCount, 10));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 10))
            .tickSizeOuter(0)
            .tickSizeInner(this._svgWidth - this._horizontalPositionOfYAxis - this._margin.right);
    }

    _convertData(): void {
        this._yMax = null;
        this._groupsCount = null;
        this._data = [];
        this._columnNames = [];
        const bars: any[] = [];

        this.data.forEach((column, columnIndex) => {
            const columnName = this.columnName(column);
            this._columnNames.push(columnName);

            const columnData: IStackedBarChartColumn = {
                columnName,
                columnIndex,
                groups: []
            };

            if (this.groupsOrderByProperty) {
                column[this.groupsProperty] = ldSortBy(
                    column[this.groupsProperty],
                    this.groupsOrderByProperty
                );
            }

            column[this.groupsProperty].forEach((group: any, groupIndex: number) => {
                const groupData: IStackedBarChartGroup = {
                    barData: [],
                    groupIndex
                };

                if (this.barIdProperty) {
                    groupData.barId = group[this.barIdProperty];
                }

                const barData: IStackedBarChartItem[] = [];

                if (this.barsOrderByProperty) {
                    group[this.barsProperty] = ldSortBy(
                        group[this.barsProperty],
                        this.barsOrderByProperty
                    );
                }

                let fromValue = 0;

                group[this.barsProperty].forEach((item: any, barIndex: any) => {
                    const { color, barColorValues } = this._getColors(item);
                    const hoverColor = this._getHoverColor(color, barColorValues);

                    const barObj: IStackedBarChartItem = {
                        item,
                        columnName,
                        columnIndex,
                        groupIndex,
                        barIndex,
                        fromValue,
                        toValue: fromValue + item[this.barValueProperty],
                        value: item[this.barValueProperty],
                        color,
                        hoverColor,
                        opacity: this.barOpacity ? this.barOpacity(item) : 1
                    };

                    barData.push(barObj);
                    bars.push(barObj.item);

                    fromValue += item[this.barValueProperty];
                });

                groupData.barData = barData;
                columnData.groups.push(groupData);

                this._groupsCount =
                    this._groupsCount == null
                        ? groupIndex + 1
                        : Math.max(this._groupsCount, Number(groupIndex + 1));
                this._yMax = this._yMax == null ? fromValue : Math.max(this._yMax, fromValue);
            });

            this._data.push(columnData);
        });
        this._bars = ldUniqBy(bars, this.barIdProperty);
    }

    private _getColors(item: any): any {
        return this._colorPalette.useNewColorPalette
            ? this._getNewColors(item)
            : this._getLegacyColors(item);
    }

    private _getNewColors(item: any): any {
        const color = this._groupColors(item[this.barIdProperty]);
        const barColorValues = this._colorPalette.getCategoricalPalette();
        return { color, barColorValues };
    }

    /**
     * @deprecated
     */
    private _getLegacyColors(item: any): { color: string; barColorValues: string[] } {
        let color = "#" + Math.floor(Math.random() * DECIMAL_FOR_WHITE_COLOR).toString(16);

        if (this.barColorProperty) {
            color = item[this.barColorProperty];
        }

        const barColorValues = this.barColor && this.barColor(item);
        if (barColorValues && barColorValues.length > 0) {
            color = barColorValues[0];
        }
        return { color, barColorValues };
    }

    private _getHoverColor(color: string, barColorValues: any[]): string {
        if (this._colorPalette.useNewColorPalette) return d3.rgb(color).darker(0.2).toString();
        return this._getLegacyHoverColor(color, barColorValues);
    }

    /**
     * @deprecated
     */
    private _getLegacyHoverColor(color: string, barColorValues: any[]): string {
        let hoverColor = d3.rgb(color).darker(0.2).toString();

        if (barColorValues && barColorValues[1]) {
            switch (typeof barColorValues[1]) {
                case "string":
                    hoverColor = barColorValues[1];
                    break;
                case "number":
                    if (barColorValues[1] < 0) {
                        hoverColor = d3
                            .rgb(color)
                            .darker(barColorValues[1] * -1)
                            .toString();
                    } else {
                        hoverColor = d3.rgb(color).brighter(barColorValues[1]).toString();
                    }
                    break;
            }
        }
        return hoverColor;
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    onClick(value: any, itemIndex: number): void {
        if (!this.clickable) {
            return;
        }

        this.itemClick.emit({ item: value.item, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }
}
