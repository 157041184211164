import ldFindLastIndex from "lodash-es/findLastIndex";
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    AfterViewInit,
    ViewChild,
    ElementRef,
    inject
} from "@angular/core";
import { ViewportRuler } from "@angular/cdk/overlay";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgSimpleChanges } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { IPivotTableLevelHeader, IPivotTableLevelHeaderDefinition } from "./types";
import { LgPivotTableComponent } from "./lg-pivot-table.component";
import { convertPivotLevelHeaders } from "./helpers/convertPivotLevelHeaders";

@Component({
    selector: "lg-pivot-table-level-headers",
    templateUrl: "./lg-pivot-table-level-headers.component.html",
    host: {
        class: "table__column--header-levels",
        "[class.table__column--header-levels--compact]": "!_areLabelsVisible"
    }
})
export class LgPivotTableLevelHeadersComponent implements OnChanges, OnDestroy, AfterViewInit {
    _table = inject(LgPivotTableComponent);
    private _elementRef = inject(ElementRef);
    private _lgTranslate = inject(LgTranslateService);
    private _ruler = inject(ViewportRuler);

    /**
     * Definition of table level header (required).
     */
    @Input({ required: true }) levels!: IPivotTableLevelHeaderDefinition;

    @ViewChild("hiddenLevelsWrapper", { static: true }) _hiddenLevelsWrapper: ElementRef;

    _hoverLevel: number | null;
    _lastVisibleIndex: number;
    _areLabelsVisible = true;
    _levelsWidth: number;

    _convertedLevels: IPivotTableLevelHeader[];

    private _width: number;
    private _destroyed$ = new Subject<void>();

    constructor() {
        this._table
            ._hoverLevelObservable()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(level => {
                this._hoverLevel = level;
            });

        this._ruler
            .change()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => this._onViewPortChange());
    }

    ngOnChanges(changes: LgSimpleChanges<LgPivotTableLevelHeadersComponent>): void {
        if (changes.levels) {
            this._convertLevels();
            this._onLevelsChange();
        }
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            this._onLevelsChange();
            this._onViewPortChange();
        }, 0);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _toggleNameLevel(level: number, $event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();

        if (!this._table.data) return;

        const last = level === this._lastVisibleIndex;
        if (this._table.maxVisibleLevel < level) {
            for (let i = this._table.maxVisibleLevel; i < level; ++i) {
                this._table.toggleLevel(i, true);
            }
        }
        if (!last) {
            this._table.toggleLevel(level);
        }
    }

    _sortByDisabled(
        levelDef: IPivotTableLevelHeader,
        isTheVisibleRow: boolean,
        index: number
    ): boolean {
        const emptyTable = this._table.passive ? !this._table.filteredData : !this._table.data;

        return (
            !levelDef.orderBy ||
            this._table.maxVisibleLevel < index ||
            emptyTable ||
            !this._levelLabelVisible(isTheVisibleRow, index)
        );
    }

    _levelLabelVisible(isTheVisibleRow: boolean, index: number): boolean {
        return !isTheVisibleRow || this._areLabelsVisible || this._table.maxVisibleLevel === index;
    }

    _showDotForLastLevel(isTheVisibleRow: boolean, index: number): boolean {
        return isTheVisibleRow && !this._areLabelsVisible && index === this._lastVisibleIndex;
    }

    private _convertLevels(): void {
        this._convertedLevels = convertPivotLevelHeaders(this.levels, this._lgTranslate);
        this._lastVisibleIndex = ldFindLastIndex(
            this._convertedLevels,
            l => l != null && l.hidden !== true
        );
    }

    private _onLevelsChange(): void {
        if (this._hiddenLevelsWrapper) {
            const newWidth = this._hiddenLevelsWrapper.nativeElement.clientWidth;
            if (newWidth !== this._levelsWidth) {
                this._levelsWidth = newWidth;
                this._setLabelsVisibility();
            }
        }
    }

    private _onViewPortChange(): void {
        if (this._elementRef.nativeElement) {
            const width =
                this._elementRef.nativeElement.style.width === ""
                    ? this._elementRef.nativeElement.parentElement.clientWidth
                    : this._elementRef.nativeElement.clientWidth;

            if (width !== this._width) {
                this._width = width;
                this._setLabelsVisibility();
            }
        }
    }

    private _setLabelsVisibility(): void {
        if (!this._levelsWidth || !this._width) return;

        this._areLabelsVisible = this._levelsWidth < this._width;
    }
}
