/* eslint-disable @typescript-eslint/no-this-alias */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject
} from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import * as d3 from "d3";
import { ChartClickEvent, LegendItem, LegendOptions, Margin } from "../../shared/chart.types";
import { getDefaultLegendOptions } from "../../shared/getDefaultLegendOptions";
import {
    LgBoxplotTypeIndices,
    LgBoxplotDatum,
    LgBoxplotItem,
    LgComparingBoxplotItem,
    LgConvertedBoxplotItem,
    LgBoxplotHighlighted,
    LgBoxplotLineIndices,
    LgBoxplotTooltipContext,
    ILegendItemDefinition,
    LgBoxplotLabelDimensions
} from "../lg-boxplot.types";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    D3TooltipApi,
    ID3TooltipOptions,
    LgD3TooltipService
} from "../../d3/lg-d3-tooltip.service";
import { getRecommendedPosition } from "../../shared/getRecommendedPosition";
import { LgColorPalette } from "../../shared/lg-color-palette";
import { LgSimpleChanges } from "@logex/framework/types";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { getLegendWidth } from "../../shared/getLegendWidth";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import ldCloneDeep from "lodash-es/cloneDeep";
import {
    LgColorsConfiguration,
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS
} from "../../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IExportableChart, LgChartExportContainerDirective } from "../../shared/lg-chart-export";
import { IImplicitContext } from "../../shared/lg-chart-template-context.directive";

const DEFAULT_MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const DEFAULT_TOOLTIP_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 2 };

const SPACE_BETWEEN_Y_LABELS_AND_GRID = 8;
const Y_AXIS_TITLE_WIDTH = 20;

const X_AXIS_TITLE_HEIGHT = 20;
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const SPACE_FOR_LEGEND_BELOW = 30;

const DEFAULT_TICK_COUNT = 5;
const DEFAULT_GROUP_COLORS = "@input, @benchmark";
const DEFAULT_MAX_LABEL_LENGTH = 10;
const DEFAULT_Y_AXIS_LABELS_WIDTH = 30;
const DEFAULT_ROTATED_LABELS_HEIGHT = 100;
const Y_AXIS_TITLE_OFFSET = 30;

const SPACE_BETWEEN_BOXES = 8;
const BOX_SPACE_FROM_MIDDLE = SPACE_BETWEEN_BOXES / 2;
const MIN_BOX_HEIGHT = 6;
const X_AXIS_LABELS_ROTATION_DEGREES = 315;
const X_AXIS_LABELS_FONT = '15px "Source Sans", sans-serif';
const ICON_SIZE = 24;

interface ColumnIconOptions {
    icon?: string;
    iconType?: string;
    tooltip?: boolean;
    tooltipText?: string;
}

@Component({
    selector: "lg-boxplot-vertical-chart",
    templateUrl: "./lg-boxplot-vertical-chart.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgBoxplotVertical")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgBoxplotVerticalChartComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy, IExportableChart
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _legacyColorPalette = inject(LgColorPalette);
    private _lgConsole = inject(LgConsole).withSource("Logex.Charts.LgBoxplotVerticalChart");
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _translateService = inject(LgTranslateService);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);
    /**
     * Specifies the data from which the chart is built.
     * Required parameter without default.
     * Either this or computedData input must be specified.
     *
     * Properties of a single object:
     *
     * @property { string } group - Defines whether the item is main or comparing.
     * If boxplot is comparing, the group must be equal to the comparingAgainst input
     * @property { number } itemValue specifies the value of the item.
     * @property { string } itemName specifies column. This is used for grouping values.
     */
    @Input() data?: LgBoxplotItem[];
    /**
     * Specifies the data from which the chart is built.
     * Required parameter without default.
     * Either this or data input must be specified.
     *
     * Properties of a single object:
     *
     * @property { string } key - Specifies the name of the column.
     * @property { ComparingBoxplotItemVerticalVertical } value - Specifies main and comparing values of the column.
     * If comparing is empty, only main data are shown.
     */
    @Input() computedData?: LgBoxplotDatum[];
    /**
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin: Margin | null = null;
    /**
     * Specifies the number to round the min and max to.
     * If specified, the number axis starts with nearest multiple of given number.
     * The minimum is rounded down and maximum is rounded up.
     *
     * If not specified, minimum and maximum values are used and d3 creates domain automatically.
     *
     * @example
     * roundToNearestMultipleOf = 500, min = 499, max = 999;
     * The X axis starts with 0 and ends with 1000.
     */
    @Input() roundToNearestMultipleOf: number | null = null;

    /**
     * Specifies the height of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) height!: number;
    /**
     * Specifies the width of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) width!: number;
    /**
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;
    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;

    /**
     * Specifies the Y axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string;
    /**
     * Specifies the X axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string;
    /**
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;
    /**
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;
    /**
     * Specifies the options for legend
     *
     * @example ```
     * getDefaultLegendOptions({
     *  visible: true,
     *  position: "bottom"
     * })
     * ```
     */
    @Input() legendOptions: LegendOptions | null = null;
    /**
     * @deprecated use colorConfiguration
     *
     * Specifies the colors for main and comparing boxplots. Colors must be separated by comma starting with @.
     * First color is used for main boxplot, second color is used for comparing.
     * Color names must be keys in ChartValueTypeDictionary from lg-color-palette.
     *
     * @example `"@input, @benchmark"`.
     */
    @Input() groupColors?: string;
    /**
     * @deprecated use colorConfiguration
     *
     * Specifies if a color definiton from computedData should be used.
     * The color definition from the computedData input is used to create legend as well as to color the graphs.
     * Color names must be colors in HEX.
     */
    @Input() useColorsFromData: boolean;

    /**
     * Specifies the name of main boxes in boxplot. Used in legend and tooltip.
     */
    @Input() mainLabel: string | null = null;
    /**
     * Specifies the name of comparing boxes in boxplot. Used in legend and tooltip and for grouping values
     * Must be equal to @property { string } group from the data input.
     */
    @Input() comparingAgainst: string | null = null;
    /**
     * Specifies the width of the Y-axis labels in pixels. Defaults to 30.
     *
     * @default 30
     */
    @Input() yAxisLabelsWidth?: number;
    /**
     * Specifies formatter type for number axis. Defaults to "float".
     *
     * @default "float"
     */
    @Input() axisFormatterType?: string;
    /**
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() axisFormatterOptions?: ILgFormatterOptions;
    /**
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<LgBoxplotTooltipContext>>;
    /**
     * Specifies formatter type for tooltip numbers. Defaults to "float"
     *
     * @default "float"
     */
    @Input() tooltipFormatterType?: string;
    /**
     * Specifies the options for number axis formatter. Defaults to 2 decimals.
     */
    @Input() tooltipFormatterOptions?: ILgFormatterOptions;
    /**
     * Specifies whether boxes are clickable or not. If true, allows emitting from itemClick, which emits clicked box.
     *
     * @default false
     */
    @Input() clickable = false;
    /**
     * Specifies the length of the X-axis title text. Defaults to 10 characters.
     *
     * @default 10
     */
    @Input() labelLength?: number = DEFAULT_MAX_LABEL_LENGTH;
    /**
     * Specifies if the X-axis labels should be rotated. Defaults to false.
     *
     * @default false
     */
    @Input() rotateXAxisLabels = false;
    /**
     * Specifies the height of the rotated X-axis labels in pixels. Defaults to 100.
     * Too long labels are truncated. Overrides the labelLength property.
     *
     * @default 100
     */
    @Input() rotatedXAxisLabelsHeight? = DEFAULT_ROTATED_LABELS_HEIGHT;
    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration?: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;
    /**
     * Specifies the color for the median line - possible options are 'white' and 'black'. Defaults to white.
     *
     * @default "white"
     */
    @Input() medianLineColor?: "white" | "black" = "white";

    /**
     * Callback for specifying whether group column contains icon or not.
     */
    @Input() columnIcons?: (item: any) => boolean;

    /**
     * Specifies group column icon options.
     *
     * @default  { icon: "icon-warning", iconType: "regular" }
     */
    @Input() columnIconOptions?: ColumnIconOptions;

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    @Output() readonly itemClick: EventEmitter<ChartClickEvent<any, any>>;
    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick: EventEmitter<LegendItem>;

    @ViewChild("chartHolder") chartHolder: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate: TemplateRef<IImplicitContext<LgBoxplotTooltipContext>>;

    private _svg: d3.Selection<any, any, any, any>;
    private _svgG: d3.Selection<any, any, any, any>;
    private _boxGroupsG: d3.Selection<any, any, any, any>;
    private _yScale: d3.ScaleLinear<number, number>;
    private _xScale: d3.ScaleBand<string>;
    private _xAxisGroup: d3.Selection<any, any, any, any>;
    private _yAxisGroup: d3.Selection<any, any, any, any>;
    private _yAxisGridG: d3.Selection<any, any, any, any>;
    private _shadowBoxGroups: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _boxGroups: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _groupColors: d3.ScaleOrdinal<string, string>;
    private _groupColorsHex: string[];
    private _groupToLegendDefinitionDictionary: { [group: string]: LegendItem };
    private _columnIconsGroup: d3.Selection<any, any, any, any>;

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener: () => void;
    private _numberFormat: (x: number) => string;
    private _tooltipHidden: boolean;

    private _yAxisLabel: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _xAxisLabel: d3.Selection<any, LgBoxplotDatum, any, any>;

    private _spaceForYAxisLabels: number;

    private _width: number;
    private _height: number;

    private _data: LgBoxplotDatum[];
    private _min: number;
    private _max: number;

    _legendDefinition: LegendItem[];
    _legendWidth: number;
    _legendPaddingBottom: number;

    private _groupNames: string[];
    _margin: Margin;

    _tooltipFormatter: ILgFormatter<any>;
    _axisFormatter: ILgFormatter<any>;
    private _tooltip: D3TooltipApi;
    private _tooltipContext: LgBoxplotTooltipContext | null = null;
    private _tooltipPortal?: TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>>;

    get tooltipContext(): LgBoxplotTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgBoxplotTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastHoveredBoxes: d3.Selection<any, LgBoxplotDatum, any, any>;
    Highlighted = LgBoxplotHighlighted;
    _highlighted: LgBoxplotHighlighted;
    _tooltipIsComparing: boolean;
    private _legendDefinitionFromData: ILegendItemDefinition[];

    private _isComparing: boolean;
    private _initialized: boolean;

    _isLabelHovered: boolean;

    constructor() {
        this.legendClick = new EventEmitter(null);
        this.itemClick = new EventEmitter(null);
    }

    ngOnInit(): void {
        this._setDefaultProperties();
        this._initializeTooltip();
        this._trackMousePosition();
        this._triggerDataSpecificMethods();
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._render();
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgBoxplotVerticalChartComponent>): void {
        if (!this._initialized) return;

        if (changes.data || changes.computedData) {
            this._triggerDataSpecificMethods();
        }

        if (changes.width || changes.height || changes.tickCount) {
            this._width = this.width;
            this._height = this.height;
        }

        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels =
                this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel.text(this.xAxisLabel);
        }

        if (changes.margin) this._margin = this.margin;

        if (changes.yAxisLabel) {
            this._yAxisLabel.text(this.yAxisLabel);
        }

        if (changes.groupColors) {
            this._groupColors.range(this.groupColors.split(","));
            this._triggerDataSpecificMethods();
        }

        if (changes.mainLabel || changes.useColorsFromData) {
            this.mainLabel =
                this.mainLabel == null
                    ? this._translateService.translate(".Main_tooltip_label")
                    : this.mainLabel;
            this._updateLegend();
            this._triggerDataSpecificMethods();
        }

        if (changes.legendOptions) {
            this._updateLegend();
        }

        if (this._svg) this._svg.remove();
        this._render();
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        if (this._tooltip) {
            this._tooltip.destroy();
        }
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        this._tooltipHidden = true;

        this._margin = this.margin == null ? DEFAULT_MARGIN : this.margin;
        this.tooltipFormatterType = this.tooltipFormatterType || "float";
        this.tooltipFormatterOptions = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptions
        };
        this._tooltipFormatter = this._formatterFactory.getFormatter(
            this.tooltipFormatterType,
            this.tooltipFormatterOptions
        );

        this.axisFormatterType = this.axisFormatterType || "float";
        this.axisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.axisFormatterOptions
        };
        this._axisFormatter = this._formatterFactory.getFormatter(
            this.axisFormatterType,
            this.axisFormatterOptions
        );

        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;

        this.tickCount = this.tickCount == null ? DEFAULT_TICK_COUNT : this.tickCount;
        this._spaceForYAxisLabels =
            this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        this._numberFormat = x => this._axisFormatter.format(x);
        this.legendOptions =
            this.legendOptions == null ? getDefaultLegendOptions() : this.legendOptions;
        this._width = this.width;
        this._height = this.height;
        this.showXAxisLabels = this.showXAxisLabels == null ? true : this.showXAxisLabels;
        this.showYAxisLabels = this.showYAxisLabels == null ? true : this.showYAxisLabels;
        this.groupColors = this.groupColors == null ? DEFAULT_GROUP_COLORS : this.groupColors;
        this.mainLabel =
            this.mainLabel == null
                ? this._translateService.translate(".Main_tooltip_label")
                : this.mainLabel;
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>> {
        return new TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>>(
            this.tooltipTemplate,
            null,
            {
                $implicit: this.tooltipContext
            }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                    this._updateTooltipHighlight();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _updateTooltipHighlight(): void {
        if (!this._lastHoveredBoxes || this._tooltipHidden) return;
        const hoveredGroup = d3.select(this._lastHoveredBoxes.node().parentNode);
        const minMaxLines = hoveredGroup.selectAll("line");
        const boxes = hoveredGroup.selectAll(".box");

        const mainLine = minMaxLines
            .filter((_unused, i) => i === LgBoxplotLineIndices.MainLineVertical)
            .node() as Element;
        const mainBox = boxes
            .filter((_unused, i) => i === LgBoxplotTypeIndices.Main)
            .node() as Element;

        if (!mainLine.getClientRects()[0]) return;
        const mainX = mainLine.getClientRects()[0].left;
        const mainDiffFromX = Math.abs(this._lastMouseX - mainX);

        const comparingLine = minMaxLines
            .filter((_unused, i) => i === LgBoxplotLineIndices.ComparingLineVertical)
            .node() as Element;
        const comparingBox = boxes
            .filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing)
            .node() as Element;

        const comparingX = comparingLine?.getClientRects()[0].left;
        const comparingDiffFromX = Math.abs(this._lastMouseX - comparingX);

        this._tooltipIsComparing = this.comparingAgainst && mainDiffFromX > comparingDiffFromX;

        const minMaxLine = this._tooltipIsComparing ? comparingLine : mainLine;
        const box = this._tooltipIsComparing ? comparingBox : mainBox;

        const minMaxRect = minMaxLine.getClientRects()[0];
        const boxRect = box.getClientRects()[0];

        const maxY = minMaxRect.top;
        const minY = minMaxRect.bottom;
        const boxTop = boxRect.top;
        const boxBottom = boxRect.bottom;

        if (this._lastMouseY < maxY) this._highlighted = LgBoxplotHighlighted.Max;
        else if (this._lastMouseY > minY) this._highlighted = LgBoxplotHighlighted.Min;
        else if (this._lastMouseY > maxY && this._lastMouseY < boxTop) {
            this._highlighted = LgBoxplotHighlighted.UpperQuartile;
        } else if (this._lastMouseY < minY && this._lastMouseY > boxBottom) {
            this._highlighted = LgBoxplotHighlighted.LowerQuartile;
        } else this._highlighted = LgBoxplotHighlighted.Median;

        this.tooltipContext = {
            ...this.tooltipContext,
            highlighted: this._isLabelHovered ? null : this._highlighted,
            isComparing:
                this._tooltipIsComparing || (this.comparingAgainst && this._isLabelHovered),
            isLabelHovered: this._isLabelHovered,
            iconTooltip: false
        };
    }

    private _triggerDataSpecificMethods(): void {
        const hasNoData = this.data == null || this.data.length === 0;
        const hasNoComputedData = this.computedData == null || this.computedData.length === 0;
        const shouldRender = hasNoData && hasNoComputedData;
        if (shouldRender) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._convertData();
        this._initializeColorScales(this._data);
        this._updateLegend();
    }

    private _convertData(): void {
        if (this.computedData) {
            this._convertComputedData();
            return;
        }
        this._convertRegularData();
    }

    private _convertComputedData(): void {
        this._data = ldCloneDeep(this.computedData);
        this._groupNames = this._data.map(i => i.key);
        this._setBoundaries();
        this._isComparing = this.comparingAgainst != null;

        this._data.forEach((d: LgBoxplotDatum, i) => {
            d.value.index = i;
        });

        this._legendDefinitionFromData = [];
        if (this.useColorsFromData) {
            const addLegendDefinitionItem = (
                d: LgBoxplotDatum,
                type: LgBoxplotTypeIndices
            ): void => {
                const currentLegendItem = (
                    type === LgBoxplotTypeIndices.Comparing ? d.value.comparing : d.value.main
                )?.legendItem;
                if (
                    currentLegendItem?.name &&
                    !this._legendDefinitionFromData.find(
                        color => color.name === currentLegendItem.name
                    )
                ) {
                    this._legendDefinitionFromData.push({
                        color: currentLegendItem.color,
                        name: currentLegendItem.name,
                        order: currentLegendItem.order
                    });
                }
            };

            this._data.forEach((d: LgBoxplotDatum) => {
                addLegendDefinitionItem(d, LgBoxplotTypeIndices.Main);
                addLegendDefinitionItem(d, LgBoxplotTypeIndices.Comparing);
            });

            this._legendDefinitionFromData.sort((a, b) => a.order - b.order);
        }
    }

    private _convertRegularData(): void {
        let index = 0;
        this._data = Array.from(
            d3.rollup(
                this.data,
                (d: LgBoxplotItem[]) => {
                    const main = d.filter(i => i.group !== this.comparingAgainst);
                    const comparing = d.filter(i => i.group === this.comparingAgainst);

                    const getItem = (d: LgBoxplotItem[]): LgConvertedBoxplotItem => {
                        const group = d[0]?.group;
                        const q1 = d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.25);
                        const median = d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.5);
                        const average = d3.mean(d.map(g => g.itemValue));
                        const q3 = d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.75);
                        const interQuantileRange = q3 - q1;
                        const min = d.reduce(
                            (x, y) => (x < y.itemValue ? x : y.itemValue),
                            Infinity
                        );
                        const max = d.reduce(
                            (x, y) => (x > y.itemValue ? x : y.itemValue),
                            -Infinity
                        );
                        return <LgConvertedBoxplotItem>{
                            group,
                            q1,
                            median,
                            average,
                            q3,
                            interQuantileRange,
                            min,
                            max
                        };
                    };

                    return <LgComparingBoxplotItem>{
                        main: getItem(main),
                        comparing: comparing ? getItem(comparing) : null,
                        index: index++
                    };
                },
                (d: LgBoxplotItem) => d.itemName
            )
        ).map(([key, value]) => ({ key, value }));

        this._setBoundaries();
        this._groupNames = this._data.map(i => i.key);
        this._isComparing = this.comparingAgainst != null;
    }

    private _setBoundaries(): void {
        this._min = Infinity;
        this._max = -Infinity;
        this._data.forEach(d => {
            const min =
                d.value.comparing?.min < (d.value.main.min ?? Infinity)
                    ? d.value.comparing?.min
                    : d.value.main.min;
            const max =
                d.value.comparing?.max > (d.value.main.max ?? -Infinity)
                    ? d.value.comparing?.max
                    : d.value.main.max;
            if (this._min > min) this._min = min;
            if (this._max < max) this._max = max;
        });
    }

    private _initializeColorScales(data: LgBoxplotDatum[]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._initializeLegacyColorScales(data);
    }

    /**
     * @deprecated
     */
    private _initializeLegacyColorScales(data: LgBoxplotDatum[]): void {
        if (!data || !data.length) {
            return;
        }
        const colors = this._legacyColorPalette.getPaletteForColors(this.groupColors);
        this._groupColors = d3.scaleOrdinal(colors);
    }

    private _updateLegend(): void {
        this._legendDefinition = [];
        this._setLegendProperties();

        if (this._legendDefinitionFromData?.length) {
            this._legendDefinitionFromData.forEach(color => {
                this._legendDefinition.push({
                    color: color.color,
                    name: color.name,
                    opacity: 1
                });
            });
        } else {
            this._legendDefinition.push({
                color: this._getColor(null, LgBoxplotTypeIndices.Main),
                name: this.mainLabel,
                opacity: 1
            });
            if (this._isComparing) {
                this._legendDefinition.push({
                    color: this._getColor(null, LgBoxplotTypeIndices.Comparing),
                    name: this.comparingAgainst,
                    opacity: 1
                });
            }
        }

        const groupToColor: Record<string, LegendItem> = {};
        this._legendDefinition.forEach(def => (groupToColor[def.name] = def));
        this._groupToLegendDefinitionDictionary = groupToColor;
    }

    private _setLegendProperties(): void {
        const isLegendVisible = this.legendOptions.visible;
        const legendBelow = isLegendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = isLegendVisible && this.legendOptions.position === "right";
        const legendSize = this._getLegendSize();
        const spaceBelowAxis =
            this._margin.bottom + (legendBelow ? legendSize : 0) + X_AXIS_LABELS_LINE_HEIGHT;
        this._legendWidth = legendOnTheRight ? legendSize : null;
        this._legendPaddingBottom = spaceBelowAxis ? spaceBelowAxis : 0;
    }

    private _render(): void {
        if (this._data == null || this._data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._createSvg();
        this._initializeScales();
        this._createShadowGroups();
        this._createAxes();
        this._createBoxes();
        this._addAxisHoverHandler();
        this._drawColumnIcons();
    }

    private _createSvg(): void {
        this._drawMainSvgHolder();
    }

    private _drawMainSvgHolder(): void {
        this._svg = d3
            .select(this.chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);

        this._svgG = this._svg.append("g");
        this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip.hide());
        this._columnIconsGroup = this._svgG
            .append("g")
            .attr("class", "lg-boxplot-vertical-chart__columnIcons");
    }

    private _drawColumnIcons(): void {
        this._columnIconsGroup.selectAll<SVGGElement, LgBoxplotDatum>(".lg-icon").remove();

        if (this.columnIcons) {
            const self = this;
            const icon = this.columnIconOptions.icon;
            const columnItems: LgBoxplotDatum[] = this._data.filter(this.columnIcons);

            const columnIconsSelection = this._columnIconsGroup
                .selectAll<SVGGElement, LgBoxplotDatum>(".lg-icon")
                .data(columnItems);

            const bandwidth = this._xScale.bandwidth();
            const firstPointPosition =
                this._horizontalPositionOfYAxis + bandwidth / 2 - ICON_SIZE / 2;

            const columnIcons = columnIconsSelection
                .enter()
                .append("g")
                .attr(
                    "transform",
                    d =>
                        "translate(" +
                        (firstPointPosition + d.value.index * bandwidth) +
                        "," +
                        this._verticalPositionOfXAxis +
                        ")"
                )
                .attr(
                    "class",
                    `${icon} lg-icon lg-icon--${this.columnIconOptions.iconType} lg-tooltip-visible`
                )
                .merge(columnIconsSelection)
                .append("use")
                .attr("xlink:href", `#${icon}`)
                .attr("height", ICON_SIZE)
                .attr("width", ICON_SIZE);

            columnIcons
                .on("mousemove", function (_event: MouseEvent) {
                    if (self.columnIconOptions.tooltip && self.columnIcons)
                        self._onMouseOverColumnIcon(d3.select(this));
                })
                .on("mouseout", (_event: MouseEvent) => {
                    if (self.columnIconOptions.tooltip && self.columnIcons) self._tooltip.hide();
                })
                .transition()
                .duration(0)
                .ease(d3.easeCubicOut);
        }
    }

    private _onMouseOverColumnIcon(target: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        const data = target.data();
        this.tooltipContext = {
            name: data[0].key,
            main: data[0].value.main,
            comparing: data[0].value.comparing,
            groupToLegendDefinitionDictionary: this._groupToLegendDefinitionDictionary,
            highlighted: this._highlighted,
            isComparing: this._tooltipIsComparing,
            isLabelHovered: this._isLabelHovered,
            iconTooltip: true
        };

        this._tooltip.show({ target: target.node() });
    }

    private _initializeScales(): void {
        const roundTo = this.roundToNearestMultipleOf;
        const min = roundTo == null ? this._min : Math.floor(this._min / roundTo) * roundTo;
        const max = roundTo == null ? this._max : Math.ceil(this._max / roundTo) * roundTo;

        this._yScale = d3
            .scaleLinear()
            .range([this._verticalPositionOfXAxis, this._margin.top])
            .domain([min, max]);

        this._xScale = d3
            .scaleBand()
            .domain(this._data.map(i => i.key))
            .range([this._horizontalPositionOfYAxis, this._svgWidth - this._margin.right]);
    }

    private _createShadowGroups(): void {
        this._shadowBoxGroups = this._svgG
            .selectAll("boxgroup")
            .data(this._data)
            .enter()
            .append("g")
            .attr("class", "shadow-box-group");
        this._shadowBoxGroups
            .append("rect")
            .attr("class", "shadow-box-rect")
            .attr("width", () => this._xScale.bandwidth())
            .attr("height", () => this._verticalPositionOfXAxis - this._margin.top)
            .attr("transform", d => `translate(${this._xScale(d.key)},${this._margin.top})`)
            .attr("fill", "transparent");
    }

    private _createAxes(): void {
        this._addYAxis();
        this._addYAxisGrid();
        this._addXAxis();
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`)
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title")
            .text(this.yAxisLabel)
            .attr(
                "transform",
                `translate(${this._margin.left}, ${
                    this._verticalPositionOfXAxis - Y_AXIS_TITLE_OFFSET
                }) rotate(-90)`
            );

        this._yAxisGroup.transition().duration(250).call(this._getYAxis());

        this._yAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(${-SPACE_BETWEEN_Y_LABELS_AND_GRID}, 0)`);
    }

    private _getYAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(this._yScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(item => this._getYAxisLabel(item))
            .ticks(coerceNumberProperty(this.tickCount, 5));
    }

    private _getYAxisLabel(value: any): string {
        return this.showYAxisLabels ? this._numberFormat(value) : "";
    }

    private _addYAxisGrid(): void {
        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis__grid" : "y__axis__grid__legacy"}`)
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGridG.transition().duration(250).call(this._getYAxisGrid(this._yScale));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 5))
            .tickSizeOuter(0)
            .tickSizeInner(this._svgWidth - this._horizontalPositionOfYAxis - this._margin.right);
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis" : "x__axis__legacy"}`)
            .attr("transform", `translate(0,${this._verticalPositionOfXAxis})`);

        this._xAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title")
            .text(this.xAxisLabel)
            .attr("text-anchor", "middle")
            .attr(
                "transform",
                `translate(
                    ${this._spaceForYAxisLabels + this._svgWidth / 2},
                    ${this._verticalPositionOfxAxisLabel}
                )`
            );

        this._xAxisGroup.call(this._getXAxis());

        if (this.rotateXAxisLabels) {
            this._xAxisGroup
                .selectAll(".tick text")
                .style("transform-box", "fill-box")
                .style("transform-origin", "center center")
                .style(
                    "transform",
                    `rotate(${X_AXIS_LABELS_ROTATION_DEGREES}deg) translate(-55%, 25%)`
                );
        } else {
            this._xAxisGroup
                .selectAll(".tick text")
                .style(
                    "transform",
                    `translate(0,${this.columnIcons && !this.rotateXAxisLabels ? -4 : 0}px)`
                );
        }
    }

    private _getXAxis(): d3.Axis<string> {
        return d3
            .axisBottom(this._xScale)
            .tickSize(0)
            .tickPadding(this.columnIcons && !this.rotateXAxisLabels ? ICON_SIZE : 12)
            .tickFormat((item, index) => {
                const label = this._getXAxisLabel(item);
                if (this.rotateXAxisLabels) {
                    return this._formatRotatedLabel(label, index);
                }
                const maxAllowedLength = this.labelLength + 2;
                if (label.length > maxAllowedLength) {
                    return label.substring(0, this.labelLength) + "...";
                }
                return label;
            });
    }

    private _getXAxisLabel(value: any): string {
        return this.showXAxisLabels ? value : "";
    }

    private _formatRotatedLabel(label: string, index: number): string {
        const overflowRate = 1.3;
        const maximumWidth = (this.width / this._data.length) * (index + overflowRate);

        let dimensions = this._measureRotatedTextSize(label);
        if (
            dimensions.height <= this.rotatedXAxisLabelsHeight &&
            dimensions.width <= maximumWidth
        ) {
            return label;
        }

        while (
            label.length > 0 &&
            (dimensions.height > this.rotatedXAxisLabelsHeight || dimensions.width > maximumWidth)
        ) {
            label = label.slice(0, -2);
            dimensions = this._measureRotatedTextSize(label + "...");
        }
        return label + "...";
    }

    private _measureRotatedTextSize(xAxisLabel: string): LgBoxplotLabelDimensions {
        const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = X_AXIS_LABELS_FONT;

        const textSize = context.measureText(xAxisLabel);
        const rotationAngle = toRadians(X_AXIS_LABELS_ROTATION_DEGREES - 90);

        const rotatedWidth = Math.abs(
            X_AXIS_LABELS_LINE_HEIGHT * Math.cos(rotationAngle) +
                textSize.width * Math.sin(rotationAngle)
        );
        const rotatedHeight = Math.abs(
            X_AXIS_LABELS_LINE_HEIGHT * Math.sin(rotationAngle) +
                textSize.width * Math.cos(rotationAngle)
        );
        return { width: rotatedWidth, height: rotatedHeight };
    }

    private _createBoxes(): void {
        this._createBoxGroups();
        this._drawMain();
        if (this._isComparing) this._drawComparing();
        this._addHoverBoxes();
    }

    private _drawMain(): void {
        this._addVerticalLines(false);
        this._addBoxes(false);
        this._drawMedian(false);
    }

    private _drawComparing(): void {
        this._addVerticalLines(true);
        this._addBoxes(true);
        this._drawMedian(true);
    }

    private _createBoxGroups(): void {
        this._boxGroupsG = this._svgG.append("g");
        this._boxGroups = this._boxGroupsG
            .selectAll("boxgroup")
            .data(this._data)
            .enter()
            .append("g")
            .attr("class", "box-group");
    }

    private _addVerticalLines(isComparing: boolean): void {
        this._boxGroups
            .data(this._data)
            .append("line")
            .attr("x1", d => {
                const boxGroupStart = this._xScale(d.key);
                const boxGroupWidth = this._xScale.bandwidth();
                const boxGroupMiddle = boxGroupWidth / 2;
                const boxWidth = this._boxWidth;
                const boxMiddle = boxWidth / 2;
                if (isComparing) {
                    const rightAlign = BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle + rightAlign + boxMiddle;
                } else if (!isComparing && this._isComparing) {
                    const leftAlign = boxWidth + BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle - leftAlign + boxMiddle;
                }
                const centerAlign = boxGroupWidth / 2;
                return boxGroupStart + centerAlign;
            })
            .attr("x2", d => {
                const boxGroupStart = this._xScale(d.key);
                const boxGroupWidth = this._xScale.bandwidth();
                const boxGroupMiddle = boxGroupWidth / 2;
                const boxWidth = this._boxWidth;
                const boxMiddle = boxWidth / 2;
                if (isComparing) {
                    const rightAlign = BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle + rightAlign + boxMiddle;
                } else if (!isComparing && this._isComparing) {
                    const leftAlign = boxWidth + BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle - leftAlign + boxMiddle;
                }
                const centerAlign = boxGroupWidth / 2;
                return boxGroupStart + centerAlign;
            })
            .attr("y1", d => {
                if (!isComparing) return this._yScale(d.value.main.min);
                return this._yScale(d.value.comparing.min);
            })
            .attr("y2", d => {
                if (!isComparing) return this._yScale(d.value.main.max);
                return this._yScale(d.value.comparing.max);
            })
            .attr("stroke", d => {
                return this._getColor(
                    d,
                    isComparing ? LgBoxplotTypeIndices.Comparing : LgBoxplotTypeIndices.Main
                );
            });
        // .attr("stroke", isComparing ? "#3E92A9" : "#83D8D8");
    }

    private _addBoxes(isComparing: boolean): void {
        this._boxGroups
            .data(this._data)
            .append("rect")
            .attr("class", "box")
            .attr("x", d => {
                const boxGroupStart = this._xScale(d.key);
                const boxGroupWidth = this._xScale.bandwidth();
                const boxGroupMiddle = boxGroupWidth / 2;
                const boxWidth = this._boxWidth;
                const boxMiddle = boxWidth / 2;
                if (isComparing) {
                    const rightAlign = BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle + rightAlign;
                } else if (!isComparing && this._isComparing) {
                    const leftAlign = boxWidth + BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle - leftAlign;
                }
                const centerAlign = boxGroupWidth / 2;
                return boxGroupStart - boxMiddle + centerAlign;
            })
            .attr("y", d => {
                const datum = !isComparing ? d.value.main : d.value.comparing;
                const startPosition = this._yScale(datum.q3);
                const endPosition = this._yScale(datum.q1);
                if (startPosition == null) return 0;
                if (startPosition !== endPosition) return startPosition;
                return startPosition - MIN_BOX_HEIGHT / 2;
            })
            .attr("height", d => {
                const datum = !isComparing ? d.value.main : d.value.comparing;
                if (datum.median == null) return 0;
                const width = this._yScale(datum.q1) - this._yScale(datum.q3);
                return Math.max(width, MIN_BOX_HEIGHT);
            })
            .attr("width", this._boxWidth)
            .attr("stroke", d => {
                return this._getColor(
                    d,
                    isComparing ? LgBoxplotTypeIndices.Comparing : LgBoxplotTypeIndices.Main
                );
            })
            .style("fill", d => {
                return this._getColor(
                    d,
                    isComparing ? LgBoxplotTypeIndices.Comparing : LgBoxplotTypeIndices.Main
                );
            });
    }

    private _drawMedian(isComparing: boolean): void {
        this._boxGroups
            .data(this._data)
            .append("line")
            .attr("x1", d => {
                const boxGroupStart = this._xScale(d.key);
                const boxGroupWidth = this._xScale.bandwidth();
                const boxGroupMiddle = boxGroupWidth / 2;
                const boxWidth = this._boxWidth;
                const boxMiddle = boxWidth / 2;
                if (isComparing) {
                    const rightAlign = BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle + rightAlign;
                } else if (!isComparing && this._isComparing) {
                    const leftAlign = boxWidth + BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle - leftAlign;
                }
                const centerAlign = boxGroupWidth / 2;
                return boxGroupStart - boxMiddle + centerAlign;
            })
            .attr("x2", d => {
                const boxGroupStart = this._xScale(d.key);
                const boxGroupWidth = this._xScale.bandwidth();
                const boxGroupMiddle = boxGroupWidth / 2;
                const boxWidth = this._boxWidth;
                const boxMiddle = boxWidth / 2;
                if (isComparing) {
                    const rightAlign = boxWidth + BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle + rightAlign;
                } else if (!isComparing && this._isComparing) {
                    const leftAlign = BOX_SPACE_FROM_MIDDLE;
                    return boxGroupStart + boxGroupMiddle - leftAlign;
                }
                const centerAlign = boxGroupWidth / 2;
                return boxGroupStart + boxMiddle + centerAlign;
            })
            .attr("y1", d => {
                if (isComparing && d.value.comparing.median == null) return 0;
                else if (!isComparing && d.value.main.median == null) return 0;
                if (!isComparing) return this._yScale(d.value.main.median);
                return this._yScale(d.value.comparing.median);
            })
            .attr("y2", d => {
                if (isComparing && d.value.comparing.median == null) return 0;
                else if (!isComparing && d.value.main.median == null) return 0;
                if (!isComparing) return this._yScale(d.value.main.median);
                return this._yScale(d.value.comparing.median);
            })
            .attr("stroke", this.medianLineColor === "white" ? "#fff" : "#000")
            .attr("stroke-width", d => {
                const datum = !isComparing ? d.value.main : d.value.comparing;
                if (datum.median == null) return "0px";
                return "2px";
            });
    }

    private _addHoverBoxes(): void {
        const self = this;

        const boxRects = this._boxGroups.append("rect");

        boxRects
            .attr("class", "box-shadow")
            .attr("width", () => this._xScale.bandwidth())
            .attr("height", () => this._verticalPositionOfXAxis - this._margin.top)
            .attr("transform", d => `translate(${this._xScale(d.key)},${this._margin.top})`)
            .attr("fill", "transparent")
            .on("mouseover", function (event: MouseEvent) {
                self._lastMouseX = event.clientX;
                self._lastMouseY = event.clientY;
                const targetBoxes = d3
                    .select(this.parentNode as d3.BaseType)
                    .selectAll(".box") as d3.Selection<any, LgBoxplotDatum, any, any>;
                self._lastHoveredBoxes = targetBoxes;
                self._onBoxMouseOver(targetBoxes);
            })
            .on("mouseout", function (_event: MouseEvent) {
                const targetBoxes = d3
                    .select(this.parentNode as d3.BaseType)
                    .selectAll(".box") as d3.Selection<any, LgBoxplotDatum, any, any>;
                self._onBoxMouseOut(targetBoxes);
            })
            .on("mouseleave", (event: MouseEvent) => {
                const x = event.clientX;
                const y = event.clientY;
                if (!x && !y) {
                    this._tooltip.hide();
                    this._tooltipHidden = true;
                    return;
                }
                const rects = (this._boxGroupsG.node() as HTMLElement)?.getBoundingClientRect();
                const isInX = x > rects.left && x < rects.right;
                const isInY = y > rects.top && y < rects.bottom;
                const isInElement = isInX && isInY;
                if (!isInElement) {
                    this._tooltip.hide();
                    this._tooltipHidden = true;
                }
            })
            .on("click", function (_event: MouseEvent, d: LgBoxplotDatum) {
                const index = boxRects.nodes().indexOf(this);
                self._onClick(d, index);
            });
    }

    private _addAxisHoverHandler(): void {
        const self = this;
        this._xAxisGroup
            .selectAll(".tick")
            .on("mouseover", function (event: MouseEvent, name: string | unknown) {
                self._lastMouseX = event.clientX;
                self._lastMouseY = event.clientY;
                self._boxGroups.each(function (d) {
                    if (d.key !== name) return;
                    const targetBoxes = d3.select(this).selectAll(".box") as d3.Selection<
                        any,
                        LgBoxplotDatum,
                        any,
                        any
                    >;
                    self._lastHoveredBoxes = targetBoxes;
                    self._onBoxMouseOver(targetBoxes);
                });
                self._isLabelHovered = true;
            })
            .on("mouseleave", function (_event: MouseEvent, name: string | unknown) {
                self._boxGroups.each(function (d) {
                    if (d.key !== name) return;

                    const targetBoxes = d3.select(this).selectAll(".box") as d3.Selection<
                        any,
                        LgBoxplotDatum,
                        any,
                        any
                    >;
                    self._onBoxMouseOut(targetBoxes);
                    self._tooltip.hide();
                    self._tooltipHidden = true;
                });
                self._isLabelHovered = false;
            });
    }

    private _onBoxMouseOver(targets: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        this._shadowBoxGroups
            .selectAll(".shadow-box-rect")
            .filter((d: any) => d.key === targets.data()[0].key)
            .style("fill", "#F5F9FF");

        const first = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Main);
        const second = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing);

        first.style("fill", d => {
            return this._getColor(d, LgBoxplotTypeIndices.Main, true);
        });
        second.style("fill", d => {
            return this._getColor(d, LgBoxplotTypeIndices.Comparing, true);
        });

        const hoveredItem = targets.data()[0];
        const itemName = hoveredItem.key;
        const itemValue = hoveredItem.value;
        this.tooltipContext = {
            name: itemName,
            main: itemValue.main,
            comparing: itemValue.comparing,
            groupToLegendDefinitionDictionary: this._groupToLegendDefinitionDictionary,
            highlighted: this._highlighted,
            isComparing: this._tooltipIsComparing,
            isLabelHovered: this._isLabelHovered,
            iconTooltip: false
        };
        if (this._tooltipHidden) {
            this._tooltip.show();
            this._tooltipHidden = false;
            this._updateTooltipPosition();
        }
    }

    private _onBoxMouseOut(targets: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        const first = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Main);
        const second = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing);

        first
            .attr("stroke", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Main);
            })
            .attr("stroke-height", 2)
            .style("fill", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Main);
            });
        second
            .attr("stroke", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing);
            })
            .attr("stroke-height", 2)
            .style("fill", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing);
            });
        this._shadowBoxGroups
            .selectAll(".shadow-box-rect")
            .filter((d: any) => d.key === targets.data()[0].key)
            .style("fill", "transparent");
    }

    private _onClick(value: any, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private _getColor(
        item: LgBoxplotDatum | null,
        type: LgBoxplotTypeIndices,
        darker?: boolean
    ): string {
        if (this.useColorsFromData && item) return item.value.main.legendItem.color;
        if (this._colorPalette.useNewColorPalette) {
            const color = this._groupColors(type === LgBoxplotTypeIndices.Comparing ? "1" : "0");
            return darker ? d3.color(color).darker(0.2).formatHex() : color;
        }
        return this._getLegacyColor(item, type, darker);
    }

    /**
     * @deprecated
     */
    private _getLegacyColor(
        item: LgBoxplotDatum | null,
        type: LgBoxplotTypeIndices,
        darker?: boolean
    ): any {
        if (this.useColorsFromData && item?.value) {
            return (
                type === LgBoxplotTypeIndices.Comparing ? item.value.comparing : item.value.main
            ).legendItem.color;
        }
        const color = d3.rgb(
            this._groupColorsHex && this._groupColorsHex?.[type]
                ? this._groupColorsHex[type]
                : this._groupColors(type.toString())
        );
        if (!darker) return color;
        return color.darker(0.2);
    }

    private get _horizontalPositionOfYAxis(): number {
        return (
            this._margin.left +
            (this.yAxisLabel ? Y_AXIS_TITLE_WIDTH : 0) +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_Y_LABELS_AND_GRID
        );
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize();
        return (
            this._width -
            this._margin.left -
            this._margin.right -
            (legendOnTheRight ? legendSize : 0)
        );
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const legendSize = this._getLegendSize();
        return (
            this._height - this._margin.top - this._margin.bottom - (legendBelow ? legendSize : 0)
        );
    }

    private get _verticalPositionOfXAxis(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const margin = isLegendBelow ? 10 : this._margin.bottom;
        const spaceForAxisTitle = this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0;
        return this._svgHeight - margin - spaceForAxisTitle - this._spaceForXAxisLabels;
    }

    private get _spaceForXAxisLabels(): number {
        if (!this.showXAxisLabels) {
            return 0;
        }
        if (this.rotateXAxisLabels) {
            return this.rotatedXAxisLabelsHeight;
        }
        return X_AXIS_LABELS_LINE_HEIGHT;
    }

    private get _verticalPositionOfxAxisLabel(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        return this._svgHeight - (isLegendBelow ? 5 : 0);
    }

    private get _boxWidth(): number {
        const boxWidth = this._xScale.bandwidth() / 2;
        const boxWidthComparing = boxWidth / 2;
        return this._isComparing ? boxWidthComparing : boxWidth;
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;
        return getLegendWidth(this._width, this.legendOptions.widthInPercents, this._groupNames);
    }
}
