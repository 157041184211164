import ldIsEqual from "lodash-es/isEqual";
import ldCloneDeep from "lodash-es/cloneDeep";

import {
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from "@angular/core";
import { ConnectedPosition } from "@angular/cdk/overlay";
import { toBoolean } from "@logex/framework/utilities";
import {
    IQuickSettingsIconDefinition,
    IQuickSettingsMenuItem,
    IQuickSettingsMenuRegularItem,
    QuickSettingsMenuItemId
} from "./lg-quick-settings-menu.types";
import { LgQuickSettingsMenuHost } from "./lg-quick-settings-menu-host";
import { LgQuickSettingsMenuPopupComponent } from "./lg-quick-settings-menu-popup.component";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-quick-settings-menu",
    template: `<lg-icon [icon]="icon" (click)="_showMenu($event)"></lg-icon>`,
    imports: [LgIconComponent],
    host: {
        class: "lg-icon-menu",
        "[class.lg-icon-menu__default]": "!customClassName",
        "[class.lg-icon-menu--active]": "_popupActive",
        "[class.disabled]": "_disabled"
    }
})
export class LgQuickSettingsMenuComponent
    extends LgQuickSettingsMenuHost
    implements OnChanges, OnDestroy
{
    private _elementRef = inject(ElementRef);

    /**
     * Menu icon.
     */
    @Input() icon = "icon-datasource";

    /**
     * Definition of menu items (required).
     */
    @Input({ required: true }) definition!: IQuickSettingsMenuItem[];

    @Input() value: any = null;

    @Input("class") customClassName: string;

    /**
     * Currently works only in combination with `rightIcons` from `IQuickSettingsMenuChoice`,
     */
    @Input() iconDefinitions: IQuickSettingsIconDefinition[] = [];

    /**
     *  Used for highlighting 'active' rows similarly to `lg-breadcrumb`
     *  But this component leaves responsibility to say which items are active on the consumer
     */
    @Input() selectedItems?: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[];

    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value, false);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() set alwaysLeftIcon(value: boolean) {
        this._alwaysLeftIcon = toBoolean(value);
    }

    get alwaysLeftIcon(): boolean {
        return this._alwaysLeftIcon;
    }

    /**
     * Specifies if menu option items should be compacted.
     *
     * @default false
     */
    @Input() set compact(value: boolean | "auto") {
        if (value === "auto") {
            this._compact = null;
        } else {
            this._compact = toBoolean(value);
        }
    }

    get compact(): boolean | "auto" {
        return this._compact === null ? "auto" : this._compact;
    }

    @Input() set onRight(value: boolean | "true" | "false") {
        this._onRight = toBoolean(value);
    }

    get onRight(): boolean {
        return this._onRight;
    }

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly change = new EventEmitter<any>();

    _disabled = false;

    private _alwaysLeftIcon = false;
    private _compact: boolean | null = null;
    private _onRight = false;

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition && this._popupInstance) {
            this._popupInstance.updateDefinition(this.definition, this.selectedItems);
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showMenu($event: MouseEvent): boolean {
        if (this._popupActive || this._disabled) return true;

        $event.stopPropagation();

        let positions: ConnectedPosition[] = [
            { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
            { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
            { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
            { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
        ];

        if (this._onRight) {
            positions = [...positions.slice(2, 4), ...positions.slice(0, 2)];
        }

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions(positions);

        super
            ._showSettingsPopup(this._elementRef, strategy, true, {
                definition: this.definition,
                value: ldCloneDeep(this.value),
                depth: 0,
                compact: this._compact,
                alwaysLeftIcon: this._alwaysLeftIcon,
                iconDefinitions: this.iconDefinitions,
                selectedItems: this.selectedItems
            })
            .subscribe(
                result => {
                    this._close();
                    if (!ldIsEqual(this.value, result)) {
                        this.value = result;
                        this.change.next(result);
                    }
                },
                null,
                () => this._close()
            );

        return false;
    }

    private _close(): void {
        this._hideSettingsPopup();
    }
}
