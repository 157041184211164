import ldIsEqual from "lodash-es/isEqual";
import ldValues from "lodash-es/values";
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    HostListener,
    ElementRef,
    HostBinding,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    inject
} from "@angular/core";

import { Subject, Observable } from "rxjs";
import { takeUntil, first } from "rxjs/operators";

import { IFilterOption } from "@logex/framework/types";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgOverlayService, IOverlayResultApi } from "../../lg-overlay/lg-overlay.service";

import { LgMultiFilterPopupComponent } from "../lg-multi-filter/lg-multi-filter-popup.component";
import {
    LgMultiFilterLook,
    LgMultiFilterItemCustomization
} from "../lg-multi-filter/lg-multi-filter.types";
import { toBoolean } from "@logex/framework/utilities";
import { NgIf } from "@angular/common";

export type LgMultiButtonFilterSourceValue =
    | IFilterOption[]
    | Promise<IFilterOption[]>
    | Observable<IFilterOption[]>;
export type LgMultiFilterSource =
    | (() => LgMultiButtonFilterSourceValue)
    | LgMultiButtonFilterSourceValue;

@Component({
    standalone: true,
    selector: "lg-multi-filter-button",
    template: `
        <button class="button lg-multi-filter-button {{ className }}">
            <span> {{ _displayText }} </span>
            <span *ngIf="_numberOfActiveOptions > 1" class="lg-multi-filter-button__count">
                {{ _numberOfActiveOptions }}
            </span>
        </button>
    `,
    imports: [NgIf],
    encapsulation: ViewEncapsulation.None
})
export class LgMultiFilterButtonComponent implements OnDestroy, OnInit {
    protected _translate = inject(LgTranslateService);
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);

    /**
     * Button label (localized)
     */
    @Input() textLc?: string;

    /**
     * Button label in case of multiple selection (localized)
     */
    @Input() multipleTextLc?: string;

    @Input() set condensed(value: boolean) {
        this._condensed = toBoolean(value);
        this._setHostClass();
    }

    get condensed(): boolean {
        return this._condensed;
    }

    static ngAcceptInputType_condensed: boolean | "true" | "false";

    @Input() set wide(value: boolean) {
        this._wide = toBoolean(value);
        this._setHostClass();
    }

    get wide(): boolean {
        return this._wide;
    }

    static ngAcceptInputType_wide: boolean | "true" | "false";

    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value);
        this._setHostClass();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    static ngAcceptInputType_disabled: boolean | "true" | "false";

    @Input() placeholder?: string;

    @Input() set filter(filter: any) {
        this._filter = filter;
        this._refilter();
    }

    get filter(): any {
        return this._filter;
    }

    /**
     * Data source.
     */
    @Input({ required: true }) set source(value: LgMultiFilterSource) {
        this._source = value;
    }

    get source(): LgMultiFilterSource {
        return this._source;
    }

    @Input() showOnInit = false;

    @Output() readonly filterChange = new EventEmitter<any>();

    @Output() readonly activeChange = new EventEmitter<boolean>();

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-native
    @Output("show") readonly onShow = new EventEmitter<void>();

    @Input() set showDataCounts(val: boolean) {
        this._showDataCounts = toBoolean(val, false);
    }

    get showDataCounts(): boolean {
        return this._showDataCounts;
    }

    static ngAcceptInputType_showDataCounts: boolean | "true" | "false";

    @Input() popupItemCustomization: LgMultiFilterItemCustomization | null = null;

    /**
     * Apply css class to the button.
     */
    @Input() className?: string;

    @HostBinding("class") _hostClass = "lg-multi-filter-button";

    // --------------------------------------------------------------------------------------------------------
    _displayText: string;

    _empty = true;
    _numberOfActiveOptions = 0;
    _active = false;
    _disabled = false;
    _condensed = false;
    _wide = false;
    _activeOptions: string[] | null = null;
    private _showDataCounts = false;
    private _filter: any = { $empty: true };
    private _destroyed$ = new Subject<void>();
    private _source!: LgMultiFilterSource;
    private _popupHidden$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi;
    private _popupInstance: LgMultiFilterPopupComponent;

    private _look: LgMultiFilterLook = "default";

    // --------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------
    private _refilter(): void {
        if (this._filter && !this._filter.$empty) {
            this._empty = false;
            this._activeOptions = ldValues(this._filter); // this._filter is of any type...
            this._numberOfActiveOptions = this._activeOptions.length;
        } else {
            this._empty = true;
            this._activeOptions = null;
            this._numberOfActiveOptions = 0;
        }

        if (this._active) {
            this._popupInstance.setSelection(this._filter);
        }

        this._displayText = this._setDisplayText();

        this._setHostClass();
    }

    ngOnInit(): void {
        if (this.showOnInit) {
            setTimeout(() => this._onClick(), 0);
        }
    }

    ngOnDestroy(): void {
        if (this._active) {
            this._doClose(true);
        }

        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _setHostClass(): void {
        let cls = "lg-multi-filter-button";

        if (this._disabled) {
            cls += " lg-multi-filter-button--disabled";
        }

        this._hostClass = cls;
    }

    // --------------------------------------------------------------------------------------------------------
    @HostListener("click")
    _onClick(): boolean {
        if (!this._disabled) {
            this._doShow();
        }
        return false;
    }

    // --------------------------------------------------------------------------------------------------------
    private _doClose(immediately?: boolean): void {
        if (!this._active) return;

        this._active = false;
        this._popupHidden$.next();
        this._popupHidden$.complete();
        this.activeChange.emit(false);

        if (immediately) {
            this._overlayInstance.hide();
        } else {
            const overlayInstance = this._overlayInstance;
            this._popupInstance
                .hide()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance.hide();
                });
        }

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;

        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _doShow(): void {
        this._popupHidden$ = new Subject<void>();

        const element = this._elementRef.nativeElement as HTMLElement;
        const elementRect = element.getBoundingClientRect();
        const cachedElementRef = new ElementRef({
            getBoundingClientRect: () => {
                const bcr = this._elementRef.nativeElement.getBoundingClientRect();
                if (!(bcr.left === 0 && bcr.top === 0 && bcr.width === 0 && bcr.height === 0)) {
                    return elementRect;
                } else {
                    return bcr;
                }
            }
        });
        let strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .setOrigin(cachedElementRef);

        strategy = strategy.withPositions([
            { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
            { originX: "end", originY: "bottom", overlayX: "end", overlayY: "bottom" }
        ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => {
                if (this._popupInstance) this._popupInstance._attemptClose();
            },
            hasBackdrop: true,
            trapFocus: true,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgMultiFilterPopupComponent>(
            LgMultiFilterPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance._updatePosition(change);
        });

        this._popupInstance
            ._initialize({
                target: this._elementRef,
                filter: this._filter,
                placeholder: this.placeholder,
                source: this._source,
                show: this.onShow,
                condensed: this._condensed,
                wide: this._wide,
                reposition: () => strategy.apply(),
                look: this._look,
                readonly: this.disabled,
                showDataCounts: this._showDataCounts,
                itemCustomization: this.popupItemCustomization,
                transparentSearchBackground: false,
                searchWidthMatchesPopup: true
            })
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                this._doClose();
                if (result !== undefined) {
                    const changed = !ldIsEqual(this._filter, result);
                    if (changed) {
                        this._filter = result;
                        this.filterChange.next(result);
                    }
                }
            });

        this._active = true;
        this.activeChange.emit(true);

        this._changeDetectorRef.markForCheck();
    }

    private _setDisplayText(): string {
        if (this._numberOfActiveOptions === 0) return this._translate.translate(this.textLc);
        if (this._numberOfActiveOptions === 1) return this._activeOptions[0];
        return this._translate.translate(this.multipleTextLc);
    }
}
