import { Injectable } from "@angular/core";

import {
    ConversionResultStatus,
    LineChartConverterConfig,
    LineChartConverterResult,
    LineChartItem
} from "./lg-line-chart.types";

@Injectable()
export class LineDataConverter {
    private getColumnName: (item: any) => string;
    private getColumnTopName: (item: any) => string;
    private showColumnTopName: (item: any) => boolean;
    private getGroupNames: string[] | (() => string[]);
    private getGroupValues: (item: any) => Array<number | null | undefined>;
    private getSpreadValues?: (item: any) => Array<[number, number] | null> | null;

    configure({
        getColumnName,
        getColumnTopName,
        showColumnTopName,
        getGroupNames,
        getGroupValues,
        getSpreadValues
    }: LineChartConverterConfig): void {
        this.getColumnName = getColumnName;
        this.getColumnTopName = getColumnTopName;
        this.showColumnTopName = showColumnTopName;
        this.getGroupNames = getGroupNames;
        this.getGroupValues = getGroupValues;
        this.getSpreadValues = getSpreadValues;
    }

    convert(rawData: any[]): LineChartConverterResult {
        if (!rawData || !rawData.length) return { status: ConversionResultStatus.Empty };

        let data = [];
        const columnNames = [];
        let groupNames: string[] = null;
        const tempResult: LineChartItem[][] = [];

        rawData.forEach((value, index) => {
            const columnName = this.getColumnName(value);
            columnNames.push(columnName);
            const columnTopName =
                this.getColumnTopName && this.showColumnTopName && this.showColumnTopName(value)
                    ? this.getColumnTopName(value)
                    : null;

            if (groupNames == null)
                groupNames = Array.isArray(this.getGroupNames)
                    ? this.getGroupNames
                    : this.getGroupNames();

            const values = this.getGroupValues(value);
            if (!values) return;

            const spreadValues = this.getSpreadValues ? this.getSpreadValues(value) : null;
            const row: LineChartItem[] = [];
            for (let i = 0, l = values.length; i < l; ++i) {
                row.push({
                    indexOfGroup: i,
                    indexWithinGroup: index,
                    column: columnName,
                    columnTop: columnTopName,
                    group: groupNames[i],
                    value: values[i],
                    spread: spreadValues ? spreadValues[i] : undefined,
                    item: value
                });
            }
            tempResult.push(row);
        });

        // now swap the order
        data = [];
        let min = null;
        let max = 0;
        for (let j = 0; j < tempResult.length; ++j) {
            const groups = tempResult[j];
            for (let i = 0; i < groups.length; ++i) {
                const groupValue = groups[i];
                let store: LineChartItem[];
                if (j === 0) {
                    store = [];
                    data.push(store);
                } else {
                    store = data[i];
                }
                max = Math.max(
                    max,
                    Math.max(
                        groupValue.value,
                        this._getSpreadExtreme(groupValue.spread, true, groupValue.value)
                    )
                );
                min = Math.min(
                    min ?? groupValue.value,
                    Math.min(
                        groupValue.value,
                        this._getSpreadExtreme(groupValue.spread, false, groupValue.value)
                    )
                );
                store.push(groupValue);
            }
        }

        return {
            status: ConversionResultStatus.Valid,
            data,
            extremes: { min, max }
        };
    }

    private _getSpreadExtreme(spread: number[], isMax: boolean, defaultValue: number): number {
        return spread ? spread[isMax ? 1 : 0] : defaultValue;
    }
}
