import ldDefaults from "lodash-es/defaults";

import { merge, Observable, Subject } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    shareReplay,
    startWith,
    switchMap,
    takeUntil
} from "rxjs/operators";
import {
    ChangeDetectorRef,
    ContentChildren,
    Directive,
    ElementRef,
    inject,
    OnDestroy,
    QueryList
} from "@angular/core";
import {
    ConnectedOverlayPositionChange,
    ConnectedPosition,
    Overlay,
    ScrollDispatcher
} from "@angular/cdk/overlay";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { easingDefs } from "@logex/framework/utilities";

import { LgTagsSelectorTagComponent } from "./lg-tags-selector-tag.component";
import { IDropdownDefinition, IDropdownGroup } from "../lg-dropdown/index";
import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay/index";
import { IOverlayOptions } from "../../lg-overlay/lg-overlay.service";
import ldGroupBy from "lodash-es/groupBy";
import ldMap from "lodash-es/map";
import ldSortBy from "lodash-es/sortBy";

export type PopupComponentVisibility = "hidden" | "initial" | "visible";

export const Animations = trigger("state", [
    state("initial, hidden", style({ opacity: 0 })),
    state("visible", style({ opacity: 1 })),

    transition("* => visible", [
        style({ opacity: 0 }),
        animate(`200ms ${easingDefs.easeOutCubic}`, style({ opacity: 1 }))
    ]),

    transition("* => hidden", [animate(`200ms ${easingDefs.easeOutCubic}`)])
]);

interface ITagInfo {
    tag: LgTagsSelectorTagComponent;
    position: number;
}

@Directive()
export abstract class LgTagsSelectorBaseDirective implements OnDestroy {
    _elementRef = inject(ElementRef);
    _changeDetectorRef = inject(ChangeDetectorRef);
    _lgTranslate = inject(LgTranslateService);
    _overlay = inject(Overlay);
    _overlayService = inject(LgOverlayService);
    _scrollDispatcher = inject(ScrollDispatcher);

    // ----------------------------------------------------------------------------------
    // Fields
    _tagsCount = 0;
    _emptyTagsCount = 0;
    _isAddingNewTag = false;
    _anchorElementRef: ElementRef<any>;
    _pmOnHide$: Subject<void>;
    _visibility: PopupComponentVisibility;
    _matchWidth = false;

    @ContentChildren(LgTagsSelectorTagComponent)
    protected _tagComponents: QueryList<LgTagsSelectorTagComponent>;

    protected _addTagDropdown: IDropdownDefinition<number>;
    protected _destroyed$ = new Subject<void>();

    private _phIsActive: boolean;
    private _phPopupHidden$: Subject<void>;
    private _phOverlayInstance: IOverlayResultApi;
    private _phPopupInstance: any;
    private _tagComponentsObservable: Observable<LgTagsSelectorTagComponent[]>;
    private _lastSyncedEmptyTags: LgTagsSelectorTagComponent[];
    private _pmRepositionCb: () => void;

    // ----------------------------------------------------------------------------------
    // Methods

    _initMixins(): void {
        if (this._elementRef == null) throw Error("_elementRef must be injected");
        if (this._changeDetectorRef == null) throw Error("_changeDetectorRef must be injected");
        if (this._overlay == null) throw Error("_overlay must be injected");
        if (this._scrollDispatcher == null) throw Error("_scrollDispatcher must be injected");
        if (this._overlayService == null) throw Error("_overlayService must be injected");

        this._phIsActive = false;
        this._phPopupHidden$ = new Subject<void>();
        this._pmOnHide$ = new Subject();
        this._visibility = "initial";
    }

    _doShowPopup<TPopup, TResult = any>(args: {
        anchorElementRef?: ElementRef<any>;
        componentType: ComponentType<TPopup>;
        initPopupComponent: (instance: TPopup) => Observable<any>;
        anchorPositions?: ConnectedPosition[];
    }): Promise<TResult> {
        ldDefaults(args, {
            anchorElementRef: this._elementRef,
            anchorPositions: [
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]
        });

        return new Promise<TResult>(resolve => {
            this._phPopupHidden$ = new Subject<void>();

            // HACK: Exploits the fact that CDK overlay strategy uses only getBoundingClientRect to get position of anchor element
            const element = args.anchorElementRef.nativeElement as HTMLElement;
            const elementRect = element.getBoundingClientRect();

            const cachedElementRef = new ElementRef({
                getBoundingClientRect: () => {
                    const bcr = this._elementRef.nativeElement.getBoundingClientRect();
                    if (!(bcr.left === 0 && bcr.top === 0 && bcr.width === 0 && bcr.height === 0)) {
                        return bcr;
                    } else {
                        return elementRect;
                    }
                }
            });

            const strategy = this._overlay
                .position()
                .flexibleConnectedTo(args.anchorElementRef)
                .setOrigin(cachedElementRef)
                .withPositions(args.anchorPositions);

            strategy.withScrollableContainers(
                this._scrollDispatcher.getAncestorScrollContainers(args.anchorElementRef)
            );

            const overlayConfig: IOverlayOptions = {
                onClick: () => {
                    if (this._phPopupInstance) this._phPopupInstance.attemptClosePopup();
                },
                hasBackdrop: true,
                trapFocus: true,
                sourceElement: args.anchorElementRef,
                positionStrategy: strategy,
                scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
            };

            if (this._matchWidth) {
                overlayConfig.width = Math.ceil(elementRect.width) + "px";
            }

            this._phOverlayInstance = this._overlayService.show(overlayConfig);

            const portal = new ComponentPortal<TPopup>(args.componentType);
            this._phPopupInstance = this._phOverlayInstance.overlayRef.attach(portal).instance;

            strategy.positionChanges.pipe(takeUntil(this._phPopupHidden$)).subscribe(change => {
                this._phPopupInstance.updatePopupPosition(change);
            });

            this.configurePopup(args.anchorElementRef, () => strategy.apply());

            args.initPopupComponent(this._phPopupInstance as TPopup)
                .pipe(takeUntil(this._phPopupHidden$))
                .subscribe(result => {
                    this._doClosePopup();
                    resolve(result);
                });

            this._phIsActive = true;

            this._changeDetectorRef.markForCheck();
        });
    }

    _doClosePopup(immediately = false): void {
        if (!this._phIsActive) return;

        this._phIsActive = false;
        this._phPopupHidden$.next();
        this._phPopupHidden$.complete();

        if (immediately) {
            this._phOverlayInstance.hide();
        } else {
            const overlayInstance = this._phOverlayInstance;
            this._phPopupInstance
                .hidePopup()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance.hide();
                });
        }

        this._phPopupHidden$ = null;
        this._phOverlayInstance = null;
        this._phPopupInstance = null;

        this._changeDetectorRef.markForCheck();
    }

    protected _bindToTagComponents(): void {
        // Then display text of tags is changed -> sync their width and visibility
        this._aggregateTagComponentsEvent(x => x.displayChangedEvent)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._syncVisibility();
            });

        // TODO: Bind _syncTags only to "empty" <-> "not empty" transition
        this._aggregateTagComponentsEvent(x => x.currentChange)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._syncTags();
            });
    }

    /**
     * Aggregate `displayChangedEvents` from all tag components. If visibility of a tag component change then the subscription must be updated
     *
     * @private
     */
    private _aggregateTagComponentsEvent<T>(
        cbEvent: (x: LgTagsSelectorTagComponent) => Observable<T>
    ): Observable<any> {
        if (this._tagComponentsObservable == null) {
            this._tagComponentsObservable = this._tagComponents.changes.pipe(
                startWith(this._tagComponents),
                map((list: QueryList<LgTagsSelectorTagComponent>) => list.toArray()),
                distinctUntilChanged((x, y) => {
                    const xLength = x.length;
                    if (xLength !== y.length) return false;
                    for (let i = 0; i < xLength; i++) {
                        if (x[i] !== y[i]) return false;
                    }
                    return true;
                }),
                shareReplay(1)
            );
        }

        return this._tagComponentsObservable.pipe(
            switchMap((tags: LgTagsSelectorTagComponent[]) =>
                merge(...tags.map(cbEvent)).pipe(startWith(true))
            ), // Emit when tagComponents collection change
            debounceTime(1)
        );
    }

    protected _syncTags(): void {
        this._tagsCount = this._tagComponents.length;

        const emptyTags = this._tagComponents.filter(x => x.isEmpty);

        // Check if "empty" state of some tags has changed
        if (this._lastSyncedEmptyTags != null) {
            if (
                this._lastSyncedEmptyTags.length === emptyTags.length &&
                this._lastSyncedEmptyTags.every((x, i) => x === emptyTags[i])
            ) {
                // Nothing has changed
                return;
            }
        }

        this._lastSyncedEmptyTags = emptyTags;
        this._emptyTagsCount = emptyTags.length;
        this._configureAddTagDropdown();

        this._changeDetectorRef.markForCheck();
    }

    protected _syncVisibility(): void {
        // empty function
    }

    protected _configureAddTagDropdown(): void {
        if (this._tagComponents == null) return;

        const tagDataList: ITagInfo[] = this._tagComponents.map((tagComponent, index) => ({
            tag: tagComponent,
            position: index
        }));

        const tagDataByGroupName = ldGroupBy(tagDataList, x => x.tag.tagGroup.groupName);

        const groups: IDropdownGroup[] = ldMap(tagDataByGroupName, (items, groupName) => ({
            id: groupName,
            name: groupName,
            groupOrder: items[0].tag.tagGroup.groupOrder,
            entries: this._prepareTags(items)
        }));

        if (groups.length === 1) {
            groups[0].name = this._lgTranslate.translate(".AddTagDropdownTitle");
        }

        this._addTagDropdown = {
            groupId: "id",
            groupName: "name",
            groups,
            allIconsInCurrent: true,
            iconName: "icon"
        } as IDropdownDefinition<number>;
    }

    private _prepareTags(
        items: ITagInfo[]
    ): Array<{ id?: number; name?: string; disabled?: boolean }> {
        const filteredItems = items.filter(x => x.tag.isEmpty);
        const sortedItems = ldSortBy(filteredItems, tagData =>
            tagData.tag.tagOrder != null ? tagData.tag.tagOrder : tagData.position
        );
        return sortedItems.map(tagData => ({
            id: tagData.position,
            name: tagData.tag.tagName,
            disabled: tagData.tag.disabled
        }));
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public _getAddTagDropdown() {
        return this._addTagDropdown;
    }

    async _addTag(tagIdx: number): Promise<void> {
        let anchorElementRef = this._elementRef;
        const addButtonQuery = (
            this._elementRef.nativeElement as HTMLDivElement
        ).getElementsByClassName("lg-tags-selector__add");
        if (addButtonQuery.length > 0) {
            const addButton = addButtonQuery[0] as HTMLDivElement;
            anchorElementRef = new ElementRef(addButton);
        }

        this._isAddingNewTag = true;

        const tag = this._tagComponents.toArray()[tagIdx];
        await tag.show(anchorElementRef);

        this._isAddingNewTag = false;
    }

    configurePopup(target: ElementRef<any>, repositionCb: () => void): void {
        this._anchorElementRef = target;
        this._pmRepositionCb = repositionCb;
    }

    attemptClosePopup(): void {
        throw Error("'attemptClose' must be implemented by the class that uses mixin.");
    }

    hidePopup(): Observable<void> {
        this._visibility = "hidden";
        return this._pmOnHide$.asObservable();
    }

    updatePopupPosition(_change: ConnectedOverlayPositionChange): void {
        // empty function
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
