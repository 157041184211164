import {
    Directive,
    DoCheck,
    Input,
    Output,
    EventEmitter,
    HostListener,
    HostBinding
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

export interface LgMultiselectChange<T> {
    id: T;
    newValue: boolean;
    selections: Record<string, any>;
}

@Directive({
    standalone: true,
    selector: "[lgMultiselect]"
})
export class LgMultiselectDirective<T> implements DoCheck {
    @Input("lgMultiselect") storage: Record<string, any>;

    @Input({ alias: "lgMultiselectId", required: true }) id!: T;

    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output("change") readonly change = new EventEmitter<LgMultiselectChange<T>>();
    private _disabled = false;

    @HostListener("click")
    _onClick(): boolean {
        if (this._disabled) return true;

        let newValue: boolean;

        if (this.storage["" + this.id]) {
            delete this.storage["" + this.id];
            newValue = false;
        } else {
            this.storage["" + this.id] = true;
            newValue = true;
        }

        this.change.next({
            id: this.id,
            newValue,
            selections: this.storage
        });

        return false;
    }

    @HostBinding("class.lg-multiselect-active")
    _active: boolean | undefined = undefined;

    ngDoCheck(): void {
        this._active = !!this.storage["" + this.id];
    }
}
