import { lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import { Provider } from "@angular/core";
import { IHelpTooltipConfig, LG_HELP_TOOLTIP_CONFIG } from "../help-tooltips";
import { LG_PASTE_DIALOG_CONFIGURATION, PasteDialogConfiguration } from "../copy-paste";

export interface UiToolboxConfig {
    /**
     * Provides configuration for help tooltips (token `LG_HELP_TOOLTIP_CONFIG`).
     * Optional. No default value.
     */
    helpTooltipConfig?: LgProvider<IHelpTooltipConfig>;
    /**
     * Provides configuration of paste dialog (token `LG_PASTE_DIALOG_CONFIGURATION`).
     * Optional. No default value.
     */
    pasteDialogConfig?: LgProvider<PasteDialogConfiguration>;
}

/**
 * Helper utility to configure and provide framework's `UiToolboxModule` providers
 */
export const uiToolboxProviders: LgProviders<UiToolboxConfig> = (
    config: UiToolboxConfig
): Provider[] => {
    const providers: Provider[] = [];
    if (config.helpTooltipConfig) {
        providers.push(lgProvide(LG_HELP_TOOLTIP_CONFIG, config.helpTooltipConfig));
    }
    if (config.pasteDialogConfig) {
        providers.push(lgProvide(LG_PASTE_DIALOG_CONFIGURATION, config.pasteDialogConfig));
    }

    return providers;
};
