/*
 * TODO:
 * - test IDENTITY() when we want to keep the location
 * - consider introducing # for temporaries, to allow something like
 *      "#kosten=SUM(kosten)",
 *      "kostprijs=DIV(#kosten, aantal)"
 *   The temp objects would be stored on elementTemp (per-node) and storeTemp. This would require additional parameter for
 *   target, and per parameter. Some functions might not support (avg global)
 * - modify ADD/MUL for more than 2 operands?
 */

import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";
import ldIsFunction from "lodash-es/isFunction";

import * as types from "@logex/framework/types";

import type { INormalizedLogexPivotDefinition } from "./lg-pivot.types";
import {
    ICalculationBlock,
    ICalculatorFunction,
    ICalculatorFunctionDictionary,
    ICalculationStep,
    ICompiledTupple,
    ICalculatorLevelStore,
    IStepDefinition,
    IFunctionLocations,
    IFunctionOptions,
    ICalculatorCallbackDefinition,
    FunctionParameter,
    ICalculatorCallback,
    ICompiledFunction
} from "./lg-pivot-calculator.types";

/**
 * Class that parses the calculator block definition and compiles it into a function for evaluating. The compiler
 * contains dictionary of function generators that must be filled first (for example by using RegisterCommonFunctions).
 *
 * TODO: decide, where to put documentation of all the supported functions
 */
export class CalculatorCompiler {
    private _functions: ICalculatorFunctionDictionary = {};
    // groups (target, function, parameters, location, condition, options)
    private _functionRegex =
        /^(?:([^=\s]+)?\s*=)?\s*(\w+)\s*\(\s*([^)]*)\s*\)\s*(?:(?:at|on|in)\s+(\w+(?:\s*,\s*\w*)*))?\s*(?:if\s*\(\s*([^)]+)\s*\))?\s*(?:options\s*\(\s*([^)]+\s*)\s*\))?\s*;?\s*$/i;

    // groups (target, source, location, condition)
    private _assignRegex =
        /^([^=(\s]+)\s*=\s*([^(\s]+)\s*(?:(?:at|on|in)\s+(\w+(?:\s*,\s*\w*)*))?\s*(?:if\s*\(\s*([^)]+)\s*\))?\s*;?\s*$/i;

    /**
     * Register a new function generator. The name is automatically converted to uppercase
     */
    registerFunction(name: string, fn: new () => ICalculatorFunction): void {
        this._functions[name.toUpperCase()] = fn;
    }

    /**
     * Compile a block definition, returning the compiled function that evaluates it (and additional information useful for
     * merging with block higher up in the hierarchy). The compiled function contains explicit dependency of the given
     * pivot level; therefore for every level the function should be called again (even if the block specification is inherited)
     *
     * @param block is definition of the calculation block
     * @param childHelper is the second output of compiler invoked on lower level; this is used for merging
     * @param blockName is the name of the calculation block. This is used for debugging purposes only
     * @param localFunctions is optional dictionary containing additional function generators. This dictionary (if specified)
     *    has priority over the built-in one, and can be therefore used also to overwrite the default functions (that is however
     *    not recommended)
     *
     * Currently, full syntax for the function is
     * target = FUNCTION( param1, param2, ... ) AT location1, location2 IF ( condition ) OPTIONS( option1, option2... )
     * All the parts apart from FUNCTION are optional, depending on the function in question. AT can be replaced by ON or IN
     * to fit better the grammar :).
     * Parameter is considered global if it starts with (,!,[,{,digit,",',-,+,context.,element.,store.,element[,store[ or if it's equal to null.
     * Some parameters in some functions can be only property type (f.ex. SUM(aantal) ) and some can be only global ( f.ex. EVAL).
     *
     * Because of the simple parser, a parameter cannot contain commas.
     *
     * Note: the original parser couldn't handle brackets inside a parameter. In order to use brackets, they were replaced
     * with [ ] or { } (not mix of those! and the bracket must be first), and then the parser would replace them with the round brackets.
     * That behaviour is now obsolete,  but still supported (and you may see {} used in older tool source)
     *
     * Notes on the merge behaviour
     * 1) if the children level specifies "thisLevelOnly" (defaults to false), or this level doesn't specify "merge" (defaults to
     *    true) then no merging occurs; that is, the current level definition uniquely identifies the calculations
     * 2) all steps with the same id (for functions, that would be the target) are, for the purpose of merging, considered as one.
     *    No matter if they affect nodes or parent
     * 3) if the current level contains the same function/callback ID as the children level, then the first instance of that ID
     *    in the children is replaced with all the steps from the current level up to the last instance of the ID (of course
     *    steps already placed before are not repeated)
     * 4) other steps with the same ID in children level are removed
     * 5) callback definition with empty fn, or function definition NOOP() is both considered empty and serves only for removing
     *    steps from lower level. However if there is both real and NOOP operation with the same id, then the NOOP is real empty
     *    operation
     * 6) the remaining steps are added to the end of the processing
     *
     * Practical example, lower level:
     * aantal=ADD(aantal, 1000) AT nodes   (line1)
     * SUM(kosten)
     * AVG(kostprijs)
     * SUM(aantal)
     * AVG(weight)
     *
     * Higher level:
     * isDone=BOOLEAN(aantal) AT nodes
     * aantal=MUL(aantal, 1000) AT nodes
     * kostprijs=NOOP()
     * PRODUCT(aantal)
     * aantal=NOOP()                                 (line2)
     *
     * On the higher level, the result of the merge will be:
     * isDone=BOOLEAN(aantal) options(nodes)           // this was placed together with the aantal calls
     * aantal=MUL(aantal, 1000) options (nodes)        // first aantal call was placed instead of  (line1)
     * PRODUCT(aantal)                                 // second aantal call was placed instead of (line1)
     *                                                 // kostprijs was removed by NOOP
     * AVG(weight)                                     // this was output unchanged
     * SUM(kosten)                                     // the remaining new lines were added
     *                                                 // the noop on (line2) was truly noop
     */
    compile(
        block: ICalculationBlock,
        definition: INormalizedLogexPivotDefinition,
        childHelper: ICalculatorLevelStore | null,
        blockName: string,
        localFunctions: ICalculatorFunctionDictionary = null
    ): ICompiledTupple {
        const highestPosition: types.ILookup<number> = {};

        // go through all steps and set them up as functions or callbacks
        let levelSteps = block.steps.map((step, index) =>
            this._convertStepDefinition(
                step,
                index,
                definition,
                localFunctions,
                highestPosition,
                block.debug ?? false
            )
        );

        if (block.merge && childHelper?.levelSteps?.length) {
            // if merging from lower levels:
            levelSteps = this._mergeLevels(
                levelSteps,
                childHelper.levelSteps,
                definition,
                highestPosition,
                block.debug ?? false
            );
        } else {
            // even when not merging, eliminate nulls that might have been forgotten (the NOOP function)
            levelSteps = this._withoutNoops(levelSteps);
        }

        // Nothing to do, early bail out
        if (levelSteps.length === 0) {
            return [
                null,
                {
                    unfiltered: block.unfiltered,
                    debug: block.debug,
                    levelSteps: null
                }
            ];
        }

        this._compactCallbacks(levelSteps);
        this._allocateTemporaries(levelSteps);

        const preamble = this._generatePreamble(blockName);
        const initialization = this._generateInitializations(levelSteps, block.debug ?? false);
        const {
            body: calculationBody,
            parameterDeclarations,
            parameters
        } = this._generateMainCalculations(levelSteps, block.debug ?? false);
        const totalsBody = this._generateTotalsCalculations(levelSteps, block.debug ?? false);

        const body = [...preamble, ...initialization, ...calculationBody, ...totalsBody].join("");
        const resultFn = this._buildFunction(
            parameterDeclarations,
            body,
            parameters,
            definition,
            block.debug ?? false
        );

        // copy necessary arrays to childHelper for next level
        let levelStore: ICalculatorLevelStore;
        if (!block.thisLevelOnly) {
            levelStore = {
                levelSteps,
                debug: block.debug,
                unfiltered: block.unfiltered
            };
        } else {
            levelStore = {
                levelSteps: null,
                debug: block.debug,
                unfiltered: block.unfiltered
            };
        }

        // return
        return [resultFn, levelStore];
    }

    /**
     * Convert a single step definition
     */
    private _convertStepDefinition(
        step: ICalculationStep,
        index: number,
        definition: INormalizedLogexPivotDefinition,
        localFunctions: ICalculatorFunctionDictionary,
        highestPosition: types.ILookup<number>,
        debug: boolean
    ): IStepDefinition {
        // lookup their definition for strings, or keep it as callback
        // console.log(step);
        if (ldIsString(step)) {
            return this._convertTextualStepDefinition(
                step,
                index,
                definition,
                localFunctions,
                highestPosition,
                debug
            );
        } else {
            return this._convertCallbackStepDefinition(step, index, highestPosition);
        }
    }

    /**
     * Convert one step defined in textual form
     */
    private _convertTextualStepDefinition(
        step: string,
        index: number,
        definition: INormalizedLogexPivotDefinition,
        localFunctions: ICalculatorFunctionDictionary,
        highestPosition: types.ILookup<number>,
        debug: boolean
    ): IStepDefinition {
        step = this._convertAssignment(step);

        // Extract blocks of text inside round brackets so that they don't interfere with our regex pattern matching
        const [inBracketContent, patchedStep] = this._extractBrackets(step);

        const match = this._functionRegex.exec(patchedStep);
        // console.log(match);
        if (!match) throw new Error(`Invalid calculation step ${step}`);

        let id: string | null = null;
        let fn: ICalculatorFunction | null = null;
        let locations: IFunctionLocations | null = null;
        const functionName = match[2].toUpperCase();
        if (functionName === "NOOP") {
            id = match[1];
            if (highestPosition[id] === undefined) highestPosition[id] = -1;
        } else {
            let cons: new () => ICalculatorFunction = null;
            if (localFunctions) cons = localFunctions[functionName];
            if (!cons) {
                cons = this._functions[functionName];
            }
            if (!cons) throw new Error(`Unknown calculation function ${match[2]} in ${step}`);
            fn = new cons();

            let supportedOptions: IFunctionOptions;
            let options: IFunctionOptions = null;
            if (match[6]) {
                if (fn.getSupportedOptions) supportedOptions = fn.getSupportedOptions();
                if (!supportedOptions) {
                    throw new Error(`Function ${match[2]} does not support any options`);
                }
                options = this._parseOptions(
                    inBracketContent[+match[6]],
                    supportedOptions,
                    match[2]
                );
            }

            let supportedLocations: IFunctionLocations;
            if (match[4]) {
                if (fn.getSupportedLocations) supportedLocations = fn.getSupportedLocations();
                if (!supportedLocations) {
                    throw new Error(
                        `Function ${match[2]} does not support any location specification`
                    );
                }
                locations = this._parseLocations(match[4], supportedLocations, match[2]);
                if (locations.onTotals && !fn.getOnTotalsSource) {
                    throw new Error(
                        `Function ${match[2]} claims ON TOTALS support, but doesn't implement it`
                    );
                }
            }

            const parameters = this._parseParameters(inBracketContent[+match[3]]);

            let condition = null;
            if (match[5]) {
                condition = inBracketContent[+match[5]].trim();
                if (condition !== "") {
                    if (condition[0] === "[") {
                        condition = condition.replace(/\[/g, "(").replace(/\]/g, ")");
                    } else if (condition[0] === "{") {
                        condition = condition.replace(/\{/g, "(").replace(/\}/g, ")");
                    }
                } else {
                    condition = null;
                }
            }

            if (!fn.prepare(match[1], parameters, definition, locations, condition, options))
                throw new Error(`Function generator refused expression ${step}`);
            // determine target / ID and store it
            id = fn.getTarget();
            if (debug) fn.sourceCode = <string>step;
            highestPosition[id] = index;
        }
        return {
            id,
            function: fn,
            functionName,
            callback: null,
            onTotals: locations?.onTotals ?? false,
            outsideTotals:
                !locations ||
                locations.onTop ||
                locations.inLoop ||
                locations.onBottom ||
                locations.onNodes ||
                locations.onParent
        };
    }

    /**
     * Convert one step defined ass callback
     */
    private _convertCallbackStepDefinition(
        step: ICalculatorCallback | ICalculatorCallbackDefinition,
        index: number,
        highestPosition: types.ILookup<number>
    ): IStepDefinition {
        const calDef: ICalculatorCallbackDefinition = ldIsFunction(step)
            ? {
                  id: "callback",
                  fn: step,
                  moveable: true
              }
            : step;

        if (calDef.moveable === undefined) calDef.moveable = true;
        if (calDef.fn) {
            highestPosition[calDef.id] = index;
        } else if (highestPosition[calDef.id] === undefined) {
            // mark for removal
            highestPosition[calDef.id] = -1;
        }

        return {
            id: calDef.id,
            function: null,
            functionName: null,
            callback: calDef.fn == null ? null : calDef,
            onTotals: false,
            outsideTotals: true
        };
    }

    /**
     * replace plain assignment with identity call
     */
    private _convertAssignment(step: string): string {
        const match = this._assignRegex.exec(step);
        if (match) {
            step = match[1] + " = IDENTITY( " + match[2] + ")";
            if (match[3]) step += " AT " + match[3];
            if (match[4]) step += " IF( " + match[4] + ")";
            // console.log("Assign %o -> %o (%o)", match[0], step, match);
        }
        return step;
    }

    /**
     * Locate all top-level brackets and gather their content in separate array of expressions. Replace
     * the original referneces with index of the expression
     *
     * @param step the original textual step to parse
     *
     * @return array of expressions, and the original step with bracket content replaced by index of the expression
     */
    private _extractBrackets(step: string): [string[], string] {
        const result: string[] = [];
        const expressions: string[] = ["/"]; // to make sure the expression index is never 0
        const length = step.length;
        let start = 0;
        let next = step.indexOf("(", start);
        while (next !== -1) {
            result.push(step.substring(start, next + 1));
            let depth = 0;
            start = next;
            while (start < length) {
                if (step[start] === "(") {
                    ++depth;
                } else if (step[start] === ")") {
                    --depth;
                    if (depth === 0) {
                        result.push(expressions.length.toString());
                        result.push(")");
                        expressions.push(step.substring(next + 1, start));
                        break;
                    }
                }
                ++start;
            }
            if (start === length) {
                // search for closing bracket failed, just export all from the last opening
                result.push(step.substring(next));
                break;
            }
            ++start;
            next = step.indexOf("(", start);
        }
        if (start < length) {
            // no bracket found
            result.push(step.substring(start));
        }
        // console.log(expressions, result.join(""));
        return [expressions, result.join("")];
    }

    /**
     * Build the function from the prepared data. This is done separately in order to reduce the closure said function will inherit.
     */
    private _buildFunction(
        parameterDeclarations: string[],
        body: string,
        callbackParameters: any[],
        definition: INormalizedLogexPivotDefinition,
        debug: boolean
    ): ICompiledFunction {
        let compiled: any;
        const functionSource = [
            "data",
            "store",
            "definition",
            "context",
            "onTopLevel",
            ...parameterDeclarations,
            body
        ];
        const parameters = [
            undefined,
            undefined,
            definition,
            undefined,
            false,
            ...callbackParameters
        ];
        try {
            // eslint-disable-next-line prefer-spread, no-new-func
            compiled = Function.apply(null, functionSource);
        } catch (e) {
            console.warn("Compilation failed: %o\n%o", e, body);
            throw e;
        }
        if (debug) {
            console.log(compiled.toString());
        }
        return function pivotLevelCalculationWrapper(data, store, context, onTopLevel): void {
            parameters[0] = data;
            parameters[1] = store;
            parameters[3] = context;
            parameters[4] = onTopLevel;
            compiled.apply(context, parameters);
            parameters[0] = undefined;
            parameters[1] = undefined;
            parameters[3] = undefined;
        };
    }

    /**
     * Parse the options-part of the function definition.
     */
    private _parseOptions(
        options: string,
        supportedOptions: IFunctionOptions,
        _targetFunction: string
    ): IFunctionOptions {
        const parts = options.toLowerCase().split(",");
        const result: IFunctionOptions = {};
        for (let part of parts) {
            part = part.trim();
            switch (part) {
                /*
                case "log":
                    if (!supportedOptions.log) throw "Option LOG is not supported by " + targetFunction;
                    result.log = true;
                    break;
                */
                default:
                    if (supportedOptions[part]) {
                        result[part] = true;
                    } else {
                        throw new Error(`Unknown function option ${part}`);
                    }
            }
        }
        return result;
    }

    /**
     * Parse the location-part of the function definition
     */
    private _parseLocations(
        paramters: string,
        supportedLocations: IFunctionLocations,
        targetFunction: string
    ): IFunctionLocations {
        const parts = paramters.toUpperCase().split(",");
        const result: IFunctionLocations = {};
        for (let part of parts) {
            part = part.trim();
            switch (part) {
                case "NODES":
                    if (!supportedLocations.onNodes) {
                        throw new Error(
                            `Location NODES not supported by function ${targetFunction}`
                        );
                    }
                    result.onNodes = true;
                    break;
                case "PARENT":
                    if (!supportedLocations.onParent) {
                        throw new Error(
                            `Location PARENT not supported by function ${targetFunction}`
                        );
                    }
                    result.onParent = true;
                    break;
                case "TOP":
                    if (!supportedLocations.onTop) {
                        throw new Error(`Location TOP not supported by function ${targetFunction}`);
                    }
                    result.onTop = true;
                    break;
                case "BOTTOM":
                    if (!supportedLocations.onBottom) {
                        throw new Error(
                            `Location BOTTOM not supported by function ${targetFunction}`
                        );
                    }
                    result.onBottom = true;
                    break;
                case "LOOP":
                    if (!supportedLocations.inLoop) {
                        throw new Error(
                            `Location LOOP not supported by function ${targetFunction}`
                        );
                    }
                    result.inLoop = true;
                    break;
                case "TOTALS":
                    if (!supportedLocations.onTotals) {
                        throw new Error(
                            `Location TOTALS is not supported by function ${targetFunction}`
                        );
                    }
                    result.onTotals = true;
                    break;
                default:
                    throw new Error(`Unknown function location ${part}`);
            }
        }
        return result;
    }

    /**
     * Parse the parameters of the function definition. This not only splits the parameter string into individual
     * entries, but also detects glboal parameters, and normalizes the brackets usage
     */
    private _parseParameters(parameters: string): FunctionParameter[] {
        const result: FunctionParameter[] = [];
        parameters = (parameters || "").trim();
        if (parameters === "") return result;

        const parts = (parameters || "").split(",");
        for (let part of parts) {
            part = part.trim();
            if (part === "") throw new Error(`Invalid function parameters ${parameters}`);
            let isGlobal = false;
            let name = part;
            if (
                part.indexOf("context.") === 0 ||
                part.indexOf("element.") === 0 ||
                part.indexOf("element[") === 0 ||
                part.indexOf("store[") === 0 ||
                part.indexOf("store.") === 0 ||
                part === "null"
            ) {
                isGlobal = true;
            } else if (
                (part[0] >= "0" && part[0] <= "9") ||
                part[0] === "+" ||
                part[0] === "-" ||
                part[0] === '"' ||
                part[0] === "'" ||
                part[0] === "(" ||
                part[0] === "!"
            ) {
                isGlobal = true;
            } else if (part[0] === "[") {
                // Before extractBrackets was implemented, we used {} or [] instead of () inside the parameters, so that the simplistic
                // regexp parser could handle them. This isn't necessary anymore, but for compatibility reasons the functionality is
                // preserved
                isGlobal = true;
                name = name.replace(/\[/g, "(").replace(/\]/g, ")");
            } else if (part[0] === "{") {
                isGlobal = true;
                name = name.replace(/\{/g, "(").replace(/\}/g, ")");
            }
            result.push(new FunctionParameter(name, isGlobal));
        }
        return result;
    }

    /**
     * Merge the current steps with those of the child
     * */
    private _mergeLevels(
        levelSteps: IStepDefinition[],
        childSteps: IStepDefinition[],
        definition: INormalizedLogexPivotDefinition,
        highestPosition: types.ILookup<number>,
        debug: boolean
    ): IStepDefinition[] {
        // and the output
        let newSteps: IStepDefinition[] = [];
        // index of first step from the new level that was not placed yet
        let firstUnplaced = 0;
        // overwrite definitions where target/ID matches, going from the top (if multiple entries used the same ID,
        // then the first one will hold all those from the new level.)
        for (const lowerStep of childSteps) {
            // does the id exist on this level?
            const currentPosition = highestPosition[lowerStep.id];
            if (currentPosition === -1 || currentPosition < firstUnplaced) continue; // the ID was already placed, or marked for deleting: drop the current entry
            if (currentPosition != null) {
                // place the ID here, and also all steps that are above it, and haven't been yet stored
                for (let j = firstUnplaced; j <= currentPosition; ++j) {
                    const theStep = levelSteps[j];
                    if (theStep.callback || theStep.function) {
                        newSteps.push(theStep);
                    }
                }
                firstUnplaced = currentPosition + 1;
                // Also mark that the given ID was stored and any later instances should be just dropped
                highestPosition[lowerStep.id] = -1;
            } else {
                // Id not found at current level, output the step from the lower level
                if (lowerStep.function && lowerStep.function.updateLevel) {
                    if (!lowerStep.function.updateLevel(definition)) {
                        throw new Error("Function generator refused to update pivot level");
                    }
                }
                newSteps.push(lowerStep);
            }
        }
        // append the rest of the current level
        for (let i = firstUnplaced; i < levelSteps.length; ++i) {
            if (levelSteps[i].function !== null || levelSteps[i].callback !== null) {
                newSteps.push(levelSteps[i]);
            }
        }
        // and move back to this level, eliminating nulls. Nulls can happen on purpose, in order to eliminate
        // lower-level equation from this level.
        newSteps = this._withoutNoops(newSteps);

        if (debug) {
            console.groupCollapsed("Levels merged");
            for (const step of newSteps) {
                if (step.function) {
                    console.log(step.function.sourceCode);
                } else {
                    console.log("Callback " + step.id);
                }
            }
            console.groupEnd();
        }
        return newSteps;
    }

    /**
     * Remove noop steps
     */
    private _withoutNoops(steps: IStepDefinition[]): IStepDefinition[] {
        return steps.filter(step => step.callback !== null || step.function !== null);
    }

    /**
     * Move callbacks to the end where possible
     */
    private _compactCallbacks(levelSteps: IStepDefinition[]): void {
        let lastCallbackPos = levelSteps.length;
        for (let i = levelSteps.length - 1; i >= 0; --i) {
            if (levelSteps[i].callback === null) continue;
            if (levelSteps[i].callback.moveable && lastCallbackPos - i > 1) {
                const toMove = levelSteps.splice(i, 1);
                levelSteps.splice(lastCallbackPos - 1, 0, toMove[0]);
                lastCallbackPos = lastCallbackPos - 1;
            } else if (levelSteps[i].callback.moveable) {
                lastCallbackPos = i;
            }
        }
    }

    /**
     * Gather the number of temps & generate them
     */
    private _allocateTemporaries(levelSteps: IStepDefinition[]): void {
        const tempIndices: Record<string, number> = {};
        for (const step of levelSteps) {
            if (!step.function) continue;
            const tempCountRequest = step.function.getTemporaryCount?.() ?? 0;
            let tempCount = 0;
            let tempPostfixes: string[] | null = null;
            if (ldIsArray(tempCountRequest)) {
                tempCount = tempCountRequest.length;
                tempPostfixes = tempCountRequest;
            } else {
                tempCount = tempCountRequest;
            }

            if (tempCount > 0) {
                const prefix = `temp${step.functionName![0]}${step
                    .functionName!.substring(1)
                    .toLowerCase()}`;
                const offset = tempIndices[prefix] ?? 0;
                if (tempPostfixes === null) {
                    const temporaries = Array.from({ length: tempCount }).map(
                        (_ignore, index) => `${prefix}${index + offset}`
                    );
                    step.function.setTemporaries(temporaries);
                    tempIndices[prefix] = offset + tempCount;
                } else {
                    const temporaries = tempPostfixes.map(
                        postfix => `${prefix}${offset}${postfix}`
                    );
                    step.function.setTemporaries(temporaries);
                    tempIndices[prefix] = offset + 1;
                }
            }
        }
    }

    /**
     * Generate the function preamble (variable declarations)
     */
    private _generatePreamble(blockName: string | null): string[] {
        const body: string[] = [];
        body.push('"use strict";\n');
        if (blockName) {
            body.push("// compiled function for calculation block " + blockName + "\n");
        }
        body.push("let length = data.length;\n");
        body.push("let index, element;\n");
        body.push("let first = length ? data[0] : {};\n");
        return body;
    }

    /**
     * Generate the individual step initialziers
     */
    private _generateInitializations(levelSteps: IStepDefinition[], debug: boolean): string[] {
        let body: string[] = [];
        body.push("\n// run init ---\n");
        for (const step of levelSteps) {
            if (step.function === null) continue;
            const initializerSource = step.function.getInitializationSource();
            if (initializerSource) {
                if (debug) {
                    body.push("// " + (step.function.sourceCode || "?") + "\n");
                }
                body = body.concat(initializerSource);
            }
        }
        return body;
    }

    /**
     * Generate the main loop calculations and finalizers
     */
    private _generateMainCalculations(
        levelSteps: IStepDefinition[],
        debug: boolean
    ): {
        body: string[];
        parameterDeclarations: string[];
        parameters: any[];
    } {
        const parameterDeclarations: string[] = [];
        const parameters: any[] = [];
        let body: string[] = [];

        const hasLoopBody: boolean[] = [];
        let runStart = 0;
        let callbackCount = 0;

        const regularSteps = levelSteps.filter(s => s.outsideTotals);

        while (runStart < regularSteps.length) {
            let runEnd = runStart;
            // determine runs in the steps, separated by callbacks
            while (runEnd < regularSteps.length && regularSteps[runEnd].function != null) ++runEnd;
            // for every run, create loop block
            let loopBody: string[] = [];
            for (let i = runStart; i < runEnd; ++i) {
                const loopSource = regularSteps[i].function.getLoopSource();
                if (loopSource) {
                    if (debug) {
                        loopBody.push("// " + (regularSteps[i].function.sourceCode || "?") + "\n");
                    }
                    loopBody = loopBody.concat(loopSource);
                    hasLoopBody[i] = true;
                }
            }
            //   create main loop + internals (drop if empty)
            if (loopBody.length) {
                body.push("\n// loop ---\n");
                body.push("for (index=0; index<length; ++index) {\n");
                body.push("element = data[index];\n");
                body = body.concat(loopBody);
                body.push("}\n");
            }

            body.push("\n// run finalize ---\n");
            //  create finalizer for aggregates (functions with loop body)
            for (let i = runStart; i < runEnd; ++i) {
                if (!hasLoopBody[i]) continue;
                const finalizerSource = regularSteps[i].function.getFinalizerSource();
                if (finalizerSource) {
                    if (debug) {
                        body.push("// " + (regularSteps[i].function.sourceCode || "?") + "\n");
                    }
                    body = body.concat(finalizerSource);
                }
            }
            // and now the rest
            for (let i = runStart; i < runEnd; ++i) {
                if (hasLoopBody[i]) continue;
                const finalizerSource = regularSteps[i].function.getFinalizerSource();
                if (finalizerSource) {
                    if (debug) {
                        body.push("// " + (regularSteps[i].function.sourceCode || "?") + "\n");
                    }
                    body = body.concat(finalizerSource);
                }
            }
            runStart = runEnd;

            // now for every callback following the run
            while (runStart < regularSteps.length && regularSteps[runStart].callback != null) {
                // call callback, add callback to fn parameters
                body.push("// callback " + regularSteps[runStart].id + "\n");
                body.push(
                    "callback" +
                        callbackCount +
                        "( data, store, definition, context, callbackParams" +
                        callbackCount +
                        " );\n"
                );
                parameterDeclarations.push("callback" + callbackCount);
                parameterDeclarations.push("callbackParams" + callbackCount);
                parameters.push(regularSteps[runStart].callback.fn);
                parameters.push(regularSteps[runStart].callback.parameters);
                ++callbackCount;
                ++runStart;
            }
        }

        return { body, parameterDeclarations, parameters };
    }

    /**
     *  Generate ON TOTALS calculations
     */
    private _generateTotalsCalculations(levelSteps: IStepDefinition[], debug: boolean): string[] {
        let body: string[] = [];
        const totalsSteps = levelSteps.filter(s => s.onTotals);
        if (totalsSteps.length) {
            body.push("// totals calculations \n");
            body.push("if (onTopLevel) {\n");
            body.push("  element = store; \n");
            for (const currentStep of totalsSteps) {
                const totalsSource = currentStep.function.getOnTotalsSource!();
                if (totalsSource) {
                    if (debug) {
                        body.push("  // " + (currentStep.function.sourceCode || "?") + "\n");
                    }
                    body = body.concat(totalsSource);
                }
            }
            body.push("}\n");
        }
        return body;
    }
}

/*

var test2: ICalculationBlock = {
    merge: true,
    steps: [
        "aantal=mul(aantal,1000)",
        "kostprijs=div(kosten, aantal)",
        "aantal=MUL(aantal,1000) at nodes",
        "aantal=product(aantal)",
        "over50=NOOP()",
        "kostprijs=mul(kostprijs, 1000)",
        "has_key=NOOP()",
        { id: "tester", fn: null }
    ],
    debug: true
};

var testA = {
    steps: [
        "aantal=ADD(aantal, 1000) AT nodes",
        "SUM(kosten)",
        "AVG(kostprijs)",
        "SUM(aantal)",
        "AVG(weight)"
    ],
    debug: true
};
var testB = {
    steps: [
        "isDone=BOOLEAN(aantal) AT nodes",
        "aantal=MUL(aantal, 1000) AT nodes",
        "kostprijs=NOOP()",
        "PRODUCT(aantal)",
        "aantal=NOOP()"
    ],
    debug: true
}
//var test = testA;
//var test2 = testB;

var level:INormalizedLogexPivotDefinition = {
    column: "product_id",
    store: "products",
    filteredStore: "filteredProducts"
}
var level0: INormalizedLogexPivotDefinition = {
    column: "specialisme",
    store: "specialisations",
    filteredStore: "filteredSpecialisations",
    children: level
}

var compiler = new CalculatorCompiler();
RegisterCommonFunctions(compiler);
var compiled = compiler.compile(test, level, null, "test");
var nodes = [{ id: 1, aantal: 5, kosten: 100, omzet: 100, contract: 50, valid: true, product_id: 500, isActive: true, aantal_new: 50, aantal_old: 40 },
    { id: 2, aantal: 10, kosten: 1000, omzet: 100, contract: 999, product_id: 600, isActive: false, aantal_new: 50, aantal_old: 100 },
    { id: 3, aantal: 3, kosten: 800, omzet: 2000, contract: 1000, product_id: 700, isActive: false, aantal_new: 50, aantal_old: 40 },
    { id: 3, aantal: 0, kosten: 800, omzet: 2000, contract: 1000, product_id: 800, isActive: false, aantal_new: 40, aantal_old: 50 }
];
var context = {
    version: { month: 2 }
};
var target = {};
compiled[0](nodes, target, context);
console.log(nodes);
console.log(target);
console.log(compiled);
var compiled2 = compiler.compile(test2, level0, compiled[1], "test");
console.log(compiled2);
    */
