import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Downtime } from "./lg-downtime-notification.types";

@Injectable({ providedIn: "root" })
export class LgDowntimeNotificationService {
    private _setNotifications$ = new BehaviorSubject<Downtime[]>(null);

    get onSetNotifications$(): Observable<Downtime[]> {
        return this._setNotifications$.asObservable();
    }

    addNotifications(notifications: Downtime[]): void {
        this._setNotifications$.next(notifications);
    }
}
