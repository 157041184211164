import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";
import ldIsFunction from "lodash-es/isFunction";
import ldIsEqual from "lodash-es/isEqual";
import ldIsEmpty from "lodash-es/isEmpty";
import ldFind from "lodash-es/find";

import { Observable, Subject, Subscription } from "rxjs";

import { LgConsole } from "@logex/framework/core";
import { IFilterOption, LgPrimitive } from "@logex/framework/types";

import {
    IGatherFilterIdCallback,
    IGatherFilterNameCallback,
    IGatherFilterOrderCallback,
    INodeStateStore,
    INormalizedLogexPivotDefinition,
    IOrderByPerLevelSpecification
} from "./lg-pivot.types";
import { LogexPivotService } from "./lg-pivot.service";

// ----------------------------------------------------------------------------------
interface Action {
    type: "render" | "ensureVisible";
    payload?: any;
}

/**
 * Helper class that encapsulates common pivot-related functions.
 * Use [[PivotInstanceManager.create]] method to get an instance.
 * PivotInstance allows for pivot building, refiltering and calculations execution.
 * Everything is done in synchronous manner, so you don't have to use post-filter callbacks.
 */
export class LgPivotInstance<TTopRow = any, TTotals = any> {
    constructor(
        public definition: INormalizedLogexPivotDefinition,
        context: any,
        private _pivotService: LogexPivotService,
        private _lgConsole: LgConsole
    ) {
        if (ldIsFunction(context)) {
            this.contextFn = context as () => any;
        } else {
            this.contextFn = () => context;
        }
        this.totals = {} as TTotals;
        this.orderBy = [];

        this._onBuild = new Subject<void>();
        this.onBuild$ = this._onBuild.asObservable();
        this._actions = new Subject<Action>();
        this.actions$ = this._actions.asObservable();
    }

    // ----------------------------------------------------------------------------------
    // Fields
    contextFn: () => any;
    all: TTopRow[];
    filtered: TTopRow[];
    totals: TTotals;
    orderBy: IOrderByPerLevelSpecification;

    /**
     * Gets fired *before* build.
     */
    onBuild$: Observable<void>;
    actions$: Observable<Action>;

    private readonly _onBuild: Subject<void>;
    private readonly _actions: Subject<Action>;

    // ----------------------------------------------------------------------------------
    //
    private _getContext(): any {
        return this.contextFn();
    }

    /**
     * Builds pivot out of given data.
     *
     * @param data Source data
     * @param noFiltering Optional flag that can be used to temporary supress refiltering
     * and calculations, if you want to perform them later by explicit call.
     * @param preserveExpanded Allows for keeping expanded nodes expanded after rebuilding
     */
    build(data: any[], noFiltering = false, preserveExpanded = false): void {
        this._lgConsole.debug("PivotInstance.build");

        this._onBuild.next();

        let state: Record<string, any>;
        if (preserveExpanded && this.all != null) {
            state = this._pivotService.extractNodesState(this.definition, this.all, ["$expanded"]);
        }

        this.totals = {} as TTotals;
        this.all = this._pivotService.build(this.definition, data, this.totals, this._getContext());

        if (!noFiltering) {
            this.refilter();
        }

        if (preserveExpanded && state != null) {
            this._pivotService.applyNodesState(this.definition, this.all, ["$expanded"], state);
        }
    }

    /**
     * Builds pivot out of given data.
     *
     * @param pivotedData Source data
     * @param noFiltering Optional flag that can be used to temporary supress refiltering
     * and calculations, if you want to perform them later by explicit call.
     * @param preserveExpanded Allows for keeping expanded nodes expanded after rebuilding
     */
    buildAlreadyPivoted(pivotedData: any[], noFiltering = false, preserveExpanded = false): void {
        this._lgConsole.debug("PivotInstance.buildAlreadyPivoted");

        this._onBuild.next();

        let state: Record<string, any>;
        if (preserveExpanded && this.all != null) {
            state = this._pivotService.extractNodesState(this.definition, this.all, ["$expanded"]);
        }

        this.totals = {} as TTotals;
        this.all = this._pivotService.buildAlreadyPivoted(
            this.definition,
            pivotedData,
            this.totals,
            this._getContext()
        );

        if (!noFiltering) {
            this.refilter();
        }

        if (preserveExpanded && state != null) {
            this._pivotService.applyNodesState(this.definition, this.all, ["$expanded"], state);
        }
    }

    /**
     * Refilters the pivot, performs calculations and sorting and then updates the rerender trigger.
     */
    refilter(): void {
        if (!this.all) return;

        this.filtered = this._pivotService.filter(this.definition, this.all, this._getContext());
        this.calculate();
        this.sort(); // Also renders the pivot
    }

    /**
     * Runs calculations.
     *
     * @param calculationName Name of the calculation to perform.
     * @param unfiltered True if it should be calculation over unfiltered data.
     * @param totals Optional object to receive calculation totals. If it is specified, then standard totals will not be updated
     */
    calculate(calculationName?: string, unfiltered?: boolean, totals?: object): void {
        this._pivotService.evaluateCalculations(
            this.definition,
            this.all,
            this.filtered || [],
            totals ?? this.totals,
            calculationName,
            unfiltered,
            this._getContext()
        );
    }

    /**
     * Sets the orderBy to default values from the pivot definition.
     */
    setOrderByToDefault() {
        this.orderBy = this._pivotService.gatherDefaultOrderByPerLevel(this.definition);
    }

    /**
     * Sorts the data and rerenders the pivot table.
     */
    sort(sortFiltered = true, sortUnfiltered = false): void {
        if (!this.all) return;

        if (ldIsEmpty(this.orderBy)) {
            this.setOrderByToDefault();
        }

        if (sortUnfiltered) {
            this.all = this._pivotService.orderByPerLevel(
                this.definition,
                this.all,
                false,
                this.orderBy
            );
        }
        if (sortFiltered) {
            this.filtered = this._pivotService.orderByPerLevel(
                this.definition,
                this.filtered,
                true,
                this.orderBy
            );
        }
        this.rerender();
    }

    /**
     * Removes the given leaf node from the pivot.
     *
     * @param node Leaf node from this pivot to be removed.
     */
    removeLeafNode(node: any): void {
        if (!this.all) return;

        this._pivotService.removeLeafNode(
            this.definition,
            this.all,
            node,
            true,
            this._getContext()
        );
    }

    /**
     * Attaches the given node to the pivot at leaf level and if necessary
     *     creates all the parent nodes.
     *
     * @param node Node to attach
     */
    reattachLeafNode(node: any): void {
        if (!this.all) return;

        this._pivotService.reattachLeafNode(this.definition, this.all, node, this._getContext());
    }

    /**
     * Attaches the given node to the pivot at leaf level and if necessary
     *     creates all the parent nodes. After it scrolls to inserted node
     *
     * @param node Node to attach
     * @param key The value of attached nodes key
     */
    reattachNodeAndEnsureVisible(node: any, key: LgPrimitive | LgPrimitive[]): void {
        this.reattachLeafNode(node);
        this.refilter();
        this.ensureVisible(key);
    }

    /**
     * Gather a state (a collection of specified properties) from every node in the unfiltered tree. The result is
     * linked using the same property that links the children in the regular pivot tree
     * (as defined by the definition.store configuration)
     *
     * @param attributes is a name of the property, or array of names, which should be gathered from every node.
     * This would be typical some state that applies to all levels, such as "$expanded" (used by the pivot table)
     * @param storage is the target storage. If not specified, a new object will be created
     */
    extractNodeState(attributes: string | string[], storage?: INodeStateStore): INodeStateStore {
        return this._pivotService.extractNodesState(
            this.definition,
            this.filtered,
            attributes,
            storage
        );
    }

    /**
     * Re-apply a state extracted previously by extractNodesState. This can be also thought of as a left join with another
     * pivot tree with the identical hierarchy.
     *
     * @param attributes is a name of the property, or array of names, which should be restored to every node. This would be
     * typical some state that applies to all levels, such as "$expanded" (used by the pivot table)
     * @param state is the source storage.
     */
    applyNodesState(attributes: string | string[], state: INodeStateStore): void {
        this._pivotService.applyNodesState(this.definition, this.filtered, attributes, state);
    }

    /**
     * Updates [[refilterTrigger]] so that the pivot table will be updated.
     */
    rerender(): void {
        this._actions.next({ type: "render" });
    }

    /**
     * Gets SelectedRow object that tracks currently selected pivot row.
     * You should almost always have just one instance of this tracker per pivot. Change its
     * `key` when selection changes instead of creating new selection.
     *
     * @param transformKey
     */
    selectedRow<TKey, TRow>(transformKey?: (id: TKey) => any[]): SelectedRow<TKey, TRow> {
        return new SelectedRow<TKey, TRow>(this, transformKey);
    }

    /**
     * Make table row visible on the screen
     *
     * @param ids ID(s) that will lead crawler of the pivot tree to the node
     */
    ensureVisible(ids: LgPrimitive | LgPrimitive[]): void {
        ids = ldIsArray(ids) ? ids : [ids];

        this._actions.next({
            type: "ensureVisible",
            payload: { ids }
        });
    }

    /**
     * Gets filtering options from the given level of the pivot.
     *
     * @param args
     *    level - Level to query.
     *    filter - Name of the filter to be ignored. If not specified, then filter name is assumed to be equal to the ID field name.
     *    id - Name of the ID field or callback that gets ID from a record.
     *    name - Name of the name field or callback that gets it from a record. If not specified, then "id" field is used as name.
     *    order - Field to order by. If not specified, then it gets sorted by name.
     */
    getFilterOptions<T extends number | string>(args: {
        level: number;
        filter: string;
        id: string | IGatherFilterIdCallback<T>;
        name?: string | IGatherFilterNameCallback<T>;
        order?: string | IGatherFilterOrderCallback<T>;
    }): IFilterOption[] {
        let optionName = args.name;
        if (!optionName) {
            const optionId = args.id;
            if (ldIsString(optionId)) {
                optionName = optionId;
            } else {
                optionName = item => <any>optionId(item);
            }
        }

        return this._pivotService.gatherFilterOptionsFromLevelSorted(
            this.definition,
            this.all,
            args.id,
            optionName,
            args.order,
            args.filter,
            args.level,
            this._getContext()
        );
    }

    /**
     * Gets filtering options' IDs.
     *
     * @param args
     *    level - Level to query
     *    filter - Name of the filter to be ignored
     *    id - Name of the ID field.
     */
    getIds<T extends number | string>(args: {
        field: string;
        valueGetter?: IGatherFilterIdCallback<T>;
        ignoredFilter?: string | string[];
        level: number;
    }): T[] {
        const field = args.field;
        const valueGetter =
            args.valueGetter != null ? args.valueGetter : (x: { [x: string]: T }) => x[field] as T;

        const results = new Set<T>();

        this._pivotService.eachNodeAtLevelPartiallyFiltered(
            this.definition,
            this.all,
            args.level,
            args.ignoredFilter ?? field,
            x => {
                results.add(valueGetter(x));
            },
            this._getContext()
        );
        return [...results.values()];
    }
}

export class SelectedRow<TKey, TRow> {
    private _key: TKey;
    private _row: TRow;
    private _subscription: Subscription;
    private _onKeyChanged: Subject<TKey>;
    private _onKeyChanged$: Observable<TKey>;

    constructor(
        private _master: LgPivotInstance,
        private _transformKey?: (id: TKey) => any[]
    ) {
        this._subscription = _master.onBuild$.subscribe(() => {
            this._row = null;
        });

        if (_transformKey == null) {
            this._transformKey = key => (ldIsArray(key) ? key : [key]);
        }

        this._onKeyChanged = new Subject<TKey>();
        this._onKeyChanged$ = this._onKeyChanged.asObservable();
    }

    destroy(): void {
        if (this._subscription != null) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }

        this._onKeyChanged.complete();
    }

    set key(value: TKey) {
        this._key = value;
        this._row = null;

        this._onKeyChanged.next(value);
    }

    get key(): TKey {
        return this._key;
    }

    get row(): null | TRow {
        if (this._row != null) return this._row;
        if (this._key === undefined) return null;

        const key = this._transformKey(this._key);
        this._row = this._findRow(key);

        return this._row;
    }

    private _findRow(key: any[]): any {
        let levelDef = this._master.definition;
        let levelData = this._master.all;
        let row;
        for (let i = 0; ; i++) {
            const k = key[i];
            const levelColumn = levelDef.column;
            const levelMergedKey = levelDef.mergedKey;

            if (levelColumn != null) {
                const kValue = levelMergedKey == null ? k : getMergedRowKeyValue(k);
                row = ldFind(levelData, { [levelColumn]: kValue });
            } else if (levelMergedKey != null) {
                // If row key is merged, but is not stored as a column
                const kValue = getMergedRowKeyValue(k);
                const ctx = this._master.contextFn();
                row = levelData.find(x => ldIsEqual(levelMergedKey(x, ctx, null), kValue));
            } else {
                throw new Error(
                    "Invalid level definition: column and/or mergedKey must be specified."
                );
            }

            if (row == null) return null;
            if (i === key.length - 1) break;

            levelDef = levelDef.children;
            levelData = row[levelDef.store];
        }
        return row;

        // ---
        function getMergedRowKeyValue(key: any | any[]): any | string {
            if (!ldIsArray(key)) {
                // If the key for a level is not an array, then the merge strategy is different than default fields concatenation.
                return key;
            }

            // Here we assume that merged keys are built by concatenating values with "__" separator.
            // For reference see how `mergedKey` function is created in [[LogexPivotService.prepareDefinition]] method.
            return key.map(x => (x != null ? x : "null")).join("__");
        }
    }

    get isEmpty(): boolean {
        return this._key === undefined;
    }

    onKeyChanged(cb: (key: TKey) => void): Subscription {
        return this._onKeyChanged$.subscribe(cb);
    }
}
