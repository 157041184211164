<div class="flex-stretch flexcol lg-sidebar-panel-scrollable lg-sidebar-panel-scrollable--full">
    <div
        [lgScrollable]
        [lgScrollableWrapper]="false"
        lgScrollbarClass="lg-scrollbar lg-scrollbar--sidebar"
        class="flex flexcol flex-stretch"
    >
        @if ({ hasChat: _hasChat(), hasCheckList: (isChecklistReady$ | async) }; as ctx) {
            @if (ctx.hasChat || ctx.hasCheckList) {
                <ul class="lg-sidebar-list">
                    @if (ctx.hasChat) {
                        <li class="lg-sidebar-list__item">
                            <button (click)="_openChat()" class="lg-sidebar-nav-link">
                                <lg-icon icon="icon-chat-bot" />
                                {{ ".AskAI" | lgTranslate }}
                                <span class="lg-sidebar-list__tag">
                                    {{ "FW.NEW" | lgTranslate }}
                                </span>
                            </button>
                        </li>
                    }
                    @if (ctx.hasCheckList) {
                        <li class="lg-sidebar-list__item">
                            <button (click)="showChecklist($event)" class="lg-sidebar-nav-link">
                                <lg-icon icon="icon-circle-check" />
                                {{ ".TakeTour" | lgTranslate }}
                            </button>
                        </li>
                    }
                </ul>
            }
        }
        <ul class="lg-sidebar-list">
            <h3 class="lg-sidebar-section__title">{{ ".GeneralSupport" | lgTranslate }}</h3>
            @for (link of _generalSupportLinks; track link.name) {
                <li class="lg-sidebar-list__item">
                    <a class="lg-sidebar-nav-link" [href]="link.url" target="_blank">
                        <lg-icon [icon]="link.icon" [inline]="true" />
                        {{ link.name | lgTranslate }}
                    </a>
                </li>
            }
            <li *ngIf="supportLink" class="lg-sidebar-list__item">
                <a [href]="supportLink" target="_blank" class="lg-sidebar-nav-link">
                    <lg-icon icon="icon-help" />
                    {{ ".Support" | lgTranslate }}
                </a>
            </li>
            @if (_hasReleaseNotes()) {
                <li class="lg-sidebar-list__item">
                    <button
                        (click)="_showReleaseNotes()"
                        class="lg-sidebar-nav-link"
                        [disabled]="!versionClickable"
                    >
                        <lg-icon icon="icon-regel" [inline]="true" />
                        {{ _releaseNotesLabel | lgTranslate: { version: appVersion.version } }}
                    </button>
                </li>
            }
        </ul>
        <!-- Additional information -->
        @if (_extensionTemplateRef) {
            <div class="lg-sidebar-section">
                <ng-container *ngTemplateOutlet="_extensionTemplateRef; context: _context" />
            </div>
        }
    </div>
</div>
