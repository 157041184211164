export interface ILineDataConverter {
    configure({
        getColumnName,
        getColumnTopName,
        showColumnTopName,
        getGroupNames,
        getGroupValues,
        getSpreadValues
    }: LineChartConverterConfig): void;
    convert(rawData: any[]): LineChartConverterResult;
}

export interface LineChartItem {
    column: string;
    columnTop?: string;
    group: string;
    value: number;
    spread?: [number, number] | null;
    item: any;
    indexWithinGroup: number;
    indexOfGroup: number;
}

export interface LineChartConverterConfig {
    getColumnName: (item: any) => string;
    getColumnTopName?: (item: any) => string;
    showColumnTopName?: (item: any) => boolean;
    getGroupNames: string[] | (() => string[]);
    getGroupValues: (item: any) => Array<number | null | undefined>;
    getSpreadValues?: (item: any) => Array<[number, number] | null> | null;
}

export interface LineChartExtremes {
    min: number;
    max: number;
}

export enum ConversionResultStatus {
    Empty = 0,
    Valid = 1,
    Invalid = 2
}

export interface LineChartConverterResult {
    status: ConversionResultStatus;
    data?: LineChartItem[][];
    extremes?: LineChartExtremes;
}

export interface LgLineChartTooltipContext {
    displayingItems: LgLineChartTooltipItem[];
    activeItem?: { group: string; column: string };
}

export interface LgLineChartTooltipItem {
    color: string;
    /**
     * @deprecated since version 2.1.78
     * @description use lineChartItem.column instead
     */
    columnName: string;
    /**
     * @deprecated since version 2.1.78
     * @description use lineChartItem.group instead
     */
    groupName: string;
    /**
     * @deprecated since version 2.1.78
     * @description use lineChartItem.value instead
     */
    value: number;
    showValueOnly: boolean;
    lineChartItem: LineChartItem;
    isColumnIconTooltip?: boolean;
    text?: string;
}

export interface LgLineChartColumnIconOptions {
    tooltip?: boolean;
    tooltipText?: string;
    icon?: string;
    iconType?: string;
}

export enum LgLineChartSpreadVisual {
    ErrorBars = "error-bars",
    Band = "band"
}
