<div [ngClass]="cssMenuItem" [style.margin-left.px]="marginLeftPx">
    <a
        class="lg-sidebar-menu-item--link"
        [href]="navNode.getHref()"
        (click)="onClick($event)"
        (contextmenu)="onContextMenu($event)"
    >
        {{ (navNode.namePrefix || "") + navNode.name }}
        <lg-icon *ngIf="!hasChildren()" icon="icon-used-arrow" />
    </a>
    <a
        *ngIf="hasToggle()"
        class="lg-sidebar-menu-item--toggle"
        [class.expanded]="isExpanded()"
        (click)="onToggle()"
    >
        <lg-icon icon="icon-arrow-down" />
    </a>
</div>
<ng-container *ngIf="isExpanded()">
    <lg-sidebar-menu-item
        *ngFor="let child of navNode.children"
        [currentUrl]="currentUrl"
        [navNode]="child"
        [level]="level + 1"
        (navigate)="navigate.emit($event)"
    ></lg-sidebar-menu-item>
</ng-container>
<div *ngIf="hasDivider()" class="lg-sidebar-menu-item--divider"></div>
