import { ESCAPE } from "@angular/cdk/keycodes";
import {
    FormStyle,
    getLocaleMonthNames,
    NgClass,
    NgForOf,
    NgIf,
    TranslationWidth
} from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
    inject
} from "@angular/core";

import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";
import { clamp } from "@logex/framework/utilities";
import classNames from "classnames";
import ldIsEqual from "lodash-es/isEqual";
import ldRange from "lodash-es/range";
import { BehaviorSubject, fromEvent, Observable, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";

import {
    IRange,
    YearMonthRange,
    YearMonthRangeConfiguration
} from "./lg-year-month-range-selector.types";
import { LgCapitalizePipe, LgRemoveSymbolPipe } from "../../pipes";

export const FIRST_MONTH = 1;
export const LAST_MONTH = 12;
export const ALL_MONTHS: () => IRange = () => ({ from: FIRST_MONTH, to: LAST_MONTH });

export const FIRST_YEAR_DEFAULT = 2017;
export const LAST_YEAR_DEFAULT = 2021;
export const ALL_YEARS_DEFAULT: () => IRange = () => ({
    from: FIRST_YEAR_DEFAULT,
    to: LAST_YEAR_DEFAULT
});

@Component({
    standalone: true,
    selector: "lg-year-month-range-selector-popup",
    templateUrl: "./lg-year-month-range-selector-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgForOf, NgClass, LgCapitalizePipe, LgTranslatePipe, LgRemoveSymbolPipe],
    viewProviders: [useTranslationNamespace("FW._Directives")]
})
export class LgYearMonthRangeSelectorPopupComponent implements OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);
    private _locale = inject(LOCALE_ID);
    private _ngZone = inject(NgZone);

    @ViewChildren("month") months!: QueryList<ElementRef<HTMLSpanElement>>;
    @ViewChildren("year") years!: QueryList<ElementRef<HTMLSpanElement>>;

    @HostListener("document:keydown", ["$event.keyCode"])
    _onEsc(keyCode: number): boolean {
        if (keyCode === ESCAPE) {
            this._setRange(this._config.initialMonthRange);
            this._setRange(this._config.initialYearRange, true);
            return false;
        }
        return true;
    }

    _initialized = false;
    _months: number[] = [];
    _years: number[] = [];
    _shortNames!: string[];
    _names!: string[];
    _dragging = { year: false, month: false };
    _hover = { year: true, month: true };
    _selectedRange: YearMonthRange | undefined;

    protected _config!: YearMonthRangeConfiguration;
    private _currentMonth: IRange | undefined;
    private _currentYear: IRange | undefined;
    private _selectedRange$!: BehaviorSubject<YearMonthRange>;
    private _destroyed$ = new Subject<void>();

    initialize(config: YearMonthRangeConfiguration): Observable<YearMonthRange> {
        this._config = config;
        // properties initialized in _defaultProps()
        if (!this._config.yearPickerRange!.from) {
            this._config.yearPickerRange!.from = FIRST_YEAR_DEFAULT;
        }
        if (!this._config.yearPickerRange!.to) {
            this._config.yearPickerRange!.to = LAST_YEAR_DEFAULT;
        }
        if (!this._config.initialMonthRange) {
            this._config.initialMonthRange = ALL_MONTHS();
        }
        if (!this._config.initialYearRange) {
            this._config.initialYearRange = this._config.yearPickerRange;
        }
        this._selectedRange$ = new BehaviorSubject({
            month: this._config.initialMonthRange,
            year: this._config.initialYearRange!
        }) as BehaviorSubject<YearMonthRange>;

        this._selectedRange$
            .pipe(takeUntil(this._destroyed$))
            .subscribe((range: YearMonthRange) => {
                this._selectedRange = range;
            });

        this._names = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Wide)
        ];
        this._shortNames = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated)
        ];

        const zeroIndexedMonths = ldRange(0, 12);
        this._months = zeroIndexedMonths.map(x => (x + (this._config.startMonth ?? 0)) % 12 || 12);

        if (this._years.length === 0) {
            for (
                let i = this._config.yearPickerRange!.from;
                i <= this._config.yearPickerRange!.to;
                i++
            ) {
                this._years.push(i);
            }
        }

        this._initialized = true;
        return this._selectedRange$.asObservable();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _onMouseDown(m: number, yearDisplay = false): boolean {
        if (yearDisplay) {
            if (this._config.singleYearPicking) return false;
            this._dragging.year = true;
        } else {
            if (this._config.singleMonthPicking) return false;
            this._dragging.month = true;
        }

        const rects = this._getRects(yearDisplay);

        this._ngZone.runOutsideAngular(() => {
            fromEvent(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed$),
                    takeWhile(() => (yearDisplay ? this._dragging.year : this._dragging.month))
                )
                .subscribe((moveEvent: Partial<MouseEvent>) => {
                    this._updateValue(m, moveEvent, rects, yearDisplay);
                    return false;
                });
        });

        fromEvent(document, "mouseup")
            .pipe(takeUntil(this._destroyed$), take(1))
            .subscribe((upEvent: Partial<MouseEvent>) => {
                if (yearDisplay) {
                    this._dragging.year = false;
                } else {
                    this._dragging.month = false;
                }

                const valueToSet = yearDisplay ? this._currentYear : this._currentMonth;
                if (!valueToSet) {
                    this._onClick(upEvent, m, yearDisplay);
                } else {
                    this._setRange(valueToSet, yearDisplay);
                }
                this._changeDetector.markForCheck();

                return false;
            });

        return false;
    }

    _onMouseOver(event: MouseEvent, m: number, yearDisplay = false): void {
        if (yearDisplay && this._config.singleYearPicking) return;
        if (!yearDisplay && this._config.singleMonthPicking) return;
        if (yearDisplay) {
            this._hover.year = true;
        } else {
            this._hover.month = true;
        }
        const rects = this._getRects(yearDisplay);
        this._updateValue(m, event, rects, yearDisplay);
    }

    _onMouseOut(event: MouseEvent, m: number, yearDisplay = false): void {
        if (yearDisplay && this._config.singleYearPicking) return;
        if (!yearDisplay && this._config.singleMonthPicking) return;
        if (yearDisplay) {
            this._hover.year = false;
        } else {
            this._hover.month = false;
        }
        const rects = this._getRects(yearDisplay);
        this._updateValue(m, event, rects, yearDisplay);
    }

    _onClick(event: Partial<MouseEvent>, _m: number, yearDisplay = false): boolean {
        event.stopPropagation!();
        event.preventDefault!();

        const rects = this._getRects(yearDisplay);
        const index = this._getCurrentIndex(event.clientX!, rects, yearDisplay);

        this._setRange({ from: index, to: index }, yearDisplay);

        return false;
    }

    _selectAll(yearDisplay = false): void {
        this._setRange(yearDisplay ? this._config.yearPickerRange : ALL_MONTHS(), yearDisplay);
        if (yearDisplay && this._config.disableMonthsWithoutYear) {
            this._setRange(ALL_MONTHS());
        }
    }

    _className(index: number, yearDisplay = false): string {
        const currentIndex = index;
        const currentRange = yearDisplay ? this._currentYear : this._currentMonth;
        const currentSelectedRange = yearDisplay
            ? this._selectedRange!.year
            : this._selectedRange!.month;
        const currentIntervalEnd = yearDisplay ? currentIndex : this._config.firstIntervalEnd;
        const currentDragging = yearDisplay ? this._dragging.year : this._dragging.month;
        const currentHover = yearDisplay ? this._hover.year : this._hover.month;

        const classes = classNames(
            {
                "lg-year-month-range-selector-popup__item--selected":
                    currentSelectedRange != null &&
                    currentIndex >= currentSelectedRange.from &&
                    currentIndex <= currentSelectedRange.to
            },
            {
                "lg-year-month-range-selector-popup__item--selected_first":
                    currentSelectedRange != null && currentIndex === currentSelectedRange.from
            },
            {
                "lg-year-month-range-selector-popup__item--selected_last":
                    currentSelectedRange != null && currentIndex === currentSelectedRange.to
            },
            {
                "lg-year-month-range-selector-popup__item--selected_inactive":
                    currentSelectedRange != null &&
                    currentIndex >= currentSelectedRange.from &&
                    currentIndex <= currentSelectedRange.to &&
                    currentDragging
            },
            {
                "lg-year-month-range-selector-popup__item--active":
                    currentRange != null &&
                    currentIndex >= currentRange.from &&
                    currentIndex <= currentRange.to &&
                    (currentDragging || currentHover)
            },
            {
                "lg-year-month-range-selector-popup__item--active_dragging":
                    currentRange != null &&
                    currentIndex >= currentRange.from &&
                    currentIndex <= currentRange.to &&
                    currentDragging
            },
            {
                "lg-year-month-range-selector-popup__item--active_first":
                    currentRange != null && currentIndex === currentRange.from
            },
            {
                "lg-year-month-range-selector-popup__item--active_last":
                    currentRange != null && currentIndex === currentRange.to
            },
            {
                "lg-year-month-range-selector-popup__item--forecast":
                    currentIntervalEnd && currentIndex > currentIntervalEnd
            },
            {
                "lg-year-month-range-selector-popup__item--forecast_selected":
                    currentIntervalEnd &&
                    currentSelectedRange &&
                    currentIndex > currentIntervalEnd &&
                    currentIndex >= currentSelectedRange.from &&
                    currentIndex <= currentSelectedRange.to
            },
            {
                "lg-year-month-range-selector-popup__item--forecast_active":
                    currentIntervalEnd &&
                    currentIndex > currentIntervalEnd &&
                    currentRange != null &&
                    currentIndex >= currentRange.from &&
                    currentIndex <= currentRange.to &&
                    (currentDragging || currentHover)
            }
        );
        return classes;
    }

    _getMonthTooltip(currentMonth: number): string {
        const year: number =
            currentMonth - this._config.startMonth! > 0
                ? this._config.year!
                : this._config.year! - 1;

        currentMonth = (currentMonth + this._config.startMonth! - 1) % 12 || 12;

        switch (this._config.tooltipDisplayType) {
            case "month":
                return this._names[currentMonth];
            case "date":
                return `${this._names[currentMonth]} ` + year;
            case "full":
                if (
                    !this._config.firstIntervalEnd ||
                    !this._config.firstIntervalName ||
                    !this._config.secondIntervalName
                )
                    return `${this._names[currentMonth]} ` + year;
                return (
                    `${this._names[currentMonth]} ` +
                    year +
                    ` (${
                        currentMonth <= this._config.firstIntervalEnd
                            ? this._config.firstIntervalName
                            : this._config.secondIntervalName
                    })`
                );
            default:
                return this._names[currentMonth];
        }
    }

    private _updateValue(
        initial: number,
        event: Partial<MouseEvent>,
        rects: DOMRect[],
        yearDisplay = false
    ): void {
        this._ngZone.run(() => {
            const index = this._getCurrentIndex(event.clientX!, rects, yearDisplay);
            const current = { from: Math.min(initial, index), to: Math.max(initial, index) };
            if (yearDisplay) {
                this._currentYear = current;
            } else {
                this._currentMonth = current;
            }
            this._changeDetector.markForCheck();
        });
    }

    private _getCurrentIndex(
        currentCursorX: number,
        rects: DOMRect[],
        yearDisplay = false
    ): number {
        let index = rects.findIndex(
            rect => rect.left <= currentCursorX && currentCursorX <= rect.right
        );
        if (currentCursorX > rects[rects.length - 1].right) {
            index = rects.length - 1;
        }
        return index + (yearDisplay ? this._years[0] : 1);
    }

    private _getRects(yearDisplay = false): DOMRect[] {
        return (yearDisplay ? this.years : this.months).map(x =>
            x.nativeElement.getBoundingClientRect()
        );
    }

    private _setRange(range: IRange | undefined, yearDisplay = false): void {
        if (range) {
            this._emitRange({
                month: yearDisplay
                    ? this._selectedRange?.month
                    : this._clampRange(range, yearDisplay),
                year: yearDisplay ? this._clampRange(range, yearDisplay) : this._selectedRange?.year
            });
        } else {
            this._emitRange({
                month: yearDisplay ? this._currentMonth : ALL_MONTHS(),
                year: yearDisplay ? this._config.yearPickerRange : this._currentYear
            });
        }
        if (yearDisplay) {
            this._currentYear = undefined;
        } else {
            this._currentMonth = undefined;
        }
    }

    private _emitRange(range: YearMonthRange): void {
        if (ldIsEqual(this._selectedRange$.getValue(), range)) {
            return;
        }

        this._selectedRange$.next(range);
    }

    private _clampRange(range: IRange, yearDisplay = false): IRange {
        const first = yearDisplay ? this._config.yearPickerRange?.from : FIRST_MONTH;
        const last = yearDisplay ? this._config.yearPickerRange?.to : LAST_MONTH;
        return {
            from: clamp(range.from, first, last),
            to: clamp(range.to, first, last)
        };
    }

    get _showMonthsPicker(): boolean {
        return (
            !this._config.disableMonthsWithoutYear ||
            !!(
                this._selectedRange &&
                this._selectedRange.year?.from === this._selectedRange.year?.to
            )
        );
    }
}
