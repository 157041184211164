<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <table class="lg-chart__tooltip__table">
        <tr *ngFor="let item of context.displayingItems" class="lg-chart__tooltip__table__item">
            <td class="lg-chart__tooltip__table__item__color_column">
                <span *ngIf="!item.showValueOnly" [style.background]="item.color"></span>
            </td>
            <td class="lg-chart__tooltip__table__item__name_column">
                {{ item.showValueOnly ? "" : item.groupName + ":" }}
            </td>
            <td class="lg-chart__tooltip__table__item__value_column">
                {{ _tooltipFormatter.format(item.value) }}
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #columnIconTemplate [lgChartTemplateContextType]="this" let-context>
    <table class="lg-chart__tooltip__table">
        <tr *ngFor="let item of context.displayingItems" class="lg-chart__tooltip__table__item">
            <td class="lg-chart__tooltip__table__item__value_column">
                {{ item.text }}
            </td>
        </tr>
    </table>
</ng-template>

<div
    #chartWithLegend
    class="lg-line-chart"
    [class.lg-line-chart--flex-row]="legendOptions.position === 'right'"
    [class.lg-line-chart--flex-column]="legendOptions.position === 'bottom'"
    [style.height.px]="height"
    [style.width.px]="width"
>
    <div #chart [style.visibility]="_data && _data.length ? 'visible' : 'hidden'"></div>

    <lg-chart-legend
        *ngIf="legendOptions.visible"
        [className]="legendOptions.className"
        [definition]="_legendDefinition"
        [position]="legendOptions.position"
        [cropLongNames]="legendOptions.cropLongNames"
        [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
        (mouseleave)="_onLegendItemLeave()"
        [width]="_legendWidth"
        [legendTemplate]="legendTemplate"
    >
    </lg-chart-legend>
</div>

<ng-template #legendTemplate>
    <span
        *ngFor="let item of _legendDefinition"
        (mouseenter)="_onLegendItemHover(item.name)"
        (mouseleave)="_onLegendItemLeave()"
        class="lg-chart-legend__item lg-chart-legend__item--interactive lg-chart-legend__item--line-chart-custom"
    >
        <lg-chart-icon [symbol]="'line-with-circle'" [color]="item.color"></lg-chart-icon>
        <span class="lg-chart-legend__item__name">
            {{ item.name }}
        </span>
    </span>
</ng-template>
