import ldDefaults from "lodash-es/defaults";
import ldIsEmpty from "lodash-es/isEmpty";
import ldGroupBy from "lodash-es/groupBy";
import ldKeyBy from "lodash-es/keyBy";
import ldCompact from "lodash-es/compact";
import ldClone from "lodash-es/clone";

import { Component, Injectable, InjectionToken, ValueProvider, inject } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";

import {
    IUser,
    LG_USER_INFO,
    IVersion,
    VersionService,
    LG_APP_GET_PERIOD_NAME
} from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    LgDialogRef,
    IDropdownDefinition,
    IDropdownGroup,
    IQuickSettingsMenuItem,
    QuickSettingsMenuType,
    LgPromptDialog
} from "@logex/framework/ui-core";

import { LG_SCENARIO_GATEWAY } from "./lg-scenario.gateway";
import { IGetVersionsListResponse, IScenario } from "./lg-scenario-gateway.types";

// ----------------------------------------------------------------------------------
//
export interface IScenarioDialogConfig<TScenario extends IScenario = IScenario> {
    allowScenarioDelete?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioCreate?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioEdit?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioLock?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowMarkAsPrimary?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    showScenarioPeriodName?: boolean;
    reloadOnScenarioChange?: boolean;
    getDataVersionName?: (scenario: TScenario) => string;
    getScenarioName?: (scenario: TScenario) => string;
    getCurrentScenario?: (() => number | null) | null;
}

export const LG_SCENARIO_DIALOG_CONFIG = new InjectionToken<IScenarioDialogConfig>(
    "lgScenarioDialogConfig"
);

export function provideScenarioDialogConfiguration<TScenario extends IScenario = IScenario>(
    configuration: IScenarioDialogConfig<TScenario>
): ValueProvider {
    return {
        provide: LG_SCENARIO_DIALOG_CONFIG,
        useValue: configuration
    };
}

type TEditMode = null | "edit" | "create";

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    templateUrl: "./lg-scenario-dialog.component.html",
    viewProviders: [useTranslationNamespace("FW._ScenarioManagement")]
})
export class LgScenarioDialogComponent implements IDialogComponent<LgScenarioDialogComponent> {
    _dialogRef = inject(LgDialogRef<LgScenarioDialogComponent>);
    _userInfo = inject(LG_USER_INFO);
    private _promptDialog = inject(LgPromptDialog);
    private _gateway = inject(LG_SCENARIO_GATEWAY);
    private _lgTranslate = inject(LgTranslateService);
    private _versionService = inject(VersionService<IVersion>);

    private getPeriodName: (scenario: IScenario, showDataVersionPeriode: boolean) => string;

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        const getPeriodName = inject(LG_APP_GET_PERIOD_NAME);

        this.getPeriodName = getPeriodName;
    }

    // ----------------------------------------------------------------------------------
    //
    _title = this._lgTranslate.translate(".ScenarioManagement");
    _dialogClass = "lg-dialog--4col";
    _ready = new BehaviorSubject<boolean>(false);

    // ----------------------------------------------------------------------------------
    //
    private _dataVersionToScerarioMap: Record<string, IScenario[]>;
    private _idToScenarioMap: Record<string, IScenario>;

    private _activeVersion: number;

    _currentDataVersion: number;
    _displayVersionId: number;
    _dataVersionDropdownDefinition: IDropdownDefinition<number>;
    _dataVersionDescription: string;

    _currentScenario: number;
    _scenarioDropdownDefinition: IDropdownDefinition<number>;
    _scenarioDescription: string;

    _scenarioMenuDefinition: IQuickSettingsMenuItem[];

    _lastSaveBy: string;
    _lastSaveAt: string;

    _searchVersionId: number;
    _searchVersionState: boolean;

    _editMode: TEditMode;
    _scenarioName: string;
    _loading = false;

    _config: IScenarioDialogConfig;

    // ----------------------------------------------------------------------------------
    //
    show(config: IScenarioDialogConfig = {}): void {
        this._config = ldDefaults(config, {
            allowScenarioDelete: true,
            allowScenarioCreate: true,
            allowScenarioEdit: true,
            allowScenarioLock: true,
            allowMarkAsPrimary: true,
            showScenarioPeriodName: false,
            reloadOnScenarioChange: false,
            getDataVersionName: this._getDataVersionName.bind(this),
            getScenarioName: this._getScenarioName.bind(this),
            getCurrentScenario: null
        });

        this._loadVersions().then(() => {
            this._subscribe();
        });
    }

    // ----------------------------------------------------------------------------------
    //
    private _subscribe(): void {
        this._versionService
            .get()
            .pipe(take(1))
            .subscribe(versionInfo => {
                if (!versionInfo) {
                    return;
                }

                this._activeVersion = versionInfo.id;
                this._setVersion(versionInfo.id);
            });
    }

    // ----------------------------------------------------------------------------------
    //
    private _loadVersions(): Promise<IGetVersionsListResponse> {
        return lastValueFrom(this._gateway.getVersionsList()).then(data => {
            if (!ldIsEmpty(data.list)) {
                this._dataVersionToScerarioMap = ldGroupBy(data.list, x => x.data_version);
                this._idToScenarioMap = ldKeyBy(data.list, x => x.id);

                this._fillDataVersionDropdownDefinition();
                this._render();
            }
            this._ready.next(true);

            return data;
        });
    }

    // ----------------------------------------------------------------------------------
    //
    private _render(): void {
        this._fillDataVersionDescription();
        this._fillScenarioDropdownDefinition();
        this._fillScenarioInfo();
        this._fillEllipsisMenu();
    }

    // ----------------------------------------------------------------------------------
    //
    _onDataVersionChange(): void {
        this._render();
    }

    // ----------------------------------------------------------------------------------
    //
    _onScenarioChange(): void {
        this._fillScenarioDescription();
        this._fillScenarioInfo();
        this._fillEllipsisMenu();
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillDataVersionDropdownDefinition(): void {
        const groups: Array<IDropdownGroup | any> = [];
        // eslint-disable-next-line guard-for-in
        for (const i in this._dataVersionToScerarioMap) {
            const item: IScenario = this._dataVersionToScerarioMap[i][0];

            groups.push({
                id: item.data_version,
                name: this._config.getDataVersionName(item),
                icon: ldCompact([
                    this._dataVersionToScerarioMap[i].some(s => s.is_primary) && "primary",
                    this._dataVersionToScerarioMap[i].some(s => s.is_readonly) && "readOnly"
                ])
            });
        }

        this._dataVersionDropdownDefinition = {
            groups,
            icons: {
                primary: {
                    icon: "icon-star",
                    iconClass: "icon__color-blue",
                    title: this._lgTranslate.translate(".ThisDatasetContainsPrimaryVersion"),
                    inCurrent: true
                }
            },
            entryId: "id",
            entryName: "name",
            iconName: "icon"
        };

        // Try to set current data version from the application if needed
        if (this._currentDataVersion == null && this._config.getCurrentScenario != null) {
            this._setVersion(this._config.getCurrentScenario());
        }

        // Fallback to first item if no current version info obtained
        if (!this._currentDataVersion) {
            this._currentDataVersion = this._dataVersionDropdownDefinition.groups[0].id;
        }

        this._fillDataVersionDescription();
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillDataVersionDescription(): void {
        if (
            !this._dataVersionToScerarioMap[this._currentDataVersion] ||
            !this._dataVersionToScerarioMap[this._currentDataVersion][0]
        ) {
            return;
        }

        this._dataVersionDescription =
            this._dataVersionToScerarioMap[this._currentDataVersion][0].data_version_comment;
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillScenarioDropdownDefinition(): void {
        const scenarios = Object.values(this._idToScenarioMap).filter(
            (x: IScenario) => x.data_version === this._currentDataVersion
        );

        const groups: Array<IDropdownGroup | any> = scenarios.map(item => ({
            id: item.id,
            name: this._config.getScenarioName(item),
            icon: ldCompact([
                item.is_primary && "primary",
                item.is_readonly && "readOnly",
                item.locked && "locked"
            ])
        }));

        this._scenarioDropdownDefinition = {
            groups,
            icons: {
                primary: {
                    icon: "icon-star",
                    iconClass: "icon__color-blue",
                    title: this._lgTranslate.translate(".ThisScenarioIsPrimaryVersion"),
                    inCurrent: true
                },
                locked: {
                    icon: "icon-lock",
                    title: this._lgTranslate.translate(".ThisScenarioIsLocked"),
                    inCurrent: true
                }
            },
            entryId: "id",
            entryName: "name",
            iconName: "icon"
        };

        // Try to set current scenario from the application if needed
        if (this._currentScenario == null && this._config.getCurrentScenario != null) {
            this._currentScenario = this._config.getCurrentScenario();
        }
        const currentScenarioId = this._currentScenario;
        const currentScenarioData =
            currentScenarioId && scenarios.find(scenario => scenario.id === currentScenarioId);

        // Fallback to first item if no current version info obtained
        if (!currentScenarioData) {
            this._currentScenario = scenarios[0].id;
        }

        this._fillScenarioDescription();
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillScenarioDescription(): void {
        if (!this._idToScenarioMap[this._currentScenario]) {
            return;
        }

        this._scenarioDescription = ldClone(
            this._idToScenarioMap[this._currentScenario].scenario_omschrijving
        );
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillScenarioInfo(): void {
        if (!this._idToScenarioMap[this._currentScenario]) {
            return;
        }

        this._displayVersionId = this._idToScenarioMap[this._currentScenario].display_version_id;

        this._lastSaveBy = this._idToScenarioMap[this._currentScenario].created_by;
        this._lastSaveAt = this._idToScenarioMap[this._currentScenario].date_created;
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillEllipsisMenu(): void {
        this._scenarioMenuDefinition = [];

        const currentScenario = this._idToScenarioMap[this._currentScenario];

        const maybeAllowLock =
            typeof this._config.allowScenarioLock === "function"
                ? this._config.allowScenarioLock(currentScenario, this._userInfo)
                : this._config.allowScenarioLock;
        if (maybeAllowLock) {
            if (currentScenario.locked) {
                this._scenarioMenuDefinition.push({
                    type: QuickSettingsMenuType.Choice,
                    nameLC: ".Unlock",
                    icon: "icon-unlock",
                    onClick: () => this._lockVersion(false)
                });
            } else {
                this._scenarioMenuDefinition.push({
                    type: QuickSettingsMenuType.Choice,
                    nameLC: ".Lock",
                    icon: "icon-lock",
                    onClick: () => this._lockVersion(true)
                });
            }
        }

        const maybeAllowPrimary =
            typeof this._config.allowMarkAsPrimary === "function"
                ? this._config.allowMarkAsPrimary(currentScenario, this._userInfo)
                : this._config.allowMarkAsPrimary;
        if (!currentScenario.is_primary && maybeAllowPrimary) {
            this._scenarioMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".MakePrimary",
                icon: "icon-star",
                //                iconClass: "icon__color-blue",
                onClick: () => this._makePrimary()
            });
        }

        const allowDelete =
            typeof this._config.allowScenarioDelete === "function"
                ? this._config.allowScenarioDelete(currentScenario, this._userInfo)
                : this._config.allowScenarioDelete && this._userInfo.roles.admin;
        if (allowDelete) {
            this._scenarioMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Delete",
                icon: "icon-delete",
                onClick: () => this._deleteScenario()
            });
        }

        const allowEdit =
            typeof this._config.allowScenarioEdit === "function"
                ? this._config.allowScenarioEdit(currentScenario, this._userInfo)
                : this._config.allowScenarioEdit && this._userInfo.roles.admin;
        if (allowEdit) {
            this._scenarioMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Edit",
                onClick: () => this._setEditMode("edit"),
                icon: "icon-edit"
            });
        }

        const allowCreate =
            typeof this._config.allowScenarioCreate === "function"
                ? this._config.allowScenarioCreate(currentScenario, this._userInfo)
                : this._config.allowScenarioCreate && this._userInfo.roles.admin;
        if (allowCreate) {
            this._scenarioMenuDefinition.push({
                type: QuickSettingsMenuType.Separator
            });

            this._scenarioMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Create",
                icon: "icon-add",
                onClick: () => this._setEditMode("create")
            });
        }
    }

    // ----------------------------------------------------------------------------------
    //
    private _getDataVersionName(version: IScenario): string {
        let output = "";

        if (this._config.showScenarioPeriodName) {
            output += this.getPeriodName(version, true) + ": ";
        }

        output += version.data_version_naam;

        return output;
    }

    // ----------------------------------------------------------------------------------
    //
    private _getScenarioName(version: IScenario): string {
        let output = "";

        if (this._config.showScenarioPeriodName) {
            output += this.getPeriodName(version, false) + ": ";
        }

        output += version.scenario_naam;

        return output;
    }

    // ----------------------------------------------------------------------------------
    //
    _searchVersion(): void {
        this._setVersion(this._searchVersionId);
    }

    // ----------------------------------------------------------------------------------
    //
    _setVersion(id = this._searchVersionId): void {
        if (!this._idToScenarioMap || isNaN(id)) {
            return;
        }

        if (!this._idToScenarioMap[id]) {
            this._promptDialog.alert("", this._lgTranslate.translate(".VersionIsNotFound", { id }));
        } else {
            const foundScenario = this._idToScenarioMap[id];
            this._currentDataVersion = foundScenario.data_version;
            this._currentScenario = foundScenario.id;

            this._render();
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _lockVersion(setLockState: boolean): void {
        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(
                    setLockState ? ".ConfirmVersionLocking" : ".ConfirmVersionUnlocking"
                )
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.lockVersion(this._currentScenario, setLockState).subscribe(() => {
                    this._loadVersions();

                    if (this._config.reloadOnScenarioChange) {
                        this._reloadApplication();
                    }
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _makePrimary(): void {
        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(".ConfirmPrimaryVersionAssigning")
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.setVersionPrimary(this._currentScenario).subscribe(() => {
                    this._loadVersions();
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _setEditMode(mode: TEditMode): void {
        const currentScenario = this._idToScenarioMap[this._currentScenario];

        switch (mode) {
            case "create":
                this._promptDialog
                    .confirm(
                        this._lgTranslate.translate(".ConfirmationNeeded"),
                        this._lgTranslate.translate(".ConfirmScenarioCreation", {
                            scenario: currentScenario.scenario_naam
                        })
                    )
                    .then(choice => {
                        if (choice !== "ok") {
                            return;
                        }

                        this._editMode = mode;
                        this._scenarioName = "";
                        this._scenarioDescription = "";
                    });
                break;

            case "edit":
                this._editMode = mode;
                this._scenarioName = currentScenario.scenario_naam;
                break;

            case null:
                this._editMode = mode;
                this._fillScenarioDescription();
                break;
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _saveScenario(): void {
        this._loading = true;
        if (this._editMode === "edit") {
            this._gateway
                .updateVersion(this._currentScenario, this._scenarioName, this._scenarioDescription)
                .subscribe(() => {
                    this._loadVersions().then(() => {
                        this._setEditMode(null);
                        this._loading = false;
                    });
                });
        } else {
            this._gateway
                .saveVersionAs(this._currentScenario, this._scenarioName, this._scenarioDescription)
                .subscribe(() => {
                    this._loadVersions().then(() => {
                        this._setEditMode(null);
                        this._loading = false;

                        // TODO: It's not good to reload the page right from the middle of the dialog
                        if (this._config.reloadOnScenarioChange) {
                            this._reloadApplication();
                        }
                    });
                });
        }
    }

    // ----------------------------------------------------------------------------------
    //
    private _deleteScenario(): void {
        const currentScenario = this._idToScenarioMap[this._currentScenario];

        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(".ConfirmScenarioDeletion", {
                    scenario: currentScenario.scenario_naam
                })
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.deleteVersion(this._currentScenario).subscribe(() => {
                    // TODO: It's not good to reload the page right from the middle of the dialog
                    if (
                        this._activeVersion === this._currentScenario &&
                        this._config.reloadOnScenarioChange
                    ) {
                        this._reloadApplication();
                    }

                    if (!this._config.reloadOnScenarioChange) {
                        this._loadVersions().then(() => this._versionService.reload());
                    } else {
                        delete this._idToScenarioMap[this._currentScenario];
                        this._currentScenario = this._activeVersion;
                        this._fillScenarioDropdownDefinition();
                    }
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _open(): void {
        this._gateway.setVersion(this._currentScenario).subscribe(
            () => {
                this._activeVersion = this._currentScenario;
                if (this._config.reloadOnScenarioChange) this._reloadApplication();
                else this._versionService.reload();
                this._dialogRef.close();
            },
            data => {
                if (data.access) {
                    this._promptDialog
                        .alert(
                            this._lgTranslate.translate(".ScenarioManagement"),
                            this._lgTranslate.translate(".AccessToSelectedVersionIsDenied")
                        )
                        .then(() => {
                            if (this._config.reloadOnScenarioChange) {
                                this._reloadApplication();
                            }
                        });
                } else {
                    this._promptDialog.alert(
                        this._lgTranslate.translate(".ScenarioManagement"),
                        this._lgTranslate.translate(".ErrorOccurredTryLater")
                    );
                }
            }
        );
    }

    // ----------------------------------------------------------------------------------
    //
    private _reloadApplication(): void {
        window.location.reload();
    }
}

@Injectable({ providedIn: "root" })
export class LgScenarioDialog extends getDialogFactoryBase(LgScenarioDialogComponent, "show") {}
