/* eslint-disable @typescript-eslint/no-unused-vars */
import { hexToHsluv, hsluvToHex } from "./hsluv";
import {
    LgColorBases,
    LgColorLightnessIdentifier,
    LgColorPaletteIdentifiers,
    LgHsluvColorBase,
    LgPredefinedChartColors,
    LgSequentialPaletteByColor
} from "./lg-colors.types";

/**
 * Base colors from which other palettes are built.
 * These colors have lightness of 61 in HSLuv model
 * Colors were taken from following Figma link:
 * https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export const BASE_COLORS_40_HEX: LgColorBases = {
    LOGEX_COBALT: "#6A8DFF",
    FINANCIAL_BLUE: "#29A0C0",
    MINT: "#08A4A6",
    OUTCOMES_GREEN: "#33A67C",
    GREEN: "#36A934",
    FORECAST: "#C2882F",
    SALMON_RED: "#E27171",
    PINK: "#E75AD9",
    VALUE_PURPLE: "#B573FF",
    GREY: "#939393",
    CLINICAL_OUTCOMES: "#00A3AD",
    ICHOM: "#7F9E29"
};

/**
 * Available lightnesses for Hsluv model taken from
 * https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 * Note that indiceis in COLOR_LIGHTNESS corresponds to indices in COLOR_INDENTIFIERS.
 */
export const COLOR_LIGHTNESSES = [93.4, 82.6, 71.8, 61, 50.2, 39.4, 28.6, 17.8, 7];
export const COLOR_IDENTIFIERS: LgColorLightnessIdentifier[] = [10, 20, 30, 40, 50, 60, 70, 80, 90];

/**
 * All colors are calculated by changing lightness
 * Lightness base was chosen to be 61 (or identifier 40)
 * See https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export const BASE_COLORS_40_HSLUV: LgHsluvColorBase = (function () {
    const result: LgHsluvColorBase = {};
    const hexBaseKeys = Object.keys(BASE_COLORS_40_HEX);
    hexBaseKeys.forEach(key => {
        result[key as keyof LgHsluvColorBase] = hexToHsluv(
            BASE_COLORS_40_HEX[key as keyof LgColorBases]
        );
    });
    return result;
})();

/**
 * Sequential palette with updated lightness per color
 * See https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export const LG_SEQUENTIAL_PALETTE_BY_COLOR: LgSequentialPaletteByColor = (function () {
    const paletteKeys = Object.keys(BASE_COLORS_40_HSLUV);
    const result: LgSequentialPaletteByColor = {};
    paletteKeys.forEach(key => {
        const [h, s, ...rest] = BASE_COLORS_40_HSLUV[key as keyof LgHsluvColorBase];
        result[key as keyof LgSequentialPaletteByColor] = COLOR_LIGHTNESSES.map(lightness =>
            hsluvToHex([h, s, lightness])
        );
    });
    return result;
})();

/**
 * Categorical palette, used as default for all charts.
 * See https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export const LG_CATEGORICAL_COLOR_PALETTE = [
    getColor(LgColorPaletteIdentifiers.Cobalt, 60),
    getColor(LgColorPaletteIdentifiers.Mint, 30),
    getColor(LgColorPaletteIdentifiers.FinancialBlue, 60),
    getColor(LgColorPaletteIdentifiers.ValuePurple, 40),
    getColor(LgColorPaletteIdentifiers.Forecast, 30),
    getColor(LgColorPaletteIdentifiers.SalmonRed, 50),
    getColor(LgColorPaletteIdentifiers.ValuePurple, 70),
    getColor(LgColorPaletteIdentifiers.FinancialBlue, 40),
    getColor(LgColorPaletteIdentifiers.Cobalt, 80),
    getColor(LgColorPaletteIdentifiers.ValuePurple, 50),
    getColor(LgColorPaletteIdentifiers.Mint, 40),
    getColor(LgColorPaletteIdentifiers.Pink, 50),
    getColor(LgColorPaletteIdentifiers.Green, 40),
    getColor(LgColorPaletteIdentifiers.Forecast, 60),
    getColor(LgColorPaletteIdentifiers.Cobalt, 40),
    getColor(LgColorPaletteIdentifiers.Green, 70),
    getColor(LgColorPaletteIdentifiers.Pink, 70),
    getColor(LgColorPaletteIdentifiers.Grey, 30),
    getColor(LgColorPaletteIdentifiers.Grey, 50),
    getColor(LgColorPaletteIdentifiers.Grey, 70)
];

/**
 * Updated predefined colors
 * See https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export const LG_PREDEFINED_COLORS: LgPredefinedChartColors = {
    output: getColor(LgColorPaletteIdentifiers.SalmonRed, 60),
    historic: getColor(LgColorPaletteIdentifiers.Grey, 30),
    input: getColor(LgColorPaletteIdentifiers.Mint, 30),
    input_darker: getColor(LgColorPaletteIdentifiers.Mint, 40),

    benchmark_lighter: getColor(LgColorPaletteIdentifiers.Cobalt, 70),
    benchmark: getColor(LgColorPaletteIdentifiers.Cobalt, 50),
    benchmark_darker: getColor(LgColorPaletteIdentifiers.Cobalt, 60),

    forecast_lighter: getColor(LgColorPaletteIdentifiers.Forecast, 50),
    forecast: getColor(LgColorPaletteIdentifiers.Forecast, 40),
    forecast_darker: getColor(LgColorPaletteIdentifiers.Forecast, 30),

    efficiency_lighter: getColor(LgColorPaletteIdentifiers.FinancialBlue, 30),
    efficiency: getColor(LgColorPaletteIdentifiers.FinancialBlue, 50),
    efficiency_darker: getColor(LgColorPaletteIdentifiers.FinancialBlue, 40),

    budget_lighter: getColor(LgColorPaletteIdentifiers.ValuePurple, 30),
    budget: getColor(LgColorPaletteIdentifiers.ValuePurple, 60),
    budget_darker: getColor(LgColorPaletteIdentifiers.ValuePurple, 40)
};

/**
 * Function returning specific color based on color type and identifier value.
 * See https://www.figma.com/file/ffYbCnTYszvY9R96eny09w/Graphs?node-id=2294%3A4132
 */
export function getColor(
    type: LgColorPaletteIdentifiers,
    identifier: LgColorLightnessIdentifier
): string {
    const position = identifier / 10 - 1;
    const colorCode = LG_SEQUENTIAL_PALETTE_BY_COLOR[type]?.[position];
    if (colorCode == null) {
        console.warn("getColor: Unknown color");
    }
    return colorCode;
}

export const OTHER_COLOR = getColor(LgColorPaletteIdentifiers.Grey, 30);
