import { InjectionToken, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable } from "rxjs";
import { ScrollerType } from "../scrolling";

import { LgDialogRef } from "./lg-dialog-ref";

export const LG_DIALOG_DATA = new InjectionToken<any>("LgDialogData");

export type DialogType = "normal" | "warning" | "alert";

export type DialogCloseInitiator =
    | "routeGuard"
    | "closeButton"
    | "backdropClick"
    | "escapeKeyPress"
    | string;

export interface DialogCloseOptions {
    initiator?: DialogCloseInitiator;
    // immediately close, passed to dialogRef.close method
    immediate?: boolean;
    // an arbitrary data which can be accessed in dialogOptions.tryClose callback
    extra?: any;
}

export type IDialogShowFinalizer<T, D> = (
    options: IDialogOptions<D>,
    dialogComponentInstance: T | undefined,
    finish: (options: IDialogOptions<D> | undefined) => void
) => void;

export interface IDialogButton {
    class?: string;
    textLc?: string;
    onClick?: () => void;
    isDisabled$?: Observable<boolean>;
    /**
     * Specifies if button action is in progress (e.g. saving, loading).
     * If `true` or `string` then action is in progress; if `false` then action is not in progress.
     * String value can be used instead of `true` to provide button label to display instead of `textLc` during the progress (e.g. "Saving" instead of "Save").
     */
    progress$?: Observable<boolean | string>;
}

// ---------------------------------------------------------------------------------------------
//  Interfaces
// ---------------------------------------------------------------------------------------------

/*
TODO: 
- input stream for hide
- input stream for ready
- output streams for after show / after hide
*/

// ---------------------------------------------------------------------------------------------
//  Parameters
// ---------------------------------------------------------------------------------------------

/**
 * Configuration for the dialog.show function.
 */
export interface IDialogOptions<T = any> {
    data?: T;
    /**
     * The title of the dialog window. Defaults to "Dialog"
     */
    title?: string | undefined;

    /**
     * The title icon (css class name) of the dialog. Defaults to none
     */
    icon?: string | undefined;

    /**
     * URL to help page for the dialog. Defaults to none
     */
    helpUrl?: string | undefined;

    /**
     * Specifies whether the dialog can be closed with the close button. Defaults to true
     */
    allowClose?: boolean | undefined;

    /**
     * Specifies a callback that will be called when the dialog is closing
     */
    onClose?: ((dialog: LgDialogRef<any>) => void) | undefined;

    /**
     * A callback which is onvoked before regular dialog close
     *
     * If tryClose returns:
     * 1. `true` or Promise of `true` or Obervable of `true` - dialog will be closed
     * 3. `false` - closing is prevented
     *    If closing is initiated by LgDialogCloseGuard (e.g. `closeOptions.initiator === "routeGuard"`),
     *    navigation will be stopped.
     * 4. Promise of `false` or Observable of `false` - more universal approach. TryClose callback returnes
     *    the result of dialog closing asynchronously so LgDialogCloseGuard (if present) waits for the result
     *    and allows/denies route leave.
     *
     *  Examples:
     *  ```
     *  // Confirmation before close, using Observable
     *  _tryClose(): Observable<boolean> | boolean {
     *    if ( !this.form.modified ) return true; // allow closing, boolean true is also OK
     *    return this._confirm("can I close..."); // should return Observable<boolean>
     *  }
     *  // Confirmation before close, using Promise
     *  _tryClose(): Promise<boolean> | boolean {
     *    if ( !this.form.modified ) return true; // allow closing
     *    return this._confirm("can I close...")
     *      .then( choice => choice === "allow_close"); // decide whether to close or not
     *  }
     *  // Using synchronous confirm
     *  _tryClose(): boolean {
     *    if (!this.form.modified ) return true;
     *    return confirm("Are you sure? You can lose unsaved changes..."); // boolean returned
     *  }
     *  ```
     */
    tryClose?:
        | ((
              dialog: LgDialogRef<any>,
              closeOptions: DialogCloseOptions
          ) => boolean | Promise<boolean> | Observable<boolean>)
        | undefined;

    /**
     * Specifies the "api" of visible dialog that the new one is related to. The code will try to arrange them visually side by side
     */
    relatedTo?: LgDialogRef<any> | undefined;

    /**
     * Specifies the minimum height of the dialog window. This is useful when the content is loaded on demand, and we know it will be big
     */
    minHeight?: number | undefined;

    viewContainerRef?: ViewContainerRef | undefined;

    /**
     * Specifies the dialog type. Currently supported values are "wide", "column" and "regular" (which is default)
     * When type equals to "column", create the large right-column dialog. For "wide" create flexible height dialog with the width as the right column
     */
    type?: DialogType | undefined;

    /**
     * Specifies additional class or classes to be applied to the dialog (in addition to those determined by the type)
     */
    dialogClass?: string | undefined;

    /**
     * Specifies additional class or classes to be applied to the dialog's body
     */
    dialogBodyClass?: string | undefined;

    /**
     * If true, pressing on "ESC"-button calls tryClose
     */
    closeOnEsc?: boolean | undefined;

    /**
     * Specifies, if the dialog can be closed by clicking on the overlay. Defaults to false. The usual validations
     * (tryClose) apply.
     */
    closeOnOverlayClick?: boolean | undefined;

    /**
     * Custom template that can be inserted after the dialog title
     */
    dialogHeaderTemplate?: TemplateRef<any> | undefined;

    /**
     * Dialog outer height. Should be in valid CSS units (e.g. '300px', '50%', '80vh').
     * If not specified the height is 'auto'.
     */
    dialogHeight?: string | undefined;

    ready?: Observable<boolean> | undefined;

    /**
     * Specifies, if the dialog should automatically close on navigation. This bypasses the usual tryClose verification,
     * but will call onClose(). Note that when dialog has this flag, it will NOT automatically force closure of all dialogs
     * above it in the stack.
     * Defaults to true
     */
    forceCloseOnNavigation?: boolean | undefined;

    /**
     * Specifies whether the dialog can be maximized with the maximize button. Defaults to false
     */
    allowMaximize?: boolean | undefined;

    /**
     * Specifies buttons in bottom panel of the dialog.
     */
    dialogButtons?: IDialogButton[] | undefined;

    /**
     * Specifies scroller type of the dialog content. Defaults to vertical.
     */
    scrollerType?: ScrollerType | undefined;

    /**
     * Specifies if some background dialog process is in progress (e.g. saving, loading).
     * If `true` or `string` then process is in progress; if `false` then no processes in progress.
     * String value can be used instead of `true` in order to provide some additional info (e.g. process name).
     */
    progress$?: Observable<boolean | string>;
}

export type OverridableDialogOptions = Omit<
    IDialogOptions,
    "onClose" | "tryClose" | "ready" | "relatedTo" | "viewContainerRef"
>;

/**
 * This is the interface that the DialogHub expects the specified controller to implement. For details of the
 * meaning of all the properties, please see the documentation for DialogService and IDialogOptions.
 *
 * Notice that all the properties are optional (however if there is no activate(), the show won't return anything. Most
 * of the configuration options ( title, icon, allowClose etc) can be present either as property, or as a method.
 * When method is used.
 *
 * The controller can (and should) be injected (using standard angular or $injectFields) with dialogApi and $scope
 *
 * Note that all the properties are "sampled" at the time of the dialog show (and every time dialog is shown),
 * but are not watched: change of the title won't be reflected on a visible dialog.
 */
export interface IDialogComponent<T, R = any> {
    /**
     * This method will be called right after the dialog is shown. It receives the dialog api, and then
     * all the arguments of the show, apart from the scope
     */
    _activate?: () => void;

    /**
     * If present, this method will be called right before the dialog is shown, and it has a chance to override
     * any of the defaults passed to it (as well as completely ignore them and return its own configuration).
     */
    _configure?: (options: IDialogOptions) => IDialogOptions;

    /**
     * Property or method specifying the title of the dialog. Note that the title is not dynamically when
     * the property changes (unless the dialog is shown the second time)
     */
    _title?: string | (() => string);

    /**
     * Property or method specyfing the icon of the dialog. See also title
     */
    _icon?: string | (() => string);

    /**
     * Property or method specyfing help URL
     */
    _helpUrl?: string | (() => string);

    /**
     * Property or method specyfing, whether the dialog should have the close button. See also title
     */
    _allowClose?: boolean | (() => boolean);

    /**
     * If present, the method will be called when the dialog is closing
     */
    _onClose?: (dialog: LgDialogRef<T, R>) => void;

    /**
     * If present, the method will be called when the dialog is trying to close using the default action (close
     * button).
     */
    _tryClose?: (
        dialog: LgDialogRef<T, R>,
        closeOptions?: DialogCloseOptions
    ) => boolean | Promise<boolean> | Observable<boolean>;

    /**
     * Property or method specyfing, whether the dialog should have the maximize button. See also title
     */
    _allowMaximize?: boolean | (() => boolean);

    /**
     * Property or method specifying the related dialog api.
     */
    _relatedTo?: LgDialogRef<any> | (() => LgDialogRef<any>) | undefined;

    /**
     * Property or method specifying the dialog's minimum height.
     */
    _minHeight?: number | (() => number);

    /**
     * Property or method specifying the dialog's type. Defaults to "regular"
     */
    _dialogType?: DialogType | (() => DialogType);

    /**
     * Property or method specifying the dialog's type. Defaults to "regular"
     *
     * @deprecated Please replace with _dialogType
     */
    dialogType?: string | (() => string);

    /**
     * Property or method specifying additional classes to be applied to the dialog
     */
    _dialogClass?: string | (() => string);

    /**
     * Property or method specifying additional classes to be applied to the dialog's body
     */
    _dialogBodyClass?: string | (() => string);

    _closeOnEsc?: boolean | (() => boolean);

    /**
     * Specifies, if the dialog can be closed by clicking on the overlay. Defaults to false. The usual validations
     * (tryClose) apply.
     */
    _closeOnOverlayClick?: boolean | (() => boolean);

    _ready?: Observable<boolean> | (() => Observable<boolean>);

    _initializationDone?: Observable<void>;

    _dialogHeaderTemplate?: TemplateRef<any>;

    /**
     * Specifies, if the dialog should automatically close on navigation. This bypasses the usual tryClose verification,
     * but will call onClose(). Note that when dialog has this flag, it will NOT automatically force closure of all dialogs
     * above it in the stack.
     * Defaults to true
     */
    _forceCloseOnNavigation?: boolean | (() => boolean);

    /**
     * Specifies buttons in bottom panel of the dialog.
     */
    _dialogButtons?: IDialogButton[];

    /**
     * Specifies scroller type of the dialog content. Defaults to vertical.
     */
    _scrollerType?: ScrollerType;
}
