<div
    #chartWithLegend
    class="lg-chart-stacked-bar__wrapper lg-chart-small-text"
    [class.lg-chart-stacked-bar__wrapper--flex-row]="legendOptions.position === 'right'"
    [class.lg-chart-stacked-bar__wrapper--flex-column]="legendOptions.position === 'bottom'"
    [style.height.px]="height"
    [style.width.px]="width"
>
    <div #chart></div>

    <lg-chart-legend
        *ngIf="legendOptions.visible"
        [className]="legendOptions.className"
        [definition]="_legendDefinition"
        [position]="legendOptions.position"
        [cropLongNames]="legendOptions.cropLongNames"
        [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
        [style.paddingBottom.px]="_legendPaddingBottom"
        [width]="_legendWidth"
        (onClick)="_onLegendItemClick($event)"
    >
    </lg-chart-legend>
</div>

<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <b class="lg-d3-tooltip__tip-name">{{ context.data.columnName }}</b>
    <br /><br />{{ context.data.item[barIdProperty] }}:
    {{ this._numberFormat(context.data.value) }}
</ng-template>
