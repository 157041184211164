import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IStringLookup } from "@logex/framework/types";
import {
    LgSlideoutApi,
    LgSlideoutPanel,
    LgSlideoutState,
    LgSlideoutVariant
} from "./lg-slideout.types";

type VariantLookup<T> = {
    [key in LgSlideoutVariant]: T;
};

@Injectable({ providedIn: "root" })
export class LgSlideoutService {
    private _variantToIdToApi: VariantLookup<IStringLookup<LgSlideoutApi>> = {
        left: {},
        right: {}
    };

    public currentPanelVariant: string | null;
    public availablePanels: Record<string, LgSlideoutPanel> = {};
    public availablePanelsState$ = new BehaviorSubject(this.availablePanels);
    get activePanelVariant(): string {
        return this.currentPanelVariant;
    }

    getAvailablePanel(panelVariant: string): LgSlideoutPanel {
        return this.availablePanels[panelVariant];
    }

    setActivePanel(panelVariant: string | null): void {
        if (this.availablePanels[this.currentPanelVariant] != null) {
            this.availablePanels[this.currentPanelVariant].isActive = false;
        }
        if (this.availablePanels[panelVariant] != null) {
            this.availablePanels[panelVariant].isActive = true;
        }
        this.currentPanelVariant = panelVariant;
        this.availablePanelsState$.next(this.availablePanels);
    }

    addAvailablePanel(panel: LgSlideoutPanel): void {
        Promise.resolve().then(() => {
            this.availablePanels[panel.panelVariant] = panel;
            this.availablePanelsState$.next(this.availablePanels);
        });
    }

    deletePanel(panelVariant: string): void {
        delete this.availablePanels[panelVariant];
        this.availablePanelsState$.next(this.availablePanels);
    }

    api(id: string, variant: LgSlideoutVariant): LgSlideoutApi {
        if (this._variantToIdToApi[variant][id]) return this._variantToIdToApi[variant][id];

        let state: LgSlideoutState = {
            pinned: false,
            expanded: false,
            hidePin: false,
            template: null,
            templateContext: null
        };

        const state$ = new BehaviorSubject<LgSlideoutState>(state);

        const api: LgSlideoutApi = {
            state$: state$.asObservable(),
            togglePinned: (pinned?: boolean): void => {
                if (state.pinned === pinned) return;
                const newVal = pinned == null ? !state.pinned : pinned;
                state = { ...state, pinned: newVal };
                state$.next(state);
            },
            togglePinVisibility: (hidden = false): void => {
                state = { ...state, hidePin: hidden };
                state$.next(state);
            },
            toggleExpanded: (expanded?: boolean): void => {
                if (state.expanded === expanded) return;
                const newVal = expanded == null ? !state.expanded : expanded;

                state = { ...state, expanded: newVal };
                if (!newVal) this._closeAllPanels(id, variant);

                state$.next(state);
            },
            setTemplate: (template: TemplateRef<object>, templateContext?: object): void => {
                state = { ...state, template, templateContext };
                state$.next(state);
            },
            set: (newState: LgSlideoutState): void => {
                state = newState;

                if (!state.expanded) this._closeAllPanels(id, variant);

                state$.next(state);
            }
        };

        this._variantToIdToApi[variant][id] = api;

        return api;
    }

    private _closeAllPanels(exceptId: string, variant: LgSlideoutVariant): void {
        Object.keys(this._variantToIdToApi[variant]).forEach(id => {
            if (id === exceptId) return;

            this._variantToIdToApi[variant][id].toggleExpanded(false);
        });
    }
}
