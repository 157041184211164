import {
    LgPanelGridLeafDef,
    LgEffectivePanelState,
    LgPanelStateExtension,
    LgPanelGridRowDef,
    LgPanelGridColumnDef,
    LgPanelState,
    ILgPanelComponent
} from "./lg-panel.types";

export enum GridNodeType {
    Leaf = 1,
    Row = 2,
    Column = 3
}

export interface HeaderRow {
    columnHeaders: boolean;
    nodes: LgPanelGridNode[];
}

export class LgPanelGridNode {
    effectiveState: LgEffectivePanelState;
    maximizedChild: LgPanelGridNode = null;
    parent: LgPanelGridNode | null = null;
    children: LgPanelGridNode[];
    headersAbove: HeaderRow[];
    headersBelow: HeaderRow[];
    effectiveMaximizedChild: LgPanelGridNode = null;
    attachedPanel: ILgPanelComponent | null = null;
    simpleTabbedRow = false;
    sizeFraction: number;

    constructor(
        public definition: LgPanelGridRowDef | LgPanelGridColumnDef | LgPanelGridLeafDef,
        public type: GridNodeType
    ) {
        this.children = [];
        this.headersAbove = [];
        this.headersBelow = [];
    }

    get isLeaf(): boolean {
        return this.type === GridNodeType.Leaf;
    }

    get isColumn(): boolean {
        return this.type === GridNodeType.Column;
    }

    get isMinimized(): boolean {
        // grid node cannot be detached
        return this.effectiveState === LgPanelStateExtension.Minimized;
    }

    get isMaximized(): boolean {
        return (
            this.effectiveState === LgPanelState.Expanded ||
            this.effectiveState === LgPanelState.Fullscreen
        );
    }

    get isFullscreen(): boolean {
        return this.effectiveState === LgPanelState.Fullscreen;
    }

    get hasMaximizedChild(): boolean {
        return this.maximizedChild !== null;
    }

    hasOnlyLeafs(): boolean {
        if (this.isLeaf) throw new Error("hasOnlyLeafs: Cannot use on leafs");
        return this.children.every(l => l.isLeaf);
    }
}
