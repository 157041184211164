import { HttpClient } from "@angular/common/http";
import { ErrorHandler, inject, Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IStringLookup } from "@logex/framework/types";

import { ErrorService } from "./error-service";
import { ExceptionsService, IException } from "./exceptions-service";
import { ApplicationTraceSeverity, LG_APPLICATION_EVENT_TRACER } from "../tracing";

export type ErrorType =
    | "time"
    | "timeEnd"
    | "perf"
    | "debug"
    | "assert"
    | "count"
    | "dir"
    | "dirxml"
    | "trace"
    | "info"
    | "warn"
    | "error"
    | "log"
    | "group"
    | "groupCollapsed"
    | "groupEnd"
    | "profile"
    | "profileEnd"
    | "select"
    | "msIsIndependentlyComposed"
    | "table"
    | "exception"
    | "clear"
    | "onerror" // global onerror handler
    | "angular"; // angular exception handler

interface IErrorBatch {
    error_type: string;
    error_short: string;
    error_full: string;
    url: string;
    filename: string;
    line_no: number | null;
    column_no: number | null;
    additional_data?: string | null | undefined;
}

@Injectable()
export class LgErrorHandler extends ErrorHandler implements OnDestroy {
    private _errorService = inject(ErrorService);
    private _exceptionsService = inject(ExceptionsService);
    private _httpClient = inject(HttpClient);
    private _tracer = inject(LG_APPLICATION_EVENT_TRACER);

    private _serverLogEnabled: IStringLookup<boolean>;
    private _clientNotificationEnabled: IStringLookup<boolean>;
    private _traceEnabled: IStringLookup<boolean>;
    private _destroyed = new Subject<void>();
    private _errorBatch: IErrorBatch[] = [];
    private _batchTimer: number | null = null;

    constructor() {
        super();

        // Server log is currently broken and probably not desired anyway; disable
        // this._serverLogEnabled = {
        //     angular: true,
        //     onerror: true,
        //     error: true
        // };
        this._serverLogEnabled = {};

        this._clientNotificationEnabled = {
            angular: true,
            onerror: true,
            error: true
        };

        this._traceEnabled = {
            angular: true,
            onerror: true,
            error: true
        };

        this._exceptionsService.exception.pipe(takeUntil(this._destroyed)).subscribe(exception => {
            this._onException(exception);
        });
    }

    enableServerLog(type: ErrorType, enable = true): void {
        this._serverLogEnabled[type] = enable;
    }

    enableClientNotification(type: ErrorType, enable = true): void {
        this._clientNotificationEnabled[type] = enable;
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    override handleError(error: Error): void {
        // error.ngDebugContext.componentRenderElement.localName ~~> filename

        let filename: string | null = null;
        const debugContext = (error as any).ngDebugContext;
        if (debugContext) {
            try {
                // This can fail in some angular phases
                filename =
                    debugContext.componentRenderElement &&
                    debugContext.componentRenderElement.localName;
            } catch (err) {
                // ignore
            }
        }

        this._javascriptError(error.message, filename, 123, 123, error);
        console.error(error);

        // super.handleError(error)
    }

    private _onException(exception: IException | null): void {
        if (
            !exception ||
            (!this._serverLogEnabled[exception.methodName] &&
                !this._clientNotificationEnabled[exception.methodName] &&
                !this._traceEnabled[exception.methodName])
        ) {
            return;
        }

        if (exception.methodName === "assert") {
            const test = exception.args.shift();
            if (test) return; // did not fail
        }
        const formated = this._formatConsole(exception.args);
        const fullMessage = `${exception.source}: ${formated} (console.${exception.methodName}())`;
        if (this._serverLogEnabled[exception.methodName]) {
            this._logToServerThrottled(
                window.name,
                formated,
                fullMessage,
                window.location.toString(),
                exception.source,
                null,
                null,
                null
            );
        }
        if (this._clientNotificationEnabled[exception.methodName]) {
            this._errorService.add({
                type: exception.methodName,
                shortDescription: formated,
                fullDescription: fullMessage,
                time: new Date()
            });
        }
        if (this._traceEnabled[exception.methodName]) {
            this._tracer.trackTrace(this._convertSeverity(exception.methodName), formated, {
                fullMessage,
                source: exception.source
            });
        }
    }

    private _angularError(exception: any, cause?: string): void {
        const typeName = "angular";
        if (!this._serverLogEnabled[typeName] || !this._clientNotificationEnabled[typeName]) return;

        let errorShort: string;
        let errorFull: string;
        let lineNo = null;
        if (exception instanceof Error) {
            errorShort = exception.message;
            const stack = (<any>exception).stack;
            if (stack) {
                errorFull =
                    exception.message && stack.indexOf(exception.message) === -1
                        ? "Error: " + exception.message + "\n" + stack
                        : stack;
            } else if ((<any>exception).sourceURL) {
                errorFull =
                    exception.message +
                    "\n" +
                    (<any>exception).sourceURL +
                    ":" +
                    (<any>exception).line;
                lineNo = (<any>exception).line;
            } else {
                errorFull = exception.toString();
            }
        } else {
            errorFull = exception.toString();
            errorShort = errorFull;
        }
        if (cause) {
            errorFull += "\n" + "Cause: " + cause;
        }

        if (this._serverLogEnabled[typeName]) {
            this._logToServer(
                typeName,
                errorShort,
                errorFull,
                window.location.toString(),
                null,
                lineNo,
                null,
                null
            );
        }
        if (this._clientNotificationEnabled[typeName]) {
            this._errorService.add({
                type: typeName,
                shortDescription: errorShort,
                fullDescription: errorFull,
                time: new Date()
            });
        }
    }

    private _javascriptError(
        errorMsg: string,
        filename?: string | null,
        lineNumber?: number,
        column?: number,
        errorObj?: any
    ): void {
        const typeName = "onerror";
        if (
            !this._serverLogEnabled[typeName] &&
            !this._clientNotificationEnabled[typeName] &&
            !this._traceEnabled[typeName]
        )
            return;

        let errorFull = "";
        if (errorObj) {
            errorFull = this._formatError(errorObj);
        }
        if (!errorFull) errorFull = errorMsg;

        if (this._serverLogEnabled[typeName]) {
            this._logToServer(
                typeName,
                errorMsg,
                errorFull,
                window.location.toString(),
                filename,
                lineNumber,
                column,
                null
            );
        }

        if (this._clientNotificationEnabled[typeName]) {
            this._errorService.add({
                type: typeName,
                shortDescription: errorMsg,
                fullDescription: errorFull,
                time: new Date()
            });
        }

        if (this._traceEnabled[typeName]) {
            this._tracer.trackException(errorObj ?? new Error(errorMsg), {
                filename,
                lineNumber,
                column
            });
        }
    }

    private _convertSeverity(methodName: string): ApplicationTraceSeverity {
        switch (methodName) {
            case "info":
                return ApplicationTraceSeverity.Information;
            case "warn":
                return ApplicationTraceSeverity.Warning;
            case "error":
                return ApplicationTraceSeverity.Error;
            case "debug":
            case "assert":
            case "perf":
            case "count":
            case "trace":
            case "log":
            default:
                return ApplicationTraceSeverity.Verbose;
        }
    }

    private _formatError(arg: any): string {
        if (arg instanceof Error) {
            // note: the Error content is not actually standardized between browsers, so TS is too restrictive here. We need cast to any
            if (arg.stack) {
                arg =
                    arg.message && arg.stack.indexOf(arg.message) === -1
                        ? "Error: " + arg.message + "\n" + arg.stack
                        : arg.stack;
            } else if ((<any>arg).sourceURL) {
                arg = arg.message + "\n" + (<any>arg).sourceURL + ":" + (<any>arg).line;
            }
        }

        return arg.toString();
    }

    private _logToServer(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string | null | undefined,
        lineNo: number | null | undefined,
        columnNo: number | null | undefined,
        additionalData?: string | null | undefined
    ): void {
        try {
            this._httpClient.post("api/log/error", {
                error_type: errorType,
                error_short: errorShort,
                error_full: errorFull,
                url,
                filename,
                line_no: lineNo,
                column_no: columnNo,
                additional_data: additionalData
            });
        } catch (e) {
            console.log(e);
        }
    }

    private _formatConsole(args: string[]): string {
        let result = "";
        const l = args.length;
        let i = 0;
        if (l && typeof args[0] === "string") {
            ++i;
            // note: we ignore the formatting type and dump everything as string
            result = (args[0] as string).replace(/%(s|d|i|f|o)/g, function () {
                return "" + args[i++];
            });
        }
        while (i < l) {
            if (i) result += ", ";
            result += args[i];
            ++i;
        }
        return result;
    }

    private _logToServerThrottled(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string,
        lineNo: number | null,
        columnNo: number | null,
        additionalData?: string | null
    ): void {
        if (!this._errorBatch) this._errorBatch = [];

        this._errorBatch.push({
            error_type: errorType,
            error_short: errorShort,
            error_full: errorFull,
            url,
            filename,
            line_no: lineNo,
            column_no: columnNo,
            additional_data: additionalData
        });

        if (!this._batchTimer) {
            this._batchTimer = window.setTimeout(() => this._pushBatch(), 1000);
        }
    }

    private _pushBatch(): void {
        this._batchTimer = null;

        if (this._errorBatch.length > 10) {
            this._errorBatch = this._errorBatch.slice(0, 10);
        }
        this._httpClient.post("api/log/error", { batch: this._errorBatch });
        this._errorBatch = [];
    }
}
