import { Component } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";

// Checkbox renderer definition extensions -------------------------------------------------------------------------
export interface ICheckboxFilterDefinition extends IFilterDefinition {
    filterType: "checkbox";

    /**
     * If this is true, the filter is active when unchecked, and inactive when checked (as
     * reported by IsActive call). Typical usage would be filter saying "Include invalid
     * activities: YES" if we always want them by default.
     *
     * You can further customize the behaviour by using the default parameter.
     */
    uncheckedFilters?: boolean;

    /**
     * Specifies the default value of the filter. If missing, then uncheckedFilters is default (the logic being
     * that by default we shouldn't filter away anything)
     *
     * Apart from the default state, this also controls the display of the preview box (i.e. if true, then the
     * preview box is visible when unchecked, not when checked)
     *
     * Note that if this is true, you probably should specify also previewText, because otherwise it might
     * not make sense (the previewText should be negation of the label)
     */
    default?: boolean;

    /**
     * Text to show inside the preview box (when missing, the name will be used)
     */
    previewText?: string;

    /**
     * Localization ID of the preview text
     */
    previewTextLC?: string;

    /**
     * Text to show inside the preview box's popup (if missing, there is no popup)
     */
    previewPopup?: string;
}

// Checkbox renderer -----------------------------------------------------------------------------------------------
/**
 * Implements simple checkbox filter. See ICheckboxFilterDefinition for the list of additional options.
 */
export class CheckboxFilterRenderer implements IFilterRenderer {
    checkId: string;
    protected static idCounter = 0;

    // ----------------------------------------------------------------------------------
    //
    constructor(
        private _definition: ICheckboxFilterDefinition,
        private _filters: any,
        private _lgTranslate: LgTranslateService
    ) {
        if (this._definition.uncheckedFilters == null) {
            this._definition.uncheckedFilters = false;
        }
        if (this._definition.default == null) {
            this._definition.default = !!this._definition.uncheckedFilters;
        }
        this.checkId = "CheckBoxFilterRenderer" + ++CheckboxFilterRenderer.idCounter;

        if (!this._definition.previewText && this._definition.previewTextLC) {
            this._definition.previewText = _lgTranslate.translate(this._definition.previewTextLC);
        }
    }

    createStorage(): void {
        if (this._filters[this._definition.storage] === undefined) {
            this._filters[this._definition.storage] = this._definition.default;
        }
    }

    active(): boolean {
        return this._filters[this._definition.storage] !== this._definition.uncheckedFilters;
    }

    previewVisible(): boolean {
        return this._filters[this._definition.storage] !== this._definition.default;
    }

    clear(): boolean {
        if (this._filters[this._definition.storage] !== this._definition.default) {
            this._filters[this._definition.storage] = this._definition.default;
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<ICheckboxFilterDefinition, CheckboxFilterRenderer>
    > {
        return CheckboxFilterRendererLineComponent;
    }

    getPreviewName(): string {
        return this._definition.previewText;
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<ICheckboxFilterDefinition, CheckboxFilterRenderer>
    > | null {
        return this._definition.previewPopup ? CheckboxFilterRendererPopupComponent : null;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.label,
            activeFn: () => this.previewVisible(),
            exportFn: () => {
                const value = this._filters[this._definition.storage];
                if (value === this._definition.default) return null;
                return [
                    this._lgTranslate.translate(
                        value
                            ? "FW._Directives.CheckboxFilterRenderer_Export__checked"
                            : "FW._Directives.CheckboxFilterRenderer_Export__unchecked"
                    )
                ];
            }
        };
    }

    serialize(): string {
        if (!this.active()) return null;
        return this._filters[this._definition.storage] ? "1" : "0";
    }

    deserialize(state: string): boolean {
        const newState = !!state;
        const oldState = !!this._filters[this._definition.storage];
        this._filters[this._definition.storage] = newState;
        return newState !== oldState;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class CheckboxFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "checkbox";

    create(
        definition: ICheckboxFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new CheckboxFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <span class="filter__control--checkbox">
            <input
                type="checkbox"
                [id]="_renderer.checkId"
                [(ngModel)]="_filters[_definition.storage]"
                (ngModelChange)="_triggerChange()"
                lgStyledCheckbox
            />
        </span>
        <label
            *ngIf="_definition.tooltip; else simpleLabel"
            lgSimpleTooltip="{{ _definition.tooltip }}"
            class="filter__label filter__label--checkbox"
            [attr.for]="_renderer.checkId"
        >
            {{ _definition.label }}
        </label>
        <ng-template #simpleLabel>
            <label class="filter__label filter__label--checkbox" [attr.for]="_renderer.checkId">
                {{ _definition.label }}
            </label>
        </ng-template>
    `,
    host: {
        class: "lg-filterset-list__item--checkbox"
    }
})
export class CheckboxFilterRendererLineComponent extends FilterRendererComponentBase<
    ICheckboxFilterDefinition,
    CheckboxFilterRenderer
> {
    // empty
}

// Popup template --------------------------------------------------------------------------------------------------
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    template: ` <div>{{ _definition.previewPopup }}</div> `
})
export class CheckboxFilterRendererPopupComponent extends FilterRendererComponentBase<
    ICheckboxFilterDefinition,
    CheckboxFilterRenderer
> {
    // empty
}
