import {
    OnInit,
    OnDestroy,
    Component,
    ViewEncapsulation,
    ElementRef,
    Input,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    inject
} from "@angular/core";
import { Subscription, Subject, asapScheduler } from "rxjs";
import { observeOn, takeUntil } from "rxjs/operators";
import ldOrderBy from "lodash-es/orderBy";

import {
    LgFilterSet,
    IFilterDefinition,
    IVisibleDefinitionGroup
} from "@logex/framework/lg-filterset";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { sanitizeForRegexp } from "@logex/framework/utilities";
import { IDropdownDefinition, LgScrollableDirective } from "@logex/framework/ui-core";
import { LgFiltersPanelService } from "./services/lg-filters-panel.service";
import { LgSlideoutService } from "../lg-slideout/lg-slideout.service";
import { LgSlideoutApi } from "../lg-slideout/lg-slideout.types";
import { LG_FW_UI_STATE_SERVICE } from "../lg-fw-ui-state/lg-fw-ui-state.types";

export enum FiltersPanelViewMode {
    Categories = 1,
    MostRecent = 2,
    Alphabetically = 3
}

@Component({
    selector: "lg-filters-panel",
    templateUrl: "./lg-filters-panel.component.html",
    host: {
        class: "lg-filters-panel"
    },
    providers: [useTranslationNamespace("FW._Directives._FiltersetSlideout")],
    encapsulation: ViewEncapsulation.None
})
export class LgFiltersPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    private _filtersPanelService = inject(LgFiltersPanelService);
    private _lgTranslate = inject(LgTranslateService);
    private _slideoutService = inject(LgSlideoutService);
    private _uiState = inject(LG_FW_UI_STATE_SERVICE);

    @Input() disableBookmarks = false;

    @Input() hideDefaultFooter = false;

    @Input() customFooterTemplate?: TemplateRef<void>;

    /**
     * @Optional
     * When true, view customization (categories/alphabetical/..) is not allowed. You can still use defaultViewMode to controlit.
     * Defaults to false
     */
    @Input() disableViewCustomization = false;

    /**
     * @Optional
     * Specifies the default view mode. This may be overwritten by stored state (if customization is enabled).
     * Defaults to categories
     */
    @Input() defaultViewMode = FiltersPanelViewMode.Categories;

    /**
     * @Optional
     * Hides the search filter. Active this only if the number of filters is really tiny.
     * Defaults to false
     */
    @Input() disableSearch = false;

    /**
     * @Optional
     * When true, all the groups are expanded by default (instead only those with active filters).
     * This might be the new preference.
     * Defaults to false
     */
    @Input() expandGroupsByDefault = false;

    /**
     * @Optional
     * When true, the search field looks not only at filter labels, but also filter ids. This may be useful
     * for employees, but confusing to users (since they don't see the IDs), so consider enabling it only for internal
     * users (or dev builds).
     * Defaults to false
     */
    @Input() searchFilterIds = false;

    @Input() showPanelWithoutSlideout = false;
    @Input() filterService?: LgFiltersPanelService;

    public _expanded: Record<number, boolean> = {};
    public _counts: Record<number, number> = {};
    public _totalCount: number;
    public _filterSetStateKey: string;
    public _visibleFilterSet: LgFilterSet = null;
    public _pinned: boolean;
    public _customFooterTemplate: TemplateRef<void>;
    _groups: IVisibleDefinitionGroup[];
    _quicksearch = "";
    _emptySearch = false;

    _viewMenuDefinition: IDropdownDefinition<number> = {};
    _viewMode = FiltersPanelViewMode.Categories;

    @ViewChild("scroller") _scroller: LgScrollableDirective;
    @ViewChild("scrollerElement") _scrollerElement: ElementRef<HTMLElement>;
    @ViewChild("filtersSlideout") public _panelTemplate: TemplateRef<void>;
    @ViewChild("filtersSlideoutTabButton") public _buttonTemplate: TemplateRef<void>;
    @ViewChild("filtersSlideoutHeaderIcons") public _headerTemplate: TemplateRef<void>;

    private _subscriptions: Subscription;
    private _filterSet: LgFilterSet = null;
    private _destroyed$ = new Subject<void>();
    private _killOldApi$ = new Subject<void>();
    private _lastUses: Record<string, number> = {};
    private _lastUseIndex = 0;
    private _search: RegExp | null = null;
    private _panelVariant = "filters";
    private _slideoutApi: LgSlideoutApi;

    get _isAnyFilterActive(): boolean {
        return this._visibleFilterSet?.isAnyActive() ?? false;
    }

    constructor() {
        this._prepareViewMenu();
    }

    ngOnInit(): void {
        this._viewMode = this.defaultViewMode;
        if (!this.disableViewCustomization) {
            this._uiState.getFilterPanelMode().then(mode => {
                if (mode !== undefined) this._viewMode = mode;
            });
        }

        this._filtersPanelService.state$
            .pipe(observeOn(asapScheduler), takeUntil(this._destroyed$))
            .subscribe(({ filterSet, filterSetKey, slideoutApi, customFooterTemplate }) => {
                if (filterSet != null) {
                    this._setFilterSet(filterSet);
                }
                if (filterSetKey != null) {
                    this._filterSetStateKey = filterSetKey;
                }
                if (slideoutApi != null) {
                    this._setSlideoutApi(slideoutApi);
                }
                this._customFooterTemplate = customFooterTemplate || this.customFooterTemplate;
            });
    }

    ngAfterViewInit(): void {
        if (!this.showPanelWithoutSlideout)
            this._slideoutService.addAvailablePanel({
                panelVariant: this._panelVariant,
                nameLc: "FW._Directives._FiltersetSlideout.Filters",
                icon: "icon-filter",
                order: 10,
                headerIconsTemplate: this._headerTemplate,
                headerTabButtonTemplate: this._buttonTemplate,
                panelTemplate: this._panelTemplate,
                isActive: this._panelVariant === this._slideoutService.currentPanelVariant
            });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._killOldApi$.next();
        this._killOldApi$.complete();

        if (!this.showPanelWithoutSlideout) {
            this._slideoutService.deletePanel(this._panelVariant);
        }
    }

    public _filterChanged(entry: IFilterDefinition): void {
        this._visibleFilterSet.triggerOnChanged(entry);
    }

    public _groupIdentity(_index: number, entry: IVisibleDefinitionGroup): number {
        return entry.index;
    }

    public _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }

    public _toggleGroup(group: IVisibleDefinitionGroup): void {
        this._expanded[group.index] = !this._expanded[group.index];
    }

    public _clearAll(): void {
        if (this._filterSet && this._isAnyFilterActive) {
            this._filterSet.clearAll(false);
            this._hide();
        }
    }

    public _hide(): void {
        if (this._slideoutApi == null) return;
        this._slideoutService.setActivePanel(this._panelVariant);
        this._slideoutApi.toggleExpanded(false);
    }

    _setViewMode(mode: FiltersPanelViewMode): void {
        this._viewMode = mode;
        this._uiState.setFilterPanelMode(mode);
        this._updateGroups();
    }

    _updateSearch(text: string): void {
        this._quicksearch = text;
        text = text.trim();
        this._emptySearch = false;
        if (text === "") {
            const hadSearch = this._search;
            this._search = null;
            if (hadSearch) {
                this._updateGroups();
            }
        } else {
            const sanitizedFilter = text.replace(/\s\s+/g, " ");
            let searchPattern: string;
            if (sanitizedFilter.endsWith("*")) {
                searchPattern = `^${sanitizeForRegexp(sanitizedFilter.slice(0, -1))}`;
            } else {
                searchPattern = sanitizeForRegexp(sanitizedFilter);
            }
            this._search = new RegExp(searchPattern, "i");
            this._updateGroups();
        }
    }

    _searchKeyDown(event: KeyboardEvent): boolean {
        if (event.key === "Delete") {
            this._updateSearch("");
            return false;
        }
        return true;
    }

    _clearSearch(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this._updateSearch("");
    }

    private _setFilterSet(value: LgFilterSet): void {
        if (value === this._filterSet) return;
        this._unsubscribe();
        this._filterSet = value;
        this._visibleFilterSet = this._filterSet;
        this._expanded = {};
        this._lastUses = {};
        this._lastUseIndex = 0;
        this._updateCounts();
        this._autoExpandGroups();
        this._updateGroups();
        this._subscriptions = this._visibleFilterSet.onChanged.subscribe(changes => {
            if (changes) {
                this._lastUseIndex += 1;
                changes.forEach(id => {
                    if (this._visibleFilterSet.isActive(id)) {
                        this._lastUses[id] = this._lastUseIndex;
                    }
                });
                if (this._viewMode === FiltersPanelViewMode.MostRecent) {
                    this._updateGroups();
                    requestAnimationFrame(() => {
                        const top =
                            this._scrollerElement.nativeElement.querySelector(
                                ".lg-filters-panel__row"
                            );
                        if (top) this._scroller.ensureVisible(new ElementRef(top), 8);
                    });
                }
            }
            this._updateCounts();
        });
        const subscription2 = this._visibleFilterSet.visibleGroups$.subscribe(() => {
            this._updateGroups();
        });
        this._subscriptions.add(subscription2);
    }

    private _setSlideoutApi(api: LgSlideoutApi): void {
        if (this._slideoutApi !== api) {
            this._killOldApi$.next();
            this._slideoutApi = api;
            this._slideoutApi.state$
                .pipe(takeUntil(this._killOldApi$))
                .subscribe(({ expanded, pinned }) => {
                    if (expanded) this._updateGroups();
                    this._pinned = pinned;
                });
        }
    }

    private _updateCounts(): void {
        this._counts = {};
        let totalCount = 0;

        if (!this._filterSet) return;

        for (const group of this._filterSet.visibleGroups) {
            let count = 0;
            for (const filter of group.filters) {
                if (this._filterSet.isActive(filter.id)) ++count;
            }
            totalCount += count;
            this._counts[group.index] = count;
        }

        this._totalCount = totalCount;
    }

    private _autoExpandGroups(): void {
        for (const group of this._filterSet.visibleGroups) {
            if (this._counts[group.index] || this.expandGroupsByDefault) {
                this._expanded[group.index] = true;
            }
        }
    }

    private _updateGroups(): void {
        if (!this._visibleFilterSet) return;
        if (this._viewMode === FiltersPanelViewMode.Categories && !this._search) {
            this._groups = this._visibleFilterSet.visibleGroups;
        } else {
            let filters = this._visibleFilterSet.visibleDefinitions;
            if (this._search) {
                filters = filters.filter(
                    filter =>
                        filter.label.match(this._search) ||
                        (this.searchFilterIds && filter.id.match(this._search))
                );
                this._emptySearch = filters.length === 0;
            }
            if (
                this._viewMode === FiltersPanelViewMode.Alphabetically ||
                this._viewMode === FiltersPanelViewMode.Categories
            ) {
                filters = ldOrderBy(filters, f => f.label, "asc");
            } else {
                filters = ldOrderBy(
                    filters,
                    [f => -(this._lastUses[f.id] ?? 0), f => f.label],
                    "asc"
                );
            }
            this._groups = [
                {
                    index: 0,
                    name: "",
                    filters
                }
            ];
        }
    }

    private _unsubscribe(): void {
        if (this._subscriptions) {
            this._subscriptions.unsubscribe();
            this._subscriptions = null;
        }
    }

    private _prepareViewMenu(): void {
        this._viewMenuDefinition = {
            groups: [
                {
                    entries: [
                        {
                            id: FiltersPanelViewMode.Categories,
                            name: this._lgTranslate.translate("._ViewAs.Categories"),
                            icon: "icon-categories"
                        },
                        {
                            id: FiltersPanelViewMode.MostRecent,
                            name: this._lgTranslate.translate("._ViewAs.Recent"),
                            icon: "icon-time"
                        },
                        {
                            id: FiltersPanelViewMode.Alphabetically,
                            name: this._lgTranslate.translate("._ViewAs.Alphabetically"),
                            icon: "icon-alphabetically"
                        }
                    ]
                }
            ],
            allIconsInCurrent: true
        };
    }
}
