import { Component, Input, OnChanges, OnInit } from "@angular/core";

import ldKeyBy from "lodash-es/keyBy";
import ldDifference from "lodash-es/difference";

import { ICopyColumnInfoLayered, ICopyLayer } from "./copy-paste.types";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { LgCopyBase } from "./lg-copy-base";

@Component({
    selector: "lg-copy-menu",
    templateUrl: "./lg-copy-menu.component.html"
})
export class LgCopyMenuComponent
    extends LgCopyBase<ICopyColumnInfoLayered>
    implements OnInit, OnChanges
{
    @Input({ required: true }) layers!: ICopyLayer[];
    private _layerDict: Record<string, ICopyLayer> = {};

    _menuDefinition!: IDropdownDefinition<string>;
    protected _keyColumns: ICopyColumnInfoLayered[] = [];
    protected _valueColumns!: ICopyColumnInfoLayered[];
    _isSingleOption = false;

    ngOnChanges(): void {
        this._layerDict = ldKeyBy(this.layers, x => this._keyByColumns(x.columns));

        this._keyColumns = this._columns.filter(x => x.key);
        const keyColumnFields = this._keyColumns.map(x => x.field);
        this._valueColumns = this._columns.filter(x => !x.key);

        const columnsNotInDefinition = new Set();
        const menuItems = [];
        for (const layer of this.layers) {
            const absent = ldDifference(layer.columns, keyColumnFields);
            absent.forEach(x => columnsNotInDefinition.add(x));

            menuItems.push({
                id: this._keyByColumns(layer.columns),
                name: layer.name,
                columns: layer.columns
            });
        }

        if (columnsNotInDefinition.size > 0) {
            throw Error(
                `The following columns are not in the definition: ${[
                    ...columnsNotInDefinition
                ].join(", ")}`
            );
        }

        if (this.layers.length === 1) {
            this._isSingleOption = true;
            return;
        } else {
            this._isSingleOption = false;
        }

        this._menuDefinition = {
            entryId: "id",
            entryName: "name",
            groups: menuItems
        };
    }

    _onMenuSelect(key: string): void {
        const columns = this._layerDict[key].columns;
        const keyColumns = columns
            // the filter function is to remove possible undefined values from keyColumns, however TS still sees the possible type to be (ICopyColumnInfoLayered | undefined)[]
            // so non-null assertion is used here to get the correct type
            .map(x => this._keyColumns.find(y => y.field === x)!)
            .filter(x => x !== undefined);
        const valueColumns = this._valueColumns.filter(
            x =>
                x.key === false &&
                (x.parentKeyColumn == null || keyColumns.find(y => y.field === x.parentKeyColumn))
        );
        this._columns = [...keyColumns, ...valueColumns];

        const data = this._getDataForColumns(columns);
        this.copyDataDo(data);
    }

    _copyFirst(): void {
        const columns = this.layers[0].columns;
        const key = this._keyByColumns(columns);
        this._onMenuSelect(key);
    }

    private _getDataForColumns(columns: string[]): string[] {
        const layerInfo = this._layerDict[this._keyByColumns(columns)];

        if (layerInfo.onGetData != null) {
            return layerInfo.onGetData();
        }

        const data = this.onGetData();
        const keyColumnFields = this._keyColumns.map(x => x.field);

        const emptyColumnFields = ldDifference(keyColumnFields, columns);
        const nonEmptyColumnFields = ldDifference(columns, emptyColumnFields);

        return data.filter(
            x =>
                emptyColumnFields.every(y => x[y] == null) &&
                nonEmptyColumnFields.every(y => x[y] != null)
        );
    }

    private _keyByColumns(columns: string[]): string {
        return columns.join("___");
    }
}
