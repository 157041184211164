import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    inject
} from "@angular/core";
import { Subject, interval, startWith, switchMap, takeUntil } from "rxjs";
import { Downtime, NotificationCategories } from "./lg-downtime-notification.types";
import { LgDowntimeNotificationService } from "./lg-downtime-notification.service";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgDowntimeNotificationGateway } from "./lg-downtime-notification-gateway";
import { IPromptDialogOptions, LgPromptDialog } from "@logex/framework/ui-core";

@Component({
    selector: "lg-downtime-notification",
    templateUrl: "./lg-downtime-notification.component.html",
    providers: [...useTranslationNamespace("FW._DowntimeNotification")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgDowntimeNotificationComponent implements OnInit, OnDestroy {
    public _lgTranslate = inject(LgTranslateService);
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _downtimeGateway = inject(LgDowntimeNotificationGateway);
    private _downtimeNotificationService = inject(LgDowntimeNotificationService);
    private _promptDialog = inject(LgPromptDialog);

    @Input() downtimeUrl: string | undefined = undefined;

    _showNotification = false;
    _currentNotification: Downtime | undefined;

    private _dialogOptions: IPromptDialogOptions = {
        closeOnOverlayClick: true,
        buttons: [LgPromptDialog.OK_BUTTON],
        columns: 5
    };

    private _notifications: Downtime[] = [];
    private readonly _destroyed$ = new Subject<void>();

    ngOnInit(): void {
        if (this.downtimeUrl !== undefined) this._getNotifications();
        this._downtimeNotificationService.onSetNotifications$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(notifications => this._processNotifications(notifications));
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected _getNotificationName(): string {
        return this._currentNotification.notificationName
            ? this._currentNotification.notificationName
            : this._lgTranslate.translate(".NewNotification");
    }

    protected _processNotifications(notifications: Downtime[]): void {
        const dissmisedNotifications = this._getDissmisedNotifications();

        if (notifications !== null && notifications.length) {
            notifications.forEach(notification => {
                if (!dissmisedNotifications.includes(notification.id)) {
                    const existingNotification = this._notifications.find(
                        item => item.id === notification.id
                    );
                    if (existingNotification === undefined) {
                        this._notifications.push(notification);
                    }
                }
            });

            this._notifications.sort((x, y) => {
                const categoryPriority = [
                    NotificationCategories.Deprecated,
                    NotificationCategories.Maintenance
                ];
                const xPriorityIndex = categoryPriority.indexOf(x.category);
                const yPriorityIndex = categoryPriority.indexOf(y.category);

                return xPriorityIndex - yPriorityIndex;
            });
            this._setCurrentNotification();
        }
    }

    protected _closeNotification(): void {
        this._showNotification = false;
        this._changeDetectorRef.detectChanges();
    }

    protected _getButtonText(): string {
        return this._currentNotification.buttonLc !== undefined
            ? this._currentNotification.buttonLc
            : this._lgTranslate.translate(".ReadNow");
    }

    protected _openNotification(): void {
        if (this._currentNotification.onButtonClick !== undefined) {
            this._currentNotification.onButtonClick();
        } else {
            this._showDialog();
        }
    }

    protected _dismissNotification(): void {
        const notificationId = this._currentNotification.id;
        const dissmisedNotificationIds = this._getDissmisedNotifications();
        const dissmisedNotificationIndex = this._notifications.findIndex(
            item => item.id === notificationId
        );

        dissmisedNotificationIds.push(notificationId);
        this._notifications.splice(dissmisedNotificationIndex, 1);
        this._currentNotification = undefined;
        localStorage.setItem("dissmisedNotifications", JSON.stringify(dissmisedNotificationIds));

        this._setCurrentNotification();
    }

    protected _showDismissButton(): boolean {
        return this._currentNotification.category !== NotificationCategories.Deprecated;
    }

    private _showDialog(): void {
        this._promptDialog.confirm(
            this._lgTranslate.translate(".NewNotification"),
            this._currentNotification.message,
            this._dialogOptions
        );
    }

    private _getNotifications(): void {
        const timer$ = interval(5 * 2000);

        timer$
            .pipe(
                startWith(0),
                switchMap(() => this._downtimeGateway.getDowntimes(this.downtimeUrl)),
                takeUntil(this._destroyed$)
            )
            .subscribe(notifications => {
                this._processNotifications(notifications);
            });
    }

    private _getDissmisedNotifications(): string[] {
        const dissmisedNotifications = JSON.parse(localStorage.getItem("dissmisedNotifications"));
        return dissmisedNotifications === null ? [] : dissmisedNotifications;
    }

    private _setCurrentNotification(): void {
        this._currentNotification = this._notifications[0];
        if (this._currentNotification !== undefined) this._showNotification = true;
        this._changeDetectorRef.detectChanges();
    }
}
