import ldIsEqual from "lodash-es/isEqual";
import ldIsString from "lodash-es/isString";
import ldIsDate from "lodash-es/isDate";
import ldExtend from "lodash-es/extend";
import { Component } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ComponentType } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { IRangeFilterValue } from "./range-filter-renderer";

// Date range renderer ---------------------------------------------------------------------------------------------
export interface IDateFilterValue {
    from: Date;
    to: Date;
    enabled: boolean;
}

export interface IDateFilterDefinition extends IFilterDefinition {
    filterType: "date";

    min?: Date;
    max?: Date;
    default?: IDateFilterValue;

    /**
     * Initial position of the min calendar control (unlike default property, this doesn't actually initialize the filter in any way)
     */
    initialMinValue?: Date;

    /**
     * Initial position of the max calendar control (unlike default property, this doesn't actually initialize the filter in any way)
     */
    initialMaxValue?: Date;

    exportFormat?: string;
}

// @dynamic
export class DateFilterRenderer implements IFilterRenderer {
    default: IDateFilterValue;

    private _datePipe: DatePipe;

    constructor(
        private _definition: IDateFilterDefinition,
        private _filters: any,
        public lgTranslate: LgTranslateService
    ) {
        this._datePipe = new DatePipe(lgTranslate.getLanguage());
        if (!_definition.exportFormat)
            _definition.exportFormat = this.lgTranslate.translate(
                "FW._Directives.DateFilterRenderer_Export_Format"
            );
        const thisYear = new Date().getFullYear();
        if (!_definition.min) _definition.min = new Date(1900, 0, 1);
        if (!_definition.max) _definition.max = new Date(thisYear + 50, 11, 31);

        this.default = <any>ldExtend(
            {
                from: null,
                to: null,
                enabled: false
            },
            this._definition.default
        );
    }

    createStorage(): void {
        if (this._filters[this._definition.storage] == null) {
            this._filters[this._definition.storage] = {
                from: this.default.from,
                to: this.default.to,
                enabled: this.default.enabled
            };
        }
    }

    clear(): boolean {
        const current: IDateFilterValue = this._filters[this._definition.storage];
        if (!current) {
            // shouldn't happen
            this.createStorage();
            return false;
        }
        const changed =
            !ldIsEqual(current.from, this.default.from) ||
            !ldIsEqual(current.to, this.default.to) ||
            current.enabled !== this.default.enabled;
        current.from = this.default.from;
        current.to = this.default.to;
        current.enabled = this.default.enabled;
        return changed;
    }

    active(): boolean {
        const current: IDateFilterValue = this._filters[this._definition.storage];
        return current.enabled && (current.from != null || current.to != null);
    }

    previewVisible(): boolean {
        const current: IDateFilterValue = this._filters[this._definition.storage];
        return current.enabled && (current.from != null || current.to != null);
    }

    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<IDateFilterDefinition, DateFilterRenderer>
    > {
        return DateFilterLineComponent;
    }

    public getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name,
            activeFn: () => this.active(),
            exportFn: () => {
                let from: string, to: string;
                const current: IRangeFilterValue = this._filters[this._definition.storage];
                if (current.from != null) {
                    // todo: mduracka
                    // from = this.dateFilter( current.from, this.definition.exportFormat );
                    from = current.from.toString();
                }
                if (current.to != null) {
                    // todo: mduracka
                    // to = this.dateFilter( current.to, this.definition.exportFormat );
                    to = current.to.toString();
                }

                if (current.from == null) {
                    return [
                        this.lgTranslate.translate(
                            "FW._Directives.DateFilterRenderer_Export_UpTo",
                            { to }
                        )
                    ];
                } else if (current.to == null) {
                    return [
                        this.lgTranslate.translate(
                            "FW._Directives.DateFilterRenderer_Export_From",
                            { from }
                        )
                    ];
                } else {
                    return [
                        this.lgTranslate.translate(
                            "FW._Directives.DateFilterRenderer_Export_FromTo",
                            { from, to }
                        )
                    ];
                }
            }
        };
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<IDateFilterDefinition, DateFilterRenderer>
    > {
        return DateFilterPopupComponent;
    }

    dateChanged(): void {
        const current: IDateFilterValue = this._filters[this._definition.storage];
        current.enabled = current.from != null || current.to != null;
    }

    /**
     * Utility generator for the filtering function. Example usage:
     * filters:( context: any) => {
     *    ...
     *    margin: DateFilterRenderer.getFilterFunction( () => context.filterDefinition.filters["margin"] ),
     *   ...
     *  }
     */
    static getFilterFunction(
        getStorage: () => any,
        nullAsMin?: boolean,
        nullAsMax?: boolean
    ): (val: Date | string) => boolean | "$empty" {
        return function (val: Date | string) {
            const current: IDateFilterValue = getStorage() || {
                enabled: false,
                from: null,
                to: null
            };
            if (arguments.length === 0) return current.enabled ? true : "$empty";
            if (!current.enabled || (current.from == null && current.to == null)) return true;
            if (val == null) {
                if (nullAsMin && current.from == null) return true;
                if (nullAsMax && current.to == null) return true;
                return false;
            }

            if (ldIsString(val)) val = new Date(Date.parse(<string>val));

            return (
                (current.from == null || current.from <= val) &&
                (current.to == null || current.to >= val)
            );
        };
    }

    serialize(): string {
        if (!this.active()) return null;
        return JSON.stringify(this._filters[this._definition.storage]);
    }

    deserialize(state: string): boolean {
        const newState = JSON.parse(state);
        if (!newState) return false;
        const parsed: IDateFilterValue = {
            enabled: newState.enabled,
            from: newState.from === undefined ? this.default.from : newState.from,
            to: newState.to === undefined ? this.default.to : newState.to
        };
        if (!ldIsDate(parsed.from) || !ldIsDate(parsed.to)) return false;
        if (parsed.from != null && parsed.to != null && parsed.from > parsed.to) return false;
        if (this._definition.min != null) {
            if (parsed.from != null && parsed.from < this._definition.min)
                parsed.from = this._definition.min;
            if (parsed.to != null && parsed.to < this._definition.min)
                parsed.to = this._definition.min;
        }
        if (this._definition.max != null) {
            if (parsed.from != null && parsed.from > this._definition.max)
                parsed.from = this._definition.max;
            if (parsed.to != null && parsed.to > this._definition.max)
                parsed.to = this._definition.max;
        }
        if (!ldIsEqual(parsed, this._filters[this._definition.storage])) {
            this._filters[this._definition.storage] = parsed;
            return true;
        }
        return false;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class DateFilterRendererFactory implements IFilterRendererFactory {
    public readonly name: string = "date";

    public create(
        definition: IDateFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new DateFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-filterset-list__item--checkbox">
            <span class="filter__control--checkbox">
                <input
                    type="checkbox"
                    [id]="_definition.id"
                    lgStyledCheckbox
                    [(ngModel)]="_filters[_definition.storage].enabled"
                    (ngModelChange)="_triggerChange()"
                />
            </span>
            <label class="filter__label filter__label--checkbox" [attr.for]="_definition.id">
                {{ _definition.label }}:
            </label>
        </div>
        <div class="filter__control filter__control__calendar">
            <div class="filter__control__calendar__row">
                <span class="filter__control__calendar__row__label">
                    {{ "FW._Directives._FiltersetSlideout.Date_picker_from" | lgTranslate }}
                </span>
                <lg-calendar
                    [ngModel]="_filters[_definition.storage].from"
                    [minDate]="_definition.min"
                    [maxDate]="_filters[_definition.storage].to || _definition.max"
                    [defaultDate]="_definition.initialMinValue"
                    [condensed]="true"
                    (ngModelChange)="_changed(true, $event)"
                    [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
                ></lg-calendar>
            </div>
            <div class="filter__control__calendar__row">
                <span class="filter__control__calendar__row__label">
                    {{ "FW._Directives._FiltersetSlideout.Date_picker_to" | lgTranslate }}
                </span>
                <lg-calendar
                    [ngModel]="_filters[_definition.storage].to"
                    [minDate]="_filters[_definition.storage].from || _definition.min"
                    [defaultDate]="_definition.initialMaxValue"
                    [maxDate]="_definition.max"
                    [condensed]="true"
                    (ngModelChange)="_changed(false, $event)"
                    [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
                ></lg-calendar>
            </div>
        </div>
    `
})
export class DateFilterLineComponent extends FilterRendererComponentBase<
    IDateFilterDefinition,
    DateFilterRenderer
> {
    _changed(from: boolean, value: Date): void {
        const filter = this._filters[this._definition.storage!];
        if (from) {
            filter.from = value;
        } else {
            filter.to = value;
        }
        this._renderer.dateChanged();
        this._triggerChange();
    }
}

// Popup template --------------------------------------------------------------------------------------------------
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ "FW._Directives.DateFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.RangeFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-calendar
            [ngModel]="_filters[_definition.storage].from"
            [minDate]="_definition.min"
            [maxDate]="_filters[_definition.storage].to || _definition.max"
            [defaultDate]="_definition.initialMinValue"
            (ngModelChange)="_changed(true, $event)"
            [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
        ></lg-calendar
        ><span class="dash">-</span
        ><lg-calendar
            [ngModel]="_filters[_definition.storage].to"
            [minDate]="_filters[_definition.storage].from || _definition.min"
            [defaultDate]="_definition.initialMaxValue"
            [maxDate]="_definition.max"
            (ngModelChange)="_changed(false, $event)"
            [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
        ></lg-calendar>
    `
})
export class DateFilterPopupComponent extends FilterRendererComponentBase<
    IDateFilterDefinition,
    DateFilterRenderer
> {
    _changed(from: boolean, value: Date): void {
        const filter = this._filters[this._definition.storage!];
        if (from) {
            filter.from = value;
        } else {
            filter.to = value;
        }
        this._renderer.dateChanged();
        this._triggerChange();
    }
}
