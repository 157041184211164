<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <div class="lg-d3-tooltip__tip-name">
        {{ context.columnsWithinGroup[0].column }}
    </div>
    <table class="lg-chart__tooltip__table">
        <tr
            *ngFor="let item of context.columnsWithinGroup"
            class="lg-chart__tooltip__table__item"
            [class.lg-chart__tooltip__table__item--not-active]="
                context.currentColumn && context.currentColumn.group !== item.group
            "
        >
            <td class="lg-chart__tooltip__table__item__color_column">
                <span
                    *ngIf="columnColorFn"
                    [style.background]="columnColorFn(item.item)"
                    [style.opacity]="1"
                ></span>
                <span
                    *ngIf="!columnColorFn"
                    [style.background]="
                        context.groupToLegendDefinitionDictionary[item.group]?.color
                    "
                    [style.opacity]="
                        context.groupToLegendDefinitionDictionary[item.group]?.opacity || 1
                    "
                ></span>
            </td>
            <td class="lg-chart__tooltip__table__item__name_column">{{ item.group }}:</td>
            <td align="right" class="lg-chart__tooltip__table__item__value_column">
                {{ _numberFormat(item.value) }}
            </td>
        </tr>
    </table>
</ng-template>

<div
    #chartWithLegend
    class="lg-chart-grouped-bar__wrapper"
    [class.lg-chart-grouped-bar__wrapper--flex-row]="legendOptions.position === 'right'"
    [class.lg-chart-grouped-bar__wrapper--flex-column]="legendOptions.position === 'bottom'"
    [style.height.px]="height"
    [style.width.px]="width"
>
    <div #chart></div>

    <lg-chart-legend
        *ngIf="legendOptions.visible"
        [className]="legendOptions.className"
        [definition]="_legendDefinition"
        [position]="legendOptions.position"
        [cropLongNames]="legendOptions.cropLongNames"
        [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
        [style.paddingBottom.px]="_legendPaddingBottom"
        [width]="_legendWidth"
        (onClick)="_onLegendItemClick($event)"
    >
    </lg-chart-legend>
</div>
