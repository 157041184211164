import ldIsEqual from "lodash-es/isEqual";
import ldIsArray from "lodash-es/isArray";
import ldFirst from "lodash-es/first";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
    IColumnFilterDictionary,
    IFilterOption,
    ITranslateFilterOptionCallback
} from "@logex/framework/types";
import * as Pivots from "@logex/framework/lg-pivot";

import type { IFilterDefinition } from "../filter-definition";
import type { LgFilterSet } from "../lg-filterset";
import { IComboFilterDefinitionBase, ComboFilterRendererBase } from "./combo-filter-renderer";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";

// Combo box renderer 2 definition extensions ----------------------------------------------------------------------
export interface ISelectableComboFilter2Definition<T> extends IComboFilterDefinitionBase<T> {
    filterType: "selectablecombo2";

    /**
     * The function that should return IDs of the currently available selections for the filter.
     */
    source:
        | Pivots.IGatherFilterIdsFactoryResult<T>
        | (() => Promise<T[]>)
        | (() => Observable<T[]>);

    /**
     * The function that maps the array of IDs to sorted array of filter options.
     */
    mapToOptions: ITranslateFilterOptionCallback<T>;

    /**
     * Optional callback to prepare the filter stater for serialization. The callback must not modify the parameter
     * - if you need to do any tranformation, use map or clone.
     * Typical use would be to change scenario-specific IDs to something more general.
     * The callback should be always accompanied by matching deserializationPostprocess
     */
    serializationPreprocess?: (ids: T[]) => any;

    /**
     * Optional callback to convert a deserialized object back into the list of option IDs. See serializationPreprocess for details
     */
    deserializationPostprocess?: (state: any) => T[];

    /**
     * When true, the IDs are converted to numbers before serialization
     */
    idType: "string" | "number";

    onSelectionChanged: (id: T) => void;

    onSelectionRemoved: () => void;

    renderer?: SelectableComboFilterRenderer2;
}
// Selectable combo box renderer 2 ---------------------------------------------------------
/**
 * Implementes combo-box filter. See ISelectableComboFilter2Definition for the list of additional options
 */
export class SelectableComboFilterRenderer2
    extends ComboFilterRendererBase
    implements IFilterRenderer
{
    private _savedState: IColumnFilterDictionary | null = null;

    // ----------------------------------------------------------------------------------
    // Dependencies
    constructor(
        protected override definition: ISelectableComboFilter2Definition<any>,
        filters: any,
        logexPivot: Pivots.LogexPivotService
    ) {
        super(definition, filters, logexPivot);
        if (!this.definition.source) {
            throw Error(`Source definition for filter "${this.definition.id}" is not found.`);
        }
        if (!this.definition.mapToOptions) {
            throw Error(`Option mapping for filter "${this.definition.id}" is not found.`);
        }
    }

    source: () => IFilterOption[] | Promise<IFilterOption[]> | Observable<IFilterOption[]> = () => {
        const ids = this.definition.source();
        const mapOptionsCallback = this.definition.mapToOptions as ITranslateFilterOptionCallback<
            number | string
        >;

        if (ldIsArray(ids)) {
            return mapOptionsCallback(ids as number[] | string[]);
        } else if ("then" in ids) {
            return ids.then(data => mapOptionsCallback(data));
        } else {
            return ids.pipe(map(data => mapOptionsCallback(data)));
        }
    };

    setSelection(id: any): void {
        if (this._savedState === null) {
            this._savedState = this.filters[this.definition.storage!];
        } else if (this.filters[this.definition.storage!][id]) return;

        const option = ldFirst(this.definition.mapToOptions([id]));
        const name = option ? option.name : "Onbekend";

        this.filters[this.definition.storage!] = {
            [id]: name
        };

        if (!this._savedState.$empty && !this._savedState[id]) {
            // Save state is not empty and doesn't have the selected option. Add it.
            this._savedState[id] = name;
        }

        super.onChanged();
    }

    removeSelection(): void {
        if (!this._savedState) return;

        this.filters[this.definition.storage!] = this._savedState;
        this._savedState = null;

        super.onChanged();
    }

    override onChanged(): void {
        if (this._savedState) {
            const value = this.filters[this.definition.storage!];
            if (value.$empty) {
                this._savedState = null;
                this.definition.onSelectionRemoved();
            } else {
                const keys = Object.keys(value || {});
                if (keys.length === 1) {
                    // Saved state is not empty and doesn't have the selected option. Add it.
                    if (!this._savedState[keys[0]]) {
                        this._savedState[keys[0]] = value[keys[0]];
                    }
                    this.definition.onSelectionChanged(
                        this.definition.idType === "number" ? +keys[0] : keys[0]
                    );
                } else {
                    // More than one items, revert to regular filter
                    this._savedState = null;
                    this.definition.onSelectionRemoved();
                }
            }
        }
        super.onChanged();
    }

    override clear(): boolean {
        if (this._savedState) {
            this._savedState = null;
            this.definition.onSelectionRemoved();
        }
        return super.clear();
    }

    serialize(): string {
        if (!this.active()) return null;
        // we serialize only the saved state, not the current selection
        const state =
            this._savedState !== null ? this._savedState : this.filters[this.definition.storage];
        if (state.$empty) return "[]";
        let ids: any[] = Object.keys(state || {});
        if (this.definition.idType === "number") {
            ids = ids.map(e => +e);
        }

        if (this.definition.serializationPreprocess) {
            return JSON.stringify((this.definition.serializationPreprocess as Function)(ids));
        } else {
            return JSON.stringify(ids);
        }
    }

    deserialize(state: string): boolean {
        let newIds = JSON.parse(state);
        if (this.definition.deserializationPostprocess) {
            newIds = this.definition.deserializationPostprocess(newIds);
        }
        const newState: any = {};
        if (ldIsArray(newIds)) {
            if (newIds.length === 0) {
                newState.$empty = true;
            } else {
                const options = (
                    this.definition.mapToOptions as ITranslateFilterOptionCallback<any>
                )(newIds);
                for (const option of options) {
                    newState[option.id] = option.name;
                }
            }
        } else {
            newState.$empty = true;
        }
        if (this._savedState) {
            this.filters[this.definition.storage] = this._savedState;
            this._savedState = null;
            this.removeSelection();
        }
        if (!ldIsEqual(this.filters[this.definition.storage], newState)) {
            this.filters[this.definition.storage] = newState;
            return true;
        }
        return false;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class SelectableComboFilterRenderer2Factory implements IFilterRendererFactory {
    private _pivotService = inject(Pivots.LogexPivotService);
    readonly name: string = "selectablecombo2";

    create(
        definition: ISelectableComboFilter2Definition<any>,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        _filterSet: LgFilterSet
    ): IFilterRenderer {
        return new SelectableComboFilterRenderer2(definition, filters, this._pivotService);
    }
}
