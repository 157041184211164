import ldFilter from "lodash-es/filter";
import ldGroupBy from "lodash-es/groupBy";
import ldKeyBy from "lodash-es/keyBy";
import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable, Subject } from "rxjs";

import { urlConcat } from "@logex/framework/utilities";

import { LG_APP_CONFIGURATION } from "../application";
import { Feature, ILgFeatures } from "./features.types";

// TODO: consider moving to FADP library
@Injectable()
export class LgFeaturesService implements ILgFeatures {
    private _httpClient = inject(HttpClient);
    private _applicationConfiguration = inject(LG_APP_CONFIGURATION);

    private _featuresLookup: Record<string, Feature>;
    private _featuresByTypeLookup: Record<string, Feature[]>;
    private _onChangedSubject = new Subject<void>();
    private _onChanged$ = this._onChangedSubject.asObservable();

    // ----------------------------------------------------------------------------------
    get onChanged(): Observable<void> {
        return this._onChanged$;
    }

    async load(params: Record<string, string | number | boolean>): Promise<void> {
        const features = await firstValueFrom(
            this._httpClient.get<Feature[]>(
                urlConcat(this._applicationConfiguration.applicationRoot, "boot/features"),
                {
                    params
                }
            )
        );

        this._featuresLookup = ldKeyBy(features, "name");
        this._featuresByTypeLookup = ldGroupBy(
            ldFilter(features, x => x.typeName != null),
            "typeName"
        );

        this._onChangedSubject.next();
    }

    getFeature<T = any>(name: string): Feature<T> {
        const feature = this._featuresLookup[name];

        if (feature === undefined) {
            throw Error(`Feature ${name} is not known`);
        }

        return feature;
    }

    getFeaturesByType<T = any>(typeName: string): Array<Feature<T>> {
        return this._featuresByTypeLookup[typeName] ?? [];
    }

    isFeatureEnabled(name: string): boolean {
        const feature = this.getFeature(name);
        return feature.isEnabled;
    }
}
