/* eslint-disable @typescript-eslint/no-this-alias */
import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
    inject
} from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { toBoolean } from "@logex/framework/utilities";
import * as d3 from "d3";
import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";
import ldFlatten from "lodash-es/flatten";
import { BaseChartComponent } from "../shared/base-chart.component";
import { CHART_SEPARATOR_SIZE, LegendItem, LegendOptions, Margin } from "../shared/chart.types";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import { getLegendWidth } from "../shared/getLegendWidth";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { interruptAxisTransition } from "../shared/interruptAxisTransition";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import { LgColorPalette } from "../shared/lg-color-palette";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import {
    GroupedBarHorizontalTooltipContext,
    IGroupedBarHorizontalItem
} from "./grouped-bar-horizontal-chart.types";

const SPACE_FOR_X_AXIS_TITLE = 20;
const SPACE_FOR_LEGEND_BELOW = 28;
const X_AXIS_TITLE_FROM_AXIS = 40;
const X_AXIS_LABELS_LINE_HEIGHT = 22;
const MAX_SPACE_FOR_Y_AXIS_LABELS = 500;
const DEFAULT_MAX_LABEL_LENGTH = 23;
const DEFAULT_TICK_COUNT = 5;
const SPACE_BETWEEN_GROUPS = 0.35;
const SPACE_BETWEEN_COLUMNS = 0;
const VALUE_LABEL_MARGIN = 10;
const SPACE_BEFORE_AND_AFTER_GROUPS = 0.15;
const MARGIN: Margin = { top: 10, right: 24, bottom: 8, left: 16 };
const MAX_TICK_VALUE_RELATIVE_TO_MAXIMUM_VALUE = 19 / 20; // very whacky I know
const ICON_SIZE = 24;
const DEFAULT_COLUMN_ICON_OPTIONS = {
    icon: "icon-warning",
    iconType: "regular"
};
const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const ONE_SYMBOL_WIDTH = 6;
const SPACE_BETWEEN_GRAPH_AND_VALUE_LABELS = 10;

interface ColumnIconOptions {
    icon?: string;
    iconType?: string;
    tooltip?: boolean;
}

@Component({
    selector: "lg-grouped-bar-horizontal-chart",
    templateUrl: "./lg-grouped-bar-horizontal-chart.component.html"
})
export class LgGroupedBarHorizontalChartComponent
    extends BaseChartComponent<IGroupedBarHorizontalItem[], GroupedBarHorizontalTooltipContext>
    implements OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _legacyColorPalette = inject(LgColorPalette);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);
    private _axisFormatterFactory = inject(LgFormatterFactoryService);

    /**
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (locals: any) => string;

    /**
     * Callback for providing the group values of related data item(required).
     */
    @Input({ required: true }) groupValues!: (locals: any) => Array<number | null>;

    /**
     * Callback for providing group names of the chart (required).
     */
    @Input({ required: true }) groupNames!: (locals: any) => Array<string | null>;

    /**
     * Callback for providing group spread ranges.
     * If specified then chart contains spreads.
     */
    @Input() spreadValues?: (locals: any) => Array<[number, number]>;

    /**
     * Callback for providing opacity of group column. Valid value is number from 0 to 1.
     */
    @Input() columnOpacity?: (value: any, groupIndex: number, groupName: string) => number;

    /**
     * Callback for providing X-axis labels of group.
     */
    @Input() groupxAxisLabels?: (locals: any) => Array<number | string | null>;

    /**
     * Specifies whether title should be aligned with axis or not.
     *
     * @default false
     */
    @Input() alignTitleWithAxis?: boolean;

    /**
     * Specifies the X-axis title. If not specified then there is no X-axis title.
     */
    @Input() xAxisLabel: string;

    /**
     * @optional
     * Specifies formatter type for X axis
     */
    @Input() xAxisFormatterType?: string;

    /**
     * @optional
     * Specifies the options for X axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() xAxisFormatterOptions?: ILgFormatterOptions;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColors: string | string[];

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupHoverColors: string;
    @Input() groupBrightness: string;
    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() columnColorFn: any;

    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;

    /**
     * Specifies if group columns must overlap one another.
     *
     * @default false
     */
    @Input() overlap?: boolean;

    /**
     * Specifies which part of column is overlapped. Valid value is number from 0 to 1.
     *
     * @default 0.4
     */
    @Input() overlapFraction: number;
    /**
     * @optional
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;

    /**
     * Specifies space for X-axis labels in pixels.
     * Max allowed value is 500.
     */
    @Input() spaceForYAxisLabels?: number;

    /**
     * Specifies whether initial transition animation is turned on or not.
     *
     * @default true
     */
    @Input() initialRenderAsTransition: boolean;

    /**
     * Specifies maximum number of rendered groups.
     */
    @Input() maxGroups?: number | "auto";

    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions?: LegendOptions = getDefaultLegendOptions();

    /**
     * Specifies length of Y-axis labels in pixels.
     * Text exceeding this length will be truncated.
     *
     * @default 23
     */
    @Input() labelLength?: number = DEFAULT_MAX_LABEL_LENGTH;

    /**
     * Specifies the minimal x value from the data to show.
     * If not specified, the lowest x value from the data is used.
     */
    @Input() xMin?: number;

    /**
     * @optional
     * Specifies the maximum value on X axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() xMax?: number;

    /**
     * Specifies whether last tick is rendered or not.
     *
     * @default false
     */
    @Input() showLastTickAndItsVerticalLine = false;

    /**
     * Specifies selected group id which will be highlighted.
     */
    @Input() selectedGroup?: string;

    /**
     * Specifies whether negative values are allowed or not.
     *
     * @default false
     */
    @Input() allowNegative?: boolean;

    /**
     * Specifies whether X scale is symmetrical or not.
     *
     * @default false
     */
    @Input() xSymmetrical?: boolean;

    /**
     * Specifies whether Y axis labels are aligned to left or not.
     *
     * @default false
     */
    @Input() yAxisLabelLeftAlignment = false;

    /**
     * Callback for specifying whether group column contains icon or not.
     */
    @Input() columnIcons?: (item: any) => boolean;

    /**
     * Specifies group column icon options.
     *
     * @default  { icon: "icon-warning", iconType: "regular" }
     */
    @Input() columnIconOptions?: ColumnIconOptions;

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration?: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies whether X axis is hidden or not.
     *
     * @default false
     */
    @Input() hideXAxis?: boolean;

    /**
     * Specifies whether Y axis is hidden or not.
     *
     * @default false
     */
    @Input() hideYAxis?: boolean;

    /**
     * Specifies whether tooltip is hidden or not.
     *
     * @default false
     */
    @Input() hideTooltip = false;

    /**
     * @optional
     * Specifies whether vertical guide lines should be shown or not.
     *
     * @default = undefined
     */
    @Input() hideVerticalGuideLines?: boolean;

    /**
     * @optional
     * Specifies whether value labels should be aligned to right or not. By default they are placed where the graph ends.
     *
     * @default = false
     */
    @Input() useRightValueLabelAlignment? = false;

    /**
     * @optional
     * Specifies the minimal number of symbols in value labels. Allows the alignment of multiple unrelated charts.
     *
     * @default = 0
     */
    @Input() minValueLabelSymbolCount = 0;

    /**
     * Emits result number of rendered groups.
     */
    @Output() readonly numberOfDisplayedGroups = new EventEmitter<number>();

    /**
     * Emits data in clicked label.
     */
    @Output() readonly labelClick = new EventEmitter<any>();

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendItemClick = new EventEmitter<string>();

    @ViewChild("chart", { static: true }) private _chartDivRef: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef: ElementRef<HTMLDivElement>;

    _legendDefinition: LegendItem[];
    _legendWidth: number;

    protected _groupColors: d3.ScaleOrdinal<string, string>;
    protected _hoverGroupColors: d3.ScaleOrdinal<string, string>;
    protected _columns: string[];
    protected _groups: Array<string | null>;
    protected _groupNames: Array<string | null>;
    protected _columnColors: string[][];
    protected _groupBrightness: Array<(arg0: any) => d3.RGBColor>;
    protected _xMax: number = null;
    protected _xMin: number = null;
    protected _xScale: d3.ScaleLinear<number, number>;
    protected _xGridScale: d3.ScaleLinear<number, number>;
    protected _xGroupScale: d3.ScaleLinear<string, number>;
    protected _yScale: d3.ScaleBand<any>;
    protected _yGroupScale: d3.ScaleBand<any>;
    protected _yAxisGridG: d3.Selection<any, any, any, any>;
    protected _xAxisG: d3.Selection<any, any, any, any>;
    protected _yAxisG: d3.Selection<any, any, any, any>;
    protected _xAxisLabelG: d3.Selection<any, any, any, any>;
    protected _labelG: d3.Selection<SVGGElement, any, any, any>;
    private _groupToLegendDefinitionDictionary: { [group: string]: LegendItem };
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _plottingAreaHeight: number;
    private _trackListener: () => void;
    private _bars: d3.Selection<any, any, any, any>;
    private _groupOnTop = "";
    private _hasxAxisLabels = false;
    private _xAxisLabelsClass = "";
    private _spaceForValueLabel = 0;

    _xAxisFormatter: ILgFormatter<any>;
    _xAxisFormat: (x: number) => string;

    constructor() {
        super();
        this._margin = MARGIN;
        this._trackMousePosition();
    }

    ngOnChanges(changes: LgSimpleChanges<LgGroupedBarHorizontalChartComponent>): void {
        if (!this._initialized) {
            this._initializeFormatters();
            this._defaultProps();
            this._propsToState();

            this._triggerDataSpecificMethods();
            this._drawMainSvgHolder(this._chartDivRef.nativeElement);
            this._create();
            this._updateSize();
            this._render(this.initialRenderAsTransition);
            this._initializeTooltip();
            this._trackMousePosition();

            this._initialized = true;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (
            changes.columnName ||
            changes.columnOpacity ||
            changes.columnColorFn ||
            changes.overlapFraction ||
            changes.columnIcons ||
            changes.columnIconOptions
        ) {
            this._triggerDataSpecificMethods();
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.formatterType || changes.formatterOptions || changes.hideXAxis) {
            needsRender = true;
        }

        if (changes.groupColors) {
            this._initializeColorScales(this._data);
        }

        if (changes.groupHoverColors) {
            this._hoverGroupColors(changes.groupHoverColors.currentValue.split(","));
        }

        if (changes.groupBrightness) {
            if (!this.groupBrightness) {
                return;
            }

            this._groupBrightness = changes.groupBrightness.currentValue
                .split(",")
                .map((e: string | number) => {
                    const val = +e;
                    if (val > 0) {
                        return (col: string) => {
                            return d3.rgb(col).brighter(val);
                        };
                    } else if (val < 0) {
                        return (col: string) => {
                            return d3.rgb(col).darker(val);
                        };
                    } else {
                        return (col: any) => {
                            return col;
                        };
                    }
                });
        }

        if (
            changes.tickCount ||
            changes.width ||
            changes.height ||
            changes.data ||
            changes.labelLength
        ) {
            if (changes.data) {
                this._tooltip.hide();
                renderAsTransition = true;
            } else {
                renderImmediate = true;
                renderAsTransition = false;
            }
            this._sizePropsToState();
            this._triggerDataSpecificMethods();
            this._updateSize();
            needsRender = true;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabelG.text(this.xAxisLabel);
            needsRender = true;
        }

        if (changes.alignTitleWithAxis) {
            this._updateSize();
            this._updateXAxisLabel();
            needsRender = true;
        }

        if (changes.margins) {
            const [top, left, bottom, right] = this.margins.split(",").map(i => +i);
            this._margin = {
                top,
                left,
                bottom,
                right
            };
            needsRender = true;
        }

        if (changes.selectedGroup) {
            this._updateLegend();
            needsRender = true;
        }

        if (changes.hideYAxis || changes.hideXAxis) {
            this.hideXAxis = toBoolean(this.hideXAxis, false);
            this.hideYAxis = toBoolean(this.hideYAxis, false);
            needsRender = true;
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        if (this.selectedGroup) {
            this._setGroupOnTop(this.selectedGroup);
        }
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    protected _defaultProps(): void {
        this.tickCount = this.tickCount || DEFAULT_TICK_COUNT;
        this.spaceForYAxisLabels = this.showYAxisLabels
            ? Math.min(this.spaceForYAxisLabels, MAX_SPACE_FOR_Y_AXIS_LABELS)
            : 0;
        this.initialRenderAsTransition = toBoolean(this.initialRenderAsTransition, true);
        this.overlapFraction = coerceNumberProperty(this.overlapFraction, 0.4);
        this.columnIconOptions = {
            ...DEFAULT_COLUMN_ICON_OPTIONS,
            ...this.columnIconOptions
        };

        if (this.margins) {
            const [top, left, bottom, right] = this.margins.split(",").map(i => +i);
            this._margin = {
                top,
                left,
                bottom,
                right
            };
        }
        this.hideXAxis = toBoolean(this.hideXAxis, false);
        this.hideYAxis = toBoolean(this.hideYAxis, false);

        if (this.xAxisFormatterType != null) {
            this.xAxisFormatterOptions = {
                ...DEFAULT_TICKS_FORMATTER_OPTIONS,
                ...this.xAxisFormatterOptions
            };

            this._xAxisFormatter = this._axisFormatterFactory.getFormatter(
                this.xAxisFormatterType,
                this.xAxisFormatterOptions
            );

            this._xAxisFormat = x => this._xAxisFormatter.format(x);
        } else {
            this._xAxisFormat = this._numberFormat;
        }
    }

    private _countSpaceForValueLabel(): void {
        if (!this.useRightValueLabelAlignment) {
            this._spaceForValueLabel = 0;
            return;
        }
        this._margin.left = 0;
        this._spaceForValueLabel = 0;
        this._data.forEach(element => {
            element.forEach(item => {
                if (item.xAxisLabel.length > this._spaceForValueLabel)
                    this._spaceForValueLabel = Math.max(
                        this.minValueLabelSymbolCount,
                        item.xAxisLabel.length
                    );
            });
        });
        this._spaceForValueLabel = this._spaceForValueLabel * ONE_SYMBOL_WIDTH;
    }

    protected _propsToState(): void {
        this._sizePropsToState();
    }

    protected _sizePropsToState(): void {
        this._width = Math.max(0, this.width);
    }

    private _triggerDataSpecificMethods(): void {
        this._convertData();
        this._initializeColorScales(this._data);
        this._updateLegend();
    }

    private _initializeColorScales(data: IGroupedBarHorizontalItem[][]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            this._hoverGroupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._initializeLegacyColorScales(data);
    }

    /**
     * @deprecated
     */
    private _initializeLegacyColorScales(data: IGroupedBarHorizontalItem[][]): void {
        if (!data || !data.length) {
            return;
        }

        let colors: string[];
        if (this.groupColors) {
            colors = this._legacyColorPalette.getPaletteForColors(this.groupColors);
        } else {
            const numberOfGroups = data.reduce(
                (result, group) => (result = Math.max(result, group.length)),
                0
            );
            colors = this._legacyColorPalette.getPalette(numberOfGroups);
        }

        this._groupColors = d3.scaleOrdinal(colors);
        this._hoverGroupColors = d3.scaleOrdinal(colors);
    }

    private _getLegendSize(below: boolean, onTheRight: boolean): number {
        if (!below && !onTheRight) return 0;

        if (below) {
            return SPACE_FOR_LEGEND_BELOW;
        }

        return getLegendWidth(this.width, this.legendOptions.widthInPercents, this._groupNames);
    }

    protected _updateSize(): void {
        this._countSpaceForValueLabel();

        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);

        if (legendOnTheRight) {
            this._legendWidth = legendSize;
        }

        this._plottingAreaHeight = Math.max(
            this.height -
                (legendBelow ? SPACE_FOR_LEGEND_BELOW : 0) -
                (!this.hideXAxis ? X_AXIS_LABELS_LINE_HEIGHT : 0) -
                (this.xAxisLabel && !this.alignTitleWithAxis ? SPACE_FOR_X_AXIS_TITLE : 0),
            0
        );

        const svgWidth = this.width - (legendOnTheRight ? legendSize : 0);

        // this._svgG.attr("transform", `translate( ${this.spaceForYAxisLabels}, 0 )`);

        this._svg
            .attr("width", Math.max(0, svgWidth))
            .attr("height", Math.max(this.height - (legendBelow ? legendSize : 0), 0));

        this._yScale
            .range([this._margin.top, this._plottingAreaHeight - this._margin.bottom])
            .domain(this._columns)
            .paddingInner(this._columns.length > 1 ? SPACE_BETWEEN_GROUPS : 0)
            .paddingOuter(this._columns.length > 1 ? SPACE_BEFORE_AND_AFTER_GROUPS : 0)
            .round(true);

        this._yGroupScale
            .range([
                0,
                ((this._plottingAreaHeight - (this._margin.bottom + this._margin.top)) /
                    Math.max(
                        1,
                        this._columns.length -
                            SPACE_BETWEEN_GROUPS +
                            SPACE_BEFORE_AND_AFTER_GROUPS * 2
                    )) *
                    (this._columns.length > 1 ? 1 - SPACE_BETWEEN_GROUPS : 1)
            ])
            .domain(this.overlap ? ["one"] : this._groups)
            .padding(SPACE_BETWEEN_COLUMNS)
            .round(true);

        let xAxisLabelSpace = 0;

        if (this.useRightValueLabelAlignment) {
            xAxisLabelSpace =
                this._spaceForValueLabel > 0
                    ? this._spaceForValueLabel + SPACE_BETWEEN_GRAPH_AND_VALUE_LABELS
                    : 0;
        } else if (this._hasxAxisLabels && !this.overlap) {
            const barHeight = this._yGroupScale.bandwidth();
            if (barHeight < 8) {
                this._xAxisLabelsClass = "lg-grouped-bar-horizontal-chart__value-labels--hidden";
            } else if (barHeight < 12) {
                this._xAxisLabelsClass = "lg-grouped-bar-horizontal-chart__value-labels--small";
            } else {
                this._xAxisLabelsClass = "";
            }
            xAxisLabelSpace = this._getSpaceForxAxisLabels();
            if (xAxisLabelSpace) xAxisLabelSpace += VALUE_LABEL_MARGIN;
        }

        const xAxisLabelXOffset = this.allowNegative ? xAxisLabelSpace : 0;

        this._xScale
            .range([
                xAxisLabelXOffset,
                this.width -
                    this.spaceForYAxisLabels -
                    (legendOnTheRight ? legendSize : 0) -
                    this._margin.right -
                    xAxisLabelSpace
            ])
            .domain([this.allowNegative ? this._xMin : 0, this._xMax])
            .nice(this._getTickCount())
            .interpolate(d3.interpolateRound);

        this._xGridScale
            .range([
                xAxisLabelXOffset + this.spaceForYAxisLabels,
                this.width -
                    (legendOnTheRight ? legendSize : 0) -
                    this._margin.right -
                    xAxisLabelSpace
            ])
            .domain([this.allowNegative ? this._xMin : 0, this._xMax])
            .nice(this._getTickCount())
            .interpolate(d3.interpolateRound);

        this._bars.attr("transform", `translate(${this.spaceForYAxisLabels}, 0)`);
        this._xAxisG.attr("transform", `translate(0, ${this._plottingAreaHeight - 6})`);
        this._yAxisG.attr("transform", `translate(${this.spaceForYAxisLabels}, 0) `);
        this._labelG.attr("transform", `translate(${this.spaceForYAxisLabels}, 0)`);
    }

    protected _render(immediate?: boolean): void {
        if (!this._bars) {
            this._create();
        }

        if (!this.data || !this.data.length) {
            return;
        }

        const getColor = (
            item: Partial<IGroupedBarHorizontalItem>,
            _column: string,
            group: string,
            groupIndex: number,
            hover: boolean
        ): any => {
            const columnIndex = item.barIndex;

            if (this.columnColorFn != null && this._columnColors[columnIndex] != null) {
                const color = this._columnColors[columnIndex][groupIndex];
                if (hover) {
                    return d3.rgb(color).darker(0.2);
                } else {
                    return d3.rgb(color);
                }
            } else if (hover) {
                if (this.groupHoverColors) {
                    return d3.rgb(this._hoverGroupColors(group));
                }
                return d3.rgb(this._groupColors(group)).darker(0.2);
            } else {
                return d3.rgb(this._groupColors(group));
            }
        };

        const self = this;

        this._svg
            .select(".x__axis")
            .selectAll(".tick")
            .each(function (_ignore, i, all) {
                d3.select(all[i]).transition("appearing").style("opacity", 1);
            });

        this._svg.on("mouseleave", () => this._tooltip.hide());

        this._groupColors.domain(this._groups);
        this._hoverGroupColors.domain(this._groups);

        let yScale: d3.ScaleBand<any>;

        if (!coerceBooleanProperty(this.showYAxisLabels)) {
            yScale = d3.scaleBand().range([0, this._plottingAreaHeight]).padding(0.1).domain([""]);
        }

        if (this.hideXAxis) {
            this._xAxisG.style("display", "none");
            this._yAxisGridG.style("display", "none");
        } else {
            this._xAxisG.style("display", "initial");
            this._yAxisGridG.style("display", "initial");
        }
        if (this.hideYAxis) {
            this._yAxisG.style("display", "none");
        } else {
            this._yAxisG.style("display", "initial");
        }
        if (this.hideVerticalGuideLines !== undefined) {
            if (this.hideVerticalGuideLines) {
                this._yAxisGridG.style("display", "none");
            } else {
                this._yAxisGridG.style("display", "initial");
            }
        }

        const xAxisGrid = this._getXAxisGrid(this._xGridScale);
        const xAxis = this._getXAxis(this._xGridScale);
        const yAxis = this._getYAxis(yScale || this._yScale);
        interruptAxisTransition(this._xAxisG);
        interruptAxisTransition(this._yAxisG);

        if (immediate) {
            this._xAxisG.call(xAxis);
            this._yAxisG.call(yAxis);
        } else {
            if (this.showLastTickAndItsVerticalLine) {
                this._xAxisG.transition().call(xAxis);
            } else {
                this._xAxisG
                    .transition()
                    .call(xAxis)
                    .on("start", function () {
                        d3.select(this)
                            .selectAll(".tick")
                            .each(function (d, i, all) {
                                if (+d > self._xMax * MAX_TICK_VALUE_RELATIVE_TO_MAXIMUM_VALUE) {
                                    d3.select(all[i])
                                        .transition("disappearing")
                                        .style("opacity", 0);
                                }
                            });
                    });
            }
            this._yAxisG.transition().call(yAxis);
        }

        if (this.columnIcons) {
            this._yAxisG
                .selectAll(".tick")
                .data(this._data)
                .each(function (d) {
                    if (self.columnIcons(d[0].item)) {
                        d3.select(this)
                            .append("g")
                            .attr("transform", () => {
                                return `translate(${-ICON_SIZE}, ${-ICON_SIZE / 2})`;
                            })
                            .attr(
                                "class",
                                `${self.columnIconOptions.icon} lg-icon lg-icon--${self.columnIconOptions.iconType} lg-tooltip-visible`
                            )
                            .on("mousemove", function (_event: MouseEvent) {
                                if (self.columnIconOptions.tooltip && self.columnIcons)
                                    self._onMouseOverColumnIcon(d3.select(this));
                            })
                            .on("mouseout", (_event: MouseEvent) => {
                                if (self.columnIconOptions.tooltip && self.columnIcons)
                                    self._tooltip.hide();
                            })
                            .append("use")
                            .attr("xlink:href", `#${self.columnIconOptions.icon}`)
                            .attr("height", ICON_SIZE)
                            .attr("width", ICON_SIZE);
                    }
                });

            this._yAxisG.selectAll(".tick").data(this._columns);
        }

        if (this.yAxisLabelLeftAlignment) {
            this._yAxisG
                .selectAll("text")
                .style("text-anchor", "start")
                .attr(
                    "transform",
                    `translate(-${
                        this.columnIcons
                            ? this.spaceForYAxisLabels - 18 - ICON_SIZE
                            : this.spaceForYAxisLabels - 24
                    }, 0)`
                );
        }

        if (immediate) {
            this._yAxisG
                .selectChild(".domain")
                .attr("transform", `translate(${this._xScale(0)}, 0) `);
        } else {
            this._yAxisG
                .selectChild(".domain")
                .transition()
                .attr("transform", `translate(${this._xScale(0)}, 0) `);
        }
        this._yAxisGridG.call(xAxisGrid).attr("transform", `translate(0, ${this._margin.top})`);

        const groups = this._bars
            .selectAll<SVGGElement, any>(".group")
            .data(this._data, d => d[0].column);

        // this is needed for fast rerenders when previous selection hasn't stopped exitting
        groups.interrupt();
        groups.transition("reappearing").style("opacity", 1);

        const groupsMerged = groups.enter().append("g").attr("class", "group").merge(groups);

        if (immediate) {
            groups.exit().remove();
        } else {
            groups.exit().transition().style("opacity", 0).remove();
        }

        groupsMerged
            .on("mouseover", function (_event: MouseEvent, d: IGroupedBarHorizontalItem[]) {
                self.tooltipContext = {
                    ...self.tooltipContext,
                    ...{
                        columnsWithinGroup: d,
                        groupToLegendDefinitionDictionary: self._groupToLegendDefinitionDictionary
                    },
                    iconTooltip: false
                };
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (di: Partial<IGroupedBarHorizontalItem>, gi: number) =>
                        getColor(di, di.column, di.group, gi, true)
                    );
                if (!self.hideTooltip) {
                    self._tooltip.hideShow();
                    self._updateTooltipPosition();
                }
            })
            .on("mouseout", function (_event: MouseEvent) {
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (di: Partial<IGroupedBarHorizontalItem>, gi: number) =>
                        getColor(di, di.column, di.group, gi, false)
                    );
            })
            .on("mouseleave", (_event: MouseEvent) => this._tooltip.scheduleHide());

        const overlapStep = Math.max(1, (this._yGroupScale.bandwidth() * this.overlapFraction) / 2);

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const getBars = (
            barGroups: d3.Selection<SVGGElement, IGroupedBarHorizontalItem[], any, any>
        ) =>
            barGroups.selectAll<SVGRectElement, IGroupedBarHorizontalItem>("rect").data(
                (d: IGroupedBarHorizontalItem[]) =>
                    // switching order of drawing for ovelapping groups. Overlap rendering is used only with 2 groups, so we can assume the size here
                    this._groupOnTop === this._groups[1] ? d.reverse() : d,
                (d: IGroupedBarHorizontalItem) => d.group
            );

        let bars = getBars(groupsMerged);

        if (immediate) {
            bars.exit().remove();
        } else {
            bars.exit().transition().style("opacity", 0).remove();
        }

        bars.enter()
            .append("rect")
            .attr("class", "bar")
            .attr("y", d =>
                this._calculateYPosition(d, this._isSelectedGroup(d.group), overlapStep)
            )
            .attr("height", d =>
                this._calculateBarHeight(this._isSelectedGroup(d.group), overlapStep)
            )
            .attr("x", 0)
            .attr("width", 0)
            .style("fill", (d, gi) => getColor(d, d.column, d.group, gi, false))
            .style("opacity", d => d.opacity)
            .style("cursor", () => (this.clickable ? "pointer" : "default"))
            .on("click", function (_event: MouseEvent, d: IGroupedBarHorizontalItem) {
                const index = bars.nodes().indexOf(this);
                self.onClick(d, index);
            })
            .on("mouseover", (_event: MouseEvent, d: IGroupedBarHorizontalItem) => {
                this.tooltipContext = {
                    ...self.tooltipContext,
                    ...{ currentColumn: d },
                    iconTooltip: false
                };
            });

        bars.interrupt();

        // Regain bars for applying props with the transition (if needed)
        bars = getBars(groupsMerged);

        if (immediate) {
            bars.transition();
        }

        bars.attr("x", d => {
            let value = d.value;
            value = Math.min(value, this._xMax);
            value = Math.max(value, this._xMin);

            return this._xScale(Math.min(0, this.allowNegative ? value : 0));
        })
            .attr("width", d => {
                if (!isFinite(d.value) || (d.value < 0 && !this.allowNegative)) return 0;

                let value = d.value;
                value = Math.min(value, this._xMax);
                value = Math.max(value, this._xMin);

                return this._xScale(value > 0 ? 0 : -value) - this._xScale(value < 0 ? 0 : -value);
            })
            .style("opacity", (d, gi) =>
                this.columnOpacity ? this.columnOpacity(d.item, gi, d.group) : 1
            )
            .attr("y", d =>
                this._calculateYPosition(d, this._isSelectedGroup(d.group), overlapStep)
            )
            .attr("height", d =>
                this._calculateBarHeight(this._isSelectedGroup(d.group), overlapStep)
            );

        this._yAxisG
            .selectAll(".tick")
            .on("mouseover", function (_event: MouseEvent, name: any) {
                const barGroups = self._bars
                    .selectAll(".group")
                    .data() as IGroupedBarHorizontalItem[][];
                const barGroupsIndex = barGroups
                    .map(group => group[0])
                    .map(groupItem => groupItem.column)
                    .indexOf(name);

                self._bars.selectAll(".group").each(function (_ignore, i) {
                    d3.select(this)
                        .selectAll("rect")
                        .style("fill", (di: Partial<IGroupedBarHorizontalItem>, gi: number) => {
                            return getColor(di, di.column, di.group, gi, i === barGroupsIndex);
                        });
                });

                self.tooltipContext = {
                    columnsWithinGroup: barGroups[barGroupsIndex],
                    groupToLegendDefinitionDictionary: self._groupToLegendDefinitionDictionary,
                    iconTooltip: false
                };

                if (!self.hideTooltip) {
                    self._tooltip.hideShow();
                    self._updateTooltipPosition();
                }
            })
            .on("mouseleave", (_event: MouseEvent) => {
                self._bars.selectAll(".group").each(function () {
                    d3.select(this)
                        .selectAll("rect")
                        .style("fill", (di: Partial<IGroupedBarHorizontalItem>, gi: number) => {
                            return getColor(di, di.column, di.group, gi, false);
                        });
                });
                this._tooltip.scheduleHide();
            });

        if (this.labelClick.observers.length > 0) {
            this._yAxisG
                .selectAll(".tick")
                .style("cursor", "pointer")
                .on("click", (_event: MouseEvent, name: any) => {
                    const barGroups = self._bars
                        .selectAll(".group")
                        .data() as IGroupedBarHorizontalItem[][];
                    const barGroupsIndex = barGroups
                        .map(group => group[0])
                        .map(groupItem => groupItem.column)
                        .indexOf(name);
                    if (barGroupsIndex < 0) return;

                    this.labelClick.next(barGroups[barGroupsIndex][0].item);
                });
        }

        if (this.spreadValues) {
            const spread = groupsMerged
                .selectAll<
                    SVGGElement,
                    IGroupedBarHorizontalItem
                >(".lg-grouped-bar-horizontal-chart__spread-group")
                .data(
                    (d: IGroupedBarHorizontalItem[]) => d,
                    (d: IGroupedBarHorizontalItem) => d.group
                );

            if (immediate) {
                spread.exit().remove();
            } else {
                spread.exit().transition().style("opacity", 0).remove();
            }

            const spreadEnter = spread
                .enter()
                .append("g")
                .classed("lg-grouped-bar-horizontal-chart__spread-group", true)
                .style("opacity", d => d.opacity);

            let spreadMerged = spreadEnter.merge(spread);

            const spreadYCoords = (
                selection: d3.Selection<
                    SVGLineElement,
                    IGroupedBarHorizontalItem,
                    SVGGElement,
                    IGroupedBarHorizontalItem[]
                >,
                middle: boolean
            ): d3.Selection<
                SVGLineElement,
                IGroupedBarHorizontalItem,
                SVGGElement,
                IGroupedBarHorizontalItem[]
            > =>
                selection
                    .attr(
                        "y1",
                        d =>
                            self._calculateYPosition(
                                d,
                                self._isSelectedGroup(d.group),
                                overlapStep
                            ) +
                            self._calculateBarHeight(self._isSelectedGroup(d.group), overlapStep) *
                                (middle ? 0.5 : 0.25)
                    )
                    .attr(
                        "y2",
                        d =>
                            self._calculateYPosition(
                                d,
                                self._isSelectedGroup(d.group),
                                overlapStep
                            ) +
                            self._calculateBarHeight(self._isSelectedGroup(d.group), overlapStep) *
                                (middle ? 0.5 : 0.75)
                    );

            const spreadXCoords = (
                selection: d3.Selection<
                    SVGLineElement,
                    IGroupedBarHorizontalItem,
                    SVGGElement,
                    IGroupedBarHorizontalItem[]
                >,
                upperIndex: number,
                lowerIndex: number
            ): d3.Selection<
                SVGLineElement,
                IGroupedBarHorizontalItem,
                SVGGElement,
                IGroupedBarHorizontalItem[]
            > =>
                selection
                    .attr("x1", d =>
                        d.spread?.[upperIndex] != null
                            ? this._xScale(Math.max(0, d.spread[upperIndex]))
                            : 0
                    )
                    .attr("x2", d =>
                        d.spread?.[lowerIndex] != null
                            ? this._xScale(Math.max(0, d.spread[lowerIndex]))
                            : 0
                    );

            let spreadLineHorizontal = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-horizontal-chart__spread-group__line-horizontal", true)
                .call(spreadYCoords, true)
                .attr("x1", 0)
                .attr("x2", 0)
                .merge(
                    spreadMerged.select(
                        ".lg-grouped-bar-horizontal-chart__spread-group__line-horizontal"
                    )
                );

            let spreadLineVerticalLower = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-horizontal-chart__spread-group__line-vertical-lower", true)
                .call(spreadYCoords, false)
                .attr("x1", 0)
                .attr("x2", 0)
                .merge(
                    spreadMerged.select(
                        ".lg-grouped-bar-horizontal-chart__spread-group__line-vertical-lower"
                    )
                );

            let spreadLineVerticalUpper = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-horizontal-chart__spread-group__line-vertical-upper", true)
                .attr("x1", 0)
                .attr("x2", 0)
                .call(spreadYCoords, false)
                .merge(
                    spreadMerged.select(
                        ".lg-grouped-bar-horizontal-chart__spread-group__line-vertical-upper"
                    )
                );

            spreadMerged.interrupt();
            spreadLineHorizontal.interrupt();
            spreadLineVerticalLower.interrupt();
            spreadLineVerticalUpper.interrupt();

            spreadMerged = immediate ? spreadMerged : (spreadMerged.transition() as any);
            spreadLineHorizontal = immediate
                ? spreadLineHorizontal
                : (spreadLineHorizontal.transition() as any);
            spreadLineVerticalLower = immediate
                ? spreadLineVerticalLower
                : (spreadLineVerticalLower.transition() as any);
            spreadLineVerticalUpper = immediate
                ? spreadLineVerticalUpper
                : (spreadLineVerticalUpper.transition() as any);

            spreadLineHorizontal.call(spreadYCoords, true).call(spreadXCoords, 0, 1);

            spreadLineVerticalLower.call(spreadYCoords, false).call(spreadXCoords, 0, 0);

            spreadLineVerticalUpper.call(spreadYCoords, false).call(spreadXCoords, 1, 1);

            spreadMerged.style("opacity", d =>
                d.spread?.[0] != null && d.spread?.[1] != null ? d.opacity : 0
            );
        }

        if (this._hasxAxisLabels && !this.overlap) {
            this._labelG.attr(
                "class",
                `lg-grouped-bar-horizontal-chart__value-labels ${this._xAxisLabelsClass}`
            );
            const labelGroups = this._labelG
                .selectAll<
                    SVGGElement,
                    IGroupedBarHorizontalItem[]
                >(".lg-grouped-bar-horizontal-chart__value-labels__group")
                .data(this._data, d => d[0].column);

            // this is needed for fast rerenders when previous selection hasn't stopped exitting
            labelGroups.interrupt("reappearing");
            labelGroups.transition("reappearing").style("opacity", 1);

            const labelGroupsMerged = labelGroups
                .enter()
                .append("g")
                .attr("class", "lg-grouped-bar-horizontal-chart__value-labels__group")
                .merge(labelGroups);

            if (immediate) {
                labelGroups.exit().remove();
            } else {
                labelGroups.exit().transition().style("opacity", 0).remove();
            }

            const labels = labelGroupsMerged
                .selectAll<SVGTextElement, IGroupedBarHorizontalItem[]>("text")
                .data<any>(
                    d => d,
                    (d: IGroupedBarHorizontalItem) => d.group
                );

            if (immediate) {
                labels.exit().remove();
            } else {
                labels.exit().transition().style("opacity", 0).remove();
            }

            let mergedLabels = labels
                .enter()
                .append("text")
                .attr("text-anchor", d => (d.value < 0 ? "end" : "start"))
                .attr(
                    "y",
                    d =>
                        self._calculateYPosition(d, self._isSelectedGroup(d.group), overlapStep) +
                        self._calculateBarHeight(self._isSelectedGroup(d.group), overlapStep) / 2
                )
                .attr("x", d => {
                    if (!isFinite(d.value)) return self._getxAxisLabelMargin(d) + this._xScale(0);

                    let value = d.value;
                    value = Math.min(value, this._xMax);
                    value = Math.max(value, this._xMin);

                    return (
                        self._getxAxisLabelMargin(d, value) * (value < 0 ? -1 : 1) +
                        this._xScale(d.spread?.[1] ?? value)
                    );
                })
                .attr("dy", "0.5ex")
                .style("opacity", 0)
                .text(d => (!this.allowNegative && d.value < 0 ? null : d.xAxisLabel))
                .merge(labels);

            mergedLabels.interrupt();
            mergedLabels = immediate ? mergedLabels : (mergedLabels.transition() as any);

            mergedLabels
                .attr("width", () =>
                    this.useRightValueLabelAlignment ? this._spaceForValueLabel : 0
                )
                .attr("text-anchor", this.useRightValueLabelAlignment ? "end" : "start")
                .attr("x", d => {
                    if (this.useRightValueLabelAlignment)
                        return (
                            this._width -
                            this.spaceForYAxisLabels -
                            this._margin.right -
                            this._margin.left
                        );
                    if (!isFinite(d.value)) return self._getxAxisLabelMargin(d) + this._xScale(0);

                    let value = d.value;
                    value = Math.min(value, this._xMax);
                    value = Math.max(value, this._xMin);

                    return (
                        self._getxAxisLabelMargin(d, value) * (value < 0 ? -1 : 1) +
                        this._xScale(d.spread?.[1] ?? value)
                    );
                })
                .attr(
                    "y",
                    d =>
                        self._calculateYPosition(d, self._isSelectedGroup(d.group), overlapStep) +
                        self._calculateBarHeight(self._isSelectedGroup(d.group), overlapStep) / 2
                )
                .style("opacity", 1)
                .text(d => (!this.allowNegative && d.value < 0 ? null : d.xAxisLabel));
        } else {
            this._labelG.selectAll("g").data([]).exit().transition().style("opacity", 0).remove();
        }

        if (!this.showLastTickAndItsVerticalLine) {
            this._removeLastTickAndItsVerticalLine(immediate);
        }
    }

    private _getxAxisLabelMargin(item: IGroupedBarHorizontalItem, overrideValue?: number): number {
        if ((overrideValue ?? item.value) || (item.spread && (item.spread[0] || item.spread[1]))) {
            return VALUE_LABEL_MARGIN;
        }

        return 0;
    }

    private _calculateYPosition(
        datum: IGroupedBarHorizontalItem,
        selected: boolean,
        overlapStep: number
    ): number {
        return (
            this._yScale(datum.column) +
            (coerceBooleanProperty(this.overlap)
                ? overlapStep * (selected ? 2 : 1)
                : this._yGroupScale(datum.group))
        );
    }

    private _calculateBarHeight(selected: boolean, overlapStep: number): number {
        return (
            this._yGroupScale.bandwidth() -
            (this.overlap ? 2 * (selected ? 2 : 1) * overlapStep : 0) -
            CHART_SEPARATOR_SIZE
        );
    }

    protected _create(): void {
        this._xScale = d3.scaleLinear();
        this._xGridScale = d3.scaleLinear();
        this._yScale = d3.scaleBand();
        this._yGroupScale = d3.scaleBand<any>();

        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis__grid" : "y__axis__grid__legacy"}`);

        this._bars = this._svgG.append("g").attr("class", "bars-group");

        this._yAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`);
        this._xAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis" : "x__axis__legacy"}`);
        this._xAxisLabelG = this._xAxisG
            .append("text")
            .attr("class", "axis__title")
            .attr("x", () => {
                if (!this.alignTitleWithAxis) {
                    return (this._width - this.spaceForYAxisLabels) / 2;
                }
                return this._margin.left;
            })
            .attr("y", this.alignTitleWithAxis ? 0 : X_AXIS_TITLE_FROM_AXIS)
            .attr("dy", this.alignTitleWithAxis ? "1.1em" : "")
            .attr("text-anchor", this.alignTitleWithAxis ? "start" : "middle")
            .text(this.xAxisLabel);
        this._labelG = this._svgG
            .append("g")
            .attr("class", "lg-grouped-bar-horizontal-chart__value-labels");
    }

    private _updateXAxisLabel(): void {
        this._xAxisLabelG
            .attr("x", () => {
                if (!this.alignTitleWithAxis) {
                    return (this._width - this.spaceForYAxisLabels) / 2;
                }
                return this._margin.left;
            })
            .attr("y", this.alignTitleWithAxis ? 0 : X_AXIS_TITLE_FROM_AXIS)
            .attr("dy", this.alignTitleWithAxis ? "1.1em" : "")
            .attr("text-anchor", this.alignTitleWithAxis ? "start" : "middle")
            .text(this.xAxisLabel);
    }

    private _getXAxis(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisBottom<any>(scale)
            .tickSizeInner(5)
            .tickSizeOuter(0)
            .tickPadding(2)
            .tickFormat(this._xAxisFormat)
            .ticks(this._getTickCount());
    }

    private _getYAxis(scale: d3.ScaleBand<any>): d3.Axis<any> {
        const maxLabelLength = this.columnIcons
            ? this.labelLength - 5
            : this.yAxisLabelLeftAlignment
              ? this.labelLength - 1
              : this.labelLength;

        return d3
            .axisLeft(scale)
            .tickSize(0)
            .tickPadding(this.columnIcons ? ICON_SIZE : 10) // space between X axis and labels
            .tickFormat(item => {
                if (item.length > maxLabelLength + 2) {
                    return item.substring(0, maxLabelLength) + "...";
                }
                return item;
            });
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisBottom(scale)
            .tickSizeInner(this._plottingAreaHeight - this._margin.top - this._margin.bottom + 2)
            .tickSizeOuter(0)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this._getTickCount());
    }

    protected _convertData(): void {
        if (!this.data) {
            return;
        }

        let croppedData = this.data;
        if (this.maxGroups) {
            const numberOfGroups =
                this.maxGroups === "auto" ? this._getNumberOfGroups() : this.maxGroups;

            croppedData = this.data.slice(0, numberOfGroups);
        }

        this.numberOfDisplayedGroups.emit(croppedData.length);

        let colors: any[] = [];
        this._xMin = this.xMin ?? 0;
        this._xMax = this.xMax ?? 0;
        this._data = [];
        this._columns = [];

        this._groups = [];
        this._groupNames = [];

        if (this.columnColorFn && this.columnColorFn.const) {
            const tColors = this.columnColorFn();
            if (ldIsString(tColors)) {
                colors = tColors.split(",");
            }
        }

        this._hasxAxisLabels = false;
        croppedData.forEach(value => {
            const columnName = this.columnName(value);
            this._columns.push(columnName);

            if (!this._groupNames.length) {
                this._groupNames = this.groupNames(value);
                this._groups = this._groupNames.map((x, i) => `group${i}`);
                this._groupOnTop = this.selectedGroup ? this.selectedGroup : this._groups[0];
            }

            if (this.columnColorFn && !this.columnColorFn.const) {
                colors.push(this.columnColorFn(value));
            }

            const values = this.groupValues(value);

            if (!values) {
                return;
            }

            let spreadValues: Array<[number, number]> | null = null;
            if (this.spreadValues) {
                spreadValues = this.spreadValues(value);
            }

            const xAxisLabels = this.groupxAxisLabels?.(value) ?? null;

            const row: IGroupedBarHorizontalItem[] = [];
            for (let i = 0; i < values.length; i++) {
                const opacity = this.columnOpacity
                    ? this.columnOpacity(value, i, this._groups[i])
                    : 1;

                let xAxisLabel: string | null = null;
                if (xAxisLabels?.[i] != null) {
                    const srcLabel = xAxisLabels[i];
                    if (ldIsString(srcLabel)) {
                        xAxisLabel = srcLabel;
                    } else if (!isNaN(srcLabel)) {
                        xAxisLabel = this._labelFormat(srcLabel);
                    }
                    if (xAxisLabel) this._hasxAxisLabels = true;
                }

                row.push({
                    column: columnName,
                    group: `group${i}`,
                    groupName: this._groupNames[i],
                    value: values[i],
                    spread: spreadValues ? spreadValues[i] : undefined,
                    opacity,
                    item: value,
                    barIndex: this._data.length,
                    xAxisLabel
                });

                if (!this.xMin) {
                    this._xMin = Math.min(
                        this._xMin,
                        !isFinite(values[i]) ? this._xMin : values[i] ?? this._xMin
                    );
                    if (spreadValues?.[i]?.[0] != null && spreadValues?.[i]?.[1] != null)
                        this._xMin = Math.min(spreadValues[i][1], this._xMin);
                }

                if (!this.xMax) {
                    this._xMax = Math.max(
                        this._xMax,
                        !isFinite(values[i]) ? this._xMax : values[i] ?? this._xMax
                    );
                    if (spreadValues?.[i]?.[0] != null && spreadValues?.[i]?.[1] != null)
                        this._xMax = Math.max(spreadValues[i][1], this._xMax);
                }

                if (this.xSymmetrical) {
                    const edge = Math.max(Math.abs(this._xMin), Math.abs(this._xMax));
                    this._xMin = this._xMin < 0 ? -edge : edge;
                    this._xMax = edge;
                }
            }
            this._data.push(row);
        });

        if (colors.length) {
            // this will be array of groups of colours
            this._columnColors = [];
            for (let i = 0; i < this._columns.length; ++i) {
                // if not enough colours were specified, just repeat the last one
                let entry = colors[Math.min(i, colors.length - 1)];
                const group = [];
                let mainColor: Record<string, any> | Array<typeof undefined>;
                if (!ldIsArray(entry)) {
                    // if the entry was single item, we'll convert it to group of colours by using the brightnesses (if specified)
                    mainColor = entry;
                    entry = [];
                } else {
                    // we've got array ,so in theory no processing, but in case there is not enough colours
                    mainColor = entry[0];
                }

                for (let j = 0; j < this._groupNames.length; ++j) {
                    if (j < entry.length) {
                        group.push(entry[j]);
                    } else if (this._groupBrightness != null && j < this._groupBrightness.length) {
                        group.push(this._groupBrightness[j](mainColor));
                    } else {
                        group.push(mainColor);
                    }
                }

                this._columnColors.push(group);
            }
        } else {
            this._columnColors = null;
        }
    }

    protected onClick(value: IGroupedBarHorizontalItem, index: number): void {
        this.itemClick.emit({ item: value.item, datum: value, index });
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    protected _updateLegend(): void {
        const legend: LegendItem[] | Array<{ color: string; name: string; opacity: number }> = [];

        if (!this._groups || !this._groups.length) {
            this._legendDefinition = legend;
            return;
        }

        const groupToColor: Record<string, LegendItem> = {};
        this._groups.forEach((group, i) => {
            const row = {
                color: this._groupColors(group),
                name: this._groupNames[i],
                opacity: this._getGroupOpacity(group)
            };

            legend.push(row);
            groupToColor[group] = row;
        });

        this._legendDefinition = legend;
        this._groupToLegendDefinitionDictionary = groupToColor;
    }

    private _getTickCount(): number {
        if (this.formatterType === "percent" || this.formatterType === "percentage")
            return this.tickCount;

        // this loop searches for multiplier that allows to display some X axis values for numbers smaller than 1
        // 0.4 produces 4 (in max * multiplier), 0.03 produces 3, 0.006 produces 6 etc.
        let multiplier = 1;
        let treshold = 1;
        let finalTresholdFound = false;
        while (!finalTresholdFound) {
            if (this._xMax < treshold) {
                multiplier *= 10;
                treshold /= 10;
            } else {
                finalTresholdFound = true;
            }
        }
        return Math.min(Number(this.tickCount), this._xMax * multiplier);
    }

    private _getNumberOfGroups(): number {
        return Math.max(0, Math.floor((this.height - 32 - 20) / 24));
    }

    private _removeLastTickAndItsVerticalLine(immediate: boolean): any {
        this._svg
            .select(".y__axis__grid")
            .selectAll(".tick")
            .each((d, i, all) => {
                if (+d > this._xMax * MAX_TICK_VALUE_RELATIVE_TO_MAXIMUM_VALUE) {
                    d3.select(all[i]).remove();
                }
            });

        // this 'if' is just to avoid running unnecessary code,
        // if it would be '!immediate' render aka as-transition then this block would do nothing anyway
        if (immediate) {
            this._svg
                .select(".x__axis")
                .selectAll(".tick")
                .each((d, i, all) => {
                    if (+d > this._xMax * MAX_TICK_VALUE_RELATIVE_TO_MAXIMUM_VALUE) {
                        d3.select(all[i]).remove();
                    }
                });
        }
    }

    _onLegendItemClick(item: LegendItem): void {
        const selectedGroup = this._getSelectedGroup(item.name);
        if (selectedGroup === this._groupOnTop) return;
        this.legendItemClick.next(selectedGroup);
        this._setGroupOnTop(selectedGroup);
    }

    private _getSelectedGroup(groupName: string): string {
        let selectedGroup = "";
        const groupDefinition = this._groupToLegendDefinitionDictionary;
        for (const key in groupDefinition) {
            if (groupDefinition[key].name === groupName) {
                selectedGroup = key;
            }
        }

        return selectedGroup ? selectedGroup : this._groups[0];
    }

    private _setGroupOnTop(name: string): void {
        this._groupOnTop = name;
        const groups = this._bars.selectAll(".group");
        this._changeAllGroups(groups);
        this._updateLegend();
    }

    private _changeAllGroups(groups: d3.Selection<d3.BaseType, {}, any, any>): void {
        const self = this;
        const chartNode = this._bars.node();

        groups.each(function () {
            const group = d3.select(this);
            const groupNode = group.node() as SVGElement;
            chartNode.appendChild(groupNode);

            const barNode = groupNode.children[0];
            groupNode.appendChild(barNode);

            const bars: d3.Selection<d3.BaseType, IGroupedBarHorizontalItem, d3.BaseType, {}> =
                group.selectAll(".bar");
            self._changeAllBars(bars);
        });
    }

    private _changeAllBars(
        bars: d3.Selection<d3.BaseType, IGroupedBarHorizontalItem, d3.BaseType, {}>
    ): void {
        const self = this;
        const overlapStep = Math.max(1, (this._yGroupScale.bandwidth() * this.overlapFraction) / 2);

        bars.each(function (d: IGroupedBarHorizontalItem, i) {
            d.opacity = self.columnOpacity ? self.columnOpacity(d.item, i, d.group) : 1;
            d3.select(this).style("opacity", d.opacity);
        })
            .attr("y", (d: IGroupedBarHorizontalItem) =>
                self._calculateYPosition(d, self._isSelectedGroup(d.group), overlapStep)
            )
            .attr("height", (d: IGroupedBarHorizontalItem) =>
                self._calculateBarHeight(self._isSelectedGroup(d.group), overlapStep)
            );
    }

    private _getGroupOpacity(group: string): number {
        return !this.columnOpacity || this.overlapFraction === 0 || this._isSelectedGroup(group)
            ? 1
            : 0.25;
    }

    private _isSelectedGroup(group: string): boolean {
        return group === this._groupOnTop;
    }

    private _getSpaceForxAxisLabels(): number {
        let maxWidth = 0;

        const xAxisLabels = ldFlatten(this._data).map(e => e.xAxisLabel);
        const labels = ldFlatten(xAxisLabels).filter(v => v != null && v !== "");

        // if text nodes were rendered inside the chart svg,
        // then sometimes `getComputedTextLength` returned 0
        // so appending to `body` instead
        // using css class so that measurement is based on styled text
        const fakeSvg = d3
            .select("body")
            .append("svg")
            .attr("class", "lg-grouped-bar-horizontal-chart");

        fakeSvg
            .append("g")
            .classed(
                `lg-grouped-bar-horizontal-chart__value-labels ${this._xAxisLabelsClass}`,
                true
            )
            .selectAll("text")
            .data(labels)
            .enter()
            .append("text")
            .text(d => d)
            .each(function () {
                maxWidth = Math.max(
                    maxWidth,
                    (this as SVGTextContentElement).getComputedTextLength()
                );
            });

        fakeSvg.remove();

        return maxWidth;
    }

    private _onMouseOverColumnIcon(
        target: d3.Selection<any, IGroupedBarHorizontalItem, any, any>
    ): void {
        const data = target.data();
        this.tooltipContext = {
            iconTooltip: true,
            currentColumn: data[0],
            columnsWithinGroup: [data[0]],
            groupToLegendDefinitionDictionary: this._groupToLegendDefinitionDictionary
        };

        this._tooltip.show({ target: target.node() });
    }
}
