import ldIsEqual from "lodash-es/isEqual";
import ldIsEmpty from "lodash-es/isEmpty";
import ldTransform from "lodash-es/transform";
import ldMap from "lodash-es/map";
import ldConcat from "lodash-es/concat";
import ldDifference from "lodash-es/difference";
import ldOrderBy from "lodash-es/orderBy";
import ldKeys from "lodash-es/keys";

import { Observable, Subject } from "rxjs";
import { catchError, finalize, first, map, takeUntil } from "rxjs/operators";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { ComponentPortal } from "@angular/cdk/portal";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";

import { atNextFrame, toBoolean, toNumber } from "@logex/framework/utilities";
import { LgConsole } from "@logex/framework/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IColumnFilterDictionary, IFilterOption, LgSimpleChanges } from "@logex/framework/types";

import { IdType } from "./lg-tags-selector.types";
import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay/index";
import { LgMultiFilterPopupComponent } from "../lg-multi-filter/index";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import { LgTooltipDirective } from "../../lg-tooltip";

export interface LgTagSelectorTagParameters {
    tagName: string;
    current: IdType[];
    currentChange: EventEmitter<IdType[]>;
    getOptionDisplayNameCallback: (id: IdType) => string;
    getOptionDisabledStateCallback: (id: IdType) => boolean;
    getOptionOrderByCallback: (id: IdType) => any;
    getOptionsCallback: () => Observable<IdType[]>;
    disabled: boolean;
}

export interface TagGroup {
    groupName: string;
    groupOrder: number;
}

@Component({
    standalone: true,
    selector: "lg-tags-selector-tag",
    template: ` <span [lgTooltip]="_tooltipText">{{ _displayText }}</span> `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [LgTooltipDirective],
    host: {
        "[hidden]": "hideWhenEmpty && (isEmpty || isOverflow)",
        "[class.lg-tags-selector-tag--disabled]": "disabled || parentDisabled"
    }
})
export class LgTagsSelectorTagComponent implements OnInit, OnChanges {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _lgConsole = inject(LgConsole);
    private _lgTranslate = inject(LgTranslateService);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);
    // ----------------------------------------------------------------------------------
    // Parameters

    @Input({ required: true }) public tagName!: string;

    @Input() public tagGroup: TagGroup = { groupName: "0", groupOrder: 0 };

    @Input() public tagOrder?: number;

    @Input() public current?: IdType[];

    @Output() public readonly currentChange = new EventEmitter<IdType[]>();

    /**
     * Callback function to get option display name.
     */
    @Input({ alias: "getOptionDisplayName", required: true })
    public getOptionDisplayNameCallback!: (id: IdType) => string;

    /**
     * Callback function to define if option is disabled or not.
     */
    @Input("getOptionDisabledState")
    public getOptionDisabledStateCallback?: (id: IdType) => boolean;

    /**
     * Callback function to ordered options.
     */
    @Input("getOptionOrderBy") public getOptionOrderByCallback?: (id: IdType) => any;

    @Input() public hideWhenEmpty = true;

    /**
     * Callback function to get options source.
     */
    @Input({ alias: "getOptions", required: true })
    public getOptionsCallback!: () => Observable<IdType[]>;

    /**
     * Type of options id
     */
    @Input() public idType?: "string" | "number" | "boolean";

    @Input()
    public set disabled(value: boolean) {
        this._isDisabled = toBoolean(value);
    }

    public get disabled(): boolean {
        return this._isDisabled;
    }

    @Output("showPopup") public readonly showPopupEvent = new EventEmitter<void>();

    @Output("displayChanged") public readonly displayChangedEvent = new EventEmitter<void>();

    // ----------------------------------------------------------------------------------
    // Fields
    private _current: IdType[];
    private _isDisabled = false;
    public _isActive = false;
    public isEmpty: boolean;
    public isOverflow: boolean;
    public parentDisabled: boolean;

    public _displayText: string;
    public _tooltipText: string;

    @HostBinding("style.width")
    _width: string = undefined;

    private _fullWidth: number;

    private _popupHidden$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi;
    private _popupInstance: LgMultiFilterPopupComponent;
    private _convertId: (id: string) => any;

    // ----------------------------------------------------------------------------------
    //
    ngOnInit(): void {
        if (this.getOptionsCallback == null) {
            throw Error("getOptions callback must be specified");
        }
        if (this.getOptionDisplayNameCallback == null) {
            throw Error("getOptionDisplayName callback must be specified");
        }

        this._configureIdConverter();
    }

    private _configureIdConverter(): void {
        switch (this.idType) {
            case "number":
                this._convertId = (id: string) => toNumber(id);
                break;

            case "boolean":
                this._convertId = (id: string) => toBoolean(id);
                break;

            case "string":
            default:
                this._convertId = (id: string) => id;
                break;
        }
    }

    ngOnChanges(changes: LgSimpleChanges<LgTagsSelectorTagComponent>): void {
        let doSync = false;

        if (changes.tagName) {
            doSync = true;
        }

        if (changes.current) {
            const current = this.current != null ? this.current : [];
            if (!ldIsEqual(current, this._current)) {
                this._current = current;
                doSync = true;
            }
        }

        if (changes.idType) {
            this._configureIdConverter();
        }

        if (doSync) {
            this._syncCurrentValue();
        }
    }

    private _syncCurrentValue(): void {
        const currentValue = this._current;

        this.isEmpty = ldIsEmpty(this._current);

        let displayText: string;

        if (this.hideWhenEmpty && this.isEmpty) {
            displayText = undefined;
            this._tooltipText = undefined;
        } else if (currentValue.length === 1) {
            displayText = this.getOptionDisplayNameCallback(currentValue[0]);
            if (displayText === currentValue[0].toString()) {
                // Handle the case when option name cannot be resolved at the moment - not loaded yet
                displayText = this.tagName;
                this._tooltipText = this.tagName;
            } else {
                this._tooltipText = `${this.tagName}: ${displayText}`;
            }
        } else {
            displayText = `${this.tagName} (${currentValue.length})`;

            const optionNames: string[] = [];
            for (const id of currentValue) {
                const name = this.getOptionDisplayNameCallback(id);

                // Handle the case when option names are not loaded from the server
                if (name !== id.toString()) {
                    optionNames.push(name);
                }

                // Take only 5 resolved names
                if (optionNames.length >= 5) {
                    if (currentValue.length > optionNames.length) {
                        optionNames.push("...");
                    }
                    break;
                }
            }

            if (optionNames.length > 0) {
                this._tooltipText = optionNames.join("<br>");
            } else {
                this._tooltipText = this.tagName;
            }
        }

        // If display text has not changed, then it is not necessary to measure the width and notify listeners
        if (displayText === this._displayText) return;

        // Change the display text and then measure the width of the control
        this._displayText = displayText;

        // Reset possible width adjustments
        this.setWidth(undefined);

        this._changeDetectorRef.markForCheck();

        // Measure the full width of the control
        if (!this.isEmpty) {
            atNextFrame(() => {
                const rect = (
                    this._elementRef.nativeElement as HTMLDivElement
                ).getBoundingClientRect();
                this._fullWidth = rect.width;

                this.displayChangedEvent.emit();
            });
        } else {
            // Shortcut for empty (and so hidden) case
            this._fullWidth = 0;
            this.displayChangedEvent.emit();
        }
    }

    public setWidth(width: number | undefined): void {
        if (width !== undefined) {
            this._width = width + "px";
        } else {
            this._width = undefined;
        }
    }

    public get fullWidth(): number {
        return this._fullWidth;
    }

    public get isWidthSet(): boolean {
        return this._width !== undefined;
    }

    // ----------------------------------------------------------------------------------
    // Popup

    @HostListener("click", ["$event"])
    public _onClick($event: MouseEvent): boolean {
        $event.stopPropagation();
        this._doShow();
        return false;
    }

    public show(anchorElementRef: ElementRef<any>): Promise<void> {
        if (!this.isEmpty) {
            return this._doShow();
        } else {
            return this._doShow(anchorElementRef);
        }
    }

    private _doShow(anchorElementRef: ElementRef<any> = this._elementRef): Promise<void> {
        const promise = new Promise<void>(resolve => {
            this._popupHidden$ = new Subject<void>();
            this._popupHidden$
                .pipe(
                    finalize(() => {
                        resolve();
                    })
                )
                .subscribe();
        });

        // HACK: Exploits the fact that CDK overlay strategy uses only getBoundingClientRect to get position of anchor element
        const element = anchorElementRef.nativeElement as HTMLElement;
        const elementRect = element.getBoundingClientRect();

        const cachedElementRef = new ElementRef({
            getBoundingClientRect: () => {
                const bcr = this._elementRef.nativeElement.getBoundingClientRect();
                if (!(bcr.left === 0 && bcr.top === 0 && bcr.width === 0 && bcr.height === 0)) {
                    return bcr;
                } else {
                    return elementRect;
                }
            }
        });

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(anchorElementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .setOrigin(cachedElementRef)
            .withPositions([
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(anchorElementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => {
                if (this._popupInstance) this._popupInstance._attemptClose();
            },
            hasBackdrop: true,
            trapFocus: true,
            sourceElement: anchorElementRef,
            positionStrategy: strategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgMultiFilterPopupComponent>(
            LgMultiFilterPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance._updatePosition(change);
        });

        const currentValue: IColumnFilterDictionary = ldIsEmpty(this._current)
            ? { $empty: true }
            : ldTransform(
                  this._current,
                  (result, x) => {
                      result[x.toString()] = this.getOptionDisplayNameCallback(x);
                  },
                  {} as IColumnFilterDictionary
              );

        this._popupInstance
            ._initialize({
                target: anchorElementRef,
                filter: currentValue,
                placeholder: this.tagName,
                source: this._getOptionsObservable(),
                show: this.showPopupEvent,
                condensed: true,
                wide: true,
                reposition: () => strategy.apply(),
                look: "grid",
                readonly: this._isDisabled || this.parentDisabled,
                width: 300
            })
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                this._doClose();
                this._onSelectionChanged(result, currentValue);
            });

        this._isActive = true;

        return promise;
    }

    private _getOptionsObservable(): Observable<IFilterOption[]> {
        const getOrderBy =
            this.getOptionOrderByCallback != null ? this.getOptionOrderByCallback : (x: any) => x;

        return this.getOptionsCallback().pipe(
            takeUntil(this._popupHidden$),
            map((data: IdType[]) => {
                let options = ldMap(
                    ldConcat(data, ldDifference(this._current, data)),
                    id =>
                        ({
                            id,
                            name: this.getOptionDisplayNameCallback(id),
                            sortBy: getOrderBy(id),
                            disabled: this.getOptionDisabledStateCallback
                                ? this.getOptionDisabledStateCallback(id)
                                : false
                        }) as IFilterOption
                );
                options = ldOrderBy(options, "sortBy");
                return options;
            }),
            catchError(err => {
                this._lgConsole.error(err);
                throw err;
            })
        );
    }

    private _doClose(immediately?: boolean): void {
        if (!this._isActive) return;

        this._isActive = false;
        this._popupHidden$.next();
        this._popupHidden$.complete();

        if (immediately) {
            this._overlayInstance.hide();
        } else {
            const overlayInstance = this._overlayInstance;
            this._popupInstance
                .hide()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance.hide();
                });
        }

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;
    }

    private _onSelectionChanged(
        newValue: IColumnFilterDictionary,
        currentValue: IColumnFilterDictionary
    ): void {
        if (newValue === undefined) return;

        if (ldIsEqual(currentValue, newValue)) return;

        let nextValue: IdType[];
        if (!newValue.$empty) {
            this._current = ldKeys(newValue).map(x => this._convertId(x));
            nextValue = this._current;
        } else {
            this._current = [];
            nextValue = null;
        }
        this._syncCurrentValue();

        // Notify subscribers
        atNextFrame(() => {
            this.currentChange.next(nextValue);
        });
    }
}
