import ldIsBoolean from "lodash-es/isBoolean";
import { inject, Injectable, OnDestroy } from "@angular/core";
import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
    HttpResponse
} from "@angular/common/http";
import { from, Observable, Subject, throwError } from "rxjs";
import {
    catchError,
    filter,
    finalize,
    map,
    mergeAll,
    switchMap,
    takeUntil,
    tap
} from "rxjs/operators";

import { LgConsole } from "@logex/framework/core";
import {
    retryOnNetworkError,
    RetryOnNetworkErrorArgs,
    urlConcat
} from "@logex/framework/utilities";

import { BrowserCache, BrowserCacheProvider } from "./BrowserCacheProvider";
import {
    IHttpRequestObserveBodyOptions,
    IHttpRequestObserveResponseOptions,
    IHttpRequestOptions,
    MaybeStaleData,
    RefreshStaleDataOptions
} from "./ServerGatewayBase.types";
import { asMaybeStaleData } from "./asMaybeStaleData";

/**
 * This class serves as base for various server gateways (services for backend interaction). Unlike using HttpClient directly, you'll get
 * - automatic prefixing with baseUrl ( @see {@link ServerGatewayBase.setBaseUrl} )
 * - automatic retries for read requests ( @see {@link ServerGatewayBase._retryArgs} )
 * - optional response caching for read requests (including those done by POST)
 * - wrappers for easy file uploads (including option to report progress)
 * - implementation of read requests for LgBackend-style stale data ( @see {@link ServerGatewayBase._getMaybeStale} @see {@link ServerGatewayBase._postMaybeStaleQuery})
 * - option to automatically wrap read or write requests, f.ex for error handling or logging ( @see {@link ServerGatewayBase._wrapReadRequest} and {@link ServerGatewayBase._wrapWriteRequest})
 *
 * To fully utilize the above capabilities, we suggest you utilize the _get, _post, _postQuery, _put, _patch or _delete methods (or
 * te file uploaders).  We also expose _cacheableRequest and _directRequest methods, but those support only subset of the functions.
 */
@Injectable()
export class ServerGatewayBase implements OnDestroy {
    protected _cacheProvider = inject(BrowserCacheProvider);
    protected _console = inject(LgConsole).withSource("Logex.Application.ServerGatewayBase");
    protected _httpClient = inject(HttpClient);

    protected readonly _destroyed$ = new Subject<void>();
    protected _fakeCache = false;
    protected _baseUrl: string | undefined;
    protected _retryArgs: RetryOnNetworkErrorArgs | undefined;

    constructor() {
        this._fakeCache = !!(window as any).fakeCache;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    // #region Legacy deprecated code
    /** @deprecated please use _setBaseUrl() */
    protected setBaseUrl(baseUrl: string): void {
        this._baseUrl = baseUrl;
    }

    /** @deprecated please use _get() with explicit data extraction */
    getSimple<TResult>(url: string, params?: object, unwrapProp?: string): Observable<TResult> {
        return this._get(url, { params: params as any } as IHttpRequestObserveBodyOptions).pipe(
            map(result => (unwrapProp ? (result as any)[unwrapProp] : result))
        );
    }

    /** @deprecated please use _get() instead  */
    get<TResult>(
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** @deprecated please use _get() instead  */
    get<TResult>(url: string, options?: IHttpRequestObserveBodyOptions): Observable<TResult>;

    /** @deprecated please use _get() instead  */
    get<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    /** @deprecated please use _get() instead  */
    get<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._get(url, options);
    }

    /** @deprecated please use _postQuery() or _post() instead  */
    post<TResult>(
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** @deprecated please use _postQuery() or _post() instead  */
    post<TResult>(url: string, options?: IHttpRequestObserveBodyOptions): Observable<TResult>;

    /** @deprecated please use _postQuery() or _post() instead  */
    post<TResult>(
        url: string,
        options: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    /** @deprecated please use _postQuery() or _post() instead  */
    post<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>> {
        // the old behaviour is a mix of both _post and _postQuery methods, so we cannot call them directly.
        // We want to cache the request, but wrap it as write (i.e. without retries)
        return this._wrapWriteRequest(
            this._cacheableRequest<TResult>("POST", url, options),
            "POST",
            url
        );
    }

    /** @deprecated please use _delete() instead  */
    delete<TResult>(
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** @deprecated please use _delete() instead  */
    delete<TResult>(url: string, options?: IHttpRequestObserveBodyOptions): Observable<TResult>;

    /** @deprecated please use _delete() instead  */
    delete<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    /** @deprecated please use _delete() instead  */
    delete<TResult>(
        url: string,
        params?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._delete(url, params);
    }

    /** @deprecated please use _uploadFile() instead  */
    uploadFile<TResult>(
        url: string,
        file: File,
        parameters: any,
        scenarioId?: number
    ): Observable<TResult> {
        return this._uploadFile(url, file, parameters, { scenarioId });
    }

    /** @deprecated please use _uploadFiles() instead  */
    uploadFiles<TResult>(
        url: string,
        files: File[],
        parameters: any,
        scenarioId?: number
    ): Observable<TResult> {
        return this._uploadFiles(url, files, parameters, { scenarioId });
    }

    /** @deprecated please use _uploadFilesWithProgress() instead  */
    uploadFilesWithProgress<TResult>(
        url: string,
        files: File[],
        parameters: any,
        scenarioId?: number
    ): { progress: Observable<number>; result: Observable<TResult> } {
        return this._uploadFilesWithProgress(url, files, parameters, { scenarioId });
    }

    /** @deprecated Please use _getMaybeStale() instead */
    getMaybeStale<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData?: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>> {
        return this._getMaybeStale(url, options, refreshStaleData);
    }

    /** @deprecated Please use _postMaybeStaleQuery() instead */
    postMaybeStale<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData?: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>> {
        return this._postMaybeStaleQuery(url, options, refreshStaleData);
    }
    // #endregion

    /** Set the base URL for this gateway. All request URLs will be automatically prefixed with it*/
    protected _setBaseUrl(baseUrl: string): void {
        this._baseUrl = baseUrl;
    }

    /** Wrap a read request (GET or postQuery). The default implementation adds call to retryOnNetworkError */
    protected _wrapReadRequest<T>(
        request: Observable<T>,
        _method: string,
        _url: string
    ): Observable<T> {
        return request.pipe(retryOnNetworkError(this._retryArgs));
    }

    /** Wrap a write request (GET or postQuery). The default implementation returns the request untouched */
    protected _wrapWriteRequest<T>(
        request: Observable<T>,
        _method: string,
        _url: string
    ): Observable<T> {
        return request;
    }

    /** Perform a GET request, and observe the HttpResponse. The request will be cacheable (if cache is available,
     * and browserCache option isn't set to false). It will be also wrapped with _wrapReadRequest() */
    protected _get<TResult>(
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** Perform a GET request, and observe the response body. The request will be cacheable (if cache is available,
     * and browserCache option isn't set to false). It will be also wrapped with _wrapReadRequest() */
    protected _get<TResult>(
        url: string,
        options?: IHttpRequestObserveBodyOptions
    ): Observable<TResult>;

    /** Perform a GET request. The request will be cacheable (if cache is available,
     * and browserCache option isn't set to false). It will be also wrapped with _wrapReadRequest() */
    protected _get<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _get<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>> {
        if (options?.params != null) {
            options.params = Object.fromEntries(
                Object.entries(options.params).filter(([, val]) => val !== undefined)
            );
        }
        return this._wrapReadRequest(
            this._cacheableRequest<TResult>("GET", url, options),
            "GET",
            url
        );
    }

    /** Perform a query POST request, and observe the HttpResponse. The request should be used only for read-only
     * requests (as alternative to GET due to too big parameters).
     *
     * The request will be cacheable (if cache is available, and browserCache option isn't set to false). It will be
     * also wrapped with _wrapReadRequest()
     *
     * @see {@link ServerGatewayBase._post} */
    protected _postQuery<TResult>(
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** Perform a query POST request, and observe the response body. The request should be used only for read-only
     * requests (as alternative to GET due to too big parameters).
     *
     * The request will be cacheable (if cache is available, and browserCache option isn't set to false). It will be
     * also wrapped with _wrapReadRequest()
     *
     * @see {@link ServerGatewayBase._post} */
    protected _postQuery<TResult>(
        url: string,
        options?: IHttpRequestObserveBodyOptions
    ): Observable<TResult>;

    /** Perform a query POST request. The request should be used only for read-only
     * requests (as alternative to GET due to too big parameters).
     *
     * The request will be cacheable (if cache is available, and browserCache option isn't set to false). It will be
     * also wrapped with _wrapReadRequest()
     *
     * @see {@link ServerGatewayBase._post} */
    protected _postQuery<TResult>(
        url: string,
        options: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _postQuery<TResult>(
        url: string,
        options?: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._wrapReadRequest(
            this._cacheableRequest<TResult>("POST", url, options),
            "POST",
            url
        );
    }

    /** Perform a mutation POST request, and observe the HttpResponse.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest()
     *
     * @see {@link ServerGatewayBase._postQuery} */
    protected _post<TResult>(
        url: string,
        options: Omit<IHttpRequestObserveResponseOptions, "browserCache">
    ): Observable<HttpResponse<TResult>>;

    /** Perform a mutation POST request, and observe the response body.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest()
     *
     * @see {@link ServerGatewayBase._postQuery} */
    protected _post<TResult>(
        url: string,
        options?: Omit<IHttpRequestObserveBodyOptions, "browserCache">
    ): Observable<TResult>;

    /** Perform a mutation POST request.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest()
     *
     * @see {@link ServerGatewayBase._postQuery} */
    protected _post<TResult>(
        url: string,
        options: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _post<TResult>(
        url: string,
        options?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._wrapWriteRequest(this._directRequest("POST", url, options), "POST", url);
    }

    /** Perform a mutation PUT request, and observe the HttpResponse.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _put<TResult>(
        url: string,
        options: Omit<IHttpRequestObserveResponseOptions, "browserCache">
    ): Observable<HttpResponse<TResult>>;

    /** Perform a mutation PUT request, and observe the response body.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _put<TResult>(
        url: string,
        options?: Omit<IHttpRequestObserveBodyOptions, "browserCache">
    ): Observable<TResult>;

    /** Perform a mutation PUT request.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _put<TResult>(
        url: string,
        options?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _put<TResult>(
        url: string,
        params?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._wrapWriteRequest(this._directRequest("PUT", url, params), "PUT", url);
    }

    /** Perform a mutation PATCH request, and observe the HttpResponse.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _patch<TResult>(
        url: string,
        options: Omit<IHttpRequestObserveResponseOptions, "browserCache">
    ): Observable<HttpResponse<TResult>>;

    /** Perform a mutation PATCH request, and observe the body.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _patch<TResult>(
        url: string,
        options?: Omit<IHttpRequestObserveBodyOptions, "browserCache">
    ): Observable<TResult>;

    /** Perform a mutation PATCH request.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _patch<TResult>(
        url: string,
        options?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _patch<TResult>(
        url: string,
        params?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._wrapWriteRequest(this._directRequest("PATCH", url, params), "PATCH", url);
    }

    /** Perform a mutation DELETE request, and observe the HttpResponse.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _delete<TResult>(
        url: string,
        options: Omit<IHttpRequestObserveResponseOptions, "browserCache">
    ): Observable<HttpResponse<TResult>>;

    /** Perform a mutation DELETE request, and observe the response body.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _delete<TResult>(
        url: string,
        options?: Omit<IHttpRequestObserveBodyOptions, "browserCache">
    ): Observable<TResult>;

    /** Perform a mutation DELETE request.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _delete<TResult>(
        url: string,
        options?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _delete<TResult>(
        url: string,
        params?: Omit<IHttpRequestOptions, "browserCache">
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._wrapWriteRequest(this._directRequest("DELETE", url, params), "DELETE", url);
    }

    /** Upload a single file. The file will be included as "uploadFile" multipart form parameter. In addition, the parameters
     * argument will be send as `parameters`, and optionally additional fields are included.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _uploadFile<TResult>(
        url: string,
        file: File,
        parameters: any,
        formFields?: object
    ): Observable<TResult> {
        return this._uploadFiles(url, [file], parameters, formFields);
    }

    /** Upload multiple files. The files will be included as "uploadFile" multipart form parameter. In addition, the parameters
     * argument will be send as `parameters`, and optionally additional fields are included.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _uploadFiles<TResult>(
        url: string,
        files: File[],
        parameters: any,
        formFields?: object
    ): Observable<TResult> {
        return this._wrapWriteRequest(
            this._prepareFileUpload<TResult>(url, files, parameters, formFields, false),
            "POST",
            url
        );
    }

    /** Upload multiple files. The files will be included as "uploadFile" multipart form parameter. In addition, the parameters
     * argument will be send as `parameters`, and optionally additional fields are included.
     * In addition to the result observable, additional `progress` observable is returned. The observable will
     * emit values representing % (i.e. number from 0 to 100) and will complete.
     * The request isn't cacheable. It will be also wrapped with _wrapWriteRequest() */
    protected _uploadFilesWithProgress<TResult>(
        url: string,
        files: File[],
        parameters: any,
        formFields?: object
    ): { progress: Observable<number>; result: Observable<TResult> } {
        const progress = new Subject<number>();
        const done = new Subject<void>();

        const result = this._wrapWriteRequest(
            this._prepareFileUpload<TResult>(url, files, parameters, formFields, true).pipe(
                filter(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        progress.next(event.loaded / event.total);
                        return false;
                    } else if (event.type === HttpEventType.Response) {
                        return true;
                    }
                    return false;
                }),
                map((event: HttpEvent<TResult>) => {
                    if (event.type === HttpEventType.Response) {
                        return event as HttpResponse<TResult>;
                    }
                    throw new Error("Unexpected event type");
                }),
                map((postResult: HttpResponse<TResult>) => postResult.body),
                finalize(() => {
                    done.next();
                    done.complete();
                })
            ),
            "POST",
            url
        );

        return {
            progress: progress.pipe(takeUntil(done)),
            result
        };
    }

    /** Request data with staledness support. This is LgBackend-specific integration method */
    protected _getMaybeStale<TResult>(
        url: string,
        options: IHttpRequestOptions
    ): Observable<MaybeStaleData<TResult>>;

    /** @deprecated the refreshStaleData parameter is deprecated and will be removed */
    protected _getMaybeStale<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>>;

    protected _getMaybeStale<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData?: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>> {
        this._addRefreshStaleDataHeader(
            options,
            refreshStaleData !== undefined ? refreshStaleData : false
        );

        return this._get<TResult>(url, {
            ...options,
            observe: "response"
        }).pipe(map(response => asMaybeStaleData(response)));
    }

    /** Request data with staledness support, using the POST method. This is LgBackend-specific integration method */
    protected _postMaybeStaleQuery<TResult>(
        url: string,
        options: IHttpRequestOptions
    ): Observable<MaybeStaleData<TResult>>;

    /** @deprecated the refreshStaleData parameter is deprecated and will be removed */
    protected _postMaybeStaleQuery<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>>;

    protected _postMaybeStaleQuery<TResult>(
        url: string,
        options: IHttpRequestOptions,
        refreshStaleData?: RefreshStaleDataOptions | null
    ): Observable<MaybeStaleData<TResult>> {
        this._addRefreshStaleDataHeader(
            options,
            refreshStaleData !== undefined ? refreshStaleData : false
        );

        return this._postQuery<TResult>(url, {
            ...options,
            observe: "response"
        }).pipe(map(response => asMaybeStaleData(response)));
    }

    /** Do generic (controlled by the parameter) request and observe the response. The request will be cacheable (when cache is
     * supported, and browserCache option isn't set to false). baseUrl will be added, but no additional processing
     * is done (specifically, _wrap*Request is not called). Prefer using _get() or _postQuery() instead. */
    protected _cacheableRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** Do generic (controlled by the parameter) request and observe the body. The request will be cacheable (when cache is
     * supported, and browserCache option isn't set to false). baseUrl will be added, but no additional processing
     * is done (specifically, _wrap*Request is not called). Prefer using _get() or _postQuery() instead. */
    protected _cacheableRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestObserveBodyOptions
    ): Observable<TResult>;

    /** Do generic (controlled by the parameter) request. The request will be cacheable (when cache is
     * supported, and browserCache option isn't set to false). baseUrl will be added, but no additional processing
     * is done (specifically, _wrap*Request is not called). Prefer using _get() or _postQuery() instead. */
    protected _cacheableRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _cacheableRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions | undefined
    ): Observable<TResult | HttpResponse<TResult>> {
        return this._cacheProvider.isAvailable && (!options || options.browserCache !== false)
            ? this._requestWithCache(method, url, options)
            : this._directRequest(method, url, options);
    }

    /** Do generic (controlled by the parameter) request and observe the response. The request isn't cacheable. baseUrl
     *  will be added, but no additional processing is done (specifically, _wrap*Request is not called).
     * Prefer using _post(), _patch(), _put() or _delete() instead */
    protected _directRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestObserveResponseOptions
    ): Observable<HttpResponse<TResult>>;

    /** Do generic (controlled by the parameter) request and observe the response body. The request isn't cacheable. baseUrl
     *  will be added, but no additional processing is done (specifically, _wrap*Request is not called).
     * Prefer using _post(), _patch(), _put() or _delete() instead */
    protected _directRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestObserveBodyOptions
    ): Observable<TResult>;

    protected _directRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions
    ): Observable<TResult | HttpResponse<TResult>>;

    protected _directRequest<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions | undefined
    ): Observable<TResult | HttpResponse<TResult>> {
        if (this._baseUrl != null) {
            url = urlConcat(this._baseUrl, url);
        }
        return this._httpClient.request<TResult>(method, url, options as any) as Observable<
            TResult | HttpResponse<TResult>
        >;
    }

    // Request through cache, if we can open it
    private _requestWithCache<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions | undefined
    ): Observable<TResult | HttpResponse<TResult>> {
        if (this._baseUrl != null) {
            url = urlConcat(this._baseUrl, url);
        }
        return from(
            this._cacheProvider.getCache().then(
                cache => this._requestAndCache<TResult>(method, url, options, cache),
                () => this._directRequest<TResult>(method, url, options)
            )
        ).pipe(mergeAll());
    }

    // We have working cache, request the data from it or the server
    private _requestAndCache<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions | undefined,
        cache: BrowserCache
    ): Observable<TResult | HttpResponse<TResult>> {
        const cacheUrl = this._cacheProvider.getCacheKeyInUrlLikeFormat(
            url,
            options && options.params,
            options && options.body
        );

        return from(cache.tryGetResponse(cacheUrl)).pipe(
            switchMap(cachedResponse =>
                this._requestDirectlyAsHttpResponse<TResult>(
                    method,
                    url,
                    this._tryAddEtagToHeaders(options, cachedResponse)
                ).pipe(
                    tap(response => {
                        this._console.debug(`"${url}" -> response 200`);
                        // note: we take this completely out of the processing queue. Is that ok?
                        if (
                            response.body &&
                            (response.headers.has("etag") ||
                                (this._fakeCache && this._cacheProvider.localhost))
                        ) {
                            this._writeToCache(cacheUrl, response, cache);
                        } else {
                            cache.deleteFromCache(cacheUrl);
                        }
                    }),
                    catchError(error => {
                        this._console.debug(
                            `"${url}" -> ${
                                error.status === 304 ? "response 304" : `error ${error}`
                            }`
                        );

                        if (error.status === 304) {
                            return this._respondFromCache<TResult>(
                                url,
                                cachedResponse,
                                error.headers
                            );
                        }
                        throw error;
                    }),
                    map(response =>
                        options != null && options.observe === "response" ? response : response.body
                    )
                )
            )
        );
    }

    // Request directly from server, and override observe type to "response"
    private _requestDirectlyAsHttpResponse<TResult>(
        method: string,
        url: string,
        options: IHttpRequestOptions | undefined
    ): Observable<HttpResponse<TResult>> {
        if (
            this._fakeCache &&
            options &&
            options.headers &&
            "if-none-match" in options.headers &&
            options.headers["if-none-match"] === "justfakeetag" &&
            this._cacheProvider.localhost
        ) {
            return throwError(() => ({ status: 304 }));
        }

        const argsWithObserveResponse = { ...options, observe: "response" };
        return this._httpClient.request<TResult>(
            method,
            url,
            argsWithObserveResponse as any
        ) as Observable<HttpResponse<TResult>>;
    }

    // Convert the cached data into response observable
    private _respondFromCache<TResult>(
        url: string,
        cached: Response,
        headers: HttpHeaders
    ): Observable<HttpResponse<TResult>> {
        this._console.debug(`"${url}": getting data from cache`, cached);

        return from(this._getResponseBody<TResult>(cached)).pipe(
            map(body => {
                const response = this._buildHttpResponse<TResult>(url, body, headers);
                this._console.debug(`"${url}": got data from cache`, response);
                return response;
            })
        );
    }

    // Build new response out of cached headers and the deserialzied body
    private _buildHttpResponse<TResult>(
        url: string,
        requestData: any,
        headers: HttpHeaders
    ): HttpResponse<TResult> {
        return new HttpResponse<TResult>({
            body: requestData,
            headers,
            url,
            status: 200,
            statusText: "OK"
        });
    }

    // Obtain the deserialized body from cached response
    private _getResponseBody<TResult>(cached: Response): Promise<TResult> {
        const ct = cached.headers.get("content-type");

        if (ct.indexOf("application/json") === 0) {
            return cached.json(); // before: cached.text().then( body => JSON.parse( body ) );
        } else if (ct.indexOf("application/octet-stream") === 0) {
            return cached.arrayBuffer() as any;
        } else {
            return Promise.reject({
                data: { Message: `Cached content has unsupported content type "${ct}"` }
            });
        }
    }

    // Store the cache-able response to cache
    private _writeToCache<TResult>(
        cacheUrl: string,
        { body, headers }: HttpResponse<TResult>,
        cache: BrowserCache
    ): void {
        let length = headers.get("content-length");
        if (length == null && body instanceof ArrayBuffer) length = "" + body.byteLength;

        const responseToCache = new Response(
            body instanceof ArrayBuffer ? body : JSON.stringify(body),
            {
                headers: {
                    date: headers.get("date"),
                    "content-type": headers.get("content-type"),
                    "content-length": length,
                    expires: headers.get("expires"),
                    etag: this._fakeCache ? "justfakeetag" : headers.get("etag")
                }
            }
        );

        cache.updateCache(cacheUrl, responseToCache).catch(error => {
            cache.deleteFromCache(cacheUrl);
            if (error.name === "QuotaExceededError" || error.code === 22) cache.reduceCache();
        });
    }

    // If cached response exists, add corresponding etag to the requestOption's headers (and create it, if not specified)
    private _tryAddEtagToHeaders(
        options: IHttpRequestOptions | undefined,
        cached: Response
    ): IHttpRequestOptions | undefined {
        let cachedRevision: string = undefined;
        if (cached != null) {
            cachedRevision = cached.headers.get("etag");
            if (cachedRevision != null) {
                return {
                    ...options,
                    headers: {
                        ...(options && options.headers),
                        "if-none-match": cachedRevision
                    }
                };
            }
        }

        return options;
    }

    private _addRefreshStaleDataHeader(
        options: IHttpRequestOptions,
        refreshStaleData: RefreshStaleDataOptions
    ): void {
        if (refreshStaleData == null) return;

        options.headers = options.headers || {};

        // Do not override Cache-Control header if it was already specified in options
        if (options.headers["Cache-Control" as keyof HttpHeaders] != null) {
            throw Error(
                `Request specifies not-null refreshStaleData parameter, but also has headers specified`
            );
        }

        if (ldIsBoolean(refreshStaleData)) {
            if (refreshStaleData) {
                options.headers["Cache-Control" as keyof HttpHeaders] = "max-stale=0"; // recalculate before returning data
            } else {
                options.headers["Cache-Control" as keyof HttpHeaders] = "max-stale=1"; // return stale data and start calculation
            }
        } else {
            options.headers["Cache-Control" as keyof HttpHeaders] = `max-stale=${refreshStaleData}`;
        }
    }

    private _prepareFileUpload<TResult>(
        url: string,
        files: File[],
        parameters: any,
        formFields: object | undefined,
        withProgress: true
    ): Observable<HttpEvent<TResult>>;

    private _prepareFileUpload<TResult>(
        url: string,
        files: File[],
        parameters: any,
        formFields: object | undefined,
        withProgress: false
    ): Observable<TResult>;

    private _prepareFileUpload<TResult>(
        url: string,
        files: File[],
        parameters: any,
        formFields: object | undefined,
        withProgress: boolean
    ): Observable<TResult | HttpEvent<TResult>> {
        const formData: FormData = new FormData();

        if (formFields)
            Object.entries(formFields).forEach(([key, value]) => {
                if (value != null) formData.append(key, value.toString());
            });

        formData.append("parameters", JSON.stringify(parameters));

        for (const file of files) {
            if (!file) continue;
            formData.append("uploadFile", file, file.name);
        }

        /*
            Adding "Content-Type" header confuses angular when working with form data
            For more information, see https://github.com/angular/angular/issues/13241
        */
        const headers = new HttpHeaders().set("Accept", "application/json");

        let options;
        if (withProgress) {
            options = { headers, reportProgress: true, observe: "events" as const };
        } else {
            options = { headers };
        }

        if (this._baseUrl != null) {
            url = urlConcat(this._baseUrl, url);
        }

        return this._httpClient.post<TResult>(url, formData, options as any);
    }
}
