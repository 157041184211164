// todo: remove definition flexibility from the core
//  - tabbable?
//  - focus
//  - look into closing on scroll

import ldIsString from "lodash-es/isString";
import ldMap from "lodash-es/map";
import ldSortBy from "lodash-es/sortBy";
import ldFilter from "lodash-es/filter";
import ldIsArray from "lodash-es/isArray";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { toBoolean } from "@logex/framework/utilities";

import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay/lg-overlay.service";
import {
    IDropdownDefinition,
    IDropdownIconDefinition,
    DropdownMatchWidth
} from "./lg-dropdown.types";
import { LgDropdownPopupComponent } from "./lg-dropdown-popup.component";
import { NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { LgIconComponent } from "../lg-icon/lg-icon.component";
import { LgTooltipDirective, LgTooltipService, TooltipApi } from "../../lg-tooltip";
import { LgMarkFocusOnDirective, lgTableInputNavigatorDirective } from "../../behavior";

type TAlign = "left" | "right" | "center";

@Component({
    standalone: true,
    selector: "lg-dropdown",
    templateUrl: "./lg-dropdown.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        NgStyle,
        NgForOf,
        LgIconComponent,
        LgTooltipDirective,
        LgMarkFocusOnDirective,
        lgTableInputNavigatorDirective,
        NgIf
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgDropdownComponent),
            multi: true
        }
    ]
})
export class LgDropdownComponent<T extends number | string>
    implements OnInit, OnDestroy, ControlValueAccessor
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);
    private _translateService = inject(LgTranslateService);
    private _tooltipService = inject(LgTooltipService);
    // ---------------------------------------------------------------------------------------------
    //  Inputs and outputs
    // ---------------------------------------------------------------------------------------------
    /**
     * Dropdown data definition (required).
     */
    @Input({ required: true })
    set definition(value: IDropdownDefinition<T> | null | undefined) {
        this._definition = value;
        this._normalized = false;
        this._normalizeDefinition();
        this._updateValue();
    }

    get definition(): IDropdownDefinition<T> | null | undefined {
        return this._definition;
    }

    /**
     * Current value.
     *
     * @default undefined
     */
    @Input()
    set current(value: T | null | undefined) {
        this._current = value;
        this._updateValue();
    }

    get current(): T | null | undefined {
        return this._current;
    }

    /**
     * Emits values on current value changes.
     */
    @Output() readonly currentChange = new EventEmitter<T>();

    /**
     * Emits whenever the dropdown is opened or collapsed.
     */
    @Output() readonly activeChange = new EventEmitter<boolean>();

    /**
     * Specifies if dropdown disabled.
     */
    @Input()
    set disabled(value: boolean | "true" | "false") {
        this._isDisabled = toBoolean(value);
        this._updateValue();
    }

    get disabled(): boolean {
        return this._isDisabled;
    }

    /**
     * Specifies if selected item should be highlighted.
     *
     * @default true
     */
    @Input()
    set highlightSelected(value: boolean | "true" | "false") {
        this._highlightSelected = toBoolean(value);
        this._updateValue();
    }

    get highlightSelected(): boolean {
        return this._highlightSelected;
    }

    /**
     * Specifies text to override current value for display.
     * This only affects what is displayed to the user, not the selected value itself.
     */
    @Input() textOverride?: string | null;

    /**
     * Specifies if value is valid.
     */
    @Input()
    set isValid(value: boolean | "true" | "false") {
        this._isValid = toBoolean(value);
    }

    get isValid(): boolean {
        return this._isValid;
    }

    /**
     * Specifies text for tooltip if value is invalid.
     */
    @Input()
    set invalidTooltipMessage(value: string | undefined) {
        this._invalidTooltipMessage = value;
        if (this._invalidTooltipMessage) this._setUpErrorTooltip();
        if (!this._invalidTooltipMessage && this._tooltip) {
            this._tooltip.hide();
        }
    }

    get invalidTooltipMessage(): string | undefined {
        return this._invalidTooltipMessage;
    }

    /**
     * Pre-select handler.
     */
    @Input()
    preSelect: (source: LgDropdownComponent<T>, currentValue: any) => any;

    @Input()
    set emptyAcceptable(value: boolean | "true" | "false") {
        this._emptyAcceptable = toBoolean(value);
    }

    get emptyAcceptable(): boolean {
        return this._emptyAcceptable;
    }

    @Input()
    set hideSearch(value: boolean | "true" | "false") {
        this._hideSearch = toBoolean(value);
    }

    get hideSearch(): boolean {
        return this._hideSearch;
    }

    /**
     * Specifies width matching.
     *
     * @default "control"
     */
    @Input()
    set matchWidth(value: DropdownMatchWidth | boolean | "true" | "false") {
        switch (value) {
            case "content":
                this._matchWidth = "content";
                break;
            case "control":
                this._matchWidth = "control";
                break;
            case "max":
                this._matchWidth = "max";
                break;
            default:
                this._matchWidth = toBoolean(value) ? "control" : "content";
        }
    }

    get matchWidth(): DropdownMatchWidth | boolean {
        return this._matchWidth;
    }

    /**
     * Text to be displayed if no selected value
     */
    @Input() emptyText?: string;

    /**
     * Applies css class to popup
     */
    @Input() popupClassName?: string;

    /**
     * Applies css class to dropdown component
     */
    @Input() className?: string;

    /**
     * Reduces the size of the input field if `true`.
     *
     * @default false
     */
    @Input()
    set condensed(value: boolean | "true" | "false") {
        this._condensed = toBoolean(value);
    }

    get condensed(): boolean {
        return this._condensed;
    }

    @Input() animationEnabled = false;

    @Input()
    set showPopupAbove(value: boolean | "true" | "false") {
        this._showPopupAbove = toBoolean(value);
    }

    get showPopupAbove(): boolean {
        return this._showPopupAbove;
    }

    @Input()
    set showTitle(value: boolean) {
        this._showTitle = value;
    }

    get showTitle(): boolean {
        return this._showTitle;
    }

    /**
     * Hide right-side arrow
     */
    @Input()
    set hideArrow(value: boolean) {
        this._hideArrow = value;
    }

    get hideArrow(): boolean {
        return this._hideArrow;
    }

    @Input() set align(value: TAlign) {
        switch (value) {
            case "left":
                this._alignClass = "";
                break;
            case "right":
                this._alignClass = "lg-dropdown--align-right";
                break;
            case "center":
                this._alignClass = "lg-dropdown--align-center";
                break;
            default:
                this._alignClass = "";
                value = "left";
                break;
        }
        this._align = value;
    }

    get align(): TAlign {
        return this._align;
    }

    @Input("attachOnLeft") _attachOnLeft = true;

    @Input()
    set itemTooltips(value: boolean | "true" | "false") {
        this._itemTooltips = toBoolean(value);
    }

    get itemTooltips(): boolean {
        return this._itemTooltips;
    }

    @Input() focusEnabled = true;

    get _allowFocus(): boolean {
        return this.focusEnabled && !this._isDropdownDisabled;
    }

    /**
     * Selector of the closest element on which to set class 'lg-contains-focus' when input is focused
     * (closest = https://developer.mozilla.org/en-US/docs/Web/API/Element/closest)
     *
     * Defaults to `".table__row"` but it's a no-op if not used inside the table so you don't need to
     * pass in `null` or `""`
     */
    @Input() markFocusOn = ".table__row";

    @Input()
    set searchPrefix(value: boolean | "true" | "false") {
        this._searchPrefix = toBoolean(value);
    }

    get searchPrefix(): boolean {
        return this._searchPrefix;
    }

    protected _showTitle = false;

    _searchPrefix = false;
    _condensed = false;
    _hideArrow = false;
    _alignClass = "";
    private _current: T | null | undefined;
    private _definition: IDropdownDefinition<T> | null | undefined;

    private _isDisabled: boolean;
    private _highlightSelected = true;
    private _isValid = true;
    private _invalidTooltipMessage: string | undefined = undefined;
    private _tooltip: TooltipApi;
    private _emptyAcceptable: boolean;
    private _hideSearch: boolean;
    private _matchWidth: DropdownMatchWidth = "control";
    private _showPopupAbove = false;
    private _itemTooltips = false;
    protected _align: TAlign = "left";

    // ---------------------------------------------------------------------------------------------
    //  State
    // ---------------------------------------------------------------------------------------------
    private readonly _destroyed$ = new Subject<void>();

    private _normalized = false;

    // used when event is used to show the dropdown
    private _mustPick: boolean;
    private _onCancelFn: (source: LgDropdownComponent<T>) => void;

    _assigned = false;
    _currentValueName: string;
    _isDropdownDisabled = true;
    _currentValueIcons: Array<IDropdownIconDefinition<any>> = [];

    _active = false;
    private _overlayInstance: IOverlayResultApi;
    private _popupHidden$: Subject<void>;
    protected _popupInstance: LgDropdownPopupComponent<T>;

    // ---------------------------------------------------------------------------------------------
    //  Initialization
    // ---------------------------------------------------------------------------------------------

    ngOnInit(): void {
        // console.log("this.emptyText", this.emptyText);
    }

    private _setUpErrorTooltip(): void {
        if (!this._tooltip) {
            this._tooltip = this._tooltipService.create({
                content: this._invalidTooltipMessage,
                target: this._elementRef,
                tooltipClass: "lg-tooltip lg-tooltip--simple lg-tooltip--invalid"
            });
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Trigger the selection from the outside
    // ---------------------------------------------------------------------------------------------
    triggerSelect(onCancel?: (source: LgDropdownComponent<T>) => void): void {
        this._onCancelFn = onCancel;
        this._mustPick = onCancel == null;
        setTimeout(() => this._doShow(), 10);
    }

    // ---------------------------------------------------------------------------------------------
    //  For the cases when the dropdown is placed in a dialog handler
    // ---------------------------------------------------------------------------------------------
    @HostListener("mousedown", ["$event"])
    _onMouseDown($event: MouseEvent): void {
        $event.stopPropagation();
    }

    @HostListener("mouseenter", ["$event"])
    _onMouseEnter(): void {
        if (this._invalidTooltipMessage && !this._isValid)
            this._tooltip.show({ content: this._invalidTooltipMessage });
    }

    @HostListener("mouseleave", ["$event"])
    _onMouseLeave(): void {
        if (this._invalidTooltipMessage) this._tooltip.hide();
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("click", ["$event"])
    _onClick($event: MouseEvent): boolean {
        if (this._isDropdownDisabled) return true;

        $event.stopPropagation();

        if (this.preSelect && this.preSelect(this, this.current) === false) {
            return false;
        }

        this._mustPick = false;
        this._onCancelFn = null;
        this._doShow();
        return false;
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("keydown.enter", ["$event"])
    _onEnter($event: KeyboardEvent): boolean {
        if (!this.focusEnabled) return true;

        return this._onClick($event as any);
    }

    // ---------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        if (this._active) {
            this._doClose(true);
        }

        this._destroyed$.next();
        this._destroyed$.complete();
    }

    // ---------------------------------------------------------------------------------------------
    //  ngModel integration
    // ---------------------------------------------------------------------------------------------
    private _onChangeFn: (value: T) => void;
    private _onTouchedFn: () => void;

    writeValue(obj: any): void {
        this.current = obj as T;
        this._changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: T) => void): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
        this._updateValue();
        if (this._active) {
            this._doClose(true);
        }
        this._changeDetectorRef.markForCheck();
    }

    // ---------------------------------------------------------------------------------------------
    //  Normalization code helpers
    // ---------------------------------------------------------------------------------------------
    // build lookup from the groups
    private _buildLookup(): void {
        const lookup: Record<string, any> = {};
        const id = this._definition.entryId;
        for (const g of this._definition.groups) {
            for (const e of g.entries) {
                lookup[e[id]] = e;
            }
        }
        this._definition.lookup = lookup;
    }

    // build groups from the lookup (sorting is undefined, so we sort _always_ by name)
    private _buildGroups(): void {
        let values: any[] = Object.values(this._definition.lookup || {});

        // If the lookup is just a dictionary of strings, convert them to objects first
        if (values.length && ldIsString(values[0])) {
            const id = this._definition.entryId;
            const name = this._definition.entryName;
            const lookup: Record<string, any> = {};
            values = ldMap(this._definition.lookup, (e, k) => {
                const item: any = {};
                item[id] = k;
                item[name] = e;
                lookup[k] = item;
                return item;
            });
            this._definition.lookup = lookup;
        }
        this._definition.groups = [{ entries: ldSortBy(values, this._definition.entryId) }];
    }

    // ---------------------------------------------------------------------------------------------
    //  Normalize the dropdown definition
    // ---------------------------------------------------------------------------------------------
    private _normalizeDefinition(): void {
        const def = this._definition;
        if (!def) return;

        if (this._normalized && def.groups && def.lookup) return;

        if (!def.entryId) def.entryId = "id";
        if (!def.entryName) def.entryName = "name";
        if (!def.iconName) def.iconName = "icon";

        if (def.groups) {
            if (def.groups.length && def.groups[0].entries == null) {
                // assume we actually got just the entries
                def.groups = [{ entries: def.groups }];
                // if the original groups were just array of strings, convert them to items (with id==name)
                if (!def.lookup && ldIsString(def.groups[0].entries[0])) {
                    const id = this._definition.entryId;
                    const name = this._definition.entryName;
                    def.groups[0].entries = ldMap(def.groups[0].entries, e => {
                        const item: Record<string, any> = {};
                        item[id] = e;
                        item[name] = e;
                        return item;
                    });
                }
            }
            // lookup is missing, reconstruct it from the groups
            if (!def.lookup) this._buildLookup();
        } else if (def.lookup) {
            this._buildGroups();
        }

        // If there is no global icon definition, convert icon-as-string values to structures
        if (def.icons === undefined) {
            for (const group of def.groups) {
                for (const entry of group.entries) {
                    if (typeof entry[def.iconName] === "string") {
                        entry[def.iconName] = { icon: entry[def.iconName] };
                    }
                }
            }
        }

        if (this._popupInstance) {
            this._popupInstance.definition = this._definition;
        }

        this._normalized = true;
    }

    // ---------------------------------------------------------------------------------------------
    //  Update currently selected value
    // ---------------------------------------------------------------------------------------------
    protected _updateValue(): void {
        if (!this._definition) {
            this._assigned = false;
            this._currentValueName = this.emptyText || "-";
            this._isDropdownDisabled = true;
            this._currentValueIcons = [];
            return;
        }

        this._normalizeDefinition();
        const res = this._definition.lookup["" + this.current];

        if (res) {
            this._assigned = true;
            this._currentValueName = res[this._definition.entryName];
            this._isDropdownDisabled = false;
            const disableProperty = this._definition.disabled;
            if (disableProperty && disableProperty.length) {
                if (disableProperty.charAt(0) === "!") {
                    this._isDropdownDisabled = !res[disableProperty.substring(1)];
                } else {
                    this._isDropdownDisabled = res[disableProperty];
                }
            }
            let icons = res[this._definition.iconName];
            if (icons) {
                if (!ldIsArray(icons)) icons = [icons];
                icons = ldFilter(
                    ldMap(icons, (e: any) => (ldIsString(e) ? this._definition.icons[e] : e)),
                    icon =>
                        icon.inCurrent ||
                        (icon.inCurrent === undefined && this._definition.allIconsInCurrent)
                );
            }
            this._currentValueIcons = icons || [];
        } else {
            this._currentValueName = this.emptyText || this._definition.empty || "-";
            this._assigned = false;
            this._isDropdownDisabled = false;
            this._currentValueIcons = [];
        }
        this._isDropdownDisabled = this._isDropdownDisabled || this._isDisabled;
    }

    // ---------------------------------------------------------------------------------------------
    //  Click on icon on the collapsed dropdown
    // ---------------------------------------------------------------------------------------------
    _multiIconClick($event: MouseEvent, icon: IDropdownIconDefinition<any>, id: any): void {
        if (!icon.clickable) return;

        $event.stopPropagation();
        $event.preventDefault();

        if (icon.onClick) {
            icon.onClick(id, icon);
        }

        if (this._definition.icons.onClick) {
            (this._definition.icons.onClick as any)(id, icon);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Make a selection
    // ---------------------------------------------------------------------------------------------
    private _doSelect(id: T): void {
        this.current = id;
        this._doClose(false);
        this.currentChange.next(id);

        if (this._onChangeFn) {
            this._onChangeFn(id);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Attempt to close the popup without selection (vetoed by mustPick)
    // ---------------------------------------------------------------------------------------------
    private _attemptClose(): void {
        if (!this._mustPick) {
            this._doClose(false);
            if (this._onCancelFn) {
                this._onCancelFn(this);
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Close the popup
    // ---------------------------------------------------------------------------------------------
    private _doClose(immediately: boolean): void {
        if (!this._active) return;

        this._popupHidden$.next();
        this._popupHidden$.complete();

        this._active = false;
        this.activeChange.emit(this._active);

        const onHidden = (overlayInstance: IOverlayResultApi): void => {
            overlayInstance.hide();

            if (!this._allowFocus) return;

            const focusable = this._elementRef.nativeElement.querySelector("[tabindex='0']");

            if (focusable && focusable.focus) {
                focusable.focus();
            }
        };

        if (immediately) {
            onHidden(this._overlayInstance);
        } else {
            const overlayInstance = this._overlayInstance;
            this._popupInstance
                .hide()
                .pipe(first())
                .subscribe(() => onHidden(overlayInstance));
        }

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;
        if (this._onTouchedFn) this._onTouchedFn();

        this._changeDetectorRef.markForCheck();
    }

    // ---------------------------------------------------------------------------------------------
    //  Show the popup
    // ---------------------------------------------------------------------------------------------
    protected _getPopupClass(): any {
        return LgDropdownPopupComponent;
    }

    private _doShow(): void {
        this._popupHidden$ = new Subject<void>();

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .setOrigin(this._elementRef)
            .withPositions([
                {
                    originX: this._attachOnLeft ? "start" : "end",
                    originY: this.hideSearch && !this._showPopupAbove ? "bottom" : "top",
                    overlayX: this._attachOnLeft ? "start" : "end",
                    overlayY: this._showPopupAbove ? "bottom" : "top"
                },
                {
                    originX: this._attachOnLeft ? "start" : "end",
                    originY: "bottom",
                    overlayX: this._attachOnLeft ? "start" : "end",
                    overlayY: "bottom"
                }
            ])
            .withDefaultOffsetY(this.hideSearch ? -1 : 0);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => this._attemptClose(),
            hasBackdrop: true,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance.isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance.isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgDropdownPopupComponent<T>>(this._getPopupClass());
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance.updatePosition(change);
        });

        this._popupInstance
            ._initialize({
                target: this._elementRef,
                matchWidth: this._matchWidth,
                itemTooltips: this._itemTooltips,
                popupClassName: this.popupClassName,
                hideSearch: this._hideSearch,
                currentValue: this.current,
                condensed: this._condensed,
                highlightSelected: this._highlightSelected,
                animationEnabled: this.animationEnabled,
                standalone: false,
                translateService: this._translateService,
                definition: this._definition,
                isControlCondensed:
                    this.className && this.className.indexOf("lg-dropdown--condensed") !== -1,
                searchPrefix: this._searchPrefix,
                reposition: () => strategy.apply()
            })
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                if (!result.selected) {
                    this._attemptClose();
                } else {
                    this._doSelect(result.id);
                }
            });

        this._active = true;
        this.activeChange.emit(this._active);

        this._changeDetectorRef.markForCheck();
    }

    _getTitle(): string {
        if (this._showTitle) return this.textOverride || this._currentValueName;
        return null;
    }
}
