import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { sanitizeForRegexp, toBoolean } from "@logex/framework/utilities";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgItemSelectorConfiguration } from "./lg-item-selector.types";
import { ValueAccessorBase } from "../value-accessor-base";

@Component({
    selector: "lg-item-selector",
    templateUrl: "./lg-item-selector.component.html",
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Directives._lgItemSelector")],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgItemSelectorComponent),
            multi: true
        }
    ],
    host: {
        "[class]": "'lg-item-selector ' + className",
        "[class.lg-item-selector--active]": "_focus"
    }
})
export class LgItemSelectorComponent
    extends ValueAccessorBase<Record<string, any>>
    implements OnChanges, OnDestroy, AfterViewInit
{
    private _ngZone = inject(NgZone);

    /**
     * Css class to be applied to host.
     */
    @Input("class") className?: string;

    /**
     * Data source (required).
     */
    @Input({ required: true }) source!: any[];

    /**
     * Selector configuration.
     */
    @Input({ required: true }) set config(config: LgItemSelectorConfiguration) {
        this._config = this._normalizeConfig(config);
    }

    get config(): LgItemSelectorConfiguration {
        return this._config;
    }

    @Input() set disabled(val: boolean) {
        this._disabled = toBoolean(val);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    get selection(): Record<string, any> {
        return this._selection;
    }

    /**
     * Current selection
     */
    @Input() set selection(val: Record<string, any>) {
        this._selection = val;
    }

    /**
     * Search is hidden if true, visible otherwise.
     */
    @Input()
    public set hideSearch(value: boolean) {
        this._hideSearch = toBoolean(value);
    }

    public get hideSearch(): boolean {
        return this._hideSearch;
    }

    @Output() readonly selectionChange = new EventEmitter<Record<string, any>>();

    @ViewChild("input", { static: true }) _inputEl: ElementRef<HTMLInputElement>;

    @ContentChild(TemplateRef) _itemInfoTemplate: TemplateRef<any>;

    _disabled: boolean;
    _available: any[];
    _visible: any[];
    _selectionSize: number;
    _search = "";
    _sanitizedSearch = "";
    _focus = false;
    _selectedOnly = false;
    _exclusiveSelected = false;
    _checkState: 0 | 1 | 2 = 1;
    _currentIndex: number | null = null;
    readonly _visible$ = new Subject<number>();

    private _config!: LgItemSelectorConfiguration;
    private _selection: Record<string, any> = {};
    private _hideSearch: boolean;
    private _refreshSubscription: Subscription;
    private _keyUnlisten: () => void;

    constructor() {
        super();
    }

    ngOnChanges(changes: LgSimpleChanges<LgItemSelectorComponent>): void {
        if (changes.config) {
            this._refreshUnsubscribe();
            if (this._config.refresh$) {
                this._refreshSubscription = this._config.refresh$.subscribe(() => {
                    this._refresh();
                });
            }
        }

        if (changes.config || changes.selection || changes.source) {
            this._refresh();
        }
    }

    ngAfterViewInit(): void {
        this._ngZone.runOutsideAngular(() => {
            this._keyUnlisten = this._renderer.listen(this._inputEl.nativeElement, "keydown", e =>
                this._keyDown(e)
            );
        });
    }

    ngOnDestroy(): void {
        this._refreshUnsubscribe();
        if (this._keyUnlisten) {
            this._keyUnlisten();
            this._keyUnlisten = null;
        }
    }

    override writeValue(value: Record<string, any>): void {
        const differ = (): boolean => {
            const keysSelection = Object.keys(this.value ?? {});
            const keysValue = Object.keys(value ?? {});
            return !(
                keysSelection.length === keysValue.length &&
                keysSelection.every(key => keysValue.includes(key))
            );
        };
        if (differ()) {
            this.selectionChange.next(value);
            this.value = { ...value };
            this._selection = { ...value };
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _getState(item: any): 0 | 1 | 2 {
        if (item === true) return this._exclusiveSelected ? 1 : 0;
        if (!this._selection[this._config.getId(item)]) return 0;
        if (this._config.isPartiallySelected && this._config.isPartiallySelected(item)) return 2;
        return 1;
    }

    _selectedOnlyToggle(): void {
        this._selectedOnly = !this._selectedOnly;
        this._refresh(true);
        this._inputEl.nativeElement.focus();
    }

    _toggleItem(item: any, index?: number): boolean {
        if (this._disabled) return false;

        if (item === true) {
            this._toggleExclusive();
            if (!this._focus) this._inputEl.nativeElement.focus();
            return false;
        }

        if (this._config.isDisabled(item)) return false;
        const state = this._getState(item);
        const canProcess = this._config.toggleItem!(item, state === 0);
        if (canProcess) {
            const id = this._config.getId(item);
            if (this._selection[id]) {
                delete this._selection[id];
            } else {
                this._selection[id] = item;
            }
        }

        if (index !== undefined) {
            this._currentIndex = index;
        }

        if (!this._focus) this._inputEl.nativeElement.focus();
        this._refresh();
        this.writeValue(this._selection);
        return true;
    }

    _toggleExclusive(): void {
        if (this._disabled) return;

        if (this._exclusiveSelected) {
            this._addVisible(true);
        } else {
            this._removeVisible(true);
        }
        if (this._currentIndex !== undefined) {
            this._currentIndex = 0;
        }
        this._refresh();
        this.writeValue(this._selection);
    }

    _checkAll(state: boolean): void {
        if (this._disabled) return;
        if (state) {
            this._addVisible(false);
        } else {
            this._removeVisible(false);
        }
        this._refresh();
        this.writeValue(this._selection);
        this._inputEl.nativeElement.focus();
    }

    private _addVisible(all: boolean): void {
        // This call is used only when no visible item is selected, so process them all
        let toAdd = all ? this._config.filterItems!(this.source) : this._visible;
        toAdd = toAdd.filter(e => e !== true && !this._config.isDisabled!(e));
        if (toAdd.length === 0) return;

        if (this._config.addMany) {
            if (!this._config.addMany!(toAdd)) return;
        }

        const getId = this._config.getId!;
        for (const item of toAdd) {
            this._selection[getId(item)] = item;
        }
    }

    private _removeVisible(all: boolean): void {
        const getId = this._config.getId!;
        // This call can be used when only some visible items are selected, so we need to filter them
        let toRemove = all
            ? this._config.filterItems!(this.source)
            : this._visible.filter(e => this._selection[getId(e)]);
        toRemove = toRemove.filter(e => e !== true && !this._config.isDisabled!(e));
        if (toRemove.length === 0) return;
        if (this._config.removeMany) {
            if (!this._config.removeMany!(toRemove)) return;
        }

        for (const item of toRemove) {
            delete this._selection[getId(item)];
        }
    }

    private _normalizeConfig(config: LgItemSelectorConfiguration): LgItemSelectorConfiguration {
        if (!config) config = {};

        if (!config.searchPlaceholder) {
            config.searchPlaceholder = "";
        }

        if (config.exclusiveItemId !== undefined && config.exclusiveItemName == null) {
            console.error("LgItemSelector: When using exclusive item, name should be provided");
            config.exclusiveItemName = "Select all";
        }

        if (!config.filterItems) config.filterItems = items => items;

        if (!config.sortItems) config.sortItems = items => items;

        if (!config.getName) config.getName = item => item.name;

        if (!config.getItemTitle) config.getItemTitle = config.getName;

        if (!config.getId) config.getId = item => item.id;

        if (!config.isDisabled) config.isDisabled = () => false;

        if (!config.toggleItem) {
            if (config.addMany && config.removeMany) {
                config.toggleItem = (item, newState) =>
                    newState ? config.addMany([item]) : config.removeMany([item]);
            } else {
                config.toggleItem = () => true;
            }
        }

        // Note: cannot simulate addMany/removeMany as they don't allow treating items differently, and while
        // we don't expect that to happen, it's better to be safe. So the looping will be handled by the main code.

        return config;
    }

    private _refreshUnsubscribe(): void {
        if (this._refreshSubscription) {
            this._refreshSubscription.unsubscribe();
            this._refreshSubscription = null;
        }
    }

    private _updateCheckState(): void {
        let state: 0 | 1 | null = null;
        for (const item of this._visible) {
            if (this._config.isDisabled(item)) continue;
            if (this._selection[this._config.getId!(item)]) {
                if (state === 0) {
                    this._checkState = 2;
                    return;
                }
                state = 1;
            } else {
                if (state === 1) {
                    this._checkState = 2;
                    return;
                }
                state = 0;
            }
        }
        this._checkState = state || 0;
    }

    private _refresh(keepSelectedOnly?: boolean): void {
        const used = new Set();
        const getId = this._config.getId!;
        this._available = [];
        this._exclusiveSelected = false;

        if (!this._selectedOnly) {
            for (const item of this.source) {
                this._available.push(item);
                used.add(getId(item));
            }
        }

        const selected = Object.values(this._selection);
        this._selectionSize = 0;
        for (const item of selected) {
            if (item === true) continue;

            this._selectionSize += 1;
            if (!used.has(getId(item))) {
                // If there is no sorting defined, we want the items without definition on top
                this._available.unshift(item);
            }
        }

        if (this._config.exclusiveItemId !== undefined) {
            if (this._selectionSize === 0) {
                this._exclusiveSelected = true;
                this._selectionSize = this._selectedOnly
                    ? this._config.filterItems!(this.source).length
                    : this._available.length;
                this._selection[this._config.exclusiveItemId!] = true;
            } else {
                delete this._selection[this._config.exclusiveItemId!];
            }
        }

        this._available = this._config.filterItems!(this._available);
        this._available = this._config.sortItems!(this._available);

        if (
            !keepSelectedOnly &&
            !this._exclusiveSelected &&
            this._selectionSize === 0 &&
            this._selectedOnly
        ) {
            this._selectedOnly = false;
            this._refresh();
        } else {
            this._filterSearch();
        }
    }

    _filterSearch(): void {
        const currentEntry =
            this._visible && this._currentIndex !== null && this._visible[this._currentIndex];
        const currentId =
            currentEntry && currentEntry !== true ? this._config.getId(currentEntry) : undefined;

        this._search = (this._search || "").trim();

        if (this._search === "") {
            this._visible =
                this._config.exclusiveItemId === undefined ? this._available : [...this._available];
            this._sanitizedSearch = "";
            if (currentEntry !== true) {
                this._currentIndex =
                    currentId !== undefined
                        ? this._visible.findIndex(e => this._config.getId(e) === currentId)
                        : null;
                if (this._currentIndex === -1) this._currentIndex = null;
            }
        } else {
            this._visible = [];
            this._sanitizedSearch = this._search.replace(/\s\s+/g, " ");
            const search = new RegExp(sanitizeForRegexp(this._sanitizedSearch), "i");
            const getName = this._config.getName!;
            let found = false;

            for (const item of this._available) {
                if (getName(item).match(search)) {
                    this._visible.push(item);
                    if (
                        !found &&
                        currentId !== undefined &&
                        this._config.getId(item) === currentId
                    ) {
                        found = true;
                        this._currentIndex = this._visible.length - 1;
                    }
                }
            }

            if (!found && currentEntry !== true) {
                this._currentIndex = currentEntry ? 0 : null;
            }
        }

        if (
            this._config.exclusiveItemId !== undefined &&
            (!this._selectedOnly || this._exclusiveSelected)
        ) {
            this._visible.unshift(true);
            if (currentEntry !== true && this._currentIndex !== null) this._currentIndex += 1;
        }

        if (this._currentIndex !== null) this._visible$.next(this._currentIndex);

        this._updateCheckState();
    }

    _keyDown(event: KeyboardEvent): boolean {
        if (this._visible.length) {
            const keyCode = event.keyCode;
            let update = false;
            // First keyboard touch, choose index

            if (
                this._currentIndex === null &&
                (keyCode === 38 ||
                    keyCode === 40 ||
                    keyCode === 36 ||
                    keyCode === 35 ||
                    keyCode === 13)
            ) {
                this._currentIndex = 0;
                // If this was keyboard up or down or enter, do not do anything else
                if (keyCode === 38 || keyCode === 40 || keyCode === 13) {
                    this._visible$.next(this._currentIndex);
                    this._ngZone.run(() => undefined);
                    return false;
                }
            }
            if (keyCode === 38 && this._currentIndex > 0) {
                --this._currentIndex;
                update = true;
            } else if (keyCode === 40) {
                if (this._currentIndex < this._visible.length - 1) {
                    ++this._currentIndex;
                }
                update = true;
            } else if (keyCode === 36) {
                this._currentIndex = 0;
                update = true;
            } else if (keyCode === 35) {
                this._currentIndex = this._visible.length - 1;
                update = true;
            } else if (keyCode === 13) {
                this._toggleItem(this._visible[this._currentIndex]);
            } else {
                return true;
            }

            this._visible$.next(this._currentIndex);
            if (update) {
                this._ngZone.run(() => undefined);
            }
            return false;
        }
        return true;
    }
}
