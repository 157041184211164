<ng-container *ngIf="enabled">
    <lg-chat-bubble
        *ngIf="!hideBubble && !_chatState.isChatVisible"
        (chatToggled)="_chatToggle()"
    ></lg-chat-bubble>
    <lg-chat-window
        *ngIf="_chatState.isChatVisible"
        (closed)="_chatToggle()"
        (sendMessage)="_sendMessage($event)"
        [introductionTemplate]="introductionTemplate"
        (prepareMail)="_prepareMail()"
        [chatTitle]="chatTitle ?? ''"
    ></lg-chat-window>

    <ng-template #introductionTemplate>
        <ng-content select="[introductionTemplate]"></ng-content>
    </ng-template>
</ng-container>
