import ldIsFunction from "lodash-es/isFunction";
import { TemplateRef, OnInit, OnDestroy, Directive } from "@angular/core";

import { toInteger } from "@logex/framework/utilities";

import type {
    LgPivotTableRowVisibility,
    LgPivotTableRowHeight,
    ItemsMap,
    IBodyRegister
} from "../types";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class LgPivotTableRowDefBase implements OnInit, OnDestroy {
    protected abstract _notifyMove(oldLevel: number | string): void;
    protected abstract _notifyChange(): void;
    protected abstract _notifyInit(): void;
    protected abstract _notifyDestroy(): void;

    public set level(level: number | string) {
        const oldLevel = this._level;

        this._level = level;
        if (this._initialized) this._notifyMove(oldLevel);
    }

    public get level(): number | string {
        return this._level;
    }

    public set visible(callback: LgPivotTableRowVisibility<any, any>) {
        this._visibilityFn = callback;

        if (this._initialized) this._notifyChange();
    }

    get visible(): LgPivotTableRowVisibility<any, any> {
        return this._visibilityFn;
    }

    public set height(value: number | LgPivotTableRowHeight<any, any>) {
        if (value == null) {
            this._heightFn = null;
        } else if (ldIsFunction(value)) {
            this._heightFn = value;
        } else {
            const height = toInteger(value);
            this._heightFn = () => height;
        }

        if (this._initialized) this._notifyChange();
    }

    get height(): LgPivotTableRowHeight<any, any> | null {
        return this._heightFn;
    }

    public get template(): TemplateRef<any> {
        return this._template;
    }

    public getVisible<T, P>(item: T, items: ItemsMap<P>): boolean {
        if (!this._visibilityFn) return true;

        return this._visibilityFn(item, items);
    }

    public getHeight<T, P>(item: T, items: ItemsMap<P>, defaultHeight: number): number {
        if (!this._heightFn) return defaultHeight;

        return this._heightFn(item, items);
    }

    public constructor(
        protected _body: IBodyRegister,
        protected _template: TemplateRef<any>
    ) {
        // empty
    }

    public ngOnInit(): void {
        this._notifyInit();
        this._initialized = true;
    }

    public ngOnDestroy(): void {
        this._notifyDestroy();
    }

    protected _level: number | string;
    protected _visibilityFn: LgPivotTableRowVisibility<any, any>;
    protected _heightFn: LgPivotTableRowHeight<any, any>;
    protected _initialized = false;
}
