import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import { toBoolean, toInteger, toNumber } from "@logex/framework/utilities";

import { LgColorPalette } from "../shared/lg-color-palette";

@Component({
    selector: "lg-color-scale-bar",
    template: `
        <div
            class="lg-color-scale-bar__item"
            *ngFor="let item of _items"
            [style.background]="item"
            [style.opacity]="_colorOpacity"
        ></div>
    `,
    host: {
        class: "lg-color-scale-bar",
        "[class.lg-color-scale-bar--vertical]": "_vertical"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgColorScaleBarComponent implements OnInit, OnChanges {
    private _palette = inject(LgColorPalette);

    /**
     * @optional
     * Specifies color palette as array of colors. Defaults to categorical palette.
     *
     * @default categorical palette
     */
    @Input() palette: string[] | null = null;

    /**
     * @optional
     * Specifies default palette size. Valid values are integers from 2 to 10.
     *
     * @defaul 10
     */
    @Input() defaultPaletteSize: number | null = null;

    /**
     * @optional
     * Specifies direction of colors in palette.
     *
     * @type {"right" | "left" | "up" | "down"}
     *
     * @default "right"
     */
    @Input() direction: "right" | "left" | "up" | "down" | null = null;

    /**
     * @optional
     * Specifies if palette is smooth or not.
     *
     * @default truer
     */
    @Input() set smooth(value: boolean | "true" | "false") {
        this._smooth = toBoolean(value, true);
    }

    get smooth(): boolean {
        return this._smooth;
    }

    /**
     * @optional
     * Specifies opacity of colors
     *
     * @default 1
     */
    @Input() set colorOpacity(value: number | string) {
        this._colorOpacity = toNumber(value);
    }

    get colorOpacity(): number {
        return this._colorOpacity;
    }

    _vertical = false;
    _smooth = true;
    _items: string[] = null;
    _colorOpacity = 1;

    ngOnInit(): void {
        if (this._items === null) this._fill();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.palette || changes.defaultPaletteSize || changes.smooth || changes.direction)
            this._fill();
    }

    private _fill(): void {
        let colors: string[];
        if (this.palette && this.palette.length) {
            colors = this.palette;
        } else {
            colors = this._palette.getPalette(toInteger(this.defaultPaletteSize, 10, 2, 10));
        }

        let gradientDirection: string;
        switch (this.direction) {
            default:
            case "right":
                gradientDirection = "to right";
                this._vertical = false;
                break;
            case "left":
                gradientDirection = "to right";
                this._vertical = false;
                colors.reverse(); // mutates
                break;
            case "down":
                gradientDirection = "to bottom";
                this._vertical = true;
                break;
            case "up":
                gradientDirection = "to bottom";
                this._vertical = true;
                colors.reverse(); // mutates
                break;
        }

        if (this._smooth) {
            if (colors.length === 1) colors.push(colors[0]);
            this._items = [];
            for (let i = 0; i < colors.length - 1; ++i) {
                this._items.push(
                    `linear-gradient(${gradientDirection}, ${colors[i]}, ${colors[i + 1]})`
                );
            }
        } else {
            this._items = colors;
        }
    }
}
