import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { LgPromptDialog } from "../../..";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Component({
    selector: "lg-editable-grid-text",
    templateUrl: "./lg-editable-grid-text.component.html",
    styleUrl: "./lg-editable-grid-text.component.scss"
})
export class LgEditableGridTextComponent implements OnInit {
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    protected _isEditing = false;

    @Input("model") _value: string;
    protected _valueOriginal: string;

    @Output("modelChange") protected readonly _valueChange = new EventEmitter<string>();

    @Input("disabled") _disabled = false;
    @Input("confirmMessageDisabled") _confirmMessageDisabled = false;
    @Input("nullOrEmptyValueDisabled") _nullOrEmptyValueDisabled = false;

    @Input("markFocusOn") protected _markFocusOn = ".table__row";

    /**
     * Specifies the placeholder in input.
     *
     * @param placeholder default = undefined
     */
    @Input() placeholder?: string | boolean | null;

    @Output("lgFocus") protected readonly _focusCb = new EventEmitter<void>();
    @Output("lgBlur") protected readonly _blurCb = new EventEmitter<string>();
    @Output("lgSave") protected readonly _saveCb = new EventEmitter<string>();

    ngOnInit(): void {
        this._valueOriginal = this._value;
    }

    startEditing(): void {
        this._isEditing = true;
    }

    protected async _onFocus(): Promise<void> {
        this._focusCb.emit();
    }

    protected async _onBlur(): Promise<void> {
        if (!this._confirmMessageDisabled && this._value !== this._valueOriginal) {
            const response = await this._promptDialog.confirm(
                this._lgTranslate.translate("FW._LgEditableGridText.ConfirmSave.Title"),
                this._lgTranslate.translate("FW._LgEditableGridText.ConfirmSave.Body"),
                {
                    buttons: [
                        {
                            id: "save",
                            name: this._lgTranslate.translate(
                                "FW._LgEditableGridText.ConfirmSave.Save"
                            ),
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate(
                                "FW._LgEditableGridText.ConfirmSave.Cancel"
                            ),
                            isCancelAction: true
                        }
                    ]
                }
            );

            switch (response) {
                case "save":
                    this._applyValue(this._value);
                    break;
                case "cancel":
                    this._value = this._valueOriginal;
                    break;
            }
        } else {
            this._applyValue(this._value);
        }

        this._blurCb.emit(this._value);
        this._isEditing = false;
    }

    protected _onPressEnter(): void {
        if (this._value === this._valueOriginal) return;
        this._applyValue(this._value);
    }

    protected async _startEditing($event: MouseEvent): Promise<void> {
        $event.stopPropagation();

        this._isEditing = true;
    }

    private _applyValue(value: string): void {
        if (this._nullOrEmptyValueDisabled && !value) {
            this._value = this._valueOriginal;
            return;
        }

        this._valueOriginal = value;
        this._saveCb.next(value);
        this._valueChange.emit(value);
    }
}
