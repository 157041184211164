import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IDropdownDefinition } from "@logex/framework/ui-core";
import { getSwitchDefinition } from "./getSwitchDefinition";
import { LgPanelChoiceComponent } from "./lg-panel-choice.component";
import {
    ChoiceIdType,
    IPanelChoiceDefinition,
    IPanelSwitchDefinition,
    LgPanelSwitchState$
} from "./lg-panel.types";

@Component({
    selector: "lg-panel-switch",
    templateUrl: "./lg-panel-switch.component.html",
    host: {
        class: "lg-panel-switch"
    }
})
export class LgPanelSwitchComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
    @Input() name: string | null = null;
    @Input() title: string | null = null;

    // TODO: allow changing choice externally, after init
    @Input() variantId?: ChoiceIdType;
    @Output() readonly variantIdChange = new EventEmitter<ChoiceIdType>();

    @Input() choiceId?: ChoiceIdType;
    @Output() readonly choiceIdChange = new EventEmitter<ChoiceIdType>();

    @ContentChildren(LgPanelChoiceComponent) _choicesDefinitions: QueryList<LgPanelChoiceComponent>;

    @ContentChild("body") _bodyTemplateRef: ElementRef<HTMLElement>;
    @ContentChild("additionalMenuItemsForSwitch")
    _leftAdditionalMenuItems: TemplateRef<any>;

    @ContentChild("rightAdditionalMenuItemsForSwitch")
    _rightAdditionalMenuItems: TemplateRef<any>;

    _switchDefinition: IPanelSwitchDefinition;
    _state$: LgPanelSwitchState$;
    _selectionMenuItems$: BehaviorSubject<IPanelChoiceDefinition[]>;
    _currentChoice$: BehaviorSubject<IPanelChoiceDefinition>;
    _leftAdditionalItems$: BehaviorSubject<Array<TemplateRef<any>>>;
    _rightAdditionalItems$: BehaviorSubject<Array<TemplateRef<any>>>;

    private _destroyed$: Subject<void>;

    _dropdownDefinition: IDropdownDefinition<number | string> | null | undefined;

    ngOnInit(): void {
        this._defaultProps();

        this._destroyed$ = new Subject<void>();
        this._currentChoice$ = new BehaviorSubject(null);
        this._selectionMenuItems$ = new BehaviorSubject([]);
        this._leftAdditionalItems$ = new BehaviorSubject([]);
        this._rightAdditionalItems$ = new BehaviorSubject([]);
        this._state$ = new BehaviorSubject({ choiceId: this.choiceId, variantId: this.variantId });
    }

    ngAfterContentInit(): void {
        this._switchDefinition = getSwitchDefinition(this._choicesDefinitions);
        this._selectionMenuItems$.next(this._switchDefinition.choices);

        this._choicesDefinitions.changes.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this._switchDefinition = getSwitchDefinition(this._choicesDefinitions);
            this._selectionMenuItems$.next(this._switchDefinition.choices);
        });

        this._selectionMenuItems$.pipe(takeUntil(this._destroyed$)).subscribe(choices => {
            // todo: change current selection if not found
            this._dropdownDefinition = {
                iconName: "icon",
                groups: [
                    {
                        entries: choices.map(ch => ({
                            ...ch,
                            icon: ch.icon
                                ? {
                                      icon: ch.icon
                                  }
                                : undefined
                        }))
                    }
                ]
            };
        });

        this._state$.pipe(takeUntil(this._destroyed$)).subscribe(currentSelection => {
            const choices = this._switchDefinition.choices;
            const currentChoiceDef = choices.find(x => x.id === currentSelection.choiceId);

            this._currentChoice$.next(currentChoiceDef);
            this._leftAdditionalItems$.next([
                this._leftAdditionalMenuItems,
                currentChoiceDef.additionalMenuItemsTemplateRef
            ]);
            this._rightAdditionalItems$.next([
                currentChoiceDef.rightAdditionalMenuItemsTemplateRef,
                this._rightAdditionalMenuItems
            ]);

            if (this.choiceId !== currentSelection.choiceId) {
                this.choiceId = currentSelection.choiceId;
                this.choiceIdChange.next(currentSelection.choiceId);
            }
            if (this.variantId !== currentSelection.variantId) {
                this.variantId = currentSelection.variantId;
                this.variantIdChange.next(currentSelection.variantId);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.variantId || changes.choiceId) {
            if (this._state$) {
                const choices = this._switchDefinition.choices;
                const choiceDef = choices.find(x => x.id === this.choiceId);
                if (choiceDef) {
                    this._state$.next({ choiceId: this.choiceId, variantId: this.variantId });
                }
            }
        }
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _choiceChange(choice: number | string): void {
        this._state$.next({
            choiceId: choice,
            variantId: this.variantId
        });
    }

    _getPanelTemplate(): TemplateRef<any> {
        const currentSelection = this._state$.getValue();

        const choice = this._switchDefinition.choices.find(
            choices => choices.id === currentSelection.choiceId
        );
        return choice ? choice.mainPaneTemplateRef : null;
    }

    private _defaultProps(): void {
        this.name = this.name || "" + Math.round(Math.random() * 1000);
        this.variantId = this.variantId != null ? this.variantId : 1;
        this.choiceId = this.choiceId != null ? this.choiceId : 1;
    }
}
