import { Input, Directive } from "@angular/core";
import * as d3 from "d3";
import ldIsObject from "lodash-es/isObject";

import { LgSimpleChanges } from "@logex/framework/types";
import { BaseChartComponent } from "./base-chart.component";
import { IReferenceLine, ReferenceLineAnchor } from "./chart.types";

export const REFERENCE_LINE_LABEL_WIDTH = 80;
const REFERENCE_LINE_LABEL_MARGIN = 5;

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseBarChartWithReferenceLineComponent<
    TConvertedItem,
    TTooltipContext extends object
> extends BaseChartComponent<TConvertedItem, TTooltipContext> {
    @Input() referenceLine: any;
    @Input() referenceLineLabel: any;

    protected _referenceLine: IReferenceLine;
    protected _referenceG: d3.Selection<any, any, any, any>;
    protected _referenceRectG: d3.Selection<any, any, any, any>;
    protected _referenceTopG: d3.Selection<any, any, any, any>;
    protected _referenceBottomG: d3.Selection<any, any, any, any>;
    protected _referenceLineLabel: d3.Selection<any, any, any, any>;

    protected abstract _yScale: d3.ScaleBand<number> | d3.ScaleLinear<number, number>;

    protected override _onInit(): void {
        super._onInit();
    }

    protected _onBaseBarChartChanges(
        changes: LgSimpleChanges<
            BaseBarChartWithReferenceLineComponent<TConvertedItem, TTooltipContext>
        >
    ): void {
        if ((changes.referenceLine || changes.referenceLineLabel) && this._chart) {
            this._setReferenceLine();
        }

        super._onBaseChartChanges(changes);
    }

    private _setReferenceLine(): void {
        const refLine = this.referenceLine;

        if (refLine === undefined) {
            this._referenceLine = null;
        } else if (!ldIsObject(refLine)) {
            this._referenceLine = this._getReferenceLineDefaults();
            this._referenceLine.value = +refLine;
        } else {
            this._referenceLine = { ...this._getReferenceLineDefaults(), ...refLine };
        }
    }

    protected _createReferenceLine(): void {
        this._referenceG = this._svgG.append("g").attr("class", "lg-chart-reference-line");

        this._referenceRectG = this._referenceG
            .append("rect")
            .attr("y", 0)
            .attr("x", 0)
            .attr("width", this._width)
            .attr("height", 1);

        this._referenceTopG = this._referenceG
            .append("text")
            .attr("y", 0)
            .attr("x", this._width)
            .attr("text-anchor", "end")
            .attr("dy", -2)
            .attr("class", "label reference-top");

        this._referenceBottomG = this._referenceG
            .append("text")
            .attr("y", 0)
            .attr("x", this._width)
            .attr("text-anchor", "end")
            .attr("dy", 13)
            .attr("class", "label reference-bottom");

        this._referenceLineLabel = this._referenceG
            .append("text")
            .attr("y", 0)
            .attr("x", this._width)
            .attr("text-anchor", "start")
            .attr("class", "label reference-bottom");

        this._setReferenceLine();
    }

    protected _renderReferenceLine(immediate: boolean, startingPoint = 0): void {
        if (this._referenceLine && this._referenceLine.value != null) {
            this._referenceLine.anchor = this._coerceReferenceLineAnchor(
                this._referenceLine.anchor
            );
            const refX = this._getReferenceLineX(this._referenceLine.anchor, this._width);

            this._referenceTopG
                .attr("text-anchor", this._referenceLine.anchor)
                .attr("x", refX)
                .text(this._referenceLine.top);

            this._referenceBottomG
                .attr("text-anchor", this._referenceLine.anchor)
                .attr("x", refX)
                .text(this._referenceLine.bottom);

            this._referenceRectG.attr("width", this._width);

            this._referenceG.attr(
                "class",
                "lg-chart-reference-line " + (this._referenceLine.className || "")
            );

            this._referenceLineLabel
                .attr("x", this._width + REFERENCE_LINE_LABEL_MARGIN)
                .text(this.referenceLineLabel)
                .call(
                    this._wrapReferenceLine,
                    REFERENCE_LINE_LABEL_WIDTH - REFERENCE_LINE_LABEL_MARGIN
                );

            if (immediate) {
                this._referenceG
                    .attr(
                        "transform",
                        `translate(${startingPoint}, ${(this._yScale as any)(
                            this._referenceLine.value
                        )})`
                    )
                    .style("opacity", 1);
            } else {
                this._referenceG
                    .transition()
                    .attr(
                        "transform",
                        `translate(${startingPoint}, ${(this._yScale as any)(
                            this._referenceLine.value
                        )})`
                    )
                    .style("opacity", 1);
            }
        } else {
            const tempValue = this._referenceLine ? this._referenceLine.value || 0 : 0;
            this._referenceG
                .style("opacity", 0)
                .attr(
                    "transform",
                    `translate(${startingPoint}, ${(this._yScale as any)(tempValue)})`
                );
        }
    }

    private _getReferenceLineDefaults(): IReferenceLine {
        return {
            value: null,
            anchor: "end",
            bottom: "",
            top: "",
            className: ""
        };
    }

    private _getReferenceLineX(refLineAnchor: ReferenceLineAnchor, width: number): number {
        switch (refLineAnchor.toLowerCase()) {
            case "start":
                return 0;
            case "middle":
                return width / 2;
            case "end":
                return width;
            default:
                return width;
        }
    }

    private _coerceReferenceLineAnchor(val: string): ReferenceLineAnchor {
        return val === "start" || val === "middle" || val === "end" ? val : "end";
    }

    private _wrapReferenceLine(text: d3.Selection<any, any, any, any>, width: number): void {
        text.each(function () {
            const textSelection = d3.select(this);
            const words = textSelection.text().split(/\s+/).reverse();
            let word;
            let line: string[] = [];
            let lineNumber = 0;
            const lineHeight = 1.1;
            const y = textSelection.attr("y");
            const x = textSelection.attr("x");
            let tspan = textSelection.text(null).append("tspan").attr("x", x).attr("y", y);

            while ((word = words.pop())) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = textSelection
                        .append("tspan")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", `${++lineNumber * lineHeight}em`)
                        .text(word);
                }
            }
        });
    }
}
