import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";
import ldEach from "lodash-es/each";

import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    Renderer2,
    inject
} from "@angular/core";

type CancelCallback = () => void;

@Directive({
    standalone: true,
    selector: "[lgStopPropagation]"
})
export class LgStopPropagationDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    /**
     * Event names for which propagation should be stopped.
     * In case of multiple events use either array of strings or string with `,` as a separator.
     */
    @Input("lgStopPropagation") events: string | string[];

    private listeners: Record<string, CancelCallback> = {};

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.events) {
            let newList: string[] = [];
            if (this.events) {
                if (ldIsString(this.events)) {
                    newList = this.events.split(",");
                } else if (ldIsArray(this.events)) {
                    newList = this.events;
                } else {
                    console.error("Unknown type for [lgStopPropagation]");
                }
            }

            const newListeners: Record<string, CancelCallback> = {};
            for (const eventName of newList) {
                if (!this.listeners[eventName]) {
                    newListeners[eventName] = this._renderer.listen(
                        this._elementRef.nativeElement,
                        eventName,
                        (event: any) => {
                            if (event.stopPropagation) {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                            return false;
                        }
                    );
                } else {
                    newListeners[eventName] = this.listeners[eventName];
                }
            }

            ldEach(this.listeners, (unsubscribe, eventName) => {
                if (!newListeners[eventName]) unsubscribe();
            });

            this.listeners = newListeners;
        }
    }

    public ngOnDestroy(): void {
        ldEach(this.listeners, (unsubscribe, _eventName) => {
            unsubscribe();
        });
        this.listeners = {};
    }
}
