import ldDefaults from "lodash-es/defaults";
import ldIsEmpty from "lodash-es/isEmpty";
import ldKeyBy from "lodash-es/keyBy";
import ldClone from "lodash-es/clone";

import { Component, Injectable, InjectionToken, ValueProvider, inject } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";

import {
    IUser,
    IVersion,
    LG_APP_CONTROL,
    LG_APP_GET_PERIOD_NAME,
    LG_USER_INFO,
    VersionService
} from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    LgDialogRef,
    LgPromptDialog,
    IQuickSettingsMenuItem,
    QuickSettingsMenuType
} from "@logex/framework/ui-core";

import { LG_SCENARIO_GATEWAY } from "./lg-scenario.gateway";
import { IGetVersionsListResponse, IScenario } from "./lg-scenario-gateway.types";
import { LogexPivotService } from "@logex/framework/lg-pivot";
import { ScenarioDialogPivot, ScenarioDialogSimplePivot } from "./lg-scenario-dialog-pivot.service";
import { Router } from "@angular/router";

// ----------------------------------------------------------------------------------
//
export interface IScenarioDialogWithPivotConfig<TScenario extends IScenario = IScenario> {
    allowScenarioDelete?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioCreate?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioEdit?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowScenarioLock?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowMarkAsPrimary?: boolean | ((scenario: TScenario, user: IUser) => boolean);
    allowGoToScenarioManagement?: boolean | ((user: IUser) => boolean);
    reloadOnScenarioChange?: boolean;
    showScenarioPeriodName?: boolean;
    showDataVersionLevel?: boolean;
    getDataVersionName?: (scenario: TScenario) => string;
    showCustomButton?: boolean | ((user: IUser) => boolean);
    customButtonText?: string;
    customButtonTextLc?: string;
    customButtonCallback?: () => boolean;
    showRecentlyOpened?: boolean;
    redirectTo?: string;
}

interface IExtendedScenario extends IScenario {
    quickSettingsMenu?: IQuickSettingsMenuItem[];
    allowLock?: boolean;
    allowPrimary?: boolean;
    data_version_full_name?: string;
}

export const LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG =
    new InjectionToken<IScenarioDialogWithPivotConfig>("lgScenarioDialogWithPivotConfig");

export function provideScenarioDialogWithPivotConfiguration<
    TScenario extends IScenario = IScenario
>(configuration: IScenarioDialogWithPivotConfig<TScenario>): ValueProvider {
    return {
        provide: LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG,
        useValue: configuration
    };
}

@Component({
    selector: "lg-scenario-dialog-with-pivot",
    templateUrl: "./lg-scenario-dialog-with-pivot.component.html",
    viewProviders: [useTranslationNamespace("FW._ScenarioManagement")]
})
export class LgScenarioDialogWithPivotComponent
    implements IDialogComponent<LgScenarioDialogWithPivotComponent>
{
    _dialogRef = inject(LgDialogRef<LgScenarioDialogWithPivotComponent>);
    _scenarioDialogPivot = inject(ScenarioDialogPivot);
    _scenarioDialogSimplePivot = inject(ScenarioDialogSimplePivot);
    _userInfo = inject(LG_USER_INFO);
    protected _pivotService = inject(LogexPivotService);
    private _appControlService = inject(LG_APP_CONTROL);
    private _promptDialog = inject(LgPromptDialog);
    private _gateway = inject(LG_SCENARIO_GATEWAY);
    private _lgTranslate = inject(LgTranslateService);
    private _router = inject(Router);
    private _versionService = inject(VersionService<IVersion>);

    private getPeriodName: (scenario: IScenario, showDataVersionPeriode: boolean) => string;

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        const getPeriodName = inject(LG_APP_GET_PERIOD_NAME);
        this.getPeriodName = getPeriodName;
    }

    // ----------------------------------------------------------------------------------
    //
    _title = this._lgTranslate.translate(".SelectScenario");
    _dialogClass = "lg-dialog--4col";
    _ready = new BehaviorSubject<boolean>(false);

    // ----------------------------------------------------------------------------------
    //
    private _scenariosDict: Record<string, IScenario>;

    private _activeVersion: number;

    _currentScenario: number;
    _scenarioDescription: string;

    _scenarioMenuDefinition: IQuickSettingsMenuItem[];

    _searchVersionId: number;

    _config: IScenarioDialogWithPivotConfig;

    _scenarioDialogPivotNodes: IScenario[] = [];
    pivotData: any[];

    _hoveredScenario: number = null;

    _allowGoToScenarioManagement = false;
    _showDataVersionLevel = false;

    _showCustomButton = false;

    // ----------------------------------------------------------------------------------
    //
    show(config: IScenarioDialogWithPivotConfig = {}): void {
        this._config = ldDefaults(config, {
            allowScenarioDelete: false,
            allowScenarioCreate: false,
            allowScenarioEdit: false,
            allowScenarioLock: false,
            allowMarkAsPrimary: false,
            allowGoToScenarioManagement: false,
            reloadOnScenarioChange: false,
            showScenarioPeriodName: false,
            showDataVersionLevel: true,
            getDataVersionName: this._getDataVersionName.bind(this),
            showCustomButton: false,
            customButtonText: null,
            customButtonTextLc: null,
            customButtonCallback: null,
            showRecentlyOpened: true,
            redirectTo: undefined
        });

        this._loadVersions().then(() => {
            this._subscribe();
        });

        if (this._config.redirectTo !== undefined && this._config.reloadOnScenarioChange) {
            console.error("redirectTo and reloadOnScenarioChange cannot be set at the same time");
        }

        this._allowGoToScenarioManagement = this._getAllowGoToScenarioManagement();
        this._showDataVersionLevel = this._config.showDataVersionLevel;
        this._showCustomButton =
            this._getShowCustomButton() &&
            (this._config.customButtonTextLc || this._config.customButtonText) &&
            !!this._config.customButtonCallback;
    }

    _goToScenarioManagement(): void {
        this._appControlService.showContextSelector();
        this._dialogRef.close();
    }

    // ----------------------------------------------------------------------------------
    //
    private _subscribe(): void {
        this._versionService
            .get()
            .pipe(take(1))
            .subscribe(versionInfo => {
                if (!versionInfo) {
                    return;
                }

                this._activeVersion = versionInfo.id;
                this._setVersion(versionInfo.id);
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _setVersion(id = this._searchVersionId): void {
        if (!this._scenariosDict || isNaN(id)) {
            return;
        }

        if (!this._scenariosDict[id]) {
            this._promptDialog.alert("", this._lgTranslate.translate(".VersionIsNotFound", { id }));
        } else {
            const foundScenario = this._scenariosDict[id];
            this._currentScenario = foundScenario.id;

            this._render();
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _lockVersion(event: any, scenario: number, isLocked: boolean): void {
        event.stopPropagation();

        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(
                    isLocked ? ".ConfirmVersionUnlocking" : ".ConfirmVersionLocking"
                )
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.lockVersion(scenario, !isLocked).subscribe(() => {
                    this._loadVersions();

                    if (this._config.reloadOnScenarioChange) {
                        this._reloadApplication();
                    }
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _makePrimary(event: any, scenario: number, isPrimary: boolean): void {
        event.stopPropagation();

        if (isPrimary) return;

        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(".ConfirmPrimaryVersionAssigning")
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.setVersionPrimary(scenario).subscribe(() => {
                    this._loadVersions();
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    private _loadVersions(): Promise<IGetVersionsListResponse> {
        return lastValueFrom(this._gateway.getVersionsList()).then(data => {
            if (!ldIsEmpty(data.list)) {
                this._scenariosDict = ldKeyBy(data.list, x => x.id);

                this._render();
            }
            this._ready.next(true);

            return data;
        });
    }

    // ----------------------------------------------------------------------------------
    //
    private _render(): void {
        this._fillScenarioPivot();
    }

    // ----------------------------------------------------------------------------------
    //
    _onScenarioChange(): void {
        this._fillScenarioDescription();
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillScenarioPivot(): void {
        const filteredScenarios = ldKeyBy(this._scenariosDict, x => x.id);

        this._scenarioDialogPivotNodes = [];

        for (const i in filteredScenarios) {
            if (Object.prototype.hasOwnProperty.call(filteredScenarios, i)) {
                const item: IExtendedScenario = filteredScenarios[i];

                item.data_version_full_name = this._config.getDataVersionName(item);
                item.quickSettingsMenu = this._getEllipsisMenu(item.id);
                item.allowLock = this._getAllowLock(item.id);
                item.allowPrimary = this._getAllowPrimary(item.id);

                this._scenarioDialogPivotNodes.push(item);
            }
        }

        // Fallback to first item if no current version info obtained
        if (
            (!this._currentScenario || !filteredScenarios[this._currentScenario]) &&
            this._scenarioDialogPivotNodes.length
        ) {
            this._currentScenario = this._scenarioDialogPivotNodes[0].id;
        }

        this._fillScenarioDescription();
    }

    // ----------------------------------------------------------------------------------
    //
    private _fillScenarioDescription(): void {
        if (!this._scenariosDict[this._currentScenario]) {
            return;
        }

        this._scenarioDescription = ldClone(
            this._scenariosDict[this._currentScenario].scenario_naam
        );
    }

    private _getAllowGoToScenarioManagement(): boolean {
        return typeof this._config.allowGoToScenarioManagement === "function"
            ? this._config.allowGoToScenarioManagement(this._userInfo)
            : this._config.allowGoToScenarioManagement;
    }

    private _getAllowLock(scenario: number): boolean {
        const currentScenario = this._scenariosDict[scenario];

        return typeof this._config.allowScenarioLock === "function"
            ? this._config.allowScenarioLock(currentScenario, this._userInfo)
            : this._config.allowScenarioLock;
    }

    private _getAllowPrimary(scenario: number): boolean {
        const currentScenario = this._scenariosDict[scenario];

        return typeof this._config.allowMarkAsPrimary === "function"
            ? this._config.allowMarkAsPrimary(currentScenario, this._userInfo)
            : this._config.allowMarkAsPrimary;
    }

    private _getEllipsisMenu(scenario: number): IQuickSettingsMenuItem[] {
        const currentScenario = this._scenariosDict[scenario];

        const allowDelete =
            typeof this._config.allowScenarioDelete === "function"
                ? this._config.allowScenarioDelete(currentScenario, this._userInfo)
                : this._config.allowScenarioDelete && this._userInfo.roles.admin;
        if (allowDelete) {
            return [
                {
                    type: QuickSettingsMenuType.Choice,
                    nameLC: ".Delete",
                    icon: "icon-delete",
                    onClick: () => this._deleteScenario(scenario)
                }
            ];
        } else {
            return [];
        }
    }

    private _getShowCustomButton(): boolean {
        return typeof this._config.showCustomButton === "function"
            ? this._config.showCustomButton(this._userInfo)
            : this._config.showCustomButton;
    }

    // ----------------------------------------------------------------------------------
    //
    private _getDataVersionName(version: IScenario): string {
        let output = "";

        if (this._config.showScenarioPeriodName) {
            output += this.getPeriodName(version, true) + ": ";
        }

        output += version.data_version_naam;

        return output;
    }

    // ----------------------------------------------------------------------------------
    //
    private _deleteScenario(scenario: number): void {
        const currentScenario = this._scenariosDict[scenario];

        this._promptDialog
            .confirm(
                this._lgTranslate.translate(".ConfirmationNeeded"),
                this._lgTranslate.translate(".ConfirmScenarioDeletion", {
                    scenario: currentScenario.scenario_naam
                })
            )
            .then(choice => {
                if (choice !== "ok") {
                    return;
                }

                this._gateway.deleteVersion(scenario).subscribe(() => {
                    // TODO: It's not good to reload the page right from the middle of the dialog
                    if (
                        this._activeVersion === this._currentScenario &&
                        this._config.reloadOnScenarioChange
                    ) {
                        this._reloadApplication();
                    }

                    if (!this._config.reloadOnScenarioChange) {
                        this._loadVersions().then(() => this._versionService.reload());
                    } else {
                        delete this._scenariosDict[this._currentScenario];
                        this._currentScenario = this._activeVersion;
                    }
                });
            });
    }

    // ----------------------------------------------------------------------------------
    //
    _open(): void {
        this._openScenario(this._currentScenario);
    }

    _openScenario(scenario: number): void {
        this._gateway.setVersion(scenario).subscribe(
            () => {
                this._activeVersion = scenario;
                if (this._config.reloadOnScenarioChange) {
                    this._reloadApplication();
                } else if (this._config.redirectTo !== undefined) {
                    this._versionService.reload();
                    this._redirectTo();
                } else this._versionService.reload();
                this._dialogRef.close();
            },
            data => {
                if (data.access) {
                    this._promptDialog
                        .alert(
                            this._lgTranslate.translate(".ScenarioManagement"),
                            this._lgTranslate.translate(".AccessToSelectedVersionIsDenied")
                        )
                        .then(() => {
                            if (this._config.reloadOnScenarioChange) {
                                this._reloadApplication();
                            }
                        });
                } else {
                    this._promptDialog.alert(
                        this._lgTranslate.translate(".ScenarioManagement"),
                        this._lgTranslate.translate(".ErrorOccurredTryLater")
                    );
                }
            }
        );
    }

    _customButtonClick(): void {
        const closeWindow = this._config.customButtonCallback();
        if (closeWindow) this._dialogRef.close();
    }

    // ----------------------------------------------------------------------------------
    //
    _hoverPivotLine(event: any, scenarioId: number): void {
        if (event.detail.over) {
            if (this._hoveredScenario !== scenarioId) this._hoveredScenario = scenarioId;
        } else {
            this._hoveredScenario = null;
        }
    }

    // ----------------------------------------------------------------------------------
    //
    private _reloadApplication(): void {
        window.location.reload();
    }

    private _redirectTo(): void {
        const redirectTo = this._config.redirectTo;
        this._router.navigate([redirectTo]);
    }
}

@Injectable({ providedIn: "root" })
export class LgScenarioDialogWithPivot extends getDialogFactoryBase(
    LgScenarioDialogWithPivotComponent,
    "show"
) {}
