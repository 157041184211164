import { InjectionToken, Injectable, inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class LgIconLoaderService {
    private _http = inject(HttpClient);
    private _loaded = new Map<string, Observable<void>>();
    private _holder: HTMLElement = null;
    private _document: Document;

    constructor() {
        const document = inject<any>(DOCUMENT); // any because of ng-packagr
        this._document = document as Document;
    }

    _loadAtlas(url: string): Observable<void> {
        const loaded = this._loaded.get(url);
        if (loaded) return loaded;

        const result = this._http.get(url, { responseType: "text" }).pipe(
            map(atlas => {
                this._addAtlas(atlas);
            })
        );

        this._loaded.set(url, result);

        return result;
    }

    _loadAtlasesAtStartup(urls: string[], contents: string[]): () => Promise<void> {
        return () => {
            if (contents.length !== 0) {
                contents.forEach(c => this._addAtlas(c));
            }

            if (urls.length === 0) return Promise.resolve();

            const requests = [...new Set(urls)].map(url => this._loadAtlas(url));

            return lastValueFrom(forkJoin(requests)).then(
                () => undefined,
                error => {
                    console.error(error);
                }
            );
        };
    }

    private _addAtlas(content: string): void {
        if (!this._holder) {
            this._holder = this._document.createElement("div");
            this._holder.className = "lg-icon-atlas";
            this._document.body.insertBefore(this._holder, this._document.body.firstChild);
        }

        const atlas = this._document.createElement("div");
        atlas.innerHTML = content;
        this._holder.appendChild(atlas);
    }
}

export const LG_ICON_LOADER_URL = new InjectionToken<string>("LG_ICON_LOADER_URL");
export const LG_ICON_LOADER_CONTENT = new InjectionToken<string>("LG_ICON_LOADER_CONTENT");
