<div class="lg-sidebar-account__information">
    <div class="lg-sidebar-account__information__profile">
        <div class="lg-sidebar-account__information__profile__name">
            {{ userInfo.name }}
        </div>
        <div
            class="lg-sidebar-account__information__profile__impersonatee"
            *ngIf="userInfo.impersonator != null"
        >
            {{ ".Impersonatee" | lgTranslate: { login: userInfo.impersonator } }}
        </div>
        <div *ngIf="userInfo.title != null" class="lg-sidebar-account__information__profile__title">
            {{ userInfo.title }}
        </div>
        <div class="lg-sidebar-account__information__profile__userId">
            {{ userInfo.userid }}
        </div>
        <div
            *ngIf="appConfiguration.myAccountAppUrl"
            class="lg-sidebar-account__information__hospital__button"
        >
            <div class="lg-sidebar-account__information__hospital__button__dev">
                <a [href]="appConfiguration.myAccountAppUrl" target="_blank">
                    <button type="button">{{ ".MyAccount" | lgTranslate }}</button>
                </a>
            </div>
        </div>
    </div>
    <div
        class="lg-sidebar-account__information__hospital"
        [class.lg-sidebar-account__information__hospital__no-info]="!_internal"
        *ngIf="_session.client != null"
    >
        <div class="lg-sidebar-account__information__hospital__header">
            {{ ".Hospital" | lgTranslate }}
        </div>
        <div class="lg-sidebar-account__information__hospital__button">
            <div
                *ngIf="_appControlService.canSwitchContext | async"
                class="lg-sidebar-account__information__hospital__button__dev"
            >
                <a (click)="_switchHospital()">
                    <button type="button">{{ _session.client.name }}</button>
                </a>
            </div>
            <div *ngIf="(_appControlService.canSwitchContext | async) === false">
                <span title="{{ _session.client.id }}">
                    {{ _session.client.name }}
                </span>

                <br />

                <lg-sidebar-merged-hospital-switch></lg-sidebar-merged-hospital-switch>
            </div>
        </div>
    </div>

    <div class="lg-sidebar-account__information__devMode" *ngIf="_internal">
        <div class="lg-sidebar-account__information__devMode__group">
            <div class="lg-sidebar-account__information__devMode__group__header">ID</div>

            <div class="lg-sidebar-account__information__devMode__group__text">
                {{ userInfo.userid ?? userInfo.id }}
            </div>
        </div>
        <div class="lg-sidebar-account__information__devMode__group">
            <div class="lg-sidebar-account__information__devMode__group__header">
                {{ ".HospitalId" | lgTranslate }}
            </div>

            <div class="lg-sidebar-account__information__devMode__group__text">
                {{ userInfo.ziekenhuiscode }}
            </div>
        </div>

        <div
            class="lg-sidebar-account__information__devMode__group lg-sidebar-account__information__devMode__group--logout"
        >
            <div class="lg-sidebar-account__information__devMode__group__left">
                <div class="lg-sidebar-account__information__devMode__group__header">
                    {{ ".Roles" | lgTranslate }}
                </div>

                <div class="lg-sidebar-account__information__devMode__group__text">
                    <a href="#" [lgStopPropagation]="'click'" (click)="_toggle()">{{
                        _roles.length
                    }}</a>
                </div>
            </div>
            <div class="lg-sidebar-account__information__devMode__group__right">
                <div class="lg-sidebar-account__information__devMode__group__logout">
                    <a (click)="_logout()"
                        ><lg-icon [icon]="'icon-log-out'" [inline]="true"></lg-icon
                        >{{ "FW.LOGOUT" | lgTranslate }}</a
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="lg-sidebar-account__information__devMode" *ngIf="!_internal">
        <div
            class="lg-sidebar-account__information__devMode__group lg-sidebar-account__information__devMode__group--logout"
        >
            <div class="lg-sidebar-account__information__devMode__group__left">
                <div class="lg-sidebar-account__information__devMode__group__header">
                    {{ ".HospitalId" | lgTranslate }}
                </div>

                <div class="lg-sidebar-account__information__devMode__group__text">
                    {{ userInfo.ziekenhuiscode }}
                </div>
            </div>
            <div class="lg-sidebar-account__information__devMode__group__right">
                <div class="lg-sidebar-account__information__devMode__group__logout">
                    <a (click)="_logout()"
                        ><lg-icon [icon]="'icon-log-out'" [inline]="true"></lg-icon
                        >{{ "FW.LOGOUT" | lgTranslate }}</a
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="lg-sidebar-account__information__devMode_roles" *ngIf="_showRoles">
        <ul>
            <li *ngFor="let role of _roles">{{ role }}</li>
        </ul>
    </div>
</div>

<div class="lg-sidebar-account__notifications" *ngIf="!this.hideVersionNotifications">
    <div class="lg-sidebar-account__notifications__header">
        {{ ".Notifications" | lgTranslate }}
        <div
            *ngIf="releaseNotes.isNew | async"
            class="lg-sidebar-account__notifications__header_badge"
        >
            {{ _userMessages }}
        </div>
    </div>
    <div
        class="lg-sidebar-account__notifications__change-log"
        *ngIf="releaseNotes.isNew | async"
        (click)="_showReleaseNotes()"
    >
        {{ ".Changelog" | lgTranslate: { version: releaseNotes.version } }}
    </div>
    <div
        *ngIf="(releaseNotes.isNew | async) === false"
        class="lg-sidebar-account__notifications__empty-notifications"
    >
        {{ ".EmptyNotifications" | lgTranslate }}
    </div>
</div>
