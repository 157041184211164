import ldSize from "lodash-es/size";
import ldIsPlainObject from "lodash-es/isPlainObject";
import ldIsEqual from "lodash-es/isEqual";
import ldIsArray from "lodash-es/isArray";
import { Component, Injectable, inject } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { Observable, BehaviorSubject } from "rxjs";

import * as types from "@logex/framework/types";
import * as Pivots from "@logex/framework/lg-pivot";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import type { LgFilterSet } from "../lg-filterset";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import { LgMultiFilterItemCustomization } from "@logex/framework/ui-core";
import { IFilterSorterOption } from "@logex/framework/types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IComboFilterDefinitionBase<T> extends IFilterDefinition {
    /**
     * The placeholder text inside the filter. If missing, this will be the same as name.
     */
    placeholder?: string;

    /*
     * Specifies callback which will be called whenever the filter becomes active (meaning it was empty before,
     * and now has some selections). The behaviour can be tweaked by using onBecameActiveLimit
     */
    onBecameActive?: (id: string) => void;

    /*
     * Optionally sets the maximum filter size to call onBecameActive. If the number of selected items is above the
     * limit, it won't be called - and it may be called once the size fills the limit!
     */
    onBecameActiveLimit?: number;

    /**
     * When true, we render wide combo instead of label and narrow. Defaults to true
     */
    wide?: boolean;

    /**
     * When N above zero, individual items (up to N) are shown in the collapsed state rather than just the message
     */
    showSelected?: number | null;

    /**
     * When above zero, restrict the number of individual items so that no more than N rows are used. This
     * has effect only if showSelected is non-zero
     */
    maxRows?: number | null;

    /**
     * If true, the item counts will be shown. These have to be provided in the appropriate source (or map) method
     * as the data field (number or string), and are shown only in the popup
     */
    showDataCounts?: boolean | null;

    /**
     * When true, show sorter icon with sort options witch depends on sorterOptions
     */
    showSorter?: boolean;

    /**
     * Contain sorting options
     */
    sorterOptions?: IFilterSorterOption[];

    /**
     * Allow customization of the item class or even template. Remember that if you change the standard height,
     * you must specify the item height as well
     */
    popupItemCustomization?: LgMultiFilterItemCustomization | null;
}
// Combo box renderer  definition extensions -----------------------------------------------------------------------
export interface IComboFilterDefinition<T> extends IComboFilterDefinitionBase<T> {
    filterType: "combo";

    /**
     * The function that should return the currently available selections for the filter. Can be replaced by
     * sourceDefinition/sourceDefinitionBase. If both are missing, then tries to search function
     * get[id]FilterOptions  (id without spaces, and with uppercase first letter) on the scope
     */
    source: Pivots.IGatherFilterFactoryResult | (() => Observable<types.IFilterOption[]>);

    /**
     * Optional callback to prepare the filter stater for serialization. The callback must not modify the parameter
     * - if you need to do any tranformation, use map or clone.
     * Typical use would be to change scenario-specific IDs to something more general.
     * The callback should be always accompanied by matching deserializationPostprocess
     */
    serializationPreprocess?: (state: Record<string, string>) => any;

    /**
     * Optional callback to convert a deserialized object back into the filter state. See serializationPreprocess for details
     */
    deserializationPostprocess?: (state: any) => Record<string, string>;

    renderer?: ComboFilterRenderer<T>;
}
// Combo box renderer base -----------------------------------------------------------------------------------------
/**
 * Implementes combo-box filter base.
 */
export abstract class ComboFilterRendererBase implements IFilterRenderer {
    // ----------------------------------------------------------------------------------
    // Fields
    private _becomeActiveTriggered = false;
    private readonly _previewName = new BehaviorSubject<string>(null);

    // ----------------------------------------------------------------------------------
    // Dependencies
    constructor(
        protected definition: IComboFilterDefinitionBase<any>,
        protected filters: any,
        protected logexPivot: Pivots.LogexPivotService
    ) {
        if (!this.definition.placeholder) {
            this.definition.placeholder = this.definition.name;
        }
        if (this.definition.wide == null) this.definition.wide = true;
    }

    createStorage(): void {
        if (this.filters[this.definition.storage] == null) {
            this.filters[this.definition.storage] = { $empty: true };
            this._updatePreviewName();
        }
    }

    active(): boolean {
        return !this.filters[this.definition.storage].$empty;
    }

    previewVisible(): boolean {
        return !this.filters[this.definition.storage].$empty;
    }

    clear(): boolean {
        this._becomeActiveTriggered = false;
        if (!this.filters[this.definition.storage].$empty) {
            this.filters[this.definition.storage] = { $empty: true };
            this._updatePreviewName();
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return ComboFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return ComboFilterRendererPopupComponent;
    }

    getPreviewName(): Observable<string> {
        this._updatePreviewName();
        return this._previewName.asObservable();
    }

    protected _updatePreviewName(): void {
        const value = this.filters[this.definition.storage];
        if (!value || value.$empty) {
            this._previewName.next(null);
            return;
        }

        // Do not use _.key for performance reasons
        let name = null;
        let count = 0;
        for (const key in value) {
            if (!Object.prototype.hasOwnProperty.call(value, key)) continue;
            if (count === 0) name = value[key];
            ++count;
        }
        this._previewName.next(count === 1 ? name : `${this.definition.name} (${count})`);
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this.definition.name,
            filter: this.filters[this.definition.storage]
        };
    }

    onChanged(): void {
        this._updatePreviewName();
        if (this.definition.onBecameActive) {
            const filter = this.filters[this.definition.storage];
            if (filter.$empty) {
                this._becomeActiveTriggered = false;
            } else if (!this._becomeActiveTriggered) {
                if (
                    (this.definition.onBecameActiveLimit || 0) <= 0 ||
                    ldSize(filter) <= this.definition.onBecameActiveLimit
                ) {
                    this._becomeActiveTriggered = true;
                    this.definition.onBecameActive(this.definition.id);
                }
            }
        }
    }

    abstract serialize(): string;

    abstract deserialize(state: string): boolean;

    abstract source: () =>
        | types.IFilterOption[]
        | Promise<types.IFilterOption[]>
        | Observable<types.IFilterOption[]>;
}

// Combo box renderer ----------------------------------------------------------------------------------------------
/**
 * Implementes combo-box filter. See ICombioFilterDefinition for the list of additional options
 */
export class ComboFilterRenderer<T> extends ComboFilterRendererBase implements IFilterRenderer {
    // ----------------------------------------------------------------------------------
    // Dependencies

    constructor(
        protected override definition: IComboFilterDefinition<any>,
        filters: any,
        logexPivot: Pivots.LogexPivotService
    ) {
        super(definition, filters, logexPivot);
        if (!this.definition.source) {
            throw Error(`Source definition for filter "${this.definition.id}" is not found.`);
        }
    }

    serialize(): string {
        if (!this.active()) return null;
        const state = this.filters[this.definition.storage];
        if (this.definition.serializationPreprocess) {
            return JSON.stringify(this.definition.serializationPreprocess(state));
        } else {
            return JSON.stringify(state);
        }
    }

    deserialize(state: string): boolean {
        let newState = JSON.parse(state);
        if (this.definition.deserializationPostprocess) {
            newState = this.definition.deserializationPostprocess(newState);
        }
        if (!ldIsPlainObject(newState)) {
            newState = {};
        }
        if (ldSize(newState) === 0) {
            newState.$empty = true;
        }
        if (!ldIsEqual(this.filters[this.definition.storage], newState)) {
            this.filters[this.definition.storage] = newState;
            this._updatePreviewName();
            return true;
        }
        return false;
    }

    source = (): types.IFilterOption[] | Observable<types.IFilterOption[]> =>
        this.definition.source();

    toggleItem(id: T, name?: string): void {
        const state = this.filters[this.definition.storage];
        if (state[id]) {
            delete state[id];
            if (ldSize(state) === 0) state.$empty = true;
        } else {
            if (name == null) {
                const source = this.definition.source();
                if (ldIsArray(source)) {
                    name = (source as types.IFilterOption[]).find(s => s.id === (id as any)).name;
                } else {
                    console.error(
                        "Filter definition with observable source() not supported; specify name"
                    );
                    name = "?";
                }
            }
            state[id] = name;
            delete state.$empty;
        }
        this.onChanged();
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class ComboFilterRendererFactory implements IFilterRendererFactory {
    private _pivotService = inject(Pivots.LogexPivotService);

    readonly name: string = "combo";

    create(
        definition: IComboFilterDefinition<any>,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        _filterSet: LgFilterSet
    ): IFilterRenderer {
        return new ComboFilterRenderer(definition, filters, this._pivotService);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-combo-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <ng-container *ngIf="!_definition.wide">
            <label class="filter__label">{{ _definition.label }}:</label>
            <div class="filter__control">
                <lg-multi-filter
                    placeholder="{{ _definition.placeholder }}"
                    [source]="_renderer.source"
                    [(filter)]="_filters[_definition.storage]"
                    (filterChange)="_onChanged()"
                    [showSelected]="_definition.showSelected || 0"
                    [maxRows]="_definition.maxRows || 0"
                    [showDataCounts]="_definition.showDataCounts || false"
                    [popupItemCustomization]="_definition.popupItemCustomization"
                    [tooltip]="_definition.tooltip"
                    [showSorter]="_definition.showSorter"
                    [sorterOptions]="_definition.sorterOptions"
                ></lg-multi-filter>
            </div>
        </ng-container>
        <ng-container *ngIf="_definition.wide">
            <div class="filter__control filter__control--wide">
                <lg-multi-filter
                    placeholder="{{ _definition.placeholder }}"
                    [source]="_renderer.source"
                    [(filter)]="_filters[_definition.storage]"
                    (filterChange)="_onChanged()"
                    [wide]="true"
                    [wideLabel]="_definition.label"
                    [showSelected]="_definition.showSelected || 0"
                    [maxRows]="_definition.maxRows || 0"
                    [showDataCounts]="_definition.showDataCounts || false"
                    [popupItemCustomization]="_definition.popupItemCustomization"
                    [tooltip]="_definition.tooltip"
                    [showSorter]="_definition.showSorter"
                    [sorterOptions]="_definition.sorterOptions"
                ></lg-multi-filter>
            </div>
        </ng-container>
    `
})
export class ComboFilterRendererLineComponent extends FilterRendererComponentBase<
    IComboFilterDefinition<any>,
    ComboFilterRenderer<any>
> {
    _onChanged(): void {
        this._renderer.onChanged();
        this._triggerChange();
    }
}

// Popup template  ---------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-combo-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    styles: [
        `
            :host {
                width: 250px;
                display: block;
            }
        `
    ],
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-filter-preview__popup__header" *ngIf="!_definition.wide">
            {{ "FW._Directives.ComboFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.ComboFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-multi-filter
            placeholder="{{ _definition.placeholder }}"
            [showOnInit]="true"
            [source]="_renderer.source"
            [wide]="_definition.wide || false"
            [wideLabel]="_definition.label"
            [showSelected]="_definition.showSelected || 0"
            [maxRows]="_definition.maxRows || 0"
            [showDataCounts]="_definition.showDataCounts || false"
            [popupItemCustomization]="_definition.popupItemCustomization"
            [(filter)]="_filters[_definition.storage]"
            (filterChange)="_onChanged()"
        ></lg-multi-filter>
    `
})
export class ComboFilterRendererPopupComponent extends FilterRendererComponentBase<
    IComboFilterDefinition<any>,
    ComboFilterRenderer<any>
> {
    _onChanged(): void {
        this._renderer.onChanged();
        this._triggerChange();
    }
}
