import ldOrderBy from "lodash-es/orderBy";

import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { LgSlideoutService } from "../lg-slideout/lg-slideout.service";
import { LgSlideoutApi, LgSlideoutPanel } from "../lg-slideout/lg-slideout.types";

@Component({
    selector: "lg-slideout-holder-header",
    templateUrl: "./lg-slideout-holder-header.component.html"
})
export class LgSlideoutHolderHeaderComponent implements OnInit, OnDestroy {
    protected _slideoutService = inject(LgSlideoutService);

    protected pinned = false;
    protected iconsTemplate: TemplateRef<any> | undefined;
    protected tabButtonTemplate: TemplateRef<any> | undefined;
    protected _availablePanels: LgSlideoutPanel[] = [];
    private _destroyed$ = new Subject<void>();

    /**
     * Slideout API service
     */
    @Input("slideoutApi") _slideoutApi: LgSlideoutApi | null = null;

    /**
     * Emits on pin toggle
     */
    @Output("pinToggled") readonly pinToggled = new EventEmitter<void>();

    ngOnInit(): void {
        if (this._slideoutApi == null) return;
        this._slideoutApi.state$.pipe(takeUntil(this._destroyed$)).subscribe(state => {
            this.pinned = state.pinned;
        });
        this._slideoutService.availablePanelsState$.subscribe(panels => {
            this.iconsTemplate =
                panels[this._slideoutService.currentPanelVariant]?.headerIconsTemplate;
            this.tabButtonTemplate =
                panels[this._slideoutService.currentPanelVariant]?.headerTabButtonTemplate;
            this._availablePanels = ldOrderBy(Object.values(panels || {}), x => x.order ?? 0);
        });
    }

    _changePanel(panel: LgSlideoutPanel): void {
        if (panel.isActive) {
            this._hide(panel.panelVariant);
        }
        this._slideoutService.setActivePanel(panel.panelVariant);
        this._slideoutApi.setTemplate(panel.panelTemplate);
    }

    _hide(panelVariant?: string): void {
        this._slideoutService.setActivePanel(panelVariant);
        this._slideoutApi.toggleExpanded(false);
    }

    _togglePin(): void {
        this._slideoutApi.togglePinned();
    }

    _keepKeyOrder(): number {
        return 0;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
