import ldLast from "lodash-es/last";

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    forwardRef,
    Input,
    isDevMode,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { auditTime, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import * as Pivots from "@logex/framework/lg-pivot";
import {
    ScrollbarApi,
    LgMeasurementsService,
    LgObserveSizeService,
    LgScrollbarService
} from "@logex/framework/ui-core";
import { scheduleChangeDetection, toBoolean, toInteger } from "@logex/framework/utilities";
import { LgPrimitive } from "@logex/framework/types";

import {
    IBodyRegister,
    IEmptyRowTemplate,
    IItemExpander,
    ILevelExpander,
    IRowFooterTemplate,
    IRowSeparatorTemplate,
    IRowTemplate,
    ItemsMap,
    IToggleEvent,
    IToggleLevelEvent,
    LgPivotTableBodyRegister,
    LgPivotTableItemExpander,
    LgPivotTableLevelExpander,
    RowSeparatorType,
    RowType,
    VerticalPosition
} from "./types";
import { IRowTemplateBase, RowTemplateCollection } from "./helpers/row-template-collection";
import { RowContext } from "./helpers/row-context";
import { RowIndexStore } from "./helpers/row-index-store";

import { LgPivotTableRowDefDirective } from "./templates/lg-pivot-table-row-def.directive";
import { LgPivotTableRowSeparatorDefDirective } from "./templates/lg-pivot-table-row-separator-def.directive";
import { LgPivotTableBodyContainerComponent } from "./helpers/lg-pivot-table-body-container.component";
import { LgPivotTableRowDirective } from "./templates/lg-pivot-table-row.directive";
import { EmptyRowContext } from "./helpers/empty-row-context";

interface IFlatListParentLink {
    data: any;
    parentLink: IFlatListParentLink;
}

interface IFlatListEntry {
    entry: any;
    definition: Pivots.INormalizedLogexPivotDefinition;
    level: number;
    parentLink: IFlatListParentLink;
    index: number;
    globalIndex: number;
    last: boolean;
    type: RowType;
    offset: number;
    prevLevel: number;
    nextLevel: number;
}

const BASE_ITEM_HEIGHT = 28;
const HIGHER_ITEM_HEIGHT = 36;

@Component({
    selector: "lg-pivot-table-body",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="lg-pivot-table__body__inner {{ bodyClass }}"
            #bodyInner
            (scroll)="_onBodyScroll()"
            [class.lg-pivot-table__body__inner--no-scrollbar]="!_hasScrollbar"
            lgDetachedPreserveScroll
        >
            <div
                class="table__row table__row--empty"
                *ngIf="_flatList.length === 0 && !_emptyRowTemplate && !_noDataAvailable"
            >
                {{ "FW._Common.Current_filters_returned_no_results_part1" | lgTranslate }}
                &nbsp;<a href="#" (click)="_clearAllFiltersRequest()">{{
                    "FW._Common.Current_filters_returned_no_results_part2" | lgTranslate
                }}</a
                >.
            </div>
            <ng-container *ngIf="_flatList.length === 0 && !!_emptyRowTemplate">
                <ng-container
                    *ngTemplateOutlet="_emptyRowTemplate.template; context: _emptyRowContext"
                ></ng-container>
            </ng-container>
            <!--<lg-link-template linker="ctl.emptyRowTemplate" source-scope="ctl.footerHeaderScope" ng-if="ctl.flatList.length==0 && ctl.emptyRowTemplateDefined"></lg-link-template>-->
            <div class="lg-pivot-table__body__holder" #bodyHolder>
                <lg-pivot-table-body-container #bodyContainer></lg-pivot-table-body-container>
            </div>
            <ng-template let-level="level" #defaultRowTemplate>
                <div class="table__row">
                    <b>Missing pivot template level {{ level }}</b>
                </div>
            </ng-template>
            <ng-template let-level="level" #defaultRowSeparatorTemplate>
                <div class="table__row table__row--thinner-separator">&nbsp;</div>
            </ng-template>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-pivot-table__body",
        "[class.lg-pivot-table__body--flexbox]": "_asFlexbox",
        "[class.lg-pivot-table__body--higher-rows]": "higherRows"
    },
    providers: [
        {
            provide: LgPivotTableBodyRegister,
            useExisting: forwardRef(() => LgPivotTableBodyComponent)
        },
        {
            provide: LgPivotTableLevelExpander,
            useExisting: forwardRef(() => LgPivotTableBodyComponent)
        },
        {
            provide: LgPivotTableItemExpander,
            useExisting: forwardRef(() => LgPivotTableBodyComponent)
        }
    ]
})
export class LgPivotTableBodyComponent
    implements OnInit, OnDestroy, DoCheck, IBodyRegister, ILevelExpander, IItemExpander
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _logexPivot = inject(Pivots.LogexPivotService);
    private _measurements = inject(LgMeasurementsService);
    private _observerService = inject(LgObserveSizeService);
    private _renderer = inject(Renderer2);
    private _scrollbarService = inject(LgScrollbarService);
    private _zone = inject(NgZone);

    // ---------------------------------------------------------------------------------------------
    /**
     * Normalized definition of the first level of the pivot shown in the table below (required).
     */
    @Input({ required: true }) set definition(value: Pivots.INormalizedLogexPivotDefinition) {
        this._definition = value;
        this._itemNames = [];
        if (value) {
            let level = 0;
            while (value) {
                if (value.levelId) this._itemNames[level] = value.levelId;
                ++level;
                value = value.children;
            }
        }
        this._forceFullRender();
    }

    get definition(): Pivots.INormalizedLogexPivotDefinition {
        return this._definition;
    }

    /**
     * Nodes of the top level of the filtered tree (required).
     */
    @Input({ required: true }) set filteredData(value: any[]) {
        this._filteredData = value;

        if (this._preserveFirstVisibleRow) {
            this._saveFirstRowPosition();
        }

        this.render();
    }

    get filteredData(): any[] {
        return this._filteredData;
    }

    /**
     * Inform the body that there is no source data available, so the usual "remove filter" message shouldn't be shown.
     */
    @Input("noDataAvailable") set noData(value: boolean | "true" | "false") {
        this._noDataAvailable = toBoolean(value);
    }

    get noData(): boolean {
        return this._noDataAvailable;
    }

    @Input() pivotContext: any = null;

    /**
     * Default height of the regular rows rendered (in pixels). This can be overridden by row template definitions.
     *
     * @default 28 - if not specified and not overridden by `higherRows`
     */
    @Input() set itemHeight(value: number) {
        this._itemHeight = toInteger(value, BASE_ITEM_HEIGHT, 1, null);
        this._itemHeightSpecified = !!value;
        this.render();
    }

    get itemHeight(): number {
        return this._itemHeight;
    }

    /**
     * Sets css class for table body to have more spacious rows with height of 36px.
     * Note that the buffer/visual-buffer settings are specified "in rows", item-height is preset to 36 as well if you do not override it.
     * Typically this and the `itemHeight` option would be exclusive, unless you override also the css styles used.
     */
    @Input() set higherRows(value: boolean | "true" | "false") {
        this._higherRows = toBoolean(value, false);
        if (!this._itemHeightSpecified) {
            this._itemHeight = this._higherRows ? HIGHER_ITEM_HEIGHT : BASE_ITEM_HEIGHT;
        }
        this.render();
    }

    get higherRows(): boolean {
        return this._higherRows;
    }

    /**
     * Height of rendered separator (in pixels)
     *
     * @default 16
     */
    @Input() set separatorHeight(value: number | string) {
        this._separatorHeight = toInteger(value, 16, 1, null);
        this.render();
    }

    get separatorHeight(): number {
        return this._separatorHeight;
    }

    /**
     * Count of extra rows to be rendered.
     *
     * @default 2
     */
    @Input() set renderExtraRows(value: number | string) {
        this._renderExtraRows = toInteger(value);
    }

    get renderExtraRows(): number {
        return this._renderExtraRows;
    }

    /**
     * Number of rows that must always exist outside the view. This should be at least 1 if tabbing across rows is needed.
     *
     * @default 1
     */
    @Input() set ensureRowsOutsideView(value: number | string) {
        this._ensureRowsOutsideView = toInteger(value);
    }

    get ensureRowsOutsideView(): number {
        return this._ensureRowsOutsideView;
    }

    // ---------------------------------------------------------------------------------------------
    @Output() readonly clearAllFilters = new EventEmitter<void>();

    // ---------------------------------------------------------------------------------------------
    @Output() readonly maxVisibleLevelChange = new EventEmitter<number>(true);

    get maxVisibleLevel(): number {
        return this._maxVisibleLevel;
    }

    /**
     * Adds css class to table body.
     */
    @Input() bodyClass: string | null = null;

    /**
     * Specifies if first visible row need to be preserved or not.
     * This is used for drilldown tables, where we want to return to the original scroll position upon drill-up.
     *
     * @default false
     */
    @Input() set preserveFirstVisibleRow(value: boolean | "true" | "false") {
        this._preserveFirstVisibleRow = toBoolean(value);
    }

    get preserveFirstVisibleRow(): boolean {
        return this._preserveFirstVisibleRow;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Event triggered when individual node is expanded/collapsed (called after re-render).
     * Note that this is not called when the whole level is expanded/collapsed!
     */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("toggle") readonly onToggle = new EventEmitter<IToggleEvent>();

    /**
     * Event triggered when the whole level is expanded/collapsed (called after re-render)
     */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("toggleLevel") readonly onToggleLevel = new EventEmitter<IToggleLevelEvent>();

    // ---------------------------------------------------------------------------------------------
    render(): void {
        if (this._renderPending) return;

        this._renderPending = true;
        this._changeDetectorRef.markForCheck();
        scheduleChangeDetection();
    }

    // ---------------------------------------------------------------------------------------------
    _clearAllFiltersRequest(): boolean {
        this.clearAllFilters.next();
        return false;
    }

    // ---------------------------------------------------------------------------------------------
    toggleExpand(item: any, state?: boolean): void {
        this._toggleExpand(item, state);
        this._listAssign();
        this._itemToggleChanged(item);
    }

    // ---------------------------------------------------------------------------------------------
    toggleLevel(level: number, expanded?: boolean): void {
        if (!this.filteredData) return;

        if (expanded == null) expanded = this._maxVisibleLevel <= level;
        expanded = !!expanded;

        this._logexPivot.eachNodeAtLevelFiltered(
            this._definition,
            this._filteredData,
            level,
            e => (e.$expanded = expanded),
            this.pivotContext,
            e => e.$expanded
        );

        this._listAssign();
        this.onToggleLevel.next({
            level,
            expanded: true,
            maxVisibleLevel: this._maxVisibleLevel
        });
    }

    @ViewChild("defaultRowTemplate", { read: TemplateRef, static: true })
    _defaultRowTemplate: TemplateRef<RowContext<any, any, any>>;

    @ViewChild("defaultRowSeparatorTemplate", { read: TemplateRef, static: true })
    _defaultRowSeparatorTemplate: TemplateRef<RowContext<any, any, any>>;

    @ViewChild("bodyContainer", { read: LgPivotTableBodyContainerComponent, static: true })
    _bodyContainer: LgPivotTableBodyContainerComponent;

    @ViewChild("bodyInner", { static: true }) _innerElement: ElementRef;
    @ViewChild("bodyHolder", { static: true }) _bodyHolder: ElementRef;

    rowTemplates: RowTemplateCollection<IRowTemplate>;
    rowSeparatorTemplates: RowTemplateCollection<IRowSeparatorTemplate>;
    rowFooterTemplates: RowTemplateCollection<IRowSeparatorTemplate>;
    _emptyRowTemplate: IEmptyRowTemplate;
    _emptyRowContext: EmptyRowContext;
    _asFlexbox = false;
    _hasScrollbar = true;
    _noDataAvailable = false;

    private _definition: Pivots.INormalizedLogexPivotDefinition;
    private _filteredData: any[];
    private _itemHeight = BASE_ITEM_HEIGHT;
    private _itemHeightSpecified = false;
    private _higherRows = false;
    private _separatorHeight = 16;
    private _renderExtraRows = 2;
    private _ensureRowsOutsideView = 1;
    private _totalHeight: number;
    private _variedHeight = false;
    private _maxVisibleLevel = 0;
    private _allExpanded: boolean[];
    _flatList: IFlatListEntry[] = [];
    private _scrollerHeight: number | null = null;
    private _scrollOffset = 0;
    private _scrollOffsetSaved: number | null = null;
    private _lastHeight = 0;
    private _minVisiblePx: number;
    private _maxVisiblePx: number;
    private _renderedRowIndices = new RowIndexStore();
    private _itemNames: string[] = [];
    private _renderPending = false;
    private _scrollbar: ScrollbarApi;
    private _preserveFirstVisibleRow = false;
    private readonly _hover = new BehaviorSubject<any>(null);
    private readonly _hoverLevel = new BehaviorSubject<number | null>(null);

    private _destroyed$ = new Subject<void>();

    // ---------------------------------------------------------------------------------------------
    constructor() {
        const levelMapper = (level: number | string): number => {
            // eslint-disable-next-line eqeqeq
            if (level == +level) {
                return +level;
            } else {
                const definition = this.definition.$levels[level];
                return definition ? definition.$levelIndex - this.definition.$levelIndex : 0;
            }
        };

        this.rowTemplates = new RowTemplateCollection<IRowTemplate>(
            levelMapper,
            () => this._templatesChanged(),
            (level: number) => {
                const template = new LgPivotTableRowDefDirective(this, this._defaultRowTemplate);
                template.level = level;
                return template;
            }
        );

        this.rowSeparatorTemplates = new RowTemplateCollection<IRowSeparatorTemplate>(
            levelMapper,
            () => this._templatesChanged(),
            (level: number) => {
                const template = new LgPivotTableRowSeparatorDefDirective(
                    this,
                    this._defaultRowSeparatorTemplate
                );
                template.level = level;
                return template;
            }
        );

        this.rowFooterTemplates = new RowTemplateCollection<IRowFooterTemplate>(
            levelMapper,
            () => this._templatesChanged(),
            null
        );

        this._emptyRowContext = new EmptyRowContext(() => this._clearAllFiltersRequest());
    }

    // ---------------------------------------------------------------------------------------------
    ngOnInit(): void {
        this._scrollbar = this._scrollbarService.createVertical(this._elementRef, { auto: true });

        this._scrollbar
            .onScroll()
            .pipe(takeUntil(this._destroyed$), auditTime(40))
            .subscribe(event => this._zone.run(() => this._onScroll(event.position)));

        this._renderer.setStyle(
            this._innerElement.nativeElement,
            "marginRight",
            -this._measurements.scrollbarWidth() + "px"
        );

        // this._renderer.setStyle( this._innerElement.nativeElement, "maxHeight", this._elementRef.nativeElement.style.maxHeight );
        this._asFlexbox = true;
        this._observerService
            .observe(this._elementRef, this._renderer)
            .change({ type: "height", auditTime: 0 })
            .pipe(takeUntil(this._destroyed$))
            .subscribe(ev => {
                this._onSizeObserved(ev.height);
            });
    }

    // ---------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._scrollbar.destroy();
    }

    // ---------------------------------------------------------------------------------------------
    private _templatesChanged(): void {
        this._forceFullRender();
    }

    // ---------------------------------------------------------------------------------------------
    ngDoCheck(): void {
        if (this._renderPending) {
            this._listAssign();
            this._renderPending = false;
        }
    }

    // ---------------------------------------------------------------------------------------------
    getAllExpanded(level: number): boolean {
        return this._allExpanded && !!this._allExpanded[level];
    }

    // ---------------------------------------------------------------------------------------------
    _renderAll(): void {
        this._forceFullRender();
        /*
        this.visibleList = [];
        // update the total height
        this.bodyHolder.css( { height: 0 } );
        this.lastHeight = 0;
        this.scrollbar.setSizes( 0, this.scrollerHeight );
        this.minVisible = 0;
        this.maxVisible = 0;
        */
    }

    // ---------------------------------------------------------------------------------------------
    addEmptyRowTemplate(template: IEmptyRowTemplate): void {
        this._emptyRowTemplate = template;
    }

    // ---------------------------------------------------------------------------------------------
    removeEmptyRowTemplate(template: IEmptyRowTemplate): void {
        if (this._emptyRowTemplate === template) {
            this._emptyRowTemplate = null;
        }
    }

    // ---------------------------------------------------------------------------------------------
    _notifyHover(element: any, level: number, hover: boolean): void {
        this._hover.next(hover ? element : null);
        this._hoverLevel.next(hover ? level : null);
    }

    // ---------------------------------------------------------------------------------------------
    _hoverObservable(): Observable<any> {
        return this._hover.asObservable();
    }

    // ---------------------------------------------------------------------------------------------
    _hoverLevelObservable(): Observable<number | null> {
        return this._hoverLevel.asObservable();
    }

    /**
     * Make table row visible on the screen.
     *
     * @param position
     * @param ids - IDs that will lead crawler of the pivot tree to the node
     * @param autoFocus - `false`/`undefined` means code won't try to focus any input,
     *   `true` means try to focus first input,
     *   `number` means focus n-th input (0 === first)
     * @internal _autoExpand
     */
    ensureVisible(
        position: VerticalPosition = "top",
        ids?: LgPrimitive[],
        autoFocus: boolean | number = false,
        _autoExpand?: boolean
    ): void {
        const inputIndex = typeof autoFocus === "number" ? autoFocus : 0;

        if (!ids || !ids.length) {
            this._scrollOffsetSaved = this._calculateOffsetForVisibility(position);
            this._calculateScrollerSize();
            if (autoFocus != null && autoFocus !== false) {
                this._focusRowInput(0, inputIndex);
            }
            return;
        }

        const nodesOnThePath = this._getNodesOnThePath(ids);

        // not a valid set of IDs, dev notified under `_getNodesOnThePath`
        if (!nodesOnThePath.length) return;

        // last node doesn't need to be expanded to be visible
        const notExpandedNodes = nodesOnThePath.filter(
            (n, i, all) => i !== all.length - 1 && !n.$expanded
        );

        if (notExpandedNodes.length && !_autoExpand) {
            console.warn(
                `Not all nodes are expanded for IDs ([${ids}]) and \`ensureVisible\` was called with \`autoExpand\` === false`
            );
            return;
        }

        if (notExpandedNodes.length) {
            notExpandedNodes.forEach(n => {
                this._toggleExpand(n, true);
                this._itemToggleChanged(n);
            });
            this._listAssign();
        }

        const flatListEntry = this._flatList.find(i => i.entry === ldLast(nodesOnThePath));

        this._calculateScrollerSize(this._calculateOffsetForVisibility(position, flatListEntry));
        this._onScroll(this._scrollbar.position());

        if (autoFocus != null && autoFocus !== false) {
            if (document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            this._focusRowInput(flatListEntry.globalIndex, inputIndex);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Flatten the pivot (calculate list of all possibly visible rows)
    // ---------------------------------------------------------------------------------------------
    private _listAssign(): void {
        if (!this.filteredData || !this.definition) {
            this._totalHeight = 0;
            this._variedHeight = false;
            this._flatList = [];
            this._maxVisibleLevel = 0;
            this._allExpanded = [];
            this._renderFlatList();
            return;
        }

        const flatList: IFlatListEntry[] = [];
        const someCollapsed: boolean[] = [];
        let maxVisibleLevel = 0;
        let offset = 0;
        let variedHeight = false;
        const itemHeight = this._itemHeight;
        const separatorHeight = this._separatorHeight;

        const createItem = (
            entry: any,
            type: RowType,
            definition: Pivots.INormalizedLogexPivotDefinition,
            level: number,
            parentLink: IFlatListParentLink,
            rowIndex: number
        ): IFlatListEntry => {
            return <IFlatListEntry>{
                entry,
                level,
                definition,
                parentLink,
                index: rowIndex,
                globalIndex: flatList.length,
                last: false,
                type,
                offset,
                prevLevel: null,
                nextLevel: null
            };
        };

        const assignImpl = (
            definition: Pivots.INormalizedLogexPivotDefinition,
            data: any[],
            level: number,
            parentLink: IFlatListParentLink,
            items: ItemsMap<any>
        ): void => {
            let rowIndex = 0;
            const deeper = definition.children && !definition.children.hidden;
            const store = definition.children && definition.children.filteredStore;
            let collapsed = false;
            let lastRowItem: IFlatListEntry = null;
            let lastSeparatorItem: IFlatListEntry = null;
            let lastRowFooterItem: IFlatListEntry = null;
            let anyVisible = false;
            let lastIsSeparator = false;
            const levelId = definition.levelId;

            const rowTemplate = this.rowTemplates.get(level);

            const levelSeparatorType = rowTemplate.getSeparatorType(
                items,
                this.rowSeparatorTemplates.contains(level)
            );

            for (let i = 0, length = data.length; i < length; ++i) {
                const el = data[i];
                items.set(level, el);
                if (levelId) items.set(levelId, el);

                const visible = rowTemplate.getVisible(el, items);
                const goDeeper = deeper && (el.$expanded || !visible);
                let height: number;

                if (visible) {
                    height = rowTemplate.getHeight(el, items, itemHeight);
                    variedHeight = variedHeight || height !== itemHeight;
                    lastRowItem = createItem(
                        el,
                        RowType.Row,
                        definition,
                        level,
                        parentLink,
                        rowIndex
                    );
                    flatList.push(lastRowItem);
                    anyVisible = true;
                    offset += height;
                }

                if (goDeeper) {
                    assignImpl(
                        definition.children,
                        el[store],
                        level + 1,
                        { data: el, parentLink },
                        items
                    );
                } else {
                    collapsed = deeper;
                }

                if (visible) {
                    const rowFooterTemplate = this.rowFooterTemplates.get(level);
                    if (rowFooterTemplate) {
                        if (rowFooterTemplate.getVisible(el, items)) {
                            height = rowFooterTemplate.getHeight(el, items, itemHeight);
                            variedHeight = variedHeight || height !== itemHeight;
                            lastRowFooterItem = createItem(
                                el,
                                RowType.RowFooter,
                                definition,
                                level,
                                parentLink,
                                rowIndex
                            );
                            flatList.push(lastRowFooterItem);
                            offset += height;
                        }
                    }
                }

                if (visible && levelSeparatorType !== RowSeparatorType.None) {
                    const rowSeparatorTemplate = this.rowSeparatorTemplates.get(level);
                    if (rowSeparatorTemplate.getVisible(el, items)) {
                        height = rowSeparatorTemplate.getHeight(el, items, separatorHeight);
                        variedHeight = variedHeight || height !== itemHeight;
                        lastSeparatorItem = createItem(
                            el,
                            RowType.Separator,
                            definition,
                            level,
                            parentLink,
                            rowIndex
                        );
                        flatList.push(lastSeparatorItem);
                        offset += height;
                        lastIsSeparator = true;
                    }
                }
                if (visible) {
                    ++rowIndex;
                }
            }

            // if not always, remove the last separator. Note this cannot be evaluated at creation time, because (thanks to visibility)
            // we don't know, which separator will be the last
            if (lastIsSeparator && levelSeparatorType !== RowSeparatorType.Always) {
                offset = flatList[flatList.length - 1].offset;
                flatList.pop();
                lastSeparatorItem = null;
            }

            if (anyVisible && level > maxVisibleLevel) maxVisibleLevel = level;

            if (lastRowItem) lastRowItem.last = true;
            if (lastRowFooterItem) lastRowFooterItem.last = true;
            if (lastSeparatorItem) lastSeparatorItem.last = true;

            someCollapsed[level] = someCollapsed[level] || collapsed;

            items.delete(level);
            if (levelId) items.delete(levelId);
        };

        assignImpl(this.definition, this.filteredData, 0, null, new Map());

        /* Were considering to never allow separator as the last row, but for now decided to respect the template settings
        if (flatList.length && flatList[flatList.length - 1].type == "separator") {
            offset = flatList[flatList.length - 1].offset;
            flatList.pop();
        }
        */

        const l = flatList.length - 1;
        for (let i = 0; i <= l; ++i) {
            const listEntry = flatList[i];
            if (i > 0) {
                listEntry.prevLevel = flatList[i - 1].level;
            } else {
                listEntry.prevLevel = -1;
            }
            const next = i + 1;
            if (next <= l) {
                listEntry.nextLevel = flatList[next].level;
            } else {
                listEntry.nextLevel = -1;
            }
        }

        this._totalHeight = offset;
        this._variedHeight = variedHeight;

        const allExpanded: boolean[] = [];
        for (let i = 0; i <= maxVisibleLevel; ++i) {
            allExpanded[i] = !someCollapsed[i];
        }

        this._flatList = flatList;
        this._maxVisibleLevel = maxVisibleLevel;
        this._allExpanded = allExpanded;
        this._renderFlatList();
    }

    private _renderFlatList(): void {
        this._update();
        this.maxVisibleLevelChange.next(this._maxVisibleLevel);
    }

    private _forceFullRender(): void {
        if (this._renderedRowIndices && this._renderedRowIndices.size) {
            this._renderedRowIndices = new RowIndexStore();
        }

        this.render();
    }

    // ---------------------------------------------------------------------------------------------
    //  Update (re-render) the table
    // ---------------------------------------------------------------------------------------------
    private _update(): void {
        if (!this._itemHeight || !this._flatList) return;

        // const t1 = Date.now();
        const bufferPx =
            Math.max(this._renderExtraRows || 1, this._ensureRowsOutsideView) * this._itemHeight;
        if (this._scrollerHeight === null) {
            this._calculateScrollerSize();
        }

        // Find the first visible item, and the end of visible range
        let firstIndex: number;
        if (this._variedHeight) {
            firstIndex = this._findFirstVisibleRow(this._scrollOffset - bufferPx);
        } else {
            // no special heights, just calculate the first visible row directly
            firstIndex = Math.max(
                0,
                Math.floor((this._scrollOffset - bufferPx) / this._itemHeight)
            );
        }

        const endOffset =
            this._scrollerHeight <= 0 ? -1 : this._scrollOffset + bufferPx + this._scrollerHeight;
        this._maxVisiblePx = 0;
        const newRowIndices = new RowIndexStore();
        // go through the visible range, mark them as visible and render when needed
        let lastVisibleViewIndex = -1;
        // console.error( this._scrollerHeight, this._flatList.length, this._scrollOffset, firstIndex );
        for (
            let i = firstIndex;
            i < this._flatList.length && this._flatList[i].offset <= endOffset;
            ++i
        ) {
            const row = this._flatList[i];
            const lastIndex = this._renderedRowIndices.getRowIndex(row.entry, row.type);
            const viewIndex = i - firstIndex;

            if (lastIndex !== undefined) {
                const viewRef = <EmbeddedViewRef<RowContext<any, any, any>>>(
                    this._bodyContainer._viewContainerRef.get(lastIndex)
                );
                const context = viewRef.context;
                context.index = row.index;
                context.globalIndex = row.globalIndex;
                context.last = row.last;
                context.prevLevel = row.prevLevel;
                context.nextLevel = row.nextLevel;
                context.deeper =
                    row.definition && row.definition.children && !row.definition.children.hidden;
                context.parent = row.level ? row.parentLink.data : null;
                const newItems: Record<string, any> = {};
                const levelAsString = "" + row.level;
                newItems[levelAsString] = row.entry;
                let itemsChanged = newItems[levelAsString] !== context.items[levelAsString];
                if (row.definition.levelId) newItems[row.definition.levelId] = row.entry;
                if (row.level) {
                    // If this is not top level item, link all the parents under their proper names (if defined)
                    let j = row.level - 1;
                    let currentParent = row.parentLink;
                    while (j >= 0) {
                        const itemName = this._itemNames[j];
                        if (itemName) {
                            newItems[itemName] = currentParent.data;
                            itemsChanged =
                                itemsChanged || currentParent.data !== context.items[itemName];
                        }
                        currentParent = currentParent.parentLink;
                        --j;
                    }
                }
                // To reduce recalculations, replace the items only if there was any change
                if (itemsChanged) {
                    context.items = newItems;
                }
                if (lastIndex !== viewIndex) {
                    this._bodyContainer._viewContainerRef.move(viewRef, viewIndex);
                    this._renderedRowIndices.markMove(row.entry, row.type, viewIndex);
                }
            } else {
                const items: Record<string, any> = {};
                items["" + row.level] = row.entry;
                if (row.definition.levelId) items[row.definition.levelId] = row.entry;
                if (row.level) {
                    // If this is not top level item, link all the parents under their proper names (if defined)
                    let j = row.level - 1;
                    let currentParent = row.parentLink;
                    while (j >= 0) {
                        const itemName = this._itemNames[j];
                        if (itemName) items[itemName] = currentParent.data;
                        currentParent = currentParent.parentLink;
                        --j;
                    }
                }

                const context = new RowContext<any, any, any>(
                    row.entry,
                    items,
                    row.level ? row.parentLink.data : null,
                    row.level,
                    row.prevLevel,
                    row.nextLevel,
                    row.index,
                    row.last,
                    row.globalIndex,
                    row.definition && row.definition.children && !row.definition.children.hidden,
                    row.type
                );

                // As of today (angular6.0.3) it is not possible to specify injector for templates. This requires either passing everything explicitly through let,
                // or using special directive to hold the required data (the directive itself will be then injectable to elements in the template)
                // Hack courtersy of angular material
                LgPivotTableRowDirective._lastInstance = null;
                LgPivotTableRowDirective._lastInstanceCount = 0;
                const template = this._getTemplate(row.type, row.level);
                this._bodyContainer._viewContainerRef.createEmbeddedView(
                    template.template,
                    context,
                    viewIndex
                );
                if (LgPivotTableRowDirective._lastInstance) {
                    if (isDevMode()) {
                        if (LgPivotTableRowDirective._lastInstanceCount > 1) {
                            console.warn(
                                "Template for lgPivotTableRow/Separator/Footer should contain only 1 lg-pivot-table-row element!"
                            );
                        }
                    }
                    LgPivotTableRowDirective._lastInstance.context = context;
                    LgPivotTableRowDirective._lastInstance.definition = row.definition;
                    LgPivotTableRowDirective._lastInstance._expander = this;
                    LgPivotTableRowDirective._lastInstance = null;
                }
                this._renderedRowIndices.markInsert(row.type, viewIndex, 1);
            }
            newRowIndices.addRowIndex(row.entry, row.type, viewIndex);
            this._maxVisiblePx = row.offset;
            lastVisibleViewIndex = viewIndex;
        }

        this._minVisiblePx =
            firstIndex < this._flatList.length ? this._flatList[firstIndex].offset : 0;

        // Remove views no longer visible
        while (this._bodyContainer._viewContainerRef.length > lastVisibleViewIndex + 1) {
            this._bodyContainer._viewContainerRef.remove();
        }

        this._renderedRowIndices = newRowIndices;

        // update the total height
        this._renderer.setStyle(
            this._bodyHolder.nativeElement,
            "paddingTop",
            this._minVisiblePx + "px"
        );
        const totalHeight = this._totalHeight;
        // console.log( totalHeight, " !== ", this._lastHeight );
        if (totalHeight !== this._lastHeight) {
            this._renderer.setStyle(this._bodyHolder.nativeElement, "height", totalHeight + "px");
            this._lastHeight = totalHeight;
            this._scrollbar.setSizes(totalHeight, this._scrollerHeight);
            this._hasScrollbar = totalHeight > this._scrollerHeight;

            if (this._scrollOffsetSaved && this._filteredData && this._filteredData.length > 1)
                this._calculateScrollerSize();
            // console.log( 'setSizes( ', totalHeight, this._scrollerHeight, ");")
            /*
            const oldScrollerHeight = this._scrollerHeight;
            if ( this.timeout ) {
                this.$timeout.cancel( this.timeout );
            }
            this.timeout = this.$timeout( () => {
                this.timeout = null;
                this.calculateScrollerSize();
                if ( this.scrollerHeight != oldScrollerHeight ) {
                    this.scrollbar.setSizes( totalHeight, this.scrollerHeight );
                    this.update();
                }
            }, 0 );
            */
        }
        // console.log("Visible %o", newVisibleList.length);
        // console.log("Visible %o, min %o, max %o, firstIndex %o", newRowIndices.size, this._minVisiblePx, this._maxVisiblePx, firstIndex);
        // if (window.console) console.log("LgPivotTable: Update done in %d ms, visible %i", new Date() - t1, visibleList.length);
        // this.lgConsole.perf( "lgPivotTable: Update done in %d ms, visible %i", Date.now() - t1, this.visibleList.length );
        this._changeDetectorRef.markForCheck();
        scheduleChangeDetection();
    }

    // ---------------------------------------------------------------------------------------------
    //  Find first visible row, using binary search
    // ---------------------------------------------------------------------------------------------
    private _findFirstVisibleRow(offset: number): number {
        let start = 0;
        let end = this._flatList.length;
        if (start === end) return start;

        // find the highest index  that's still under the offset
        while (start < end - 1) {
            const middle = Math.floor((start + end) / 2);
            const rowOffset = this._flatList[middle].offset;
            if (rowOffset > offset) {
                end = middle;
            } else if (rowOffset === offset) {
                return middle;
            } else {
                start = middle;
            }
        }
        return start;
    }

    // ---------------------------------------------------------------------------------------------
    private _getTemplate(type: RowType, level: number): IRowTemplateBase | undefined {
        switch (type) {
            case RowType.Row:
                return this.rowTemplates.get(level);
            case RowType.Separator:
                return this.rowSeparatorTemplates.get(level);
            case RowType.RowFooter:
                return this.rowFooterTemplates.get(level);
        }
        return undefined;
    }

    // ---------------------------------------------------------------------------------------------
    private _onScroll(position: number): void {
        this._calculateScrollerSize();
        const visualBufferPx = this._ensureRowsOutsideView * this._itemHeight;
        if (
            position - visualBufferPx >= this._minVisiblePx &&
            position + visualBufferPx + this._scrollerHeight < this._maxVisiblePx
        ) {
            this._renderer.setProperty(
                this._innerElement.nativeElement,
                "scrollTop",
                this._scrollOffset
            );
            // console.log( "@ ", this._scrollOffset, this._innerElement.nativeElement.scrollTop );
            return;
        }
        this._update();
        this._renderer.setProperty(
            this._innerElement.nativeElement,
            "scrollTop",
            this._scrollOffset
        );
        // console.log( this._scrollOffset, this._innerElement.nativeElement.scrollTop );
    }

    // ---------------------------------------------------------------------------------------------
    _onBodyScroll(): void {
        const offset = this._innerElement.nativeElement.scrollTop;
        if (offset === this._scrollOffset) {
            // console.log( "Same ", offset );
            return;
        }
        // console.log( "->", offset );
        this._scrollbar.position(offset);
    }

    // ---------------------------------------------------------------------------------------------
    private _onSizeObserved(_height: number): void {
        // console.log( "onSize", height );
        // this._renderer.setStyle( this._innerElement.nativeElement, "height", height + "px" );

        this._calculateScrollerSize();
        this._renderer.setProperty(
            this._innerElement.nativeElement,
            "scrollTop",
            this._scrollOffset
        );
        this._update();
        this._scrollbar.recalculate();
        this._scrollbar.setSizes(null, this._scrollerHeight);
        this._hasScrollbar = this._lastHeight > this._scrollerHeight;
    }

    // ---------------------------------------------------------------------------------------------
    //  Calculate current height and state of the scroller area
    // ---------------------------------------------------------------------------------------------
    private _calculateScrollerSize(ensureVisibleOffset?: number): void {
        // todo: make sure this works in elements without maxHeight
        // uggly hack, solve!
        const maxHeight = parseInt(this._elementRef.nativeElement.style.maxHeight);
        // scrollerMaxHeight = maxHeight && !isNaN(maxHeight);
        this._scrollerHeight = maxHeight || this._elementRef.nativeElement.clientHeight;
        // console.error( "New scroller height", this._scrollerHeight );
        // console.log( "Sizes: ", this._elementRef.nativeElement.style.maxHeight, maxHeight, this._scrollerHeight, this._elementRef.nativeElement.clientHeight );

        let offset = this._scrollbar.position();
        if (
            this._filteredData &&
            (ensureVisibleOffset != null ||
                (this._scrollOffsetSaved != null && this._filteredData.length > 1))
        ) {
            const scrollOffset = ensureVisibleOffset ?? this._scrollOffsetSaved;
            //            const maxScrollPosition = this._scrollbar.documentLength() - this._scrollerHeight;
            const maxScrollPosition = this._totalHeight - this._scrollerHeight;
            offset = Math.max(0, Math.min(scrollOffset, maxScrollPosition));
            // console.log( "Scroll ", offset, scrollOffset, maxScrollPosition );
            this._scrollbar.position(offset);

            if (this._scrollOffsetSaved != null) {
                setTimeout(() => {
                    this._scrollOffsetSaved = null;
                }, 200);
            }
        }
        this._scrollOffset = offset;
    }

    private _saveFirstRowPosition(): void {
        if (this.filteredData && this.filteredData.length === 1) {
            const itemFound = this._flatList.find(item => item.entry === this.filteredData[0]);
            this._scrollOffsetSaved = itemFound ? itemFound.offset : this._scrollOffset;
        }
    }

    private _toggleExpand(item: any, state?: boolean): void {
        if (item.$expanded && state !== true) {
            if (!item.$expanded) return;
            item.$expanded = false;
        } else {
            if (item.$expanded) return;
            item.$expanded = true;
        }
    }

    private _itemToggleChanged(item: any): void {
        this.onToggle.next({
            item,
            expanded: item.$expanded,
            maxVisibleLevel: this._maxVisibleLevel
        });
    }

    private _calculateOffsetForVisibility(
        position: VerticalPosition,
        entry?: IFlatListEntry
    ): number {
        if (!entry) {
            switch (position) {
                case "top":
                    return 0;
                case "middle":
                    return (this._totalHeight - this._scrollerHeight) / 2;
                default:
                    return this._totalHeight;
            }
        }

        const { offset } = entry;
        switch (position) {
            case "top":
                return offset;
            case "middle":
                return offset - this._scrollerHeight / 2 + this._itemHeight;
            default:
                return offset - this._scrollerHeight + this._itemHeight;
        }
    }

    private _getNodesOnThePath(ids: LgPrimitive[]): any[] {
        let definition = this.definition;
        let nodes = this.filteredData;
        const result = [];

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const propertyNameForLevel = definition.column;
            const theNode = nodes.find(node => node[propertyNameForLevel] === id);
            if (!theNode) {
                console.warn(
                    `There's no node where "n.${propertyNameForLevel} ==='${id}'" (current 0-based depth is '${i}')`
                );
                return [];
            }

            definition = definition.children;
            nodes = theNode[definition?.filteredStore];
            result.push(theNode);
        }

        return result;
    }

    private _focusRowInput(rowIndex: number, inputIndex: number): void {
        // postponed the execution, because otherwise it's not working correctly for a case
        // when we're trying to focus a row that was just added to the DOM
        // (Promise.resolve().then(() => /* ... */) is not enough)
        setTimeout(() => {
            const allRows: HTMLElement[] = Array.from(
                this._elementRef.nativeElement.querySelectorAll(".table__row")
            );

            const targetRow = allRows.find(
                r => r.getAttribute("data-global-index") === "" + rowIndex
            );

            const inputs = targetRow.getElementsByTagName("input");

            if (inputs.length === 0) return;

            const targetInput = inputs[Math.min(inputs.length - 1, inputIndex)];

            if (targetInput) {
                this._renderer.addClass(targetRow, "lg-contains-focus");
                targetInput.focus();
            }
        }, 0);
    }
}
