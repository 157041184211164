<div [style.height.px]="height" [style.width.px]="width">
    <div
        #chartWithLegend
        class="lg-histogram-chart__wrapper"
        [class.lg-histogram-chart__wrapper--flex-row]="legendOptions.position === 'right'"
        [class.lg-histogram-chart__wrapper--flex-column]="legendOptions.position === 'bottom'"
        style="width: 100%; height: 100%"
    >
        <div
            #chartHolder
            [style.height.px]="legendOptions.visible ? _svgHeight + margin.top : _svgHeight"
            [style.marginTop.px]="margin.top"
            [style.marginLeft.px]="margin.left"
        ></div>

        <lg-chart-legend
            *ngIf="legendOptions.visible"
            style="align-self: center"
            [style.paddingBottom.px]="_legendPaddingBottom"
            [className]="legendOptions.className"
            [definition]="_legendDefinition"
            [position]="legendOptions.position"
            [cropLongNames]="legendOptions.cropLongNames"
            [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition"
            [width]="_legendWidth"
            (onClick)="_onLegendItemClick($event)"
        >
        </lg-chart-legend>
    </div>
</div>

<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <div>
        <div class="lg-d3-tooltip__tip-name">
            {{ xAxisLabel }}: {{ xAxisLabelFormatter(context.current.xValue) }}
        </div>
        <table *ngIf="context.hasData; else noData" class="lg-chart__tooltip__table">
            <tr *ngIf="context.current.yValue != null" class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__color_column">
                    <span [style.background]="_groupColors('0')" [style.opacity]="1"></span>
                </td>
                <td class="lg-chart__tooltip__table__item__color_column">
                    {{ mainLabel }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _yAxisFormatter.format(context.current.yValue) }}
                </td>
            </tr>
            <ng-container *ngFor="let reference of references">
                <tr
                    *ngIf="context.current[reference.referenceKey] != null"
                    class="lg-chart__tooltip__table__item"
                >
                    <td class="lg-chart__tooltip__table__item__color_column">
                        <span [style.background]="reference.colorHex" [style.opacity]="1"></span>
                    </td>
                    <td class="lg-chart__tooltip__table__item__color_column">
                        {{ reference.name }}
                    </td>
                    <td class="lg-chart__tooltip__table__item__name_column">
                        {{ _yAxisFormatter.format(context.current[reference.referenceKey]) }}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</ng-template>

<ng-template #noData>{{ "FW.NO_DATA_AVAILABLE" | lgTranslate }}</ng-template>
