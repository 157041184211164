import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "lg-chat-bubble",
    templateUrl: "./lg-chat-bubble.component.html"
})
export class LgChatBubbleComponent {
    @Output() readonly chatToggled = new EventEmitter();

    _toggleChat(): void {
        this.chatToggled.emit();
    }
}
