import ldUniq from "lodash-es/uniq";
import ldFilter from "lodash-es/filter";
import ldEach from "lodash-es/each";
import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";

import { Observable, Subject } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IApplicationEventTracer } from "@logex/framework/core";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { LgFormatTypePipe } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "./filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "./filter-renderer";

export interface IVisibleDefinitionGroup {
    index: number;
    name: string;
    filters: IFilterDefinition[];
}

export type IFilterList = Record<string, any>;

/**
 * FilterSet is a class that gathers (ideally) all the filters on one page. In cooperation with the filterSetList
 * and filterSetPreview directives, the filters can be rendered.
 */
export class LgFilterSet<
    DefinitionsType extends Record<string, IFilterDefinition> = Record<string, IFilterDefinition>,
    FiltersType extends IFilterList = IFilterList
> {
    readonly onChanged = new Subject<Array<keyof DefinitionsType> | undefined>();
    readonly visibleGroups$: Observable<IVisibleDefinitionGroup[]>;
    visibleDefinitions: IFilterDefinition[];
    potentiallyVisiblePreviewDefinitions: IFilterDefinition[];
    visibleGroups: IVisibleDefinitionGroup[];
    filters: FiltersType;

    private definitionLookup: DefinitionsType;
    private groupIndexLookup: Record<string, number>;
    private hasGroups: boolean;
    private readonly _visibleGroupsSubject = new Subject<IVisibleDefinitionGroup[]>();

    // ----------------------------------------------------------------------------------
    constructor(
        public definitions: IFilterDefinition[],
        //        logexPivot: Pivots.LogexPivotService,
        public lgTranslate: LgTranslateService,
        public trackingService: IApplicationEventTracer,
        public context: any,
        filterTypes: Record<string, IFilterRendererFactory>,
        private _formatPipe: LgFormatTypePipe
    ) {
        (this.definitionLookup as any) = {};
        (this.filters as any) = {};
        this.visibleGroups$ = this._visibleGroupsSubject.asObservable();
        this._preprocess(filterTypes);
        this.updateVisible();
    }

    // ----------------------------------------------------------------------------------
    /**
     * Clear the specified filter (onChanged triggers are called)
     */
    clear(id: keyof DefinitionsType): this {
        const def = this.definitionLookup[id];
        if (def) {
            if (def.renderer.clear()) {
                this.triggerOnChanged(def);
            }
        }
        return this;
    }

    // ----------------------------------------------------------------------------------
    /**
     * Get the filter definition. Consult the code of the renderers to see which fields (if any)
     * can be patched at runtime. Please do not manipulate the IDs of the filters
     */
    getFilterDefinition<P extends keyof DefinitionsType>(id: P): DefinitionsType[P] {
        return this.definitionLookup[id];
    }

    // ----------------------------------------------------------------------------------
    /**
     * Get the filter renderer.
     */
    getRenderer<
        P extends keyof DefinitionsType,
        T extends IFilterRenderer = DefinitionsType[P]["renderer"]
    >(id: P): T {
        return this.definitionLookup[id].renderer as T;
    }

    // ----------------------------------------------------------------------------------
    /**
     * Returns true if the specified filter is active (i.e. filtering something)
     */
    isActive(id: keyof DefinitionsType): boolean {
        const def = this.definitionLookup[id];
        return def.renderer.active();
    }

    // ----------------------------------------------------------------------------------
    /**
     * Returns true if any filter is active
     */
    isAnyActive(): boolean {
        for (const filter of this.definitions) {
            if (filter.renderer.active()) return true;
        }
        return false;
    }

    // ----------------------------------------------------------------------------------
    /**
     * Serialize the state of all the active filters into a dictionary of strings
     */
    serialize(): Record<string, string> {
        const result: Record<string, string> = {};

        for (const filter of this.definitions) {
            if (!filter.renderer.active()) continue;
            const state = filter.renderer.serialize();
            if (state != null) {
                result[filter.id] = state;
            }
        }

        return result;
    }

    // ----------------------------------------------------------------------------------
    /**
     * Deserialize the state of all the filters. If clearAll is specified, the filters not stored in the
     * state dictionary are cleared.
     */
    deserialize(state: Record<string, string>, clearAll: boolean): void {
        const changedFilters = [];

        ldEach(state, (val, id) => {
            const definition = this.definitionLookup[id];
            if (!definition) return;

            if (definition.renderer.deserialize(val)) {
                if (definition.onChanged)
                    definition.onChanged.apply(definition.context || this, [definition.id]);
                changedFilters.push(definition.id);
            }
        });

        if (clearAll) {
            for (const definition of this.definitions) {
                if (state[definition.id] !== undefined) continue;
                if (definition.renderer.clear()) {
                    changedFilters.push(definition.id);
                    if (definition.onChanged)
                        definition.onChanged.apply(definition.context || this, [definition.id]);
                }
            }
        }

        if (changedFilters.length > 0 && this.onChanged)
            this.onChanged.next(ldUniq(changedFilters));
    }

    // ----------------------------------------------------------------------------------
    /**
     * Clear either all, or all main filters. onChanged triggers are called
     *
     * @mainlOnly specifies, if only main filters, or all should be cleared
     * @force specifies, if the clearWithOthers callback in filter definition should be ignored
     */
    clearAll(mainOnly?: boolean, force?: boolean): this {
        const changedFilters = [] as IFilterDefinition[];

        for (const d of this.definitions) {
            if (mainOnly && !d.main) continue;

            if (
                !force &&
                d.clearWithOthers &&
                !d.clearWithOthers.call(d.context || this.context || this, this as any, d)
            )
                continue;

            const currentChanged = d.renderer.clear();
            if (currentChanged) {
                changedFilters.push(d);
            }
        }

        if (changedFilters.length > 0) {
            for (const d of changedFilters) {
                if (d.onChanged) d.onChanged.apply(d.context || this, [d.id]);
            }

            if (this.onChanged) this.onChanged.next(changedFilters.map(f => f.id));
        }

        return this;
    }

    /**
     * Return list of the filters suitable for logex-xlsx. When no tags are
     * specified, return those currently visible. Otherwise, return all filters
     * conforming to the tags (ignoring visibility)
     */

    getExportDefinition(tags?: string | string[]): IFilterExportDefinition[] {
        let source: IFilterDefinition[] = [];
        if (tags == null) {
            source = this.visibleDefinitions;
        } else {
            if (!ldIsArray(tags)) tags = [tags];
            source = this._filterVisible(this.definitions);
        }
        const result: IFilterExportDefinition[] = [];
        source.forEach(d => {
            result.push(d.renderer.getExportDefinition());
        });
        return result;
    }

    // ----------------------------------------------------------------------------------
    /**
     * Trigger the onChanged event on the specified filter. This is used internally by the directives
     */
    triggerOnChanged(entry: IFilterDefinition | string): void {
        let def: IFilterDefinition;
        if (ldIsString(entry)) {
            def = this.definitionLookup[<string>entry];
        } else {
            def = entry;
        }

        if (def.filterType.toLowerCase() !== "selected") {
            this.trackingService.trackEvent("Filters", "Touched", def.id);
        }

        if (def.onChanged) {
            def.onChanged.apply(def.context || this, [def.id]);
        }
        if (this.onChanged) {
            this.onChanged.next([def.id]);
        }
    }

    // ----------------------------------------------------------------------------------
    /**
     * Update visibility of the filters, using the current tag set and the optional visibility callbacks.
     */
    updateVisible(): void {
        this.visibleDefinitions = this._filterVisible(this.definitions);

        if (!this.hasGroups) {
            this.visibleGroups = [
                {
                    index: 0,
                    name: "",
                    filters: this.visibleDefinitions
                }
            ];
        } else {
            this.visibleGroups = [];
            let lastIndex = -1;
            let lastGroup: IVisibleDefinitionGroup = null;
            for (const filter of this.visibleDefinitions) {
                const index = this.groupIndexLookup[filter.id];
                if (index !== lastIndex) {
                    lastGroup = {
                        index,
                        name: <string>filter.startGroup,
                        filters: []
                    };
                    this.visibleGroups.push(lastGroup);
                    lastIndex = index;
                }
                lastGroup.filters.push(filter);
            }
        }

        this.potentiallyVisiblePreviewDefinitions = this._filterPotentiallyVisiblePreviews(
            this.definitions
        );

        this._visibleGroupsSubject.next(this.visibleGroups);
    }

    // ----------------------------------------------------------------------------------
    private _filterVisible(source: IFilterDefinition[]): IFilterDefinition[] {
        return ldFilter(
            source,
            def =>
                !def.visible ||
                !!def.visible.call(def.context || this.context || this, this as any, def)
        );
    }

    // ----------------------------------------------------------------------------------
    private _filterPotentiallyVisiblePreviews(source: IFilterDefinition[]): IFilterDefinition[] {
        return ldFilter(
            source,
            def =>
                !def.previewPotentiallyVisible ||
                !!def.previewPotentiallyVisible.call(
                    def.context || this.context || this,
                    this as any,
                    def
                )
        );
    }

    // ----------------------------------------------------------------------------------
    //  Initial processing of the definitions
    // ----------------------------------------------------------------------------------
    private _preprocess(filterTypes: Record<string, IFilterRendererFactory>): void {
        let lastGroup: string = null;
        let groupIndex = 0;
        this.hasGroups = false;
        this.groupIndexLookup = {};

        for (const def of this.definitions) {
            if (!def.name) {
                if (def.nameLC) {
                    def.name = this.lgTranslate.translate(def.nameLC);
                } else {
                    def.name = def.id.substring(0, 1).toUpperCase() + def.id.substring(1);
                }
            }

            const lowId = def.id.substring(0, 1).toLowerCase() + def.id.substring(1);
            const idBase = lowId.replace(/ /g, "");

            (this.definitionLookup as Record<string, IFilterDefinition>)[def.id] = def;

            if (!def.storage) {
                def.storage = idBase;
            }

            if (!def.label) {
                def.label = def.name;
            }

            if (!def.context) def.context = this.context;

            if (def.main == null) def.main = true;

            if (def.startGroup || def.startGroupLC) {
                if (!def.startGroup && def.startGroupLC) {
                    def.startGroup = this.lgTranslate.translate(def.startGroupLC);
                }
                if (typeof def.startGroup === "string") {
                    if (def.startGroup !== lastGroup) {
                        lastGroup = def.startGroup;
                        ++groupIndex;
                    }
                } else {
                    def.startGroup = lastGroup = "";
                    ++groupIndex;
                }
                this.hasGroups = true;
            } else {
                def.startGroup = lastGroup;
            }
            this.groupIndexLookup[def.id] = groupIndex;

            if (!def.filterType) def.filterType = "combo";

            if (!def.tooltip && def.tooltipLC) {
                def.tooltip = this.lgTranslate.translate(def.tooltipLC);
            }

            const factory = filterTypes[def.filterType.toUpperCase()];
            if (!factory) {
                throw new Error("Unknown filter type " + def.filterType);
            }

            def.renderer = factory.create(
                def,
                this.filters,
                this.definitions,
                this as any,
                this._formatPipe
            );
            def.renderer.createStorage();
        }
    }
}
